import React, { useState, useEffect } from "react";
import "../../stylesheets/finance-qr-modal.css";

const QRAssignForm = ({ modalProps, setModalState, getCampaignRef }) => {
  const {
    campaignName,
    amount,
    isEditable,
    isGlobal,
    isPayOnce,
    isPrivate,
    expiry,
    campaignStart,
    comments,
    qrType,
  } = modalProps;

  useEffect(() => {
    if (qrType === "P") {
      setModalState({
        ...modalProps,
        isPrivate: false,
        isPayOnce: false,
        isGlobal: false,
      });
    }
  }, [qrType]);

  useEffect(() => {
    if (isPrivate) {
      setModalState({
        ...modalProps,
        isPayOnce: false,
        isGlobal: false,
      });
    }
  }, [isPrivate]);

  return (
    <div className="create-qr-modal__body">
      <div className="create-qr-modal__section-title">QR Details</div>
      <div className="create-qr-modal__section--border-row">
        <div className="create-qr-modal__side-section--border">
          <table className="create-qr-modal__detail-table">
            <tbody>
              <tr>
                <td>Campaign Name:</td>
                <td>{campaignName}</td>
              </tr>
              <tr>
                <td>Amount:</td>
                <td>S$ {amount}</td>
              </tr>
              <tr>
                <td>Amount Editable:</td>
                <td>{isEditable ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>Start Date:</td>
                <td>{campaignStart ? campaignStart.toString() : "-"}</td>
              </tr>
              <tr>
                <td>Expiry:</td>
                <td>{expiry ? expiry.toString() : "-"}</td>
              </tr>
              <tr>
                <td>Reference:</td>
                <td>{getCampaignRef()}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="create-qr-modal__side-section--padding">
          <table className="create-qr-modal__detail-table">
            <tbody>
              <tr>
                <td>Comments:</td>
                <td>{comments}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="create-qr-modal__section-title">Assign QR</div>
      <div className="create-qr-modal__side-section">
        <div className="create-qr-modal__section">
          <div className="create-qr-modal__section-sub-title">QR Type</div>
          <select
            className="create-qr-modal__select"
            onChange={(e) =>
              setModalState({ ...modalProps, qrType: e.target.value })
            }
            value={qrType}
          >
            <option value="U">U (User)</option>
            /*{" "}
            {/* <option value="P">
              P (Product)
            </option> */}
          </select>
        </div>
        <div className="create-qr-modal__section">
          <div className="create-qr-modal__section-sub-title">
            Is Private Campaign
          </div>
          <div className="create-qr-modal__radio">
            <div className="create-qr-modal__radio-text">
              <input
                type="radio"
                className="create-qr-modal__radio-input"
                checked={isPrivate}
                onChange={() =>
                  setModalState({ ...modalProps, isPrivate: true })
                }
                disabled={qrType === "P"}
              />
              Yes
            </div>
            <div className="create-qr-modal__radio-text">
              <input
                type="radio"
                className="create-qr-modal__radio-input"
                checked={!isPrivate}
                onChange={() =>
                  setModalState({ ...modalProps, isPrivate: false })
                }
                disabled={qrType === "P"}
              />
              No
            </div>
          </div>
        </div>
        <div className="create-qr-modal__section">
          <div className="create-qr-modal__section-sub-title">
            Allowed to Only Pay Once
          </div>
          <div className="create-qr-modal__radio">
            <div className="create-qr-modal__radio-text">
              <input
                type="radio"
                className="create-qr-modal__radio-input"
                checked={isPayOnce}
                onChange={() =>
                  setModalState({ ...modalProps, isPayOnce: true })
                }
                disabled={qrType === "P" || isPrivate}
              />
              Yes
            </div>
            <div className="create-qr-modal__radio-text">
              <input
                type="radio"
                className="create-qr-modal__radio-input"
                checked={!isPayOnce}
                onChange={() =>
                  setModalState({ ...modalProps, isPayOnce: false })
                }
                disabled={qrType === "P" || isPrivate}
              />
              No
            </div>
          </div>
        </div>
        <div className="create-qr-modal__section">
          <div className="create-qr-modal__section-sub-title">
            Publish Outside of Parish / Organization
          </div>
          <div className="create-qr-modal__radio">
            <div className="create-qr-modal__radio-text">
              <input
                type="radio"
                className="create-qr-modal__radio-input"
                checked={isGlobal}
                onChange={() =>
                  setModalState({ ...modalProps, isGlobal: true })
                }
                disabled={qrType === "P" || isPrivate}
              />
              Yes
            </div>
            <div className="create-qr-modal__radio-text">
              <input
                type="radio"
                className="create-qr-modal__radio-input"
                checked={!isGlobal}
                onChange={() =>
                  setModalState({ ...modalProps, isGlobal: false })
                }
                disabled={qrType === "P" || isPrivate}
              />
              No
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRAssignForm;
