import React from "react";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DOMPurify from "dompurify";
import PillType from "../../massschedule/component/PillType";
import { getMapAllParishes } from "../services/allparishes";
import SmallDirections from "../../common/smallDirections";
import { getParishes } from "../../../services/parish";
import { getCommunionServiceNotes } from "../../../utils/misc";

function DisplayScheduleItem({
  details,
  selected,
  labelday,
  labeldate,
  separator,
  clickHandler,
}) {
  const { time, notes, type, lang, location, parish } = details;
  if (!selected) {
    return <></>;
  }
  const church = getMapAllParishes()[parish];
  let address;
  if (selected === "All Churches") {
    const parishObj = getParishes().filter((i) => i._id === parish);
    address = parishObj[0].address;
  }
  //check if communion service only
  const updatedNotes = getCommunionServiceNotes(details);
  return (
    <>
      {separator}
      {labelday !== "" && (
        <h4 className="pb-4">
          <span className="float-left text-danger">{labelday}</span>
          <span className="float-right">{labeldate}</span>
        </h4>
      )}
      <div
        style={{ border: "1px solid #dedede" }}
        className="shadow-sm roundcornernocolor btn btn-block my-3 py-3 pl-3"
      >
        <h4 className="float-left text-left mb-0">
          <span className="text-dark">{time}</span>
        </h4>
        <div className="float-right">
          <PillType type={type} />
        </div>
        <div className="clearfix"></div>
        {selected === "All Churches" && (
          <div className="text-left text-dark biggerfontsize">
            <AccountBalanceIcon className="text-danger normalsmallfontsize mr-2" />
            <span
              className="dangerunderline text-primary"
              onClick={() => clickHandler(church)}
            >
              {church}
            </span>
          </div>
        )}
        <div className="float-left text-left text-dark biggerfontsize">
          {lang} &bull; {location}
        </div>
        {!updatedNotes && selected === "All Churches" && (
          <div className="float-right">
            <SmallDirections address={address} />
          </div>
        )}
        <div className="clearfix"></div>
        <div className="float-left">
          {updatedNotes && (
            <p
              className="mb-0 text-left biggerfontsize"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(updatedNotes),
              }}
            ></p>
          )}
        </div>
        {notes && selected === "All Churches" && (
          <div className="float-right">
            <SmallDirections address={address} />
          </div>
        )}
        <div className="clearfix pb-1"></div>
      </div>
    </>
  );
}

export default DisplayScheduleItem;
