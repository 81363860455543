import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { getWebVersion } from "../../services/settings";
import { getLatestVersion } from "../../services/getversion";

class ReloadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      loaded: false,
      version: null,
    };
  }

  componentDidMount() {
    getLatestVersion().then((version) => {
      this.setState({ loaded: true, version: version });
    });
  }
  doReload = () => {
    window.location.reload();
  };

  render() {
    let show = this.state.show;
    if (this.state.loaded === false || this.state.version === null) {
      return null;
    }
    const webversion = getWebVersion();
    const { icon, title, text, label, version } = this.state.version;
    if (webversion === version) {
      return null;
    } else {
      show = true;
    }
    console.log(`Latest version: ${version} Web Version: ${webversion}`);

    return (
      <Modal
        show={show}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
      >
        <h4 className="text-danger pl-3 pt-3 pr-2">{title}</h4>
        <Modal.Body>
          <div className="text-center mb-4">
            <span
              role="img"
              aria-label="celebrate"
              style={{ fontSize: "40px" }}
            >
              {icon}
            </span>
            <br />
            {text}
          </div>
        </Modal.Body>
        <Button
          variant="danger"
          onClick={this.doReload}
          className="roundcornernocolorbutton mb-4 mx-4"
        >
          {label}
        </Button>
      </Modal>
    );
  }
}

export default ReloadModal;
