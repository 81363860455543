import fire from "./fire";

export async function getLatestVersion() {
  const db = fire.firestore();
  const version = await db.doc(`version/web`).get();

  if (version && version.exists) {
    const data = version.data();
    if (data?.icon && data?.text && data?.title && data?.label) {
      return data;
    }
    return {
      version: data?.version,
      title: "New Update Available!",
      icon: "🎉",
      label: "Get Update",
      text: "We have a new update for you!",
    };
  } else {
    return "1-00";
  }
}
