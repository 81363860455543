import React, { useState, useRef, useEffect } from "react";

import { isEnableStrongPassword } from "../../services/settings";
import PasswordChecklist from "react-password-checklist";

const ENABLE_STRONG_PASSWORD = isEnableStrongPassword();

function StrongPasswordChecklist({ password = "", validHandler }) {
  const [isValid, setIsValid] = useState(false);
  const prevIsValidRef = useRef();

  useEffect(() => {
    const prevIsValid = prevIsValidRef.current;

    // Only call validHandler if isValid has changed
    if (isValid !== prevIsValid) {
      validHandler(isValid);
    }

    // Store current isValid value in ref
    prevIsValidRef.current = isValid;
  }, [isValid]);

  return ENABLE_STRONG_PASSWORD && password?.trim()?.length >= 1 ? (
    <div className="mb-4">
      <PasswordChecklist
        rules={["minLength", "specialChar", "number", "capital"]}
        minLength={8}
        value={password?.trim()}
        onChange={(valid) => {
          setIsValid(valid);
        }}
      />
    </div>
  ) : null;
}

export default StrongPasswordChecklist;
