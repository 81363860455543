import { StatusDictionary, RegistrationStatus } from "../hooks/hooks";

/** @type {RegistrationStatus[]} */
const registrationStatusEnum = [
  "unregistered",
  "accepted",
  "pending",
  "rejected",
  "withdrawn",
  "transferred",
];

/** @param {RegistrationStatus} status */
const registrationStatusNameToStatus = (status = "") => {
  const index = registrationStatusEnum.findIndex(
    (status_) => status_ === status
  );

  return typeof index === "number" ? index - 1 : -1;
};

/** @param {number} id */
const registrationStatusToStatusName = (id = -1) =>
  registrationStatusEnum[parseInt(id) + 1];

// TODO: Uncomment options for Dec release
/**
 * @type {StatusDictionary<"empty" | "unregistered" | RegistrationStatus>}
 */
const guardianRegistrationStatusDictionary = {
  empty: {
    message: "Please select a child above!",
    userLabel: "No Child Selected",
    adminLabel: "",
    pages: ["guardianControl", "home"],
    bg: "light",
    text: "dark",
  },
  unregistered: {
    message: "Your child is not currently enrolled in any parish.",
    userLabel: "Unregistered",
    adminLabel: "",
    pages: ["guardianControl", "registrationForm", "home"],
    bg: "dark",
    text: "light",
  },
  accepted: {
    message: "Your child is currently enrolled!",
    userLabel: "Accepted",
    adminLabel: "Accepted",
    pages: [
      "guardianControl",
      "classFund",
      "contactUpdate",
      "parishTransfer",
      "confirmation",
      "home",
    ],
    bg: "success",
    text: "light",
  },
  transferred: {
    message: "Your child is in a parish outside the system!",
    userLabel: "Transferred",
    adminLabel: "Transferred",
    pages: ["guardianControl", "registrationForm", "home"],
    bg: "warning",
    text: "dark",
  },
  pending: {
    message: "Your child's application is being processed!",
    userLabel: "Pending",
    adminLabel: "Pending",
    pages: [
      "guardianControl",
      // "registrationReview",
      "home",
    ],
    bg: "secondary",
    text: "light",
  },
  rejected: {
    message: "Your child's application was not approved.",
    userLabel: "Unsuccessful",
    adminLabel: "Rejected",
    pages: ["guardianControl", "registrationForm", "home"],
    bg: "danger",
    text: "light",
  },
};

/**
 * @type {StatusDictionary<"unregistered" | RegistrationStatus>}
 */
const childRegistrationStatusDictionary = {
  unregistered: {
    message: "You're not currently enrolled in any parish.",
    userLabel: "Unregistered",
    adminLabel: "Unregistered",
    pages: ["wardControl", "home"],
    bg: "light",
    text: "dark",
  },
  accepted: {
    message: "You're currently enrolled!",
    userLabel: "Accepted",
    adminLabel: "Accepted",
    pages: ["wardControl", "home"],
    bg: "success",
    text: "light",
  },
  transferred: {
    message: "You are transferred!",
    userLabel: "Transferred",
    adminLabel: "Transferred",
    pages: ["wardControl", "home"],
    bg: "success",
    text: "light",
  },
  pending: {
    message: "Your application is being processed!",
    userLabel: "Pending",
    adminLabel: "Pending",
    pages: ["wardControl", "home"],
    bg: "secondary",
    text: "light",
  },
  rejected: {
    message: "Your application was not approved.",
    userLabel: "Unsuccessful",
    adminLabel: "Rejected",
    pages: ["wardControl", "home"],
    bg: "danger",
    text: "light",
  },
};

function isValidPage({ isGuardian, statusName, page }) {
  return isGuardian
    ? page === "menu" ||
        !!guardianRegistrationStatusDictionary[statusName].pages.includes(page)
    : page === "menu" ||
        !!childRegistrationStatusDictionary[statusName].pages.includes(page);
}

export {
  registrationStatusNameToStatus,
  registrationStatusToStatusName,
  guardianRegistrationStatusDictionary,
  childRegistrationStatusDictionary,
  isValidPage,
};
