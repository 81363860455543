import React, { Component } from "react";
import { Button, Card } from "react-bootstrap";
import { Prompt } from "react-router-dom";
// import fire from "../../../services/fire";

import LinkButton from "../../blocks/linkButtonBlock";
import Header from "../../common/header";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import Select from "../../common/select";

import { logEvent } from "../../../services/log";
import { getParishes, getParishLinkById } from "../../../services/parish";
import { userLogout, getCurrentUserData } from "../../../services/user";

import PreRegistration from "../component/preRegistration";
import PreRegistrationForm from "../component/preRegistrationForm";
import RegistrationFormBasic from "../component/registrationFormBasic";
import RegistrationFormBaptism from "../component/registrationFormBaptism";
import RegistrationFormEducation from "../component/registrationFormEducation";
import RegistrationFormMiscellaneous from "../component/registrationFormMiscellaneous";
import RegistrationFormMother from "../component/registrationFormMother";
import RegistrationFormFather from "../component/registrationFormFather";
import RegistrationFormContact from "../component/registrationFormContact";
import RegistrationFormAcknowledgement from "../component/registrationFormAcknowledgement";
import RegistrationReview from "../component/registrationReview";

import {
  getDraftFromDB,
  saveDraftToDB,
  getParishesFromDB,
  createNewSubmission,
  enterSubmission,
} from "../services/catechismRegistration";
import { detectedParishNameToId } from "../services/resources";

import "../../common/stylesheets/utils.css";

class CatechismPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formStatus: "notice",
      formState: {},
      user: props.user,
      data: {
        userid: "",
        fullname: "",
        email: "",
        mobile: "+65",
        parish: 0,
        parishcode: "",
        dob: "",
      },
      parishes: [],
      selectedParish: "0",
      loading: true,
      preRegistrationDetails: {},
      registrationBasicDetails: {},
      registrationBaptismDetails: {},
      registrationEducationDetails: {},
      registrationMiscellaneousDetails: {},
      registrationMothersDetails: {},
      registrationFathersDetails: {},
      registrationContactDetails: {},
      registrationAcknowledgementDetails: {},
      isSaving: false,
      draft: {},
      parishesFromDB: [],
      isGettingParishes: false,
      isRetrievingData: true,
      isCreatingNewSubmission: false,
      isEnteringSubmission: false,
      parishEmail: "",
    };
  }

  async componentDidMount() {
    await this.initGetCurrentUserData();
    await this.initGetParishes();
    // await this.initStoreParishes();
    await this.initGetParishesFromDB();

    setTimeout(async () => {
      await this.initGetDraft();
    }, 1000);

    window.scrollTo(0, 0);
    window.addEventListener("beforeunload", this.handleUnload);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isSaving, isCreatingNewSubmission, isEnteringSubmission } =
      this.state;

    if (isSaving !== prevState.isSaving) {
      if (isSaving) {
        this.saveToStorage();
      } else {
        this.initGetDraft();
      }
    }

    if (isCreatingNewSubmission !== prevState.isCreatingNewSubmission) {
      if (isCreatingNewSubmission) {
        this.handleCreateNewSubmission();
      } else {
        this.initGetDraft();
      }
    }

    if (isEnteringSubmission !== prevState.isEnteringSubmission) {
      if (isEnteringSubmission) {
        this.handleEnterSubmission();
      } else {
        this.initGetDraft();
      }
    }

    // if (isGettingParishes && isGettingParishes !== prevState.isGettingParishes) {
    // 	this.initGetParishesFromDB();
    // }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUnload);
  }

  handleUnload = (e) => {
    if (
      this.state.formStatus.startsWith("input") ||
      this.state.formStatus === "review"
    ) {
      e.preventDefault();
      e.returnValue = "";

      const existingState = JSON.parse(
        localStorage.getItem("registrationFormState")
      );
      localStorage.setItem(
        "registrationFormState",
        JSON.stringify({
          ...existingState,
          ...this.state.formState,
        })
      );

      return "";
    }
  };

  initGetCurrentUserData = async () => {
    //retrieve data from firestore
    try {
      const uid = this.state.user.uid;
      const user = await getCurrentUserData(uid);

      if (user) {
        const { userid, fullname, email, mobile, parish, dob } = user;

        if (!parish) {
          this.props.history.push("/account/setup");
          return;
        }
        const parishcode = getParishLinkById(parish);

        this.setState({
          data: {
            userid,
            fullname,
            email,
            mobile,
            parish,
            parishcode,
            dob,
          },
          loading: false,
        });
      }
    } catch (error) {
      console.log(error);
      console.error("User not yet created");
      this.setState({ loading: false });
    }
  };

  initGetParishes = () => {
    const parishes = getParishes();

    this.setState({
      parishes,
    });
  };

  initGetDraft = async () => {
    try {
      const { parishesFromDB } = this.state;
      const uid = this.state.user.uid;
      const draft = await getDraftFromDB(uid);
      const finalDraft = { ...draft[0] };
      const newState = { ...this.state };

      let coordinatorEmail = "";

      if (finalDraft.selectedParish && parishesFromDB.length) {
        const [item] = parishesFromDB.filter(
          (g) => g.id === finalDraft.selectedParish
        );

        if (item.id) {
          finalDraft.selectedParishName = item.parish;
          coordinatorEmail = item.coordinatorEmail;
        }
      }

      // console.log("WOW initGetDraft", finalDraft);

      if (draft.length) {
        newState.draft = finalDraft;
      }

      if (finalDraft.formStatus > 0 || finalDraft.formStatus === -1) {
        newState.formStatus = "review";
      }

      newState.isRetrievingData = false;
      newState.preRegistrationDetails.coordinatorEmail = coordinatorEmail;

      this.setState(newState);
    } catch (e) {
      console.log("initGetDraft::error:", e);
    }
  };

  // initStoreParishes = async() => {
  // 	try {
  // 		await storeParishes();

  // 		this.setState({ isGettingParishes: true });
  // 	} catch (e) {
  // 		console.log("initStoreParishes:error:", e);
  // 	}
  // }

  initGetParishesFromDB = async () => {
    try {
      const { parishes } = this.state;
      const uid = this.state.user.uid;
      const user = await getCurrentUserData(uid);
      const parishesFromDB = await getParishesFromDB();
      const finalParishes = [];

      let parishEmail = "",
        selectedParish = "0";

      parishesFromDB.forEach((item) => {
        if (item.isActive) {
          finalParishes.push(item);
        }

        const parishId = detectedParishNameToId(item.parish);

        if (parishId.toString() === user.parish.toString()) {
          parishEmail = item.coordinatorEmail;
          selectedParish = parishId.toString();

          if (!parishEmail) {
            const [parish] = parishes.filter(
              (g) => g._id === parseInt(parishId)
            );

            parishEmail = parish.email;
          }
        }
      });

      // console.log("WOW initGetParishesFromDB", finalParishes);

      this.setState({
        parishesFromDB: finalParishes,
        isGettingParishes: false,
        parishEmail,
        selectedParish,
      });
    } catch (e) {
      console.log("initGetParishesFromDB::error:", e);
    }
  };

  doSubmit = async () => {
    try {
      const data = this.props.user;
      logEvent("logout_click", { email: data.email });
      await userLogout();
      window.location.reload();

      // signed out
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  handleChange = ({ currentTarget: input }) => {
    const { parishes, parishesFromDB } = this.state;
    const [parish] = parishes.filter((g) => g._id === parseInt(input.value));

    let parishEmail = "";

    if (parish._id) {
      parishesFromDB.forEach((item) => {
        const parishId = detectedParishNameToId(item.parish);

        if (parishId.toString() === parish._id.toString()) {
          parishEmail = item.coordinatorEmail;
        }
      });

      if (!parishEmail) {
        parishEmail = parish.email;
      }
    }

    this.setState({
      selectedParish: input.value,
      parishEmail,
    });
  };

  contentCard() {
    const {
      data: { userid = "", dob = "" },
      parishes,
      selectedParish,
      parishEmail,
    } = this.state;

    if (!userid) {
      return null;
    }

    if (new Date().getFullYear() - parseInt(dob) >= 17) {
      return (
        <>
          <Card className="my-5">
            <Card.Body className="p-4">
              <div className="w-100 text-left">
                Hello and peace be with you!
              </div>
              <br />
              <div className="w-100 text-left">
                Welcome to the Archdiocesan Catechetical Registration system.
              </div>
              <br />
              This is an online registration service for Catechesis for certain
              Parishes in Singapore. The list of supported Parishes you can
              register for is as shown below. If your Parish is not in this
              list, please contact your desired Parish directly to register,
              instead of doing so through this system.
              <br />
              <br />
              <strong>Opens 1 Oct</strong>
              <br />
              &bull; Church of Christ the King
              <br />
              &bull; Church of Divine Mercy
              <br />
              &bull; Church of Our Lady Star of the Sea
              <br />
              &bull; Church of St Anthony
              <br />
              &bull; Church of St Bernadette
              <br />
              &bull; Church of St Francis Xavier
              <br />
              &bull; Church of St Mary of the Angels
              <br />
              &bull; Church of St Vincent de Paul
              <br />
              &bull; Church of the Holy Spirit
              <br />
              <strong>Opens 15 Oct</strong>
              <br />
              &bull; Church of St Teresa
              <br />
              &bull; Church of the Risen Christ
              <br />
              <strong>Opens 19 Oct</strong>
              <br />
              &bull; St Joseph's Church (Bukit Timah)
              <br />
              <br />
              <p className="text-danger">
                If your desired Parish is in the list, please click the{" "}
                <strong>Logout</strong> button below, then{" "}
                <strong>Login</strong> with the child's account.
              </p>
              <p className="text-danger">
                If the child does not have an account, please select the{" "}
                <strong>Logout</strong> button below, then{" "}
                <strong>Sign Up</strong> for an account for him/her.
              </p>
            </Card.Body>
          </Card>
          <Button
            variant="outline-primary"
            className="btn-block mb-3"
            size="lg"
            style={{ border: "1px solid #dddddd" }}
            onClick={this.doSubmit}
          >
            Logout
          </Button>
        </>
      );
    } else if (new Date().getFullYear() - parseInt(dob) >= 9) {
      return (
        <>
          <Card className="my-5">
            <Card.Body className="p-4">
              <h5 className="text-danger">
                <strong>Oh no, </strong>
                looks like your child may need to sign-up for a bridging
                programme
              </h5>
              <br />
              Please select the parish you would like to register at and contact
              the Catechetical coordinator
              <br />
              <br />
              {parishes.length ? (
                <Select
                  name={"selectedParish"}
                  value={selectedParish}
                  options={parishes}
                  onChange={this.handleChange}
                />
              ) : null}
              <br />
              <h4>{parishEmail}</h4>
              <br />
            </Card.Body>
          </Card>
          <Button
            variant="outline-primary"
            className="btn-block mb-3"
            size="lg"
            style={{ border: "1px solid #dddddd" }}
            onClick={this.doSubmit}
          >
            Logout
          </Button>
        </>
      );
    }
  }

  setFormStatus = (formStatus) => this.setState({ formStatus });

  proceedToNextStep = (type, next) => (details) => {
    this.setState({
      [type]: details,
      formStatus: next,
      isSaving: true,
    });

    window.scrollTo(0, 0);
  };

  handleSaveAsDraft = (type) => (details) => {
    this.setState({
      [type]: details,
      isSaving: true,
    });

    alert("Your form has been saved as draft.");
  };

  saveToStorage = async (type = "") => {
    try {
      const {
        data: { userid, fullname },
        draft,
        formStatus,
      } = this.state;
      const {
        preRegistrationDetails = {},
        registrationBasicDetails = {},
        registrationBaptismDetails = {},
        registrationEducationDetails = {},
        registrationMiscellaneousDetails = {},
        registrationMothersDetails = {},
        registrationFathersDetails = {},
        registrationContactDetails = {},
        registrationAcknowledgementDetails = {},
      } = this.state;

      let finalFormStatus = 0;

      if (formStatus === "review") {
        finalFormStatus = 1;

        if (registrationBaptismDetails.isBaptized.toString() !== "true") {
          finalFormStatus = 4;
        }
      }

      const docData = {
        ...preRegistrationDetails,
        ...registrationBasicDetails,
        ...registrationBaptismDetails,
        ...registrationEducationDetails,
        ...registrationMiscellaneousDetails,
        ...registrationMothersDetails,
        ...registrationFathersDetails,
        ...registrationContactDetails,
        ...registrationAcknowledgementDetails,
        submittedBy: userid,
        submittedByName: fullname,
        id: draft.id,
        createdAt: draft.createdAt,
        formStatus: finalFormStatus,
      };

      await saveDraftToDB(docData);

      this.setState({
        isSaving: false,
      });
    } catch (e) {
      console.log("saveToStorage::error:", e);
    }
  };

  setCreatingNewSubmission = () =>
    this.setState({ isCreatingNewSubmission: true });

  setEnteringSubmission = () => this.setState({ isEnteringSubmission: true });

  handleCreateNewSubmission = async () => {
    try {
      const {
        data: { userid, fullname },
        draft,
      } = this.state;
      const docData = {
        submittedBy: userid,
        submittedByName: fullname,
        formStatus: 0,
      };

      await createNewSubmission(docData, draft.id);

      setTimeout(() => {
        this.setState({
          isCreatingNewSubmission: false,
          preRegistrationDetails: {},
          registrationBasicDetails: {},
          registrationBaptismDetails: {},
          registrationEducationDetails: {},
          registrationMiscellaneousDetails: {},
          registrationMothersDetails: {},
          registrationFathersDetails: {},
          registrationContactDetails: {},
          registrationAcknowledgementDetails: {},
          formStatus: "input",
        });
      }, 3000);
    } catch (e) {
      console.log("handleCreateNewSubmission::error:", e);
    }
  };

  handleEnterSubmission = async () => {
    try {
      const {
        data: { userid, fullname },
        draft,
      } = this.state;
      const docData = {
        submittedBy: userid,
        submittedByName: fullname,
      };
      const docData2 = {
        userId: draft.submittedBy,
        dob: draft.dob,
        isBaptized: draft.isBaptized,
        isBaptizedInSG: draft.isBaptizedinSG,
        churchOfBaptism: draft.churchOfBaptism,
        dateOfBaptism: draft.dateOfBaptism,
        mainContact: draft.mainContact,
        userRegistrationId: draft.userRegistrationId || "",

        motherName: draft.motherName,
        motherEmail: draft.motherEmail,
        motherReligion: draft.motherReligion,
        motherMobileNo: draft.motherMobileNo,
        motherMaritalStatus: draft.motherMaritalStatus,

        fatherName: draft.fatherName,
        fatherEmail: draft.fatherEmail,
        fatherReligion: draft.fatherReligion,
        fatherMobileNo: draft.fatherMobileNo,
        fatherMaritalStatus: draft.fatherMaritalStatus,

        guardianName: draft.guardianName,
        guardianEmail: draft.guardianEmail,
        guardianMobileNo: draft.guardianMobileNo,

        selectedParishName: draft.selectedParishName,
        coordinatorEmail: draft.coordinatorEmail,
      };

      await enterSubmission(docData, draft.id, docData2);

      setTimeout(() => {
        this.setState({
          isEnteringSubmission: false,
          preRegistrationDetails: {},
          registrationBasicDetails: {},
          registrationBaptismDetails: {},
          registrationEducationDetails: {},
          registrationMiscellaneousDetails: {},
          registrationMothersDetails: {},
          registrationFathersDetails: {},
          registrationContactDetails: {},
          registrationAcknowledgementDetails: {},
          draft: {},
        });
      }, 3000);
    } catch (e) {
      console.log("handleEnterSubmission::error:", e);
    }
  };

  handlePressBack = (formStatus) => () => this.setState({ formStatus });

  renderProgressBar = () => {
    const { formStatus } = this.state;

    let divBackground = "#e9ecef",
      fontColor = "#000",
      divName1 = "",
      divName2 = "",
      divName3 = "",
      text = "";

    switch (formStatus) {
      case "input2":
        text = "Step 1 of 4: Enter the child's basic details";
        break;
      case "input3":
        text = "Step 2 of 4: Enter the child's Baptism details";
        divName1 = "progress-bar";
        break;
      case "input4":
        text = "Step 3 of 4: Enter the child's education details";
        divName1 = "progress-bar";
        divName2 = "progress-bar";
        break;
      case "input5":
        text = "Step 4 of 4: Enter miscellaneous details";
        divName1 = "progress-bar";
        divName2 = "progress-bar";
        divName3 = "progress-bar";
        break;
      case "input6":
        text = "Step 1 of 4: Enter the mother's details";
        divBackground = "#007bff";
        fontColor = "#fff";
        break;
      case "input7":
        text = "Step 2 of 4: Enter the father's details";
        divBackground = "#007bff";
        fontColor = "#fff";
        divName1 = "progress-bar";
        break;
      case "input8":
        text = "Step 3 of 4: Enter the main contact's details";
        divBackground = "#007bff";
        fontColor = "#fff";
        divName1 = "progress-bar";
        divName2 = "progress-bar";
        break;
      case "input9":
        text = "Step 4 of 4: Acknowledge terms & conditions";
        divBackground = "#007bff";
        fontColor = "#fff";
        divName1 = "progress-bar";
        divName2 = "progress-bar";
        divName3 = "progress-bar";
        break;
      default:
    }

    return (
      <>
        <br />
        <div
          className="w-100 d-flex flex-row"
          style={{ height: "50px", lineHeight: "initial" }}
        >
          <div
            className="d-flex flex-column justify-content-center align-items-center rounded-left position-relative"
            style={{
              width: "calc(33.33% - 7.5px)",
              background: "#007bff",
              height: "50px",
              marginRight: "2.5px",
              zIndex: 3,
            }}
          >
            <div
              style={{ color: "#fff", fontSize: "18px", marginLeft: "15px" }}
            >
              Child's
            </div>
            <div
              style={{ color: "#fff", fontSize: "14px", marginLeft: "15px" }}
            >
              Details
            </div>
            <div
              className="position-absolute"
              style={{
                width: 0,
                height: 0,
                borderTop: "25px solid transparent",
                borderBottom: "25px solid transparent",
                borderLeft: "20px solid #007bff",
                right: "-20px",
              }}
            />
          </div>
          <div
            className="d-flex flex-column justify-content-center align-items-center position-relative"
            style={{
              width: "calc(33.34% - 15px)",
              background: divBackground,
              height: "50px",
              marginRight: "2.5px",
              marginLeft: "2.5px",
              zIndex: 2,
            }}
          >
            <div
              className="position-absolute"
              style={{
                width: 0,
                height: 0,
                borderTop: "25px solid transparent",
                borderBottom: "25px solid transparent",
                borderLeft: "20px solid #fff",
                left: "0px",
              }}
            />
            <div
              style={{ color: fontColor, fontSize: "18px", marginLeft: "20px" }}
            >
              Parent's
            </div>
            <div
              style={{ color: fontColor, fontSize: "14px", marginLeft: "20px" }}
            >
              Details
            </div>
            <div
              className="position-absolute"
              style={{
                width: 0,
                height: 0,
                borderTop: "25px solid transparent",
                borderBottom: "25px solid transparent",
                borderLeft: `20px solid ${divBackground}`,
                right: "-20px",
              }}
            />
          </div>
          <div
            className="d-flex flex-column justify-content-center align-items-center position-relative"
            style={{
              width: "calc(33.33% - 7.5px)",
              background: "#e9ecef",
              height: "50px",
              marginRight: "2.5px",
              marginLeft: "2.5px",
              zIndex: 1,
            }}
          >
            <div
              className="position-absolute"
              style={{
                width: 0,
                height: 0,
                borderTop: "25px solid transparent",
                borderBottom: "25px solid transparent",
                borderLeft: "20px solid #fff",
                left: "0px",
              }}
            />
            <div
              style={{ color: "#000", fontSize: "18px", marginLeft: "20px" }}
            >
              Review
            </div>
            <div
              className="position-absolute"
              style={{
                width: 0,
                height: 0,
                borderTop: "25px solid transparent",
                borderBottom: "25px solid transparent",
                borderLeft: "20px solid #e9ecef",
                right: "-20px",
              }}
            />
          </div>
        </div>
        <Card className="my-2">
          <Card.Body className="p-2 m-0">
            <h6>{text}</h6>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: "calc(25% - 5px)",
                  marginRight: "5px",
                }}
              />
              <div
                className={divName1}
                role="progressbar"
                style={{
                  width: "calc(25% - 7.5px)",
                  marginLeft: "2.5px",
                  marginRight: "3.75px",
                }}
              />
              <div
                className={divName2}
                role="progressbar"
                style={{
                  width: "calc(25% - 5.5px)",
                  marginLeft: "3.75px",
                  marginRight: "2.5px",
                }}
              />
              <div
                className={divName3}
                role="progressbar"
                style={{
                  width: "calc(25% - 5px)",
                  marginLeft: "5px",
                }}
              />
            </div>
          </Card.Body>
        </Card>
      </>
    );
  };

  getContent() {
    const {
      data,
      formStatus,
      parishes,
      draft,
      isSaving,
      user,
      parishesFromDB,
      isRetrievingData,
      registrationBasicDetails,
      registrationMothersDetails,
      registrationFathersDetails,
    } = this.state;
    const { userid = "", dob = "" } = data;

    let content;

    if (
      userid &&
      new Date().getFullYear() - parseInt(dob) <= 8 &&
      parishes.length
    ) {
      let content2, type, next, previous;

      switch (formStatus) {
        case "input":
          type = "preRegistrationDetails";
          next = "input2";
          content2 = (
            <PreRegistrationForm
              userDetails={data}
              parishesFromDB={parishesFromDB}
              setPreRegistration={this.proceedToNextStep(type, next)}
              saveDraft={this.handleSaveAsDraft(type)}
              draft={draft}
            />
          );
          break;
        case "input2":
          type = "registrationBasicDetails";
          next = "input3";
          previous = "input";
          content2 = (
            <RegistrationFormBasic
              setRegistrationBasicDetails={this.proceedToNextStep(type, next)}
              saveDraft={this.handleSaveAsDraft(type)}
              pressBack={this.handlePressBack(previous)}
              dob={dob}
              draft={draft}
              coordinatorEmail={
                this.state.preRegistrationDetails.coordinatorEmail
              }
            />
          );
          break;
        case "input3":
          type = "registrationBaptismDetails";
          next = "input4";
          previous = "input2";
          content2 = (
            <RegistrationFormBaptism
              parishes={parishes}
              setRegistrationBaptismDetails={this.proceedToNextStep(type, next)}
              saveDraft={this.handleSaveAsDraft(type)}
              pressBack={this.handlePressBack(previous)}
              dob={dob}
              draft={draft}
              coordinatorEmail={
                this.state.preRegistrationDetails.coordinatorEmail
              }
            />
          );
          break;
        case "input4":
          type = "registrationEducationDetails";
          next = "input5";
          previous = "input3";
          content2 = (
            <RegistrationFormEducation
              setRegistrationEducationDetails={this.proceedToNextStep(
                type,
                next
              )}
              saveDraft={this.handleSaveAsDraft(type)}
              pressBack={this.handlePressBack(previous)}
              draft={draft}
              coordinatorEmail={
                this.state.preRegistrationDetails.coordinatorEmail
              }
            />
          );
          break;
        case "input5":
          type = "registrationMiscellaneousDetails";
          next = "input6";
          previous = "input4";
          content2 = (
            <RegistrationFormMiscellaneous
              setRegistrationMiscellaneousDetails={this.proceedToNextStep(
                type,
                next
              )}
              saveDraft={this.handleSaveAsDraft(type)}
              pressBack={this.handlePressBack(previous)}
              draft={draft}
              coordinatorEmail={
                this.state.preRegistrationDetails.coordinatorEmail
              }
            />
          );
          break;
        case "input6":
          type = "registrationMothersDetails";
          next = "input7";
          previous = "input5";
          content2 = (
            <RegistrationFormMother
              setRegistrationMothersDetails={this.proceedToNextStep(type, next)}
              saveDraft={this.handleSaveAsDraft(type)}
              pressBack={this.handlePressBack(previous)}
              setFormStatus={this.setFormStatus}
              draft={draft}
              coordinatorEmail={
                this.state.preRegistrationDetails.coordinatorEmail
              }
            />
          );
          break;
        case "input7":
          type = "registrationFathersDetails";
          next = "input8";
          previous = "input6";
          content2 = (
            <RegistrationFormFather
              setRegistrationFathersDetails={this.proceedToNextStep(type, next)}
              saveDraft={this.handleSaveAsDraft(type)}
              pressBack={this.handlePressBack(previous)}
              setFormStatus={this.setFormStatus}
              draft={draft}
              coordinatorEmail={
                this.state.preRegistrationDetails.coordinatorEmail
              }
            />
          );
          break;
        case "input8":
          type = "registrationContactDetails";
          next = "input9";
          previous = "input7";
          content2 = (
            <RegistrationFormContact
              motherDetails={registrationMothersDetails}
              fatherDetails={registrationFathersDetails}
              setRegistrationContactDetails={this.proceedToNextStep(type, next)}
              saveDraft={this.handleSaveAsDraft(type)}
              pressBack={this.handlePressBack(previous)}
              draft={draft}
              coordinatorEmail={
                this.state.preRegistrationDetails.coordinatorEmail
              }
            />
          );
          break;
        case "input9":
          type = "registrationAcknowledgementDetails";
          next = "review";
          previous = "input8";
          content2 = (
            <RegistrationFormAcknowledgement
              childDetails={registrationBasicDetails}
              motherDetails={registrationMothersDetails}
              fatherDetails={registrationFathersDetails}
              setRegistrationAcknowledgementDetails={this.proceedToNextStep(
                type,
                next
              )}
              saveDraft={this.handleSaveAsDraft(type)}
              pressBack={this.handlePressBack(previous)}
              draft={draft}
              coordinatorEmail={
                this.state.preRegistrationDetails.coordinatorEmail
              }
            />
          );
          break;
        case "review":
          content2 = (
            <RegistrationReview
              userDetails={data}
              draft={draft}
              isSaving={isSaving}
              user={user}
              setFormStatus={this.setFormStatus}
              getDraft={this.initGetDraft}
              handleCreateNewSubmission={this.setCreatingNewSubmission}
              handleEnterSubmission={this.setEnteringSubmission}
              coordinatorEmail={
                this.state.preRegistrationDetails.coordinatorEmail
              }
            />
          );
          break;
        default:
          content2 = (
            <PreRegistration
              // userDetails={ data }
              setFormStatus={this.setFormStatus}
              isRetrievingData={isRetrievingData}
              draft={draft}
            />
          );
      }

      content = (
        <>
          {formStatus !== "notice" &&
          formStatus !== "input" &&
          formStatus !== "review"
            ? this.renderProgressBar()
            : null}
          {content2}
        </>
      );
    } else {
      content = (
        <>
          <div className="text-left my-4">
            <h1>Registration for Parish Catechesis</h1>
            <h3 className="text-muted">Foundation Level 2023</h3>
          </div>
          <div className="pb-3 pt-0">
            {this.contentCard()}
            <LinkButton type="link" link="/home" label="Back to Home" />
          </div>
        </>
      );
    }

    return <main className="container px-0 mx-auto">{content}</main>;
  }

  render() {
    const { loading, user, isRetrievingData } = this.state;

    if (loading === true || isRetrievingData === true) {
      return <LoadingSpinnerNav type="backandlogo" smallpic={user.photoURL} />;
    }

    return (
      <>
        <Prompt
          when={
            this.state.formStatus.startsWith("input") ||
            this.state.formStatus === "review"
          }
          message="You will lose progress on this form if you navigate away from this page. Do you still want to continue?"
        />
        <Header smallpic={user.photoURL} type="backandlogo" />
        <div className="row justify-content-center mx-0">
          <div className="col-lg-6">{this.getContent()}</div>
        </div>
      </>
    );
  }
}

export default CatechismPage;
