export const QR_COLUMNS = [
  {
    Header: 'Date Created',
    accessor: row => { return row.createdAt ? row.createdAt.toDate().toString() : "" },
    sortMethod: (a, b) => {
      const aTime = new Date(a).getTime();
      const bTime = new Date(b).getTime();
      return bTime > aTime ? 1 : -1;
    }
  },
  { Header: 'Reference', accessor: 'campaignRef' },
  { Header: 'Campaign', accessor: 'campaignName' },
  { Header: 'Created By', accessor: 'createdByName' },
  {
    Header: 'Approved By',
    accessor: row => {
      let approver = '-';
      const approverStatus = row.approvalStatus;
      if (approverStatus === 0) {
        approver = 'Pending';
      } else if (approverStatus === 1) {
        approver = row.approvedByName;
      } else if (approverStatus === -1) {
        approver = 'Rejected';
      } else if (approverStatus === -2) {
        approver = 'Retired';
      }
      return approver;
    }
  },
  { Header: 'Amount', accessor: 'amount' },
  {
    Header: 'Start Date',
    accessor: row => { return row.campaignStart ? row.campaignStart.toDate().toString() : "" },
    sortMethod: (a, b) => {
      const aTime = new Date(a).getTime();
      const bTime = new Date(b).getTime();
      return bTime > aTime ? 1 : -1;
    }
  },
  {
    Header: 'Expiry',
    accessor: row => { return row.expiry ? row.expiry.toDate().toString() : "" },
    sortMethod: (a, b) => {
      const aTime = new Date(a).getTime();
      const bTime = new Date(b).getTime();
      return bTime > aTime ? 1 : -1;
    }
  },
  {
    Header: 'Action',
    accessor: 'action'
  }
];

export const INIT_QR_STATE = {
  filterSelection: 0,
  searchKey: '',
  isCreateModalOpen: false,
  isDetailModalOpen: false,
  campaignModalDetails: {},
  isCampaignListReady: false,
};

export const qrDashboardReducer = (state, action) => {
  const { type = '', payload = {} } = action;
  switch (type) {
    case "SELECT_FILTER": {
      const { filterSelection } = payload;
      return { ...state, filterSelection };
    }
    case "SET_CREATE_MODAL_OPEN": {
      return { ...state, isCreateModalOpen: true };
    }
    case "SET_CREATE_MODAL_CLOSE": {
      return { ...state, isCreateModalOpen: false };
    }
    case "SET_DETAIL_MODAL_OPEN": {
      const { campaignModalDetails } = payload;
      return {
        ...state,
        isDetailModalOpen: true,
        campaignModalDetails
      }
    }
    case "SET_DETAIL_MODAL_CLOSE": {
      return {
        ...state,
        isDetailModalOpen: false,
        campaignModalDetails: {}
      }
    }
    case "SET_CAMPAIGN_LIST": {
      const { campaignList = [] } = payload;
      return {
        ...state,
        campaignList,
        isCampaignListReady: true
      };
    }
    case "SET_SEARCH_KEY": {
      const { searchKey } = payload;
      return { ...state, searchKey };
    }
    default:
      return state;
  }
}