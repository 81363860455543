import React from "react";
import Button from "react-bootstrap/Button";

import { transfersStatusDictionary } from "../../services/transfersStatus";

import { Column, UseSortByState } from "react-table";
import StatusPill from "../components/StatusPill";
import LevelPill from "../components/LevelPill";
import SacramentsPill from "../components/SacramentsPill";

import formatTimestamp from "./formatTimestamp";
import { TransfersStatus, TransfersDatum } from "../../hooks/hooks";
import { getLevelAtDate } from "../../services/levels";
import {
  StaticActionProps,
  StatusActionDictionary,
} from "../components/AbstractList";

import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import MailIcon from "@material-ui/icons/Mail";

/** @type {Column<TransfersDatum>[]} */
const columnProps = [
  {
    id: "submittedAt",
    Header: "Submitted at",
    accessor: ({ submittedAt }) => submittedAt,
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">{formatTimestamp(value)}</div>
    ),
    width: 150,
    sortType: (rowA, rowB, columnId) => {
      const timestampA = rowA.original.submittedAt;
      const timestampB = rowB.original.submittedAt;

      // Convert to JavaScript Date objects
      let dateA;

      if (Object.hasOwn(timestampA, "_seconds")) {
        dateA = new Date(
          timestampA._seconds * 1000 + timestampA._nanoseconds / 1000000
        );
      } else {
        dateA = new Date(
          timestampA.seconds * 1000 + timestampA.nanoseconds / 1000000
        );
      }

      let dateB;

      if (Object.hasOwn(timestampB, "_seconds")) {
        dateB = new Date(
          timestampB._seconds * 1000 + timestampB._nanoseconds / 1000000
        );
      } else {
        dateB = new Date(
          timestampB.seconds * 1000 + timestampB.nanoseconds / 1000000
        );
      }

      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    },
  },
  {
    id: "name",
    Header: "Name",
    accessor: ({ registration: { name } }) => name,
    Cell: ({ value }) => <div className="text-wrap pt-1 pb-1">{value}</div>,
    width: 150,
  },
  {
    id: "programme",
    Header: "Programme",
    accessor: ({ to: { programmeName } }) => programmeName,
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">{value ?? "--"}</div>
    ),
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "level",
    Header: "Level",
    accessor: ({ registration: { level } }) => parseInt(level),
    Cell: ({ value }) => <LevelPill id={value} />,
    width: 100,
  },
  {
    id: "timeslot",
    Header: "Timeslot",
    accessor: ({ to: { timeslotName } }) => timeslotName,
    Cell: ({ value }) => <div className="text-wrap pt-1 pb-1">{value}</div>,
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "class",
    Header: "Class",
    Cell: () => <div className="text-wrap pt-1 pb-1">--</div>,
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "sacraments",
    Header: "Sacraments",
    accessor: (registrationDatum) => registrationDatum,
    Cell: ({ value }) => <SacramentsPill registrationDatum={value} />,
    width: 600,
    disableGlobalFilter: true,
  },
  {
    id: "status",
    Header: "Status",
    accessor: ({ status }) => status,
    Cell: ({ value }) => (
      <StatusPill statusDictionary={transfersStatusDictionary} value={value} />
    ),
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "view",
    Header: "",
    Cell: ({ row }) => (
      <Button
        variant="outline-dark"
        className="w-100"
        onClick={() => {
          const name = `${row.original.id || ""}-${
            row.original.from.id || ""
          }-${row.original.to.id || ""}`;

          const rowButton = document.getElementById(`button-${name}`);

          if (rowButton) {
            rowButton.click();
          }
        }}
        style={{ minWidth: 50 }}
      >
        View
      </Button>
    ),
    width: 100,
    disableGlobalFilter: true,
    sticky: "right",
  },
];

/** @type {UseSortByState<TransfersDatum>} */
const sortByProps = [
  {
    id: "submittedAt",
    desc: true,
  },
];

/**
 * @typedef { "unassign" | "accept" | "reject" | "email" } TransfersAction
 */

/**
 * @type {Record<TransfersAction, StaticActionProps<TransfersAction>>}
 */
const actions = {
  unassign: {
    action: "unassign",
    Icon: RemoveCircleIcon,
    title: "Set as Pending",
    variant: "outline-secondary",
  },
  accept: {
    action: "accept",
    Icon: CheckCircleIcon,
    title: "Accept",
    variant: "outline-success",
  },
  reject: {
    action: "reject",
    Icon: CancelIcon,
    title: "Reject",
    variant: "outline-danger",
  },
  email: {
    action: "email",
    Icon: MailIcon,
    title: "Send Email",
    variant: "primary",
  },
};
/**
 * @type {Record<TransfersAction, StaticActionProps<TransfersAction>>}
 */
const disabledActions = Object.fromEntries(
  Object.keys(actions).map((key) => [key, { ...actions[key], disabled: true }])
);

/** @type {Partial<Record<TransfersAction, Partial<Record<TransfersStatus, TransfersStatus>>>>} */
const intFsmEdges = {
  unassign: {
    rejectedOut: "pendingOut",
    rejectedIn: "pendingIn",
  },
  accept: {
    pendingOut: "pendingIn",
    pendingIn: "accepted",
  },
  reject: {
    pendingOut: "rejectedOut",
    pendingIn: "rejectedIn",
  },
};

/** @type {Partial<Record<TransfersAction, Partial<Record<TransfersStatus, TransfersStatus>>>>} */
const extFsmEdges = {
  unassign: {
    rejectedOut: "pendingOut",
    rejectedIn: "pendingIn",
  },
  accept: {
    pendingOut: "accepted",
    pendingIn: "accepted",
  },
  reject: {
    pendingOut: "rejectedOut",
    pendingIn: "rejectedIn",
  },
};

/**
 * @type {StatusActionDictionary<TransfersStatus, TransfersAction>}
 */
const statusActionDictionary = {
  pendingOut: [
    [disabledActions.unassign, actions.accept, actions.reject],
    [actions.email],
  ],
  pendingIn: [
    [disabledActions.unassign, actions.accept, actions.reject],
    [actions.email],
  ],
  rejectedOut: [
    [actions.unassign, disabledActions.accept, disabledActions.reject],
    [actions.email],
  ],
  rejectedIn: [
    [actions.unassign, disabledActions.accept, disabledActions.reject],
    [actions.email],
  ],
  accepted: [
    [disabledActions.unassign, disabledActions.accept, disabledActions.reject],
    [actions.email],
  ],
};

const listProps = { columnProps, sortByProps, statusActionDictionary };

export { listProps, intFsmEdges, extFsmEdges };
