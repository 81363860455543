import React, { Component } from "react";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

class SystemBarError extends Component {
  render() {
    const { text, link } = this.props;
    return (
      <div className="mb-2 py-2 text-center" style={{ background: "#f8d7da" }}>
        <span className="" style={{ color: "#721c24" }}>
          <Link style={{ textDecoration: "none", color: "#721c24" }} to={link}>
            {text}
            <ArrowForwardIosIcon style={{ fontSize: "1em" }} />
          </Link>
        </span>
      </div>
    );
  }
}

export default SystemBarError;
