import React, { useRef, useState, useEffect } from "react";
import { renderToString } from "react-dom/server";
import ForceGraph2D, {
  ForceGraphMethods,
  NodeObject,
  LinkObject,
} from "react-force-graph-2d";
import proj4 from "proj4";

import {
  parishes,
  computeParishDistance,
  Parish,
} from "../../services/parishes";
import { useRegistration, useTransfers } from "../hooks/hooks";
import { registrationStatusNameToStatus } from "../../services/registrationStatus";

import PersonIcon from "@material-ui/icons/Person";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import "../styles.css";

/**
 * @type {{[name: string]: string[]}}
 */
const CHART_PALETTES = {
  retroMetro: [
    "#ea5545",
    "#f46a9b",
    "#ef9b20",
    "#edbf33",
    "#ede15b",
    "#bdcf32",
    "#87bc45",
    "#27aeef",
    "#b33dc6",
  ],
  dutchField: [
    "#e60049",
    "#0bb4ff",
    "#50e991",
    "#e6d800",
    "#9b19f5",
    "#ffa300",
    "#dc0ab4",
    "#b3d4ff",
    "#00bfa0",
  ],
  riverNights: [
    "#b30000",
    "#7c1158",
    "#4421af",
    "#1a53ff",
    "#0d88e6",
    "#00b7c7",
    "#5ad45a",
    "#8be04e",
    "#ebdc78",
  ],
  springPastels: [
    "#fd7f6f",
    "#7eb0d5",
    "#b2e061",
    "#bd7ebe",
    "#ffb55a",
    "#ffee65",
    "#beb9db",
    "#fdcce5",
    "#8bd3c7",
  ],
  ibm: ["#0fb5ae", "#4046ca", "#f68511", "#de3d82", "#7e84fa", "#72e06a"],
};

proj4.defs(
  "EPSG:32648",
  "+proj=utm +zone=48 +ellps=WGS84 +datum=WGS84 +units=m +no_defs"
);
/** @param {[number, number]} latLng */
function convertLatLngToUtm([lat, lng]) {
  const utmCoords = proj4("EPSG:32648", [lng, lat]);
  return { x: utmCoords[0], y: utmCoords[1] };
}

const ACCEPTED_STATUS = registrationStatusNameToStatus("accepted");
const REJECTED_STATUS = registrationStatusNameToStatus("rejected");

export default function ArchdioceseGraph() {
  /** @type {{id: string, group: number, value: number}[]} */
  const registrationCounter = [];
  const filteredRegistrations = useRegistration().data.filter(
    ({ status }) => status === ACCEPTED_STATUS || status === REJECTED_STATUS
  );

  /** @type {{source: string, target: string, group: number, value: number}[]} */
  const transfersCounter = [];
  const filteredTransfers = useTransfers().data.filter(
    ({ status }) => status === "accepted"
  );

  /** @type {React.MutableRefObject<ForceGraphMethods<NodeObject<typeof graphData.nodes[0]>, LinkObject<typeof graphData.nodes[0], GraphLink>>?>} */
  const fgRef = useRef(null);
  const [elementWidth, setElementWidth] = useState(0);

  useEffect(() => {
    const updateElementWidth = () => {
      const element = document.getElementById("fg-container");
      if (element) {
        const width = element.offsetWidth;
        setElementWidth(width);
      }
    };

    // Initial width on component mount
    updateElementWidth();

    // Update width when the window is resized
    window.addEventListener("resize", updateElementWidth);

    fgRef.current.d3Force("link").distance(({ distance }) => distance * 1e-6);
    fgRef.current.d3Force("link").strength(({ value, distance }) => {
      const valueStrength = value ? 1e-5 * (Math.log(value) + 1) : 0;
      const distanceStrength = distance * 5e-8;
      // console.log({ valueStr, distanceStr });
      const strength = valueStrength + distanceStrength;
      return strength;
    });

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateElementWidth);
    };
  }, []);

  for (const { selectedParishId, status } of filteredRegistrations) {
    const current = registrationCounter.find(
      ({ id }) => id === selectedParishId
    ) ?? {
      id: selectedParishId,
      group:
        parishes.find(({ parishId }) => parishId == selectedParishId)
          ?.district ?? -1,
      value: 0,
      accepted: 0,
      rejected: 0,
    };
    registrationCounter.push(current);

    if (status === ACCEPTED_STATUS) {
      current.value++;
    } else {
      current.rejected++;
    }
  }

  for (const {
    from: { parish: fromParish },
    to: { parish: toParish },
  } of filteredTransfers) {
    const current = transfersCounter.find(
      ({ source, target }) => source === fromParish && target === toParish
    );

    if (current === undefined)
      transfersCounter.push({
        source: fromParish,
        target: toParish,
        value: 1,
        group:
          parishes.find(({ parishId }) => parishId == fromParish)?.district ??
          -1,
      });
    else current.value++;

    for (const curParish of [fromParish, toParish]) {
      if (!registrationCounter.some(({ id }) => id === curParish))
        registrationCounter.push({
          id: curParish,
          group:
            parishes.find(({ parishId }) => parishId == curParish)?.district ??
            -1,
          value: 0,
        });
    }

    // return transfersCounter;
  }

  //   const graphData = { nodes: registrationCounter, links: transfersCounter };

  const graphNodes = [
    { id: "3", value: 33 * 9 }, // CTK
    { id: "4", value: 35 * 9 }, // Divine Mercy
    { id: "7", value: 88 * 9 }, // Holy Spirit
    { id: "10", value: 2 * 9 }, // OLL
    { id: "14", value: 61 * 9 }, // OLSS
    { id: "15", value: 46 * 9 }, // Risen Christ
    { id: "19", value: 45 * 9 }, // St Anthony
    { id: "20", value: 3 * 9 }, // St Bernadette
    { id: "22", value: 49 * 9 }, // SFX
    { id: "24", value: 25 * 9 }, // SJBT
    { id: "26", value: 101 * 9 }, // St Mary
    { id: "29", value: 21 * 9 }, // St Teresa
    { id: "30", value: 34 * 9 }, // SVDP
  ].map((node) => {
    /** @type {Parish} */
    const parish = parishes.find(({ parishId }) => parishId == node.id);
    const utm = convertLatLngToUtm(parish?.location);
    return {
      ...node,
      group: parish.district,
      parishInfo: parish,
      ...utm,
    };
  });

  const [minX, maxX, minY, maxY] = graphNodes.reduce(
    (acc, { x, y }) => {
      if (x < acc[0]) acc[0] = x;
      if (x > acc[1]) acc[1] = x;
      if (x < acc[2]) acc[2] = y;
      if (x > acc[3]) acc[3] = y;
      return acc;
    },
    [Infinity, -Infinity, Infinity, -Infinity]
  );
  const rangeX = maxX - minX;
  const rangeY = maxY - minY;

  for (const node of graphNodes) {
    node.x = ((node.x - minX) / rangeX) * 400;
    node.y = (1 - (node.y - minY) / rangeY) * 50;
    node.transfersIn = 0;
    node.transfersOut = 0;
  }

  // console.log({ graphNodes });

  /**
   * @typedef GraphLink
   * @prop {string} source
   * @prop {string} srcName
   * @prop {string} target
   * @prop {string} tgtName
   * @prop {number} value
   * @prop {number} distance
   */

  /** @type {GraphLink[]} */
  const graphLinks = [
    {
      source: "3",
      target: "15",
      value: 20,
    },
    {
      source: "10",
      target: "29",
      value: 1,
    },
    {
      source: "15",
      target: "3",
      value: 10,
    },
    {
      source: "22",
      target: "3",
      value: 4,
    },
    {
      source: "22",
      target: "26",
      value: 2,
    },
  ];

  for (const id of [
    ...new Set(graphLinks.flatMap(({ source, target }) => [source, target])),
  ]) {
    if (!graphNodes.some(({ id: id_ }) => id === id_)) {
      graphNodes.push({ id, value: -1 }); // Unknown value
    }
  }

  // Iterate across all unordered node pairs & fill details, create link if necessary.
  for (let i = 0; i < graphNodes.length - 1; i++) {
    for (let j = i + 1; j < graphNodes.length; j++) {
      const node0 = graphNodes[i],
        {
          id: id0,
          parishInfo: { parish: name0 },
        } = node0;
      const node1 = graphNodes[j],
        {
          id: id1,
          parishInfo: { parish: name1 },
        } = node1;
      const distance = computeParishDistance(id0.toString(), id1.toString()); // Distance is commutative

      const link01 = graphLinks.find(
        ({ source, target }) => id0 == source && id1 == target
      );
      const link10 = graphLinks.find(
        ({ source, target }) => id1 == source && id0 == target
      );

      if (!link01)
        graphLinks.push({
          source: id0.toString(),
          srcName: name0,
          target: id1.toString(),
          tgtName: name1,
          value: 0,
          distance,
        });
      else {
        link01.srcName = name0;
        link01.tgtName = name1;
        link01.distance = distance;
        node0.transfersOut += link01.value;
        node1.transfersIn += link01.value;
      }
      if (!link10)
        graphLinks.push({
          source: id1.toString(),
          srcName: name1,
          target: id0.toString(),
          tgtName: name0,
          value: 0,
          distance,
        });
      else {
        link10.srcName = name1;
        link10.tgtName = name0;
        link10.distance = distance;
        node0.transfersIn += link10.value;
        node1.transfersOut += link10.value;
      }
    }
  }

  const graphData = {
    nodes: graphNodes,
    links: graphLinks,
  };

  // console.log({ graphData });

  const nodeVals = graphData.nodes.map(({ value }) => value);
  const avgNodeVal = nodeVals
    .filter((val) => val !== -1)
    .reduce((acc, val, _, { length }) => acc + val / length, 0);
  const maxNodeVal = Math.max(...nodeVals);
  const maxLinkVal = Math.min(
    Math.max(...graphData.links.map(({ value }) => value)),
    maxNodeVal / 5
  );

  return (
    <div id="fg-container" className="w-100">
      <ForceGraph2D
        ref={fgRef}
        className="w-100"
        width={elementWidth}
        height={500}
        graphData={graphData}
        nodeLabel={({
          parishInfo: { parish },
          value,
          transfersIn,
          transfersOut,
        }) =>
          renderToString(
            <span
              style={{
                fontFamily: '"Inter Tight", sans-serif',
                fontSize: 12,
                color: "#212529",
                lineHeight: 1,
              }}
            >
              <span style={{ fontWeight: 700 }}>{parish}</span>
              <br />
              <span style={{ fontWeight: 400 }}>
                <FlightLandIcon style={{ fontSize: "inherit" }} /> {transfersIn}
                {" | "}
                <PersonIcon style={{ fontSize: "inherit" }} />{" "}
                {value === -1 ? "?" : value}{" "}
                <span
                  className={
                    transfersIn - transfersOut < 0
                      ? "text-danger"
                      : transfersIn - transfersOut > 0
                      ? "text-success"
                      : "text-muted"
                  }
                >
                  ({transfersIn - transfersOut >= 0 && "+"}
                  {transfersIn - transfersOut})
                </span>
                {" | "}
                <FlightTakeoffIcon style={{ fontSize: "inherit" }} />{" "}
                {transfersOut}
              </span>
            </span>
          )
        }
        nodeColor={({ parishInfo: { district, isActive } }) =>
          CHART_PALETTES.ibm[district] + (isActive ? "ff" : "7f")
        }
        nodeVal={({ value }) =>
          (value === -1 ? avgNodeVal : value) / maxNodeVal + 0.01
        }
        nodeRelSize={20}
        nodeCanvasObjectMode={() => "after"}
        nodeCanvasObject={(
          { x, y, value, parishInfo: { parishShort } },
          ctx,
          globalScale
        ) => {
          const label = parishShort;
          const fontSize = 12 / globalScale;
          ctx.font = `800 ${fontSize}px "Inter Tight", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
          "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
          sans-serif`;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = "black";
          ctx.fillText(
            label,
            x,
            20 * Math.sqrt((value === -1 ? avgNodeVal : value) / maxNodeVal) +
              10 +
              y
          );
        }}
        enableNodeDrag={false}
        linkCurvature={0.25}
        linkLabel={({ srcName, tgtName, value }) =>
          renderToString(
            <span
              style={{
                fontFamily: '"Inter Tight", sans-serif',
                fontSize: 12,
                color: "#212529",
              }}
            >
              <span style={{ fontWeight: 800 }}>{srcName}</span>
              <br />
              <span style={{ fontWeight: 500 }}>
                <ArrowDownwardIcon style={{ fontSize: "inherit" }} /> {value}
              </span>
              <br />
              <span style={{ fontWeight: 800 }}>{tgtName}</span>
            </span>
          )
        }
        // linkDirectionalArrowLength={5}
        // linkDirectionalArrowRelPos={1}
        linkDirectionalParticles={({ value }) =>
          value && Math.round(1 + 3 * Math.log(value))
        }
        linkDirectionalParticleSpeed={({ distance }) => distance * 5e-7}
        linkDirectionalParticleWidth={4}
        linkColor={({ value }) =>
          `#777777${Math.round(
            32 + 192 * Math.tanh((2 * value) / maxLinkVal)
          ).toString(16)}`
        }
        linkVisibility={({ value }) => !!value}
        // warmUpTicks={100}
        cooldownTicks={150}
        onEngineStop={() => fgRef.current?.zoomToFit(100, 50)}
        enableZoomInteraction={false}
        enablePanInteraction={false}
      />
    </div>
  );
}
