import React from "react";
import Joi from "joi-full";
import { Button } from "react-bootstrap";

import Form from "../../common/form";

class RegistrationFormAcknowledgement extends Form {
	constructor(props) {
		super(props);

		const { draft } = props;

		let personalUndertaking = false, dataStorageAgreement = false,
			acknowledgement3 = false, acknowledgement4 = false, acknowledgement5 = false, acknowledgement6 = false,
			volunteerForActivities = false;

		if (draft.hasOwnProperty("personalUndertaking")) {
			personalUndertaking = draft.personalUndertaking;
		}

		if (draft.hasOwnProperty("dataStorageAgreement")) {
			dataStorageAgreement = draft.dataStorageAgreement;
		}

		if (draft.hasOwnProperty("pdpaAgreement")) {
			acknowledgement3 = draft.pdpaAgreement;
			acknowledgement4 = draft.pdpaAgreement;
		}

		if (draft.hasOwnProperty("commsAgreement")) {
			acknowledgement5 = draft.commsAgreement;
			acknowledgement6 = draft.commsAgreement;
		}

		if (draft.hasOwnProperty("volunteerForActivities")) {
			volunteerForActivities = draft.volunteerForActivities;
		}


		this.state = {
			data: {
				formSubmitter: draft.formSubmitter || "",
				formSubmitterRelation: draft.formSubmitterRelation || "",
				personalUndertaking,
				dataStorageAgreement,
				acknowledgement3,
				acknowledgement4,
				acknowledgement5,
				acknowledgement6,
				volunteerForActivities,
			},
			childDetails: props.childDetails,
			errors: {},
		};
	}

	schema = {
		formSubmitter: Joi.string().trim().required().label("Form Submitter"),
		formSubmitterRelation: Joi.string().trim().required().label("Form Submitter Relation"),
	};

	async componentDidMount() {
		window.scrollTo(0, 0);
	}

	handleRadioChange = ({ currentTarget: input }) => {
		const { data } = this.state;
		const newData = { ...data };

		newData[input.id] = !data[input.id];

		this.setState({
			data: newData,
		})
	}

	doSubmit = async () => {
		const { setRegistrationAcknowledgementDetails } = this.props;
		const { data } = this.state;
		const {
			formSubmitter = "",
			formSubmitterRelation = "",
			personalUndertaking = false,
			dataStorageAgreement = false,
			acknowledgement3 = false,
			acknowledgement4 = false,
			acknowledgement5 = false,
			acknowledgement6 = false,
		} = data;
		const newData = { ...data };

		if (acknowledgement3 && acknowledgement4) {
			newData.pdpaAgreement = true;
		} else {
			newData.pdpaAgreement = false;
		}

		if (acknowledgement5 && acknowledgement6) {
			newData.commsAgreement = true;
		} else {
			newData.commsAgreement = false;
		}

		delete newData.acknowledgement3;
		delete newData.acknowledgement4;
		delete newData.acknowledgement5;
		delete newData.acknowledgement6;

		if (formSubmitter && formSubmitterRelation && personalUndertaking && dataStorageAgreement &&
			acknowledgement3 && acknowledgement4 && acknowledgement5 && acknowledgement6) {
			setRegistrationAcknowledgementDetails(newData);
		}
	}

	renderNextButton = () => {
		const { data } = this.state;
		const {
			formSubmitter = "",
			formSubmitterRelation = "",
			personalUndertaking = false,
			dataStorageAgreement = false,
			acknowledgement3 = false,
			acknowledgement4 = false,
			acknowledgement5 = false,
			acknowledgement6 = false,
		} = data;

		let isDisabled = true;

		if (formSubmitter && formSubmitterRelation && personalUndertaking && dataStorageAgreement && acknowledgement3 &&
			acknowledgement4 && acknowledgement5 && acknowledgement6) {
			isDisabled = false;
		}

		return (
			<Button
				className="btn-block mb-3"
				size="lg"
				onClick={ this.doSubmit }
				disabled={ isDisabled }
			>
				Review Form
			</Button>
		);
	}

	handleOnClick = () => {
		const { saveDraft } = this.props;
		const { data } = this.state;
		const newData = { ...data };
		if (data.acknowledgement3 && data.acknowledgement4) {
			newData.pdpaAgreement = true;
		} else {
			newData.pdpaAgreement = false;
		}

		if (data.acknowledgement5 && data.acknowledgement6) {
			newData.commsAgreement = true;
		} else {
			newData.commsAgreement = false;
		}

		delete newData.acknowledgement3;
		delete newData.acknowledgement4;
		delete newData.acknowledgement5;
		delete newData.acknowledgement6;

		saveDraft(newData);
	}

	handlePressBack = () => {
		const { pressBack } = this.props;

		pressBack();
	}

	render() {
		const { childDetails, data } = this.state;

		return (
			<div>
				<strong>Acknowledgement of T&C</strong>
				<br />
				<br />
				<React.Fragment>
					<form>
						<div className="d-flex mt-2">
							<div className="pb-4 d-flex align-items-end">
								I,
							</div>
							<div className="ml-1 w-100">
								{ this.renderInput(
									"formSubmitter",
									"",
									"text",
									"Enter your name"
								)}
							</div>
							<div className="pb-4 d-flex align-items-end"
								style={{ width: "20px" }}>
								,
							</div>
						</div>
						<div className="d-flex ml-1	">
							<div className="ml-2 mr-1 w-100">
								{ this.renderInput(
									"formSubmitterRelation",
									"",
									"text",
									"Enter your relationship"
								)}
							</div>
							<div className="pb-4 d-flex align-items-end">
								of
							</div>
						</div>
						<h5>
							{ childDetails.childName }
						</h5>
						<br />
						<div className="d-flex mt-3 ml-4">
							<input type="checkbox" className="custom-control-input" id="personalUndertaking"
								onChange={ this.handleRadioChange }
								checked={ data.personalUndertaking }
							/>
							<label className="custom-control-label" htmlFor="personalUndertaking">
								Commit to sharing my Catholic faith with my child at home and bringing my child for Mass every week.
							</label>
						</div>
						<div className="d-flex mt-3 ml-4">
							<input type="checkbox" className="custom-control-input" id="dataStorageAgreement"
								onChange={ this.handleRadioChange }
								checked={ data.dataStorageAgreement }
							/>
							<label className="custom-control-label" htmlFor="dataStorageAgreement">
								Agree to the collection, storage, retention, adaptation, modification, reading, retrieval, use, transmission, blocking, erasure or destruction ("Processing") of the personal data provided by me in this form ("Personal Data").
							</label>
						</div>
						<div className="d-flex mt-3 ml-4">
							<input type="checkbox" className="custom-control-input" id="acknowledgement3"
								onChange={ this.handleRadioChange }
								checked={ data.acknowledgement3 }
							/>
							<label className="custom-control-label" htmlFor="acknowledgement3">
								Agree to the church entity processing my Personal Data for a registration to a course by the church entity ("Catechesis").
							</label>
						</div>
						<div className="d-flex mt-3 ml-4">
							<input type="checkbox" className="custom-control-input" id="acknowledgement4"
								onChange={ this.handleRadioChange }
								checked={ data.acknowledgement4 }
							/>
							<label className="custom-control-label" htmlFor="acknowledgement4">
								Agree to the church capturing photos/videos/audio which may contain my/my child's image/audio, and may be used for archival & publicity purposes on the church's website & publications.
							</label>
						</div>
						<div className="d-flex mt-3 ml-4">
							<input type="checkbox" className="custom-control-input" id="acknowledgement5"
								onChange={ this.handleRadioChange }
								checked={ data.acknowledgement5 }
							/>
							<label className="custom-control-label" htmlFor="acknowledgement5">
								Agree to the Catechist creating a mobile chat group for candidates & parents to facilitate communication.
							</label>
						</div>
						<div className="d-flex mt-3 ml-4">
							<input type="checkbox" className="custom-control-input" id="acknowledgement6"
								onChange={ this.handleRadioChange }
								checked={ data.acknowledgement6 }
							/>
							<label className="custom-control-label" htmlFor="acknowledgement6">
								Agree to the Catechist registering the child for online sessions on digital platforms (e.g. Zoom, Google Meet).
							</label>
						</div>
						<div className="d-flex mt-3 ml-4">
							<input type="checkbox" className="custom-control-input" id="volunteerForActivities"
								onChange={ this.handleRadioChange }
								checked={ data.volunteerForActivities }
							/>
							<label className="custom-control-label" htmlFor="volunteerForActivities">
								I am available to volunteer for Catechetical activities. (Optional)
							</label>
						</div>
						<p className="ml-4" style={{ fontSize: "12px" }}>*The Catechists will contact you for help if needed.</p>
					</form>
					<br />
					<br />
					{ this.props.coordinatorEmail ?
						<>
							If you have enquiries, plase contact your Parish Catechist Coordinator at{" "}
							<a href={ `mailto:${ this.props.coordinatorEmail }` }>{ this.props.coordinatorEmail }</a>
							<br />
							<br />
						</> : null
					}
					{ this.renderNextButton() }
					<Button className="btn-block mb-3" variant="outline-primary" size="lg"
						onClick={ this.handlePressBack }>
						Back
					</Button>
					<button
						className="btn-block mb-3 btn outline-primary btn-lg"
						style={{ border: "none", color: "#007bff" }}
						onClick={ this.handleOnClick }
					>
						Save as draft
					</button>
				</React.Fragment>
			</div>
		);
	}
}

export default RegistrationFormAcknowledgement;
