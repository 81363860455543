/**
 * @typedef Rate An object representing a monetary amount.
 * @prop {number} currency The currency being represented.
 * @prop {number} value The amount of the currency.
 * @prop {string} [note] Optional note for the rate.
 */

/**
 * @typedef CurrencyRefDatum Reference for a single currency.
 * @prop {string} name The name of the currency.
 * @prop {(value?: number|undefined) => string} format Callback to format the amount of the currency as a string.
 */

/**
 * Master reference for all currencies.
 * @type CurrencyRefDatum[]
 */
const currencyRef = [
    { name: "None", format: () => "FREE" },
    { name: "SGD", format: value => `$${value}` },
    { name: "Credit", format: value => value > 1 ? `${value} Credits` : `${value} Credit` }
];

/**
 * Formats a single Rate object as a string.
 * @param {Rate} rate Rate object to be formatted.
 * @param {boolean} [appendNote] If the note for the Rate object should be appended.
 * @return {string} Formatted Rate object.
 */
export function formatSingleRate(rate, appendNote = false) {
    return currencyRef[rate.currency].format(rate.value) + (appendNote && "note" in rate ? " " + rate.note : "");
}

/**
 * Formats multiple Rate objects as a string.
 * @param {Rate[]} rates Array of Rate objects to be formatted.
 * @return {string} Formatted Rate object array.
 */
export function formatMultiRate(rates) {
    return rates.map(rate => formatSingleRate(rate, true)).join(" / ");
}