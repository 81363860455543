import React from "react";

function DownloadLinks() {
  return (
    <div>
      <a
        href="https://apps.apple.com/sg/app/catholicsg-app/id1151027240"
        className="button-link shadow"
        rel="noopener noreferrer"
        title="Download on App Store"
        target="_blank"
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fapp%2Fapple_download_resize.png?alt=media&token=9275c502-274e-4f6a-b3cd-a36ec6ef65a3"
          alt="Apple Download"
          width={120}
          className="mr-1 button-image"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.CSG.CatholicSG"
        rel="noopener noreferrer"
        target="_blank"
        className="button-link shadow"
        title="Get it on Google Play"
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fapp%2Fandroid_download_resize.png?alt=media&token=8928af52-41fa-429c-8da1-051b42cc1d63"
          width={120}
          alt="Android Download"
          className="ml-1 button-image"
        />
      </a>
    </div>
  );
}

export default DownloadLinks;
