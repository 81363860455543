import fire from "../../../services/fire";
import { isValidEmail } from "../../../services/validation";

export const ROLE_ID_ARCHBISHOP = "1";
export const ROLE_ID_ARCHCOMS = "2";
export const ROLE_ID_PARISH_PRIEST = "3";
export const ROLE_ID_SECRETARY = "4";
export const ROLE_ID_SUPER_ADMIN = "5";
export const ROLE_ID_SUPPORT = "6";
export const ROLE_ID_SYSTEM_ADMIN = "7";
export const ROLE_ID_ATTENDANCE_VERIFIER = "8";
export const ROLE_ID_SYSTEM_VALIDATOR = "9";
export const ROLE_ID_API_CONSUMER = "10";
export const ROLE_ID_FINANCE = "11";
export const ROLE_ID_CATCH_ADMIN = "12";
export const ROLE_ID_CATCH_PARISH_ADMIN = "13";
export const ROLE_ID_FESTIVAL_ADMIN = "14";
export const ROLE_ID_SYNOD_ADMIN = "15";
export const ROLE_ID_FESTIVAL_SCANNER = "16";
export const ROLE_ID_EVENT_HUB_ADMIN = "17";
export const ROLE_ID_GENERIC_ADMIN = "2020";

export const userroles = [
  { _id: ROLE_ID_ARCHBISHOP, name: "Archbishop" },
  { _id: ROLE_ID_ARCHCOMS, name: "ArchComms" },
  { _id: ROLE_ID_PARISH_PRIEST, name: "Parish Priest" },
  { _id: ROLE_ID_SECRETARY, name: "Secretary" },
  { _id: ROLE_ID_SUPER_ADMIN, name: "Super Admin" },
  { _id: ROLE_ID_SUPPORT, name: "Support" },
  { _id: ROLE_ID_SYSTEM_ADMIN, name: "System Admin" },
  { _id: ROLE_ID_ATTENDANCE_VERIFIER, name: "Attendance Verifier" },
  { _id: ROLE_ID_SYSTEM_VALIDATOR, name: "System Validator" },
  { _id: ROLE_ID_API_CONSUMER, name: "API Consumer" },
  { _id: ROLE_ID_FINANCE, name: "Finance" },
  { _id: ROLE_ID_CATCH_ADMIN, name: "CATCH Admin" },
  { _id: ROLE_ID_CATCH_PARISH_ADMIN, name: "CATCH Parish Admin" },
  { _id: ROLE_ID_FESTIVAL_ADMIN, name: "Festival Admin" },
  { _id: ROLE_ID_SYNOD_ADMIN, name: "Synod Admin" },
  { _id: ROLE_ID_FESTIVAL_SCANNER, name: "Festival Scanner" },
  { _id: ROLE_ID_EVENT_HUB_ADMIN, name: "Event Hub Admin" },
  { _id: ROLE_ID_GENERIC_ADMIN, name: "Generic Admin" },
];

export function getUserRoles() {
  return userroles;
}

export function getUserRoleNameById(id) {
  const [role] = userroles.filter((g) => g._id === id);

  return role.name;
}

export async function getUserRoleByEmail(email) {
  let emailtrim = email.trim();
  if (!isValidEmail(emailtrim)) {
    return null;
  }

  const db = fire.firestore();

  const parishioners = await db
    .collection("roles")
    .where("email", "==", emailtrim)
    .get();
  var list = [];
  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function getUserRoleById(id) {
  if (!id) {
    return null;
  }

  const db = fire.firestore();

  const parishioners = await db
    .collection("roles")
    .where("authid", "==", id)
    .get();
  var list = [];
  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export function isSystemValidatorRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("systemvalidator")) {
    return true;
  }

  return false;
}

export function isAttendanceVerifierRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("attendanceverifier")) {
    return true;
  }

  return false;
}

export function isSuperAdminRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("superadmin")) {
    return true;
  }

  return false;
}

export function isArchCommsRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("archcomms")) {
    return true;
  }

  return false;
}

export function isParishPriestRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("parishpriest")) {
    return true;
  }

  return false;
}

export function isArchbishopRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("archbishop")) {
    return true;
  }

  return false;
}

export function isSecretaryRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("secretary")) {
    return true;
  }

  return false;
}

export function isSupportRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("support")) {
    return true;
  }

  return false;
}

export function isSystemAdminRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("systemadmin")) {
    return true;
  }

  return false;
}

export function isAPIConsumerRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("apiconsumer")) {
    return true;
  }

  return false;
}

export function isFinanceRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("finance")) {
    return true;
  }

  return false;
}

export function isCATCHAdminRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("catchadmin")) {
    return true;
  }

  return false;
}

export function isCATCHParishAdminRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("catchparishadmin")) {
    return true;
  }

  return false;
}

export function isFestivalAdminRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("festivaladmin")) {
    return true;
  }

  return false;
}

export function isFestivalScannerRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("festivalscanner")) {
    return true;
  }

  return false;
}

export function isSynodAdminRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("synodadmin")) {
    return true;
  }

  return false;
}

export function isEventHubAdminRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("eventhubadmin")) {
    return true;
  }

  return false;
}

export function isGenericAdminRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("genericadmin")) {
    return true;
  }

  return false;
}
