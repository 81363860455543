import fire from "../../../services/fire";

export async function getPosts(parish, limit = 3) {
  //retrieve data from firestore
  const posts = await fire
    .firestore()
    .collection("instaposts")
    .where("parish", "==", parish)
    .limit(limit)
    .orderBy("created", "desc")
    .get();

  let list = [];
  posts.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}
