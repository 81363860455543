import React from "react";
import { Button, Modal } from "react-bootstrap";
import SpinnerText from "./spinnerwithText";

function GenericModal({
  show,
  size = "md",
  closehandler,
  body,
  title,
  processing,
  okhandler,
  enablebutton,
  backdrop = true,
  buttontext = "Save",
  buttontype = "success",
  buttoncanceltext = "Cancel",
  css = "text-danger font-weight-bold",
}) {
  return (
    <Modal
      size={size}
      show={show}
      onHide={closehandler}
      animation={true}
      centered
      backdrop={backdrop}
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className={css}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">{body}</Modal.Body>
      <Modal.Footer>
        {closehandler && (
          <Button
            disabled={processing}
            variant="outline-secondary"
            onClick={closehandler}
          >
            {buttoncanceltext}
          </Button>
        )}
        {okhandler && processing !== true && (
          <Button
            variant={buttontype}
            disabled={!enablebutton}
            onClick={okhandler}
          >
            {buttontext}
          </Button>
        )}
        {processing === true && (
          <Button disabled variant={buttontype}>
            <SpinnerText text="Please wait..." />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default GenericModal;
