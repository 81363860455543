import React from "react";
import Joi from "joi-full";
import { Button } from "react-bootstrap";

import Form from "../../common/form";

import { transformList } from "../services/resources";

class RegistrationFormContact extends Form {
	constructor(props) {
		super(props);

		const { draft } = props;

		let mainContact = "", increment = 0, mobile = "";

		if (draft.hasOwnProperty("mainContact")) {
			increment = draft.mainContact + 1;
			mainContact = increment.toString();
		}

		if (draft.guardianMobileNo) {
			mobile = "+".concat(draft.guardianMobileNo.toString());
		}

		this.state = {
			data: {
				mainContact: mainContact || 0,
				guardianName: draft.guardianName || "",
				mobile: mobile || "+65",
				email: draft.guardianEmail || "",
			},
			contact: [],
			errors: {},
		}
	}

	schema = {
		mainContact: Joi.string().trim().required().label("Main Contact"),
		guardianName: Joi.string().trim().required().label("Guardian's Name"),
		mobile: Joi.string().trim().required().label("Mobile Number")
			// eslint-disable-next-line no-useless-escape
			.regex(/^\+[1-9?\s]{1}[0-9?\s]{9,14}$/),
		email: Joi.string().trim().required().label("Email Address")
			// eslint-disable-next-line no-useless-escape
			.regex(/[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/),
	};

	componentDidUpdate(prevProps, prevState) {
		const { data, errors } = this.state;
		const newState = { ...this.state };

		if (!data.mobile && data.mobile !== prevState.data.mobile) {
			if (errors.mobile) {
				delete newState.errors.mobile;

				this.setState(newState);
			}

		}

		if (!data.email && data.email !== prevState.data.email) {
			if (errors.email) {
				delete newState.errors.email;

				this.setState(newState);
			}
		}

		if (parseInt(data.mainContact) && data.mainContact !== prevState.data.mainContact) {
			const newData = { ...data };

			newData.guardianName = "";
			newData.mobile = "+65";
			newData.email = "";

			this.setState({ data: newData });
		}
	}

	async componentDidMount() {
		await this.initTransformList();
		window.scrollTo(0, 0);
	}

	initTransformList = () => {
		const { motherDetails, fatherDetails } = this.props;
		const contact = transformList("contact");
		const newContact = [];
		
		contact.forEach((item) => {
			const newItem = { ...item };

			switch (newItem.name) {
			case "Both Parents":
				if (!motherDetails.motherName || !fatherDetails.fatherName) {
					newItem.disabled = true;
				}

				break;
			case "Mother":
				if (!motherDetails.motherName) {
					newItem.disabled = true;
				}
				break;
			case "Father":
				if (!fatherDetails.fatherName) {
					newItem.disabled = true;
				}
				break;
			default:
			}

			newContact.push(newItem);
		});

		this.setState({
			contact: newContact,
		});
	}

	doSubmit = async () => {
		const { setRegistrationContactDetails } = this.props;
		const { data, errors } = this.state;
		const {
			mainContact = 0,
			guardianName = "",
			mobile = "",
			email = "",
		} = data;

		const newData = { ...data };
		
		newData.guardianMobileNo = mobile;
		newData.guardianEmail = email;

		delete newData.mobile;
		delete newData.email;

		if (parseInt(mainContact)) {
			if (parseInt(mainContact) === 4) {
				if (guardianName && mobile && email && Object.keys(errors).length === 0) {
					setRegistrationContactDetails(newData);
				}
			} else {
				setRegistrationContactDetails(newData);
			}
		}
	}

	renderNextButton = () => {
		const { data, errors } = this.state;
		const {
			mainContact = 0,
			guardianName = "",
			mobile = "",
			email = "",
		} = data;

		let isDisabled = true;

		if (parseInt(mainContact)) {
			if (parseInt(mainContact) === 4) {
				if (guardianName && mobile && email && Object.keys(errors).length === 0) {
					isDisabled = false;
				}
			} else {
				isDisabled = false;
			}
		}

		return (
			<Button
				className="btn-block mb-3"
				size="lg"
				onClick={ this.doSubmit }
				disabled={ isDisabled }
			>
				Acknowledgement of T&Cs
			</Button>
		);
	}

	handleRadioChange = ({ currentTarget: input }) => {
		const { data } = this.state;
		const newData = { ...data };

		newData[input.id] = !data[input.id];

		this.setState({
			data: newData,
		})
	}

	handleOnClick = () => {
		const { saveDraft } = this.props;
		const { data } = this.state;

		saveDraft(data);
	}

	handlePressBack = () => {
		const { pressBack } = this.props;

		pressBack();
	}

	render() {
		const { contact, data } = this.state;

		return (
			<div>
				<strong>Main Contact</strong>
				<br />
				<br />
				<React.Fragment>
					<form>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								1
							</div>
							<div className="container">
								<div>
									<strong>Who should we contact for Catechetical matters?</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ contact.length ?
										this.renderSelect(
											"mainContact",
											"",
											contact,
										) : null
									}
								</div>
							</div>
						</div>
						{ data.mainContact.toString() === "4" ?
							<>
								<div className="d-flex mt-2">
									<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
										style={{ height: "25px", minWidth: "25px" }}>
										2
									</div>
									<div className="container">
										<div>
											<strong>Name</strong>
										</div>
										<div className="ml-2 mt-2 pl-0 container">
											{ this.renderInput(
												"guardianName",
												"",
												"text",
												"Enter name"
											)}
										</div>
									</div>
								</div>
								<div className="d-flex mt-2">
									<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
										style={{ height: "25px", minWidth: "25px" }}>
										3
									</div>
									<div className="container">
										<div>
											<strong>Mobile Number</strong>
										</div>
										<div className="ml-2 mt-2 pl-0 container">
											{ this.renderInput(
												"mobile",
												"",
												"text",
												"Enter mobile number"
											)}
										</div>
									</div>
								</div>
								<div className="d-flex mt-2">
									<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
										style={{ height: "25px", minWidth: "25px" }}>
										4
									</div>
									<div className="container">
										<div>
											<strong>Email Address</strong>
										</div>
										<div className="ml-2 mt-2 pl-0 container">
											{ this.renderInput(
												"email",
												"",
												"text",
												"Enter email address"
											)}
										</div>
									</div>
								</div>
							</> : null
						}
					</form>
					<br />
					<br />
					{ this.props.coordinatorEmail ?
						<>
							If you have enquiries, plase contact your Parish Catechist Coordinator at{" "}
							<a href={ `mailto:${ this.props.coordinatorEmail }` }>{ this.props.coordinatorEmail }</a>
							<br />
							<br />
						</> : null
					}
					{ this.renderNextButton() }
					<Button className="btn-block mb-3" variant="outline-primary" size="lg"
						onClick={ this.handlePressBack }>
						Back
					</Button>
					<button
						className="btn-block mb-3 btn outline-primary btn-lg"
						style={{ border: "none", color: "#007bff" }}
						onClick={ this.handleOnClick }
					>
						Save as draft
					</button>
				</React.Fragment>
			</div>
		);
	}
}

export default RegistrationFormContact;
