import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncParishListing({ ...rest }) {
  const AsyncLoad = loadable(() => import("../parishListingPage"));
  console.log("Loading", AsyncParishListing.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncParishListing;
