import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncParishEmailPage({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/ParishEmailPage"));
  console.log("Loading", AsyncParishEmailPage.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncParishEmailPage;
