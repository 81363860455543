const countryList = [
  "Singapore",
  "Brunei Darussalam",
  "Cambodia",
  "Indonesia",
  "Lao People's Democratic Republic",
  "Malaysia",
  "Myanmar",
  "Philippines",
  "Thailand",
  "Timor-Leste",
  "Viet Nam",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "United States Minor Outlying Islands",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cameroon",
  "Canada",
  "Cabo Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo (Democratic Republic of the)",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Côte d'Ivoire",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia (the former Yugoslav Republic of)",
  "Madagascar",
  "Malawi",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Korea (Democratic People's Republic of)",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of Kosovo",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Korea (Republic of)",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom of Great Britain and Northern Ireland",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const schoolList = [
  "Admiralty Primary School",
  "Ahmad Ibrahim Primary School",
  "Ai Tong School",
  "Alexandra Primary School",
  "Anchor Green Primary School",
  "Anderson Primary School",
  "Ang Mo Kio Primary School",
  "Anglo-Chinese School (Junior)",
  "Anglo-Chinese School (Primary)",
  "Angsana Primary School",
  "Beacon Primary School",
  "Bedok Green Primary School",
  "Bendemeer Primary School",
  "Blangah Rise Primary School",
  "Boon Lay Garden Primary School",
  "Bukit Panjang Primary School",
  "Bukit Timah Primary School",
  "Bukit View Primary School",
  "Canberra Primary School",
  "Canossa Catholic Primary School",
  "Cantonment Primary School",
  "Casuarina Primary School",
  "Cedar Primary School",
  "Changkat Primary School",
  "CHIJ (Katong) Primary",
  "CHIJ (Kellock)",
  "CHIJ Our Lady Of Good Counsel",
  "CHIJ Our Lady Of The Nativity",
  "CHIJ Our Lady Queen Of Peace",
  "CHIJ Primary (Toa Payoh)",
  "Chongfu School",
  "Chongzheng Primary School",
  "Chua Chu Kang Primary School",
  "Clementi Primary School",
  "Compassvale Primary School",
  "Concord Primary School",
  "Corporation Primary School",
  "Damai Primary School",
  "Dazhong Primary School",
  "De La Salle School",
  "East Spring Primary School",
  "Edgefield Primary School",
  "Elias Park Primary School",
  "Endeavour Primary School",
  "Eunos Primary School",
  "Evergreen Primary School",
  "Fairfield Methodist School (Primary)",
  "Farrer Park Primary School",
  "Fengshan Primary School",
  "Fern Green Primary School",
  "Fernvale Primary School",
  "First Toa Payoh Primary School",
  "Frontier Primary School",
  "Fuchun Primary School",
  "Fuhua Primary School",
  "Gan Eng Seng Primary School",
  "Geylang Methodist School (Primary)",
  "Gongshang Primary School",
  "Greendale Primary School",
  "Greenridge Primary School",
  "Greenwood Primary School",
  "Guangyang Primary School",
  "Haig Girls' School",
  "Henry Park Primary School",
  "Holy Innocents' Primary School",
  "Hong Wen School",
  "Horizon Primary School",
  "Hougang Primary School",
  "Kranji Primary School",
  "Huamin Primary School",
  "Innova Primary School",
  "Jiemin Primary School",
  "Jing Shan Primary School",
  "Junyuan Primary School",
  "Jurong Primary School",
  "Jurong West Primary School",
  "Juying Primary School",
  "Keming Primary School",
  "Kheng Cheng School",
  "Kong Hwa School",
  "Kuo Chuan Presbyterian Primary School",
  "Lakeside Primary School",
  "Lianhua Primary School",
  "Maha Bodhi School",
  "Marsiling Primary School",
  "Marymount Convent School",
  "Mayflower Primary School",
  "Mee Toh School",
  "Meridian Primary School",
  "Methodist Girls' School (Primary)",
  "Montfort Junior School",
  "Nan Chiau Primary School",
  "Nan Hua Primary School",
  "Nanyang Primary School",
  "Naval Base Primary School",
  "New Town Primary School",
  "Opera Estate Primary School",
  "Ngee Ann Primary School",
  "North Spring Primary School",
  "North View Primary School",
  "North Vista Primary School",
  "Northland Primary School",
  "Northoaks Primary School",
  "Northshore Primary School",
  "Oasis Primary School",
  "Palm View Primary School",
  "Park View Primary School",
  "Pasir Ris Primary School",
  "Paya Lebar Methodist Girls' School (Primary)",
  "Pei Chun Public School",
  "Pei Hwa Presbyterian Primary School",
  "Pei Tong Primary School",
  "Peiying Primary School",
  "Pioneer Primary School",
  "Poi Ching School",
  "Princess Elizabeth Primary School",
  "Punggol Cove Primary School",
  "Punggol Green Primary School",
  "Punggol Primary School",
  "Punggol View Primary School",
  "Qifa Primary School",
  "Qihua Primary School",
  "Queenstown Primary School",
  "Radin Mas Primary School",
  "Raffles Girls' Primary School",
  "Red Swastika School",
  "River Valley Primary School",
  "Riverside Primary School",
  "Rivervale Primary School",
  "Rosyth School",
  "Rulang Primary School",
  "Sembawang Primary School",
  "Seng Kang Primary School",
  "Sengkang Green Primary School",
  "Shuqun Primary School",
  "Si Ling Primary School",
  "Singapore Chinese Girls' Primary School",
  "South View Primary School",
  "Springdale Primary School",
  "St. Andrew's Junior School",
  "St. Anthony's Canossian Primary School",
  "St. Anthony's Primary School",
  "St. Gabriel's Primary School",
  "St. Hilda's Primary School",
  "St. Joseph's Institution Junior",
  "St. Margaret's Primary School",
  "St. Stephen's School",
  "Stamford Primary School",
  "Tampines North Primary School",
  "Tampines Primary School",
  "Tanjong Katong Primary School",
  "Tao Nan School",
  "Teck Ghee Primary School",
  "Teck Whye Primary School",
  "Telok Kurau Primary School",
  "Temasek Primary School",
  "Townsville Primary School",
  "Unity Primary School",
  "Valour Primary School",
  "Waterway Primary School",
  "Wellington Primary School",
  "West Grove Primary School",
  "West Spring Primary School",
  "West View Primary School",
  "Westwood Primary School",
  "White Sands Primary School",
  "Woodgrove Primary School",
  "Woodlands Primary School",
  "Woodlands Ring Primary School",
  "Xinghua Primary School",
  "Xingnan Primary School",
  "Xinmin Primary School",
  "Xishan Primary School",
  "Yangzheng Primary School",
  "Yew Tee Primary School",
  "Yio Chu Kang Primary School",
  "Yishun Primary School",
  "Yu Neng Primary School",
  "Yuhua Primary School",
  "Yumin Primary School",
  "Zhangde Primary School",
  "Zhenghua Primary School",
  "Zhonghua Primary School",
];

const religionList = [
  "Catholic",
  "Christian (Non-Catholic)",
  "Buddhist",
  "Taoist",
  "Islam",
  "Hindu",
  "No Religion",
];

const levels = [
  "Kindergarten 2",
  "Primary 1",
  "Primary 2",
];

const maritalStatus = [
  "Married",
  "Separated",
  "Annulled by Church Tribunal",
  "Divorced (Civil)",
  "Remarried",
  "Widowed",
  "Deceased",
  "Single Parent",
  "N/A (other parent is Single Parent)",
];

const contact = [
  "Both Parents",
  "Father",
  "Mother",
  "Guardian",
];

const formSubmitter = [
  "Mother",
  "Father",
];

const gender = [
  "Male",
  "Female",
];

const citizenship = [
  "Singapore Citizen",
  "Permanent Resident",
  "FIN/Pass Holder",
  "Singapore Visitor",
  "Rather not say",
];

const userRegistryList = [
  "Child",
  "Mother",
  "Father",
  "Guardian",
];

const lists = {
  countryList,
  schoolList,
  religionList,
  levels,
  maritalStatus,
  contact,
  formSubmitter,
  gender,
  citizenship,
};

// TO DO: dynamic fields for forms
// const formFields = {
//   preRegistration: [
//     { label: "Select a district" },
//   ],
// };

// TO DO: dynamic form data to be saved to DB
// const docFields = [
//   {
//     name: "selectedParish",
//     needsChange: true,
//     apply: "getParishNameById",
//   },
// ];

// TO DO: dynamic displaying of draft data in review
// const formDataFields = [
//   {
//     label: "Basic Details",
//     isDataTitle: true,
//   },
//   {
//     label: "Name",
//     isDataTitle: false,
//     grabFrom: "childName",
//   },
//   {
//     label: "Gender",
//     isDataTitle: false,
//     grabFrom: "childGender",
//     apply: "getNameById",
//     fromList: "gender",
//     isInteger: true,
//   },
// ];

const idMap = {
  "Blessed Sacrament": 1,
  "Christ the King": 3,
  "Divine Mercy": 4,
  "Holy Cross": 5,
  "Holy Family": 6,
  "Holy Spirit": 7,
  "Holy Trinity": 8,
  "Immaculate Heart of Mary": 9,
  "Our Lady of Lourdes": 10,
  "Nativity of the BVM": 11,
  "Our Lady of Perpetual Succour": 12,
  "Our Lady Queen of Peace": 13,
  "Our Lady Star of the Sea": 14,
  "Risen Christ": 15,
  "Sacred Heart": 16,
  "St Anne": 18,
  "St Anthony": 19,
  "St Bernadette": 20,
  "St Francis of Assisi": 21,
  "St Francis Xavier": 22,
  "St Ignatius": 23,
  "St Joseph (Bukit Timah)": 24,
  "St Mary of the Angels": 26,
  "St Michael": 27,
  "St Stephen": 28,
  "St Teresa": 29,
  "St Vincent de Paul": 30,
  "Sts Peter and Paul": 31,
  "Transfiguration": 32,
};

const detectIdToParishName = (id) => {
  if (id === null) return null;

  let parishName = "";

  Object.keys(idMap).forEach((item) => {
    if (idMap[item] === id) {
      parishName = item;
    }
  });

  return parishName;
}

const detectedParishNameToId = (parishName) => {
  if (parishName == null) return null;
  return idMap[parishName];
}

const transformList = (type) => {
  const newList = [];

  lists[type].map((item, index) => {
    return newList.push({
      _id: index + 1,
      name: item,
    });
  });

  return newList;
};

const getList = (type) => {
  const list = transformList(type);

  return list;
}

const getItemById = (type, id) => {
  const list = getList(type);
  const [ item ] = list.filter((g) => g._id === parseInt(id));

  return item;
};

const getItemByName = (type, name) => {
  const list = getList(type);
  const [ item ] = list.filter((g) => g.name === name);

  return item;
};

const getNameById = (type, id) => {
  const list = getList(type);
  const [ item ] = list.filter((g) => g._id === parseInt(id));

  return item.name;
};

const getIdByName = (type, name) => {
  const list = getList(type);

  const [ item ] = list.filter((g) => g.name === name);

  return item._id;

};

export { countryList, schoolList, religionList, userRegistryList, transformList, getItemById, getItemByName, getNameById, getIdByName, detectIdToParishName, detectedParishNameToId };
