import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Moment from "react-moment";

import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import Error from "@material-ui/icons/Error";

import { useRegistrationDetails, useParishConfig } from "../../hooks/hooks";
import { levelIdToName } from "../../services/levels";

import SaveDraftConfirmationModal from "./SaveDraftConfirmationModal";

export default function Review({
  registrationStep,
  setRegistrationStep,
  setFromReview,
}) {
  const registrationDetails = useRegistrationDetails();
  const parishConfig = useParishConfig();

  function onSubmit() {
    setRegistrationStep(registrationStep + 1);

    window.scrollTo(0, 0);
  }

  function onSaveDraft() {
    setRegistrationStep(0);

    window.scrollTo(0, 0);
  }

  function jumpTo(step) {
    setRegistrationStep(step);

    window.scrollTo(0, 0);
  }

  let showWarning = false,
    fieldType = "";

  if (registrationDetails.boundary != registrationDetails.selectedParishId) {
    fieldType = "outsideBoundaryReason";

    if (registrationDetails.outsideBoundaryReason === "") {
      showWarning = true;
    }
  } else if (
    registrationDetails.boundary == registrationDetails.selectedParishId
  ) {
    fieldType = "registrationType";

    if (registrationDetails.registrationType === "") {
      showWarning = true;
    }
  }

  const selectedParish = parishConfig.find(
    ({ id }) => id == registrationDetails.selectedParishId
  );

  let parishName = "—";

  if (selectedParish) {
    parishName = selectedParish.parish;
  }

  let registrationType = "—";

  switch (registrationDetails.registrationType) {
    case "new":
      registrationType = "New Registration";
      break;
    case "existing":
      registrationType = "Onboard Existing";
      break;
    case "transfer":
      registrationType = "External Transfer-In";
      break;
    default:
  }

  let churchAndAddressOfBaptism =
    registrationDetails.sacraments?.baptism?.church || "—",
    churchAndAddressOfFirstRecon =
      registrationDetails.sacraments?.reconciliation?.church || "—",
    churchAndAddressOfFhc =
      registrationDetails.sacraments?.eucharist?.church || "—",
    churchAndAddressOfConfirmation =
      registrationDetails.sacraments?.confirmation?.church || "—";

  if (registrationDetails.sacraments?.baptism?.church) {
    const selectedParishsOfBaptism = parishConfig.find(
      ({ id }) => id == registrationDetails.sacraments?.baptism?.church
    );

    if (selectedParishsOfBaptism) {
      churchAndAddressOfBaptism = selectedParishsOfBaptism.parish;
    }
  }

  if (registrationDetails.sacraments?.reconciliation?.church) {
    const selectedParishsOfFirstRecon = parishConfig.find(
      ({ id }) => id == registrationDetails.sacraments?.reconciliation?.church
    );

    if (selectedParishsOfFirstRecon) {
      churchAndAddressOfFirstRecon = selectedParishsOfFirstRecon.parish;
    }
  }

  if (registrationDetails.sacraments?.eucharist?.church) {
    const selectedParishsOfFhc = parishConfig.find(
      ({ id }) => id == registrationDetails.sacraments?.eucharist?.church
    );

    if (selectedParishsOfFhc) {
      churchAndAddressOfFhc = selectedParishsOfFhc.parish;
    }
  }

  if (registrationDetails.sacraments?.confirmation?.church) {
    const selectedParishsOfConfirmation = parishConfig.find(
      ({ id }) => id == registrationDetails.sacraments?.confirmation?.church
    );

    if (selectedParishsOfConfirmation) {
      churchAndAddressOfConfirmation = selectedParishsOfConfirmation.parish;
    }
  }

  let programmeType = "—",
    timeslot = "—",
    savedProgramme;

  if (registrationDetails.programmeType) {
    if (typeof registrationDetails.programmeType === "string") {
      savedProgramme = selectedParish?.programmes?.find(
        ({ id }) => id == registrationDetails.programmeType
      );

      if (!!savedProgramme) {
        programmeType = savedProgramme?.name;
      }
    } else if (typeof registrationDetails.programmeType !== "string") {
      programmeType = registrationDetails?.programmeType?.name;
    }
  }

  if (registrationDetails.timeslot) {
    let savedTimeslot;

    if (typeof registrationDetails.programmeType !== "string") {
      savedTimeslot = registrationDetails?.programmeType?.timeslots?.find(
        ({ id, name }) =>
          id == registrationDetails.timeslot ||
          name == registrationDetails.timeslot
      );
    } else if (!!savedProgramme) {
      savedTimeslot = savedProgramme?.timeslots?.find(
        ({ id, name }) =>
          id == registrationDetails.timeslot ||
          name == registrationDetails.timeslot
      );
    }

    if (!!savedTimeslot) {
      timeslot = savedTimeslot?.name;
    }
  }

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showWarning ? (
        <>
          <Card
            className="d-flex flex-column border-danger"
            style={{ backgroundColor: "#FFF4F3" }}
          >
            <Card.Body className="d-flex flex-column align-items-center py-4">
              <Container>
                <h3>There is a problem with your submission</h3>
                <u className="text-danger">
                  You have made changes to the Child's Address,&nbsp;
                  {fieldType === "outsideBoundaryReason"
                    ? "please share why you are applying to another parish."
                    : "please select a registration type."}
                </u>
              </Container>
            </Card.Body>
          </Card>
          <br />
        </>
      ) : (
        <></>
      )}
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <h3>Review your application</h3>
            <br />
            Please look over your application before you submit it. You can't
            change your application after you click Submit, so if you see
            anything wrong, please edit it now.
            <br />
            <br />
            <Row className="w-100 mx-0 d-flex align-items-center">
              <Col className="px-0">
                <h5>Child's Basic Details</h5>
              </Col>
              <Col
                className="px-0 text-primary"
                xs="auto"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setFromReview(true);
                  jumpTo(1);
                }}
              >
                Edit
              </Col>
            </Row>
            <br />
            <b>Name</b>
            <br />
            {registrationDetails.name || "—"}
            <br />
            <br />
            <b>Sex</b>
            <br />
            {registrationDetails.sex || "—"}
            <br />
            <br />
            <b>Date of Birth</b>
            <br />
            {registrationDetails.dateOfBirth ? (
              <Moment
                date={registrationDetails.dateOfBirth.toDate()}
                format="DD MMM YYYY"
              />
            ) : (
              "—"
            )}
            <br />
            <br />
            <b>Country of birth</b>
            <br />
            {registrationDetails.placeOfBirth || "—"}
            <br />
            <br />
            <b>Nationality</b>
            <br />
            {registrationDetails.nationality || "—"}
            <br />
            <br />
            <hr className="w-100" />
            <br />
            <Row className="w-100 mx-0 d-flex align-items-center">
              <Col className="px-0">
                <h5>Child's Address</h5>
              </Col>
              <Col
                className="px-0 text-primary"
                xs="auto"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setFromReview(true);
                  jumpTo(2);
                }}
              >
                Edit
              </Col>
            </Row>
            <br />
            <b>Address</b>
            <br />
            {`${registrationDetails.roadName
              ? `${registrationDetails.roadName} `
              : ""
              }
            ${registrationDetails.blkNo ? `${registrationDetails.blkNo} ` : ""}
            ${registrationDetails.floorNumber
                ? `${registrationDetails.floorNumber} `
                : ""
              }
            ${registrationDetails.unitNumber || ""}`}
            <br />
            Singapore {registrationDetails.postalCode || "—"}
            <br />
            <br />
            <hr className="w-100" />
            <br />
            <Row className="w-100 mx-0 d-flex align-items-center">
              <Col className="px-0">
                <h5>Child's Baptism</h5>
              </Col>
              <Col
                className="px-0 text-primary"
                xs="auto"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setFromReview(true);
                  jumpTo(3);
                }}
              >
                Edit
              </Col>
            </Row>
            <br />
            <b>Received Baptism?</b>
            <br />
            {registrationDetails.sacraments?.baptism?.received === true
              ? "Yes"
              : "No"}
            {registrationDetails.sacraments?.baptism?.received === true ? (
              <>
                <br />
                {registrationDetails.sacraments?.baptism?.date ? (
                  <Moment
                    date={registrationDetails.sacraments?.baptism?.date.toDate()}
                    format="DD MMM YYYY"
                  />
                ) : (
                  "—"
                )}
                <br />
                {churchAndAddressOfBaptism}
                <br />
                {registrationDetails.sacraments?.baptism
                  ?.receivedInSingapore === true
                  ? "Singapore"
                  : "—"}
                <br />
                <br />
                <b>Baptism certificate</b>
                <br />
                <Button
                  variant="secondary"
                  className="text-wrap text-dark"
                  style={{ backgroundColor: "#f3f3f3", borderColor: "#f3f3f3" }}
                >
                  <AttachFileOutlinedIcon
                    className="mr-1"
                    style={{ color: "#7c7c7c" }}
                  />
                  {registrationDetails.sacraments?.baptism?.cert.path
                    ? registrationDetails.sacraments?.baptism?.cert.name
                    : "—"}
                </Button>
              </>
            ) : (
              <></>
            )}
            <br />
            <br />
            <b>Received First Reconciliation?</b>
            <br />
            {registrationDetails.sacraments?.reconciliation?.received === true
              ? "Yes"
              : "No"}
            {registrationDetails.sacraments?.reconciliation?.received ===
              true ? (
              <>
                <br />
                {registrationDetails.sacraments?.reconciliation?.date ? (
                  <Moment
                    date={registrationDetails.sacraments?.reconciliation?.date.toDate()}
                    format="DD MMM YYYY"
                  />
                ) : (
                  "—"
                )}
                <br />
                {churchAndAddressOfFirstRecon}
                <br />
                {registrationDetails.sacraments?.reconciliation
                  ?.receivedInSingapore === true
                  ? "Singapore"
                  : "—"}
              </>
            ) : (
              <></>
            )}
            <br />
            <br />
            <b>Received First Holy Communion?</b>
            <br />
            {registrationDetails.sacraments?.eucharist?.received === true
              ? "Yes"
              : "No"}
            {registrationDetails.sacraments?.eucharist?.received === true ? (
              <>
                <br />
                {registrationDetails.sacraments?.eucharist?.date ? (
                  <Moment
                    date={registrationDetails.sacraments?.eucharist?.date.toDate()}
                    format="DD MMM YYYY"
                  />
                ) : (
                  "—"
                )}
                <br />
                {churchAndAddressOfFhc}
                <br />
                {registrationDetails.sacraments?.eucharist
                  ?.receivedInSingapore === true
                  ? "Singapore"
                  : "—"}
              </>
            ) : (
              <></>
            )}
            <br />
            <br />
            <b>Received Confirmation?</b>
            <br />
            {registrationDetails.sacraments?.confirmation?.received === true
              ? "Yes"
              : "No"}
            {registrationDetails.sacraments?.confirmation?.received === true ? (
              <>
                <br />
                {registrationDetails.sacraments?.confirmation?.date ? (
                  <Moment
                    date={registrationDetails.sacraments?.confirmation?.date.toDate()}
                    format="DD MMM YYYY"
                  />
                ) : (
                  "—"
                )}
                <br />
                {churchAndAddressOfConfirmation}
                <br />
                {registrationDetails.sacraments?.confirmation
                  ?.receivedInSingapore === true
                  ? "Singapore"
                  : "—"}
                <br />
                <br />
                <b>Confirmation certificate</b>
                <br />
                <Button
                  variant="secondary"
                  className="text-wrap text-dark"
                  style={{ backgroundColor: "#f3f3f3", borderColor: "#f3f3f3" }}
                >
                  <AttachFileOutlinedIcon
                    className="mr-1"
                    style={{ color: "#7c7c7c" }}
                  />
                  {registrationDetails.sacraments?.confirmation?.cert?.path
                    ? registrationDetails.sacraments?.confirmation?.cert?.name
                    : "—"}
                </Button>
              </>
            ) : (
              <></>
            )}
            <br />
            <br />
            <hr className="w-100" />
            <br />
            <Row className="w-100 mx-0 d-flex align-items-center">
              <Col className="px-0">
                <h5>Child's Miscellaneous</h5>
              </Col>
              <Col
                className="px-0 text-primary"
                xs="auto"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setFromReview(true);
                  jumpTo(4);
                }}
              >
                Edit
              </Col>
            </Row>
            <br />
            <b>School (as of 2024)</b>
            <br />
            {registrationDetails.school || "—"}
            <br />
            {registrationDetails.schoolLevel
              ? levelIdToName(parseInt(registrationDetails.schoolLevel))
              : "—"}
            <br />
            <br />
            <b>
              Do you have any additional information to share with us?
              (optional)
            </b>
            <br />
            {registrationDetails.additionalInformation || "—"}
            <br />
            <br />
          </Container>
        </Card.Body>
      </Card>
      <br />
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <Row className="w-100 mx-0 d-flex align-items-center">
              <Col className="px-0">
                <h5>Parish of Interest</h5>
              </Col>
              <Col
                className="px-0 text-primary"
                xs="auto"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setFromReview(true);
                  jumpTo(5);
                }}
              >
                Edit
              </Col>
            </Row>
            <br />
            <b>Parish</b>
            <br />
            {parishName}
            <br />
            <br />
            {fieldType === "outsideBoundaryReason" ? (
              <>
                <b>Reason for applying to another parish</b>
                <br />
                {registrationDetails.outsideBoundaryReason || "—"}
                {showWarning ? (
                  <>
                    <br />
                    <span className="text-danger">
                      <Error className="mr-1" style={{ fontSize: "18px" }} />
                      Reason for applying to another parish must be filled in
                    </span>
                  </>
                ) : (
                  <></>
                )}
                <br />
                <br />
              </>
            ) : (
              <>
                <b>Purpose for registration</b>
                <br />
                {registrationType}
                {showWarning ? (
                  <>
                    <br />
                    <span className="text-danger">
                      <Error className="mr-1" style={{ fontSize: "18px" }} />
                      Purpose for registration must be selected
                    </span>
                  </>
                ) : (
                  <></>
                )}
                <br />
                <br />
              </>
            )}
            <b>Programme</b>
            <br />
            {programmeType}
            <br />
            <br />
            <b>Level</b>
            <br />
            {registrationDetails.level
              ? levelIdToName(parseInt(registrationDetails.level))
              : "—"}
            <br />
            <br />
            <b>Timeslot</b>
            <br />
            {timeslot}
            {registrationDetails.hasSiblings === true ? (
              <>
                <br />
                <br />
                <b>Sibling's Details</b>
                <br />
                <>
                  {registrationDetails.siblingsDetails
                    ? registrationDetails.siblingsDetails.map((data, index) => {
                      return (
                        <span className="w-100" key={`sibling-${index}`}>
                          {data.siblingFullName || "—"}
                          <br />
                          {data.siblingLevel && data.siblingClass
                            ? `${levelIdToName(
                              parseInt(data.siblingLevel)
                            )} ${data.siblingClass}`
                            : "—"}
                        </span>
                      );
                    })
                    : null}
                </>
              </>
            ) : (
              <></>
            )}
            <br />
            <br />
          </Container>
        </Card.Body>
      </Card>
      <br />
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <Row className="w-100 mx-0 d-flex align-items-center">
              <Col className="px-0">
                <h5>Contact Details</h5>
              </Col>
              <Col
                className="px-0 text-primary"
                xs="auto"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setFromReview(true);
                  jumpTo(6);
                }}
              >
                Edit
              </Col>
            </Row>
            <br />
            <span className="d-block mb-1">
              <b>Preferred Contact</b>
            </span>
            {registrationDetails.mainContact
              ? registrationDetails.mainContact.name
              : "—"}
            <br />
            {registrationDetails.mainContact
              ? registrationDetails.mainContact.relationship === "Others"
                ? registrationDetails.mainContact.relationshipOthers
                : registrationDetails.mainContact.relationship
              : "—"}
            <br />
            {registrationDetails.mainContact
              ? registrationDetails.mainContact.religion
              : "—"}
            <br />
            {registrationDetails.mainContact
              ? registrationDetails.mainContact.email
              : "—"}
            <br />
            {registrationDetails.mainContact
              ? registrationDetails.mainContact.mobileNo
              : "—"}
            {registrationDetails.mainContact ? (
              registrationDetails.mainContact.volunteer ? (
                <>
                  <br />
                  Note: Volunteer for Catechetical activities
                </>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            <br />
            <br />
            {registrationDetails.secondaryContact ? (
              registrationDetails.secondaryContact.exists === true ? (
                <>
                  <span className="d-block mb-1">
                    <b>Secondary Contact</b>
                  </span>
                  {registrationDetails.secondaryContact.name || "—"}
                  <br />
                  {registrationDetails.secondaryContact
                    .secondaryRelationship === "Others"
                    ? `Others - ${registrationDetails.secondaryContact.relationshipOthers}`
                    : registrationDetails.secondaryContact.relationship}
                  {registrationDetails.secondaryContact.legalGuardian ===
                    true ? (
                    <>
                      <br />
                      Guardian (legal)
                    </>
                  ) : (
                    ""
                  )}
                  <br />
                  {registrationDetails.secondaryContact.email || "—"}
                  <br />
                  {registrationDetails.secondaryContact.mobileNo || "—"}
                  {registrationDetails.secondaryContact.volunteer ? (
                    <>
                      <br />
                      Note: Volunteer for Catechetical activities
                    </>
                  ) : (
                    ""
                  )}
                  {registrationDetails.secondaryContact.emergencies ? (
                    <>
                      <br />
                      Note: Only to be contacted for emergencies
                    </>
                  ) : (
                    ""
                  )}
                  <br />
                  <br />
                </>
              ) : null
            ) : null}
          </Container>
        </Card.Body>
      </Card>
      <Container className="px-0 mt-4">
        <Row>
          <Col xs={12} sm="auto">
            <Button
              variant="outline-primary"
              size="lg"
              className="w-100"
              onClick={() => jumpTo(registrationStep - 1)}
            >
              Back
            </Button>
          </Col>
          <Col className="d-none d-sm-flex" />
          <Col className="mt-3 mt-sm-0" xs="auto">
            <Button
              variant="outline-primary"
              size="lg"
              className="w-100"
              onClick={() => setShowModal(true)}
            >
              Save Draft
            </Button>
          </Col>
          <Col className="col mt-3 mt-sm-0" sm="auto">
            <Button
              variant="primary"
              size="lg"
              className="w-100"
              onClick={onSubmit}
              disabled={showWarning}
            >
              Next
            </Button>
          </Col>
        </Row>
      </Container>
      {registrationDetails.id && registrationDetails.programmeType ? (
        <Container className="px-0 mt-4 text-wrap text-center">
          If you have enquiries, please contact your Parish Catechist
          Coordinator at&nbsp;
          <span className="text-primary" style={{ cursor: "pointer" }}>
            {registrationDetails.programmeType.enquiryEmail}
          </span>
        </Container>
      ) : (
        <></>
      )}
      <SaveDraftConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        onSaveDraft={onSaveDraft}
      />
    </>
  );
}
