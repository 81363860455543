import {
  createContext,
  useContext,
  Context,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Color } from "react-bootstrap/esm/types";

import { UserPageId } from "../services/routeInfo";
import { firestore } from "firebase";

/**
 * @typedef StatusProps
 * @prop {string} message
 * @prop {string} userLabel
 * @prop {string} adminLabel
 * @prop {UserPageId[]} pages
 * @prop {string} bg
 * @prop {Color} text
 */
/**
 * @template {string} Status
 * @typedef {Record<Status, StatusProps>} StatusDictionary
 */
/**
 * @template {string} Status
 * @typedef {Record<Status, Omit<StatusProps, "message" | "buttons">>} StatusDictionaryLite
 */

/**
 * @template Status
 * @typedef {Object} DocumentDatum
 * @prop {string} id
 * @prop {Status} status
 * @prop {firestore.Timestamp} submittedAt
 * @prop {string} submittedBy
 * @prop {firestore.Timestamp} [withdrawnAt]
 * @prop {string} [withdrawnBy]
 */

/**
 * @typedef ContactPerson
 * @prop {string} name
 * @prop {string} [email]
 */
/**
 * @typedef Ward
 * @prop {string} name Name of the ward.
 * @prop {string} uid User ID of the ward.
 */
/**
 * @typedef Sacrament
 * @prop {boolean} received
 * @prop {boolean} receivedInSingapore
 * @prop {string} church
 * @prop {firestore.Timestamp} date
 * @prop {Object} [cert]
 * @prop {string} cert.name
 * @prop {string} cert.path
 */

/**
 * @typedef PaymentBase
 * @prop {string} recipientParish
 * @prop {string} year
 */
/**
 * @typedef PaidPaymentExtension
 * @prop {false} waiverRequested
 * @prop {false} waiverApproved
 * @prop {number} paymentAmountSgd
 * @prop {firestore.Timestamp} paymentReceivedAt
 */
/**
 * @typedef WaivedPaymentExtension
 * @prop {true} waiverRequested
 * @prop {boolean} waiverApproved
 * @prop {string} waiverReason
 */
/**
 * @typedef {PaymentBase & (PaidPaymentExtension | WaivedPaymentExtension)} Payment
 */

/**
 * @typedef ContactUpdateRecord
 * @prop {firestore.Timestamp} updatedAt
 * @prop {Partial<ContactPerson>} mainContact
 */

/**
 * @typedef {"baptism" | "reconciliation" | "eucharist" | "confirmation" | "matrimony" | "holyorders" | "anointingofthesick"} SacramentName
 */
/**
 * @typedef {Partial<Record<SacramentName, Sacrament>>} Sacraments
 */
/**
 * @template {SacramentName} SelectedSacramentName
 * @typedef {Record<SelectedSacramentName, {received: boolean}>} ReceivedSacraments
 */

/**
 * @template {SacramentName} SelectedSacramentName
 * @typedef GodparentDetails
 * @prop {string} name
 * @prop {ReceivedSacraments<SelectedSacramentName>} sacraments
 */

/**
 * @template {SacramentName} SelectedSacramentName
 * @typedef ConfirmationFormDetails
 * @prop {boolean} isApproved
 * @prop {boolean} [isReceiving]
 * @prop {string} [confirmationName]
 * @prop {GodparentDetails<SelectedSacramentName>} [godfather]
 * @prop {GodparentDetails<SelectedSacramentName>} [godmother]
 */

/**
 * @typedef {"pending" | "withdrawn" | "rejected" | "accepted" | "unregistered"} RegistrationStatus
 */

/**
 * @typedef RegistrationDatumExtension
 * @prop {string} selectedParishId
 * @prop {Object} level
 * @prop {number} level.id
 * @prop {string} level.year
 * @prop {string} timeslot
 * @prop {Ward} child
 * @prop {ContactPerson} mainContact
 * @prop {Sacraments} sacraments
 * @prop {Payment[]} payments
 * @prop {ContactUpdateRecord[]} contactUpdateHistory
 * @prop {ConfirmationFormDetails} [confirmationFormDetails]
 */

/**
 * @typedef {DocumentDatum<number> & RegistrationDatumExtension} RegistrationDatum
 */

/**
 * @typedef ActiveRegistrationState
 * @prop {boolean} exists Whether the document exists.
 * @prop {RegistrationDatum} [data] The document data.
 * @prop {"empty" | "unregistered" | RegistrationStatus} status Status of the active registration ("unregistered if empty").
 */

/**
 * @typedef Parish
 * @prop {string} parish
 * @prop {string} timeslot
 */

/**
 * @typedef {"pendingOut" | "pendingIn" | "withdrawn" | "rejectedOut" | "rejectedIn" | "accepted"} TransfersStatus
 */

/**
 * @typedef TransfersDatumExtension
 * @prop {Parish} from
 * @prop {Parish} to
 * @prop {string} transferReason
 * @prop {RegistrationDatum} registration
 */

/**
 * @typedef {DocumentDatum<TransfersStatus> & TransfersDatumExtension} TransfersDatum
 */

/**
 * @typedef PendingTransferState
 * @prop {boolean} exists Whether the document(s) exist(s).
 * @prop {TransfersDatum?} data The document data.
 */

/**
 * @typedef {"generated" | "consumed" | "invalidated" | "deleted"} GuardianCodeStatus
 */
/**
 * @typedef GuardianCodeDatum
 * @prop {firestore.Timestamp} createdAt
 * @prop {firestore.Timestamp} expireAt
 * @prop {firestore.Timestamp} [consumedAt]
 * @prop {string} code
 * @prop {GuardianCodeStatus} status
 * @prop {string} wardId
 * @prop {string} wardName
 * @prop {string} [guardianId]
 */

/**
 * @typedef GuardianState
 * @prop {boolean} isGuardian If user is a Guardian (based on age).
 * @prop {Ward[]} wardsData Array of wards. Empty if user is not a guardian.
 */
/**
 * @typedef WardSelectionState
 * @prop {number} selectedWardIndex Index of the current ward. -1 if no ward selected.
 * @prop {Dispatch<SetStateAction<number>>} setSelectedWardIndex Setter for `selectedWardIndex`.
 */
/**
 * @typedef DynamicUserState State of the user that is shared across CATCH but can change dynamically.
 * @prop {GuardianState} guardianState Contains guardian info.
 * @prop {WardSelectionState} wardSelectionState Contains info of selected ward. Updates must propagate to queried state.
 */

/**
 * @typedef QueriedUserState State of the user that is queried from Firestore.
 * @prop {ActiveRegistrationState} activeRegistrationState Contains the active registration.
 * @prop {PendingTransferState} pendingTransferState Contains transfer requests.
 */

/**
 * @type {Context<QueriedUserState>}
 */
export const QueriedUserContext = createContext();

export const useActiveRegistration = () =>
  useContext(QueriedUserContext).activeRegistrationState;
export const usePendingTransfer = () =>
  useContext(QueriedUserContext).pendingTransferState;
export const usePendingConfirmation = () =>
  useContext(QueriedUserContext).pendingConfirmationState;

/**
 * @type {Context<DynamicUserState>}
 */
export const DynamicUserContext = createContext();

export const useWardSelection = () =>
  useContext(DynamicUserContext).wardSelectionState;
export const useGuardian = () => useContext(DynamicUserContext).guardianState;

export const useMediaQuery = (query) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);
  });
  return matches;
};

export const RegistrationFormContext = createContext();

export const useRegistrationDetails = () => useContext(RegistrationFormContext);

/**
 * @typedef TimeslotFields
 * @prop {string} name
 * @prop {string[]} classes
 */
/**
 * @typedef ProgrammeConfig
 * @prop {string} name
 * @prop {string} id
 * @prop {string} caption
 * @prop {string} notice
 * @prop {[ number, number ]} levelRange
 * @prop {{ number: number }} feeByLevel
 * @prop {number?} intakeSize
 * @prop {import("firebase").firestore.Timestamp} registrationOpen
 * @prop {import("firebase").firestore.Timestamp} registrationClose
 * @prop {import("firebase").firestore.Timestamp} catecheticalOpen
 * @prop {boolean} isConfirmationPrep
 * @prop {number | ""} confirmationLevel
 * @prop {number | ""} confirmationMonth
 * @prop {TimeslotFields[]} timeslots
 * @prop {string} enquiryEmail
 * @prop {string[]} subscriberEmails
 * @prop {string[]} adminEmails
 */
/**
 * @typedef ParishConfig
 * @prop {import("firebase").firestore.Timestamp} created
 * @prop {string} district
 * @prop {string} id
 * @prop {string} parish
 * @prop {boolean} isActive
 * @prop {boolean} hasCatechesis
 * @prop {boolean} isCatchParish
 * @prop {boolean} isPayplusEnabled
 * @prop {import("firebase").firestore.GeoPoint} location
 * @prop {ProgrammeConfig[]} programmes
 */

/**
 * @type {Context<ParishConfig[]>>}
 */
export const ParishConfigContext = createContext();

export const useParishConfig = () => useContext(ParishConfigContext);
