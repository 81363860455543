import React, { useContext, useEffect, forwardRef, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import fire from "../../../services/fire";
import { TRANSACTION_COLUMNS } from "../reducers/transactionReducer";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import AlarmOffIcon from "@material-ui/icons/AlarmOff";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { getStartofMonth, getDayMonthYear } from "../../../utils/utils";
import Table from "../../common/table";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import FinanceContext from "./financeContext";

import "../stylesheets/finance-transaction-page.css";

const TransactionPage = () => {
  const {
    transactionDashboardState,
    financeDashboardState,
    transactionDashboardDispatch,
  } = useContext(FinanceContext);
  const {
    startDate,
    endDate,
    isLive,
    searchKey,
    transactionList,
    filteredData,
  } = transactionDashboardState;
  const { user, org } = financeDashboardState;
  const { orgDBSId = "" } = org;
  const csvLinkRef = useRef(null);

  useEffect(() => {
    // Init Dates
    if (!startDate && !endDate) {
      const startDate = getStartofMonth();
      const endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      transactionDashboardDispatch({
        type: "SET_DATE_RANGE",
        payload: { startDate, endDate },
      });
    }
    // Run effect once on component mount, recheck if deps updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Changes in Data subscription
  useEffect(() => {
    // endDate is null while selecting date range
    const endDateQuery = endDate ?? startDate;

    const addCampaignName = async (transactionObj, cb) => {
      const fullRef = transactionObj.customerReference;
      const campaignRef = fullRef.substring(0, 14);
      // cb(transactionObj);
      const resultSnapshot = await fire
        .firestore()
        .collection("paypluscampaigns")
        .where("campaignRef", "==", campaignRef)
        .get();

      const newTransactionObj = {
        ...transactionObj,
        campaignRef,
      };
      if (!resultSnapshot.empty) {
        const campaignObj = resultSnapshot.docs[0].data();
        newTransactionObj.campaignName = campaignObj.campaignName;
      } else {
        newTransactionObj.campaignName = "UNREGISTERED";
      }
      cb(newTransactionObj);
    };

    // If live mode is disabled, get and update once
    if (!isLive) {
      fire
        .firestore()
        .collection("payplustransactions")
        .where("orgId", "==", orgDBSId)
        .where("timeStamp", ">=", startDate)
        .where("timeStamp", "<=", endDateQuery)
        .get()
        .then((resultSnapshot) => {
          const newTransactionList = [];
          const promiseArr = [];
          if (!resultSnapshot.empty) {
            resultSnapshot.docs.forEach((doc) => {
              const transactionObj = doc.data();
              promiseArr.push(
                addCampaignName(transactionObj, (newTransactionObj) => {
                  newTransactionList.push(newTransactionObj);
                })
              );
            });
          }
          Promise.allSettled(promiseArr).then(() => {
            transactionDashboardDispatch({
              type: "SET_TRANSACTION_LIST",
              payload: { transactionList: newTransactionList },
            });
          });
        });
    }

    // Data subscription is enabled but only updates if live mode is enabled
    const payplusTransactionSub = fire
      .firestore()
      .collection("payplustransactions")
      .where("orgId", "==", orgDBSId)
      .where("timeStamp", ">=", startDate)
      .where("timeStamp", "<=", endDateQuery)
      .onSnapshot((documentSnapshot) => {
        if (isLive) {
          const newTransactionList = [];
          const promiseArr = [];
          if (!documentSnapshot.empty) {
            documentSnapshot.docs.forEach((doc) => {
              const transactionObj = doc.data();
              promiseArr.push(
                addCampaignName(transactionObj, (newTransactionObj) => {
                  newTransactionList.push(newTransactionObj);
                })
              );
            });
          }
          Promise.allSettled(promiseArr).then(() => {
            transactionDashboardDispatch({
              type: "SET_TRANSACTION_LIST",
              payload: { transactionList: newTransactionList },
            });
          });
        }
      });
    return () => payplusTransactionSub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, isLive]);

  useEffect(() => {
    // Remove live mode if endDate !== today
    const now = new Date();
    if (endDate && getDayMonthYear(now) !== getDayMonthYear(endDate)) {
      transactionDashboardDispatch({
        type: "SET_LIVE",
        payload: { isLive: false },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(endDate)]);

  const setLive = () => {
    const newIsLive = !isLive;
    if (newIsLive) {
      const now = new Date();
      if (getDayMonthYear(now) === getDayMonthYear(endDate)) {
        transactionDashboardDispatch({
          type: "SET_LIVE",
          payload: { isLive: newIsLive },
        });
      } else {
        // Set endDate to NOW and enable live mode
        transactionDashboardDispatch({
          type: "SET_LIVE_NOW",
          payload: { endDate: now, isLive: newIsLive },
        });
      }
    } else {
      transactionDashboardDispatch({
        type: "SET_LIVE",
        payload: { isLive: newIsLive },
      });
    }
  };

  const changeDateRange = (newDates) => {
    let [start, end] = newDates;
    if (end) {
      // Set endDate to 2359 HRS
      end.setHours(23, 59, 59, 999);
    }
    transactionDashboardDispatch({
      type: "SET_DATE_RANGE",
      payload: { startDate: start, endDate: end },
    });
  };

  const handleChange = (e) => {
    transactionDashboardDispatch({
      type: "SET_SEARCH_KEY",
      payload: { searchKey: e.target.value },
    });
  };

  const CustomDateDisplay = forwardRef(({ onClick }, ref) => {
    const startDateStr = getDayMonthYear(startDate);
    // endDate is set to null when user has not selected it
    const endDateStr = endDate ? getDayMonthYear(endDate) : startDateStr;
    return (
      <div onClick={onClick} ref={ref}>
        <CalendarTodayIcon className="finance-transaction__date-icon" />
        {startDateStr} - {endDateStr}
      </div>
    );
  });

  const customDropdownToggle = React.forwardRef(
    ({ children, onClick }, ref) => (
      <MoreVertIcon
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </MoreVertIcon>
    )
  );

  const downloadCSV = () => {
    // TODO: log every download request
    const payplusDownloadRef = fire
      .firestore()
      .collection("payplustransactiondownloads")
      .doc();

    payplusDownloadRef.set({
      id: payplusDownloadRef.id,
      createdAt: new Date(),
      createdBy: user.userid,
      orgId: org.orgId,
      startDate,
      endDate,
      searchKey,
    });

    csvLinkRef.current.link.click();
  };

  return (
    <div className="finance-transaction__container">
      <div className="finance-transaction__header">Transactions</div>
      <div className="finance-transaction__table-controls">
        <div className="finance-transaction__table-controls-section">
          <div className="finance-transaction__date-picker">
            <DatePicker
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              onChange={changeDateRange}
              selectsRange
              popperClassName="finance-transaction__date-picker__popper"
              dayClassName={(date) => "finance-transaction__date-picker-day"}
              customInput={<CustomDateDisplay />}
            />
          </div>
          <div
            className={
              isLive
                ? "finance-transaction__date-live--active"
                : "finance-transaction__date-live"
            }
            onClick={() => setLive()}
          >
            {isLive ? (
              <AccessAlarmIcon className="finance-transaction__date-icon" />
            ) : (
              <AlarmOffIcon className="finance-transaction__date-icon" />
            )}
            Live Mode {isLive ? "enabled" : "disabled"}
          </div>
        </div>
        <div className="finance-transaction__table-controls-section">
          <div className="finance-transaction__filter">Filter</div>
          <input
            className="finance-transaction__search-input"
            placeholder="Search ..."
            onChange={(e) => handleChange(e)}
            value={searchKey}
          />
          <CSVLink
            data={filteredData}
            filename="transactions.csv"
            className="finance-transaction__download--hidden"
            ref={csvLinkRef}
          />
          <Dropdown>
            <Dropdown.Toggle
              as={customDropdownToggle}
              id="dropdown-custom-components"
            ></Dropdown.Toggle>
            <Dropdown.Menu className="finance-transaction__dropdown-menu">
              <Dropdown.Item
                className="finance-transaction__dropdown-item"
                onClick={() => downloadCSV()}
              >
                Download CSV
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="finance-transaction__table-body">
        <Table
          columnProps={TRANSACTION_COLUMNS}
          dataProps={transactionList}
          filterProps={searchKey}
          sortByEnabled={true}
          setFilteredData={(filteredData) =>
            transactionDashboardDispatch({
              type: "SET_FILTERED_DATA",
              payload: { filteredData },
            })
          }
        />
      </div>
    </div>
  );
};
export default TransactionPage;
