import React, { Component } from "react";
import { Image, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import smallLogo from "../../img/logo/logo-archdiocese_white-90.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import DvrOutlinedIcon from "@material-ui/icons/DvrOutlined";
import { showAdminMenu } from "../admin/service/admin";

class Header extends Component {
  render() {
    const { type, user, hide, disablecontrol, smallpic, loader } = this.props;
    const showAdmin = showAdminMenu(user);
    return (
      <>
        <div
          style={{
            backgroundColor: "#ffffff",
            height: "61px",
          }}
        >
          <Navbar
            bg=""
            fixed="top"
            style={{
              backgroundColor: "#ffffff",
              borderBottom: "1px solid #efefef",
              boxShadow: "1px 1px 4px #dfdfdf",
              height: "60px",
            }}
            // expand="sm"
          >
            <Navbar.Text style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              {!type && (
                <Link
                  to="/home"
                  style={{ textDecoration: "none", marginTop: " 5px" }}
                >
                  <div className="float-left" style={{ width: "35px" }}>
                    <img
                      alt="myCatholicSG"
                      src={smallLogo}
                      width="30px"
                      height="30px"
                      className="d-inline-block align-top"
                    />{" "}
                  </div>
                  <div className="float-left" style={{ marginTop: "3px" }}>
                    <span className="font-italic">my</span>CatholicSG
                  </div>
                </Link>
              )}

              {type === "backonly" && (
                <button
                  onClick={() => this.props.history.goBack()}
                  className="btn-default btn"
                >
                  <div className="float-left" style={{ width: "35px" }}>
                    <ArrowBackIosIcon
                      style={{ fontSize: "30", color: "#aaaaaa" }}
                    />
                  </div>
                </button>
              )}
              {type === "backandlogo" && (
                <button
                  disabled={disablecontrol}
                  onClick={() => this.props.history.goBack()}
                  className="btn-default btn p-0"
                >
                  <div
                    className="float-left"
                    style={{ paddingTop: "2px", width: "25px" }}
                  >
                    <ArrowBackIosIcon
                      style={{ fontSize: "20", color: "#666666" }}
                    />
                  </div>
                  <div className="float-left p-0" style={{ width: "35px" }}>
                    <img
                      alt="myCatholicSG"
                      src={smallLogo}
                      width="30px"
                      height="30px"
                      className="d-inline-block align-top"
                    />{" "}
                  </div>
                  <div className="float-left" style={{ marginTop: "3px" }}>
                    <span className="font-italic">my</span>CatholicSG
                  </div>
                </button>
              )}
            </Navbar.Text>
            {/* <Navbar.Toggle /> */}
            <Navbar.Collapse
              className="justify-content-end"
              id="basic-navbar-nav"
            >
              <Navbar.Text>
                {/* <div className="clearfix d-sm-none my-2"></div> */}

                {/* <div className="clearfix d-sm-none my-3"></div> */}

                {hide !== "profile" && (
                  <div className="float-right mx-2">
                    {/* <a
                    className="btn btn-link ml-0 px-0 my-0 py-0"
                    style={{ textDecoration: "none" }}
                    rel="noopener noreferrer"
                    href="profile"
                  > */}
                    <Link
                      to="/profile"
                      className="btn btn-link ml-0 px-0 my-0 py-0"
                    >
                      {!smallpic && (
                        <AccountCircleOutlinedIcon
                          style={{
                            fontSize: "24px",
                            color: "#888",
                            marginRight: "5px",
                            marginTop: "-1px",
                          }}
                        />
                      )}
                      {smallpic && (
                        <Image
                          src={smallpic}
                          width="25px"
                          height="25px"
                          alt=""
                          roundedCircle
                          style={{
                            zIndex: "1",
                            marginRight: "5px",
                            marginTop: "-5px",
                          }}
                        />
                      )}{" "}
                      <span className="float-right d-none d-sm-block">
                        Profile
                      </span>
                    </Link>
                  </div>
                )}
                {showAdmin === true && (
                  <div className="float-right mx-2">
                    {/* <Link
                    to="/commandcenter"
                    style={{ textDecoration: "none", marginRight: "5px" }}
                  >
                    <DvrOutlinedIcon
                      style={{ fontSize: "24px", color: "#888" }}
                    />{" "}
                    <span className="float-right d-none d-sm-block">Admin</span>
                  </Link> */}
                    {/* <a
                    className="btn btn-link ml-0 px-0 my-0 py-0"
                    style={{ textDecoration: "none" }}
                    rel="noopener noreferrer"
                    href="commandcenter"
                  > */}
                    <Link
                      to="/commandcenter"
                      className="btn btn-link ml-0 px-0 my-0 py-0"
                    >
                      <DvrOutlinedIcon
                        style={{
                          fontSize: "24px",
                          color: "#888",
                          marginRight: "5px",
                        }}
                      />{" "}
                      <span className="float-right d-none d-sm-block">
                        Admin
                      </span>
                    </Link>
                  </div>
                )}
                {/* <div className="clearfix d-sm-none my-2"></div> */}
              </Navbar.Text>
            </Navbar.Collapse>
          </Navbar>
        </div>
        {loader && <div className="horizontalloader yt-loader"></div>}
      </>
    );
  }
}

export default withRouter(Header);
