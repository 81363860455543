import React, { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const InputPassword = ({
  name,
  label,
  error,
  showsuccess,
  disabled = false,
  css = "",
  bold = false,
  inputSize = "lg",
  labelextra = null,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  let formClass = `form-control form-control-${inputSize} `;
  if (error) {
    formClass = `${formClass} is-invalid`;
  }
  if (showsuccess) {
    formClass = `${formClass} is-valid`;
  }
  return (
    <div className={`form-group ${css}`}>
      {bold === false
        ? label && (
            <label htmlFor={name}>
              {label} {labelextra}
            </label>
          )
        : label && (
            <label htmlFor={name}>
              <span className="font-weight-bold">
                {label}
                {labelextra}
              </span>
            </label>
          )}

      <div className="input-group">
        <input
          {...rest}
          name={name}
          id={name}
          className={formClass}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          disabled={disabled}
          type={showPassword ? "text" : "password"}
        />
        <div className="input-group-append">
          <button
            className="btn greyframecolor"
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {!showPassword ? (
              <VisibilityIcon className="text-muted" />
            ) : (
              <VisibilityOffIcon className="text-muted" />
            )}
          </button>
        </div>
      </div>
      {error && (
        <small style={{ marginTop: "5px" }} className="text-danger">
          {error}
        </small>
      )}
    </div>
  );
};

export default InputPassword;
