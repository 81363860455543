import fire from "../../../services/fire";
// import { FieldValue } from "firebase-admin/firestore";

import { sendGenericHTMLEmail } from "../../email/emailTemplateHelpers";

import { getParishNameById } from "../../../services/parish";

import { getNameById } from "./resources";
import { parishes } from "./parishes";

const getDraftFromDB = async(userid) => {
	try {
		const db = fire.firestore();
		const catechismRef = db
			.collection("catechismregistration")
			.where("submittedBy", "==", userid)
			.where("hasReapplied", "==", false)
			.orderBy("submittedAt", "desc");
		const refResult = await catechismRef.get();

		if (refResult) {
			let savedDraft = [];
	
			refResult.forEach((doc) => {
				const docData = doc.data();
	
				docData.id = doc.id;
	
				savedDraft.push(docData);
			});
			
			return savedDraft;
		}
	
		return [];
	} catch (e) {
		console.log("getDraftFromDB::error:", e);
	
		return [];
	}
};

const saveDraftToDB = async(data) => {
	try {
		const db = fire.firestore();
		const catechismCollection = db.collection("catechismregistration");
		
		let catechismDraftRef;
	
		if (data.id) {
			catechismDraftRef = catechismCollection.doc(data.id);
		} else {
			catechismDraftRef = catechismCollection.doc();
		}
	
		const docData = {
			submittedAt: new Date(),
			submittedBy: data.submittedBy,
			submittedByName: data.submittedByName,
			formStatus: data.formStatus,
			hasReapplied: false,
		};
	
		if (data.selectedParishId) {
			docData.selectedParish = data.selectedParishId;
		}
		
		//
		// delete value if incoming data.selectedParish is empty and outgoing prevState.selectedParish has value
		// when user erases input on a previously saved item that has input
		//
		// else if (prevState.selectedParish) {
		// 	docData.selectedParish = FieldValue.delete();
		// }
		//
	
		if (data.selectedParishName) {
			docData.selectedParishName = data.selectedParishName;
		}
	
		if (data.timeslot) {
			docData.timeslot = data.timeslot;
		}

		if (data.parishId) {
			docData.parishId = data.parishId.toString();
		}

		if (data.coordinatorEmail) {
			docData.coordinatorEmail = data.coordinatorEmail;
		}
	
		if (data.childName) {
			docData.childName = data.childName;
		}
	
		if (data.childGender) {
			docData.childGender = parseInt(data.childGender);
		}
	
		if (data.dob) {
			docData.dob = data.dob;
		}
	
		if (data.countryOfBirth) {
			docData.countryOfBirth = getNameById("countryList", data.countryOfBirth);
		}
	
		if (data.nationality) {
			docData.nationality = getNameById("countryList", data.nationality);
		}
	
		if (data.citizenship) {
			docData.citizenship = parseInt(data.citizenship);
		}
	
		if (data.homeAddress) {
			docData.homeAddress = data.homeAddress;
		}
	
		if (data.postalCode) {
			docData.postalCode = parseInt(data.postalCode);
		}
	
		if (data.parishReason) {
			docData.parishReason = data.parishReason;
		}
	
		if (data.isBaptized) {
			docData.isBaptized = data.isBaptized === "true";
		}
	
		if (data.isBaptizedinSG) {
			docData.isBaptizedinSG = data.isBaptizedinSG === "true";
		}
	
		if (data.dateOfBaptism) {
			docData.dateOfBaptism = data.dateOfBaptism;
		}
	
		if (data.churchOfBaptism) {
			if (isNaN(parseInt(data.churchOfBaptism))) {
				docData.churchOfBaptism = data.churchOfBaptism;
			} else {
				docData.churchOfBaptism = getParishNameById(data.churchOfBaptism);
			}
		}
	
		if (data.nameOfSchool) {
			docData.nameOfSchool = data.nameOfSchool;
		}
	
		if (data.levelInSchool) {
			docData.levelInSchool = data.levelInSchool;
		}
	
		if (data.hasSiblings) {
			docData.hasSiblings = data.hasSiblings === "true";
		}
	
		if (data.siblingInformation) {
			docData.siblingInformation = data.siblingInformation;
		}
	
		if (data.additionalInfo) {
			docData.additionalInfo = data.additionalInfo;
		}
	
		if (data.motherName) {
			docData.motherName = data.motherName;
		}
	
		if (data.motherMobileNo) {
			docData.motherMobileNo = parseInt(data.motherMobileNo.replace(/\s+/g, ""));
		}
	
		if (data.motherHomeNo) {
			docData.motherHomeNo = parseInt(data.motherHomeNo.replace(/\s+/g, ""));
		}
	
		if (data.motherEmail) {
			docData.motherEmail = data.motherEmail;
		}
	
		if (data.motherMaritalStatus) {
			docData.motherMaritalStatus = getNameById("maritalStatus", data.motherMaritalStatus);
		}
	
		if (data.isMotherMarriedInChurch) {
			docData.isMotherMarriedInChurch = data.isMotherMarriedInChurch;
		}
	
		if (data.motherReligion) {
			docData.motherReligion = getNameById("religionList", data.motherReligion);
		}
	
		if (data.motherParishInvolvement) {
			docData.motherParishInvolvement = data.motherParishInvolvement;
		}
	
		if (data.fatherName) {
			docData.fatherName = data.fatherName;
		}
	
		if (data.fatherMobileNo) {
			docData.fatherMobileNo = parseInt(data.fatherMobileNo.replace(/\s+/g, ""));
		}
	
		if (data.fatherHomeNo) {
			docData.fatherHomeNo = parseInt(data.fatherHomeNo.replace(/\s+/g, ""));
		}
	
		if (data.fatherEmail) {
			docData.fatherEmail = data.fatherEmail;
		}
	
		if (data.fatherMaritalStatus) {
			docData.fatherMaritalStatus = getNameById("maritalStatus", data.fatherMaritalStatus);
		}
	
		if (data.isFatherMarriedInChurch) {
			docData.isFatherMarriedInChurch = data.isFatherMarriedInChurch;
		}
	
		if (data.fatherReligion) {
			docData.fatherReligion = getNameById("religionList", data.fatherReligion);
		}
	
		if (data.fatherParishInvolvement) {
			docData.fatherParishInvolvement = data.fatherParishInvolvement;
		}
	
		if (data.mainContact) {
			docData.mainContact = parseInt(data.mainContact) - 1;
		}
	
		if (data.guardianName) {
			docData.guardianName = data.guardianName;
		}
	
		if (data.guardianMobileNo) {
			docData.guardianMobileNo = parseInt(data.guardianMobileNo.replace(/\s+/g, ""));
		}
	
		if (data.guardianEmail) {
			docData.guardianEmail = data.guardianEmail;
		}
	
		if (data.formSubmitter) {
			docData.formSubmitter = data.formSubmitter;
		}
	
		if (data.formSubmitterRelation) {
			docData.formSubmitterRelation = data.formSubmitterRelation;
		}
	
		if (data.hasOwnProperty("personalUndertaking")) {
			docData.personalUndertaking = data.personalUndertaking;
		}
	
		if (data.hasOwnProperty("dataStorageAgreement")) {
			docData.dataStorageAgreement = data.dataStorageAgreement;
		}
	
		if (data.hasOwnProperty("pdpaAgreement")) {
			docData.pdpaAgreement = data.pdpaAgreement;
		}
	
		if (data.hasOwnProperty("commsAgreement")) {
			docData.commsAgreement = data.commsAgreement;
		}
	
		if (data.hasOwnProperty("volunteerForActivities")) {
			docData.volunteerForActivities = data.volunteerForActivities;
		}
	
		if (!data.createdAt) {
			docData.createdAt = new Date();
	
			await catechismDraftRef.set(docData);
		} else {
			await catechismDraftRef.update(docData);
		}
	} catch (e) {
		console.log("saveDraftToDB::error:", e);
	}
};

const getBirthCertUrl = (userid, filename) => {
	const imageDir = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/user`;
	const photo = encodeURIComponent(`/${userid}/private/baptism/${filename}`);
	const bigPic = `${imageDir}${photo}?alt=media`;

	return bigPic;
};

const saveUserRegistry = async(data) => {
	try {
		const db = fire.firestore();
		const useRegistryCollection = db.collection("userregistry");

		const docData = {
			userId: data.userId.toString(),
			submittedAt: new Date(),
		};

		docData.dob = data.dob;
		docData.isBaptized = data.isBaptized.toString() === "true";

		if (docData.isBaptized) {
			docData.isBaptizedInSG = data.isBaptizedInSG.toString() === "true";
			docData.churchOfBaptism = data.churchOfBaptism;
			docData.dateOfBaptism = data.dateOfBaptism;
		}

		if (data.baptismCertKey) {
			docData.baptismCertKey = data.baptismCertKey;
		}

		docData.isLinked = true;

		if (data.motherEmail && (parseInt(data.mainContact) === 0 || parseInt(data.mainContact) === 2)) {
			docData.motherEmail = data.motherEmail;
			docData.motherReligion = data.motherReligion;
			docData.motherMobile = data.motherMobileNo.toString();
			docData.motherMaritalStatus = data.motherMaritalStatus;
		}

		if (data.fatherEmail && (parseInt(data.mainContact) === 0 || parseInt(data.mainContact) === 1)) {
			docData.fatherEmail = data.fatherEmail;
			docData.fatherReligion = data.fatherReligion;
			docData.fatherMobile = data.fatherMobileNo.toString();
			docData.fatherMaritalStatus = data.fatherMaritalStatus;
		}

		if (data.guardianEmail && parseInt(data.mainContact) === 3) {
			docData.guardianEmail = data.guardianEmail;
			docData.guardianMobile = data.guardianMobileNo.toString();
		}

		let userRegistryRef;

		if (data.userRegistrationId) {
			userRegistryRef = useRegistryCollection.doc(data.userRegistrationId);

			await userRegistryRef.update(docData);
		} else {
			userRegistryRef = useRegistryCollection.doc();

			docData.createdAt = new Date();

			await userRegistryRef.set(docData);
		}


		const arrayOfContactEmails = [];

		let selectedMainContacts = "";

		switch (parseInt(data.mainContact)) {
		case 0:
			selectedMainContacts = `${data.fatherName} & ${data.motherName}`;
			arrayOfContactEmails.push(data.fatherEmail);
			arrayOfContactEmails.push(data.motherEmail);
			break;
		case 1:
			selectedMainContacts = `${data.fatherName}`;
			arrayOfContactEmails.push(data.fatherEmail);
			break;
		case 2:
			selectedMainContacts = `${data.motherName}`;
			arrayOfContactEmails.push(data.motherEmail);
			break;
		case 3:
			selectedMainContacts = `${data.guardianName}`;
			arrayOfContactEmails.push(data.guardianEmail);
			break;
		default:
		}

		const emailHTML = `Dear ${selectedMainContacts},
			<br/><br/>
			Your registration has been submitted to the Catechetical Ministry of ${data.selectedParishName} for review. You will receive an email whether it has been successful or not. For any clarifications or enquiries, please contact ${data.coordinatorEmail}.
			<br/><br/>
			God Bless,
			<br/>
			myCatholicSG Admin`;

		await sendGenericHTMLEmail({
			email: [ ...new Set(arrayOfContactEmails) ],
			emailSubject: "Catechetical Registration Submitted",
			emailBody: emailHTML,
		});

		updateCatechismRegistrationFormStatus(data.userId, 2, docData.baptismCertKey || "", data.userRegistrationId || "");
	} catch(e) {
		console.log("saveUserRegistry::error:", e);
	}
};

const updateCatechismRegistrationFormStatus = async(userId, formStatus, hasBaptismCert, userRegistryId = "") => {
	try {
		const db = fire.firestore();
		const catechismCollection = db.collection("catechismregistration");
		const catechismRef = catechismCollection
			.where("submittedBy", "==", userId)
			.where("hasReapplied", "==", false)
			.orderBy("submittedAt", "desc");
		const refResult = await catechismRef.get();

		let catechismRegistration = []

		if (refResult) {
			refResult.forEach((doc) => {
				if (doc.id) {
					catechismRegistration.push(doc.id);
				}
			});
		}

		if (catechismRegistration.length) {
			let usrRgsttnId = userRegistryId;

			if (!usrRgsttnId) {
				const useRegistryCollection = db.collection("userregistry");
				const userRegistryRef = useRegistryCollection
					.where("userId", "==", userId)
					.orderBy("submittedAt", "desc");
				const refResult2 = await userRegistryRef.get();
	
				if (refResult2) {
					const userRegistrationIds = [];
	
					refResult2.forEach((doc2) => {
						userRegistrationIds.push(doc2.id);
					});
	
					if (userRegistrationIds.length) {
						usrRgsttnId = userRegistrationIds[0]
					}
				}
			}

			if (usrRgsttnId) {
				const catechismDoc = catechismCollection.doc(catechismRegistration[0]);
				const docData = {
					formStatus,
					userRegistrationId: usrRgsttnId,
				};
	
				await catechismDoc.update(docData);
	
				if (hasBaptismCert) {
					alert("Child's Baptism Certificate has been successfully uploaded.");
				}
			}
		}
	} catch (e) {
		console.log("updateCatechismRegistrationFormStatus::error:e", e);
	}
}

const storeParishes = async() => {
	try {
		const db = fire.firestore();
		const catechismCollection = db
			.collection("settings")
			.doc("catechismsettings")
			.collection("config");

		let catechismRef;

		parishes.forEach(async(item) => {
			catechismRef = catechismCollection
				.where("parish", "==", item.parish);
			
			let refResult = await catechismRef.get();
			let parish = [];

			if (refResult) {
				refResult.forEach((doc) => {
					let docData = doc.data();
		
					docData.id = doc.id;
		
					parish.push(docData);
				});
			}

			if (parish.length) {
				catechismRef = catechismCollection.doc(parish[0].id);

				await catechismRef.update(item);
			} else {
				catechismRef = catechismCollection.doc();

				await catechismRef.set(item);
			}
		});
	} catch (e) {
		console.log("storeParishes::error:", e);
	}
}

const getParishesFromDB = async() => {
	try {
		const db = fire.firestore();
		const catechismCollection = db
			.collection("settings")
			.doc("catechismsettings")
			.collection("config");
		const refResult = await catechismCollection.get();

		if (refResult) {
			let parishes = [];
	
			refResult.forEach((doc) => {
				const docData = doc.data();
	
				docData.id = doc.id;
	
				parishes.push(docData);
			});
			
			return parishes;
		}

		return [];
	} catch (e) {
		console.log("getParishesFromDB::error:", e);

		return [];
	}
};

const createNewSubmission = async (data, oldId) => {
	try {
		const db = fire.firestore();
		const catechismCollection = db.collection("catechismregistration");
		const catechismDraftRef = catechismCollection.doc(oldId);
		const refResult = await catechismDraftRef.get();

		if (refResult) {
			let catechismRegistration = {};

			await catechismDraftRef.update({ hasReapplied: true });

			catechismRegistration = refResult.data();

			if (Object.keys(catechismRegistration).length > 0) {
				const catechismDraftRef2 = catechismCollection.doc();
				const docData = {
					...catechismRegistration,
				};
		
				docData.submittedAt = new Date();
				docData.submittedBy = data.submittedBy;
				docData.submittedByName = data.submittedByName;
				docData.formStatus = data.formStatus;
				docData.createdAt = new Date();

				delete docData.notes;
				delete docData.approvalBy;
				delete docData.approvalByName;

				await catechismDraftRef2.set(docData);
			}
		}
	} catch (e) {
		console.log("createNewSubmission::error:e", e);
	}
};

const enterSubmission = async (data, docId, docData2) => {
	try {
		const db = fire.firestore();
		const catechismCollection = db.collection("catechismregistration");
		const catechismDraftRef = catechismCollection.doc(docId);
		const docData = {
			submittedAt: new Date(),
			submittedBy: data.submittedBy,
			submittedByName: data.submittedByName,
		};

		await catechismDraftRef.update(docData);

		saveUserRegistry(docData2);
	} catch (e) {
		console.log("enterSubmission::error:", e)
	}
};

export {
	getDraftFromDB,
	saveDraftToDB,
	getBirthCertUrl,
	saveUserRegistry,
	storeParishes,
	getParishesFromDB,
	createNewSubmission,
	enterSubmission,
 };