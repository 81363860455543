import React from "react";

function Checkbox({ name, label, value, error, ...rest }) {
  return (
    <div className="p-2">
      <div className="pb-0" style={{ fontSize: "14px", lineHeight: "140%" }}>
        <div className="text-center custom-control-lg custom-control custom-checkbox pointer">
          <input
            className="custom-control-input pointer"
            checked={value}
            id={name}
            name={name}
            type="checkbox"
            {...rest}
          />
          <label className="custom-control-label" htmlFor={name}>
            <div className="d-flex justify-content-start align-items-center">
              {label}
            </div>
          </label>
        </div>
        {error && (
          <div className="ml-4">
            <small style={{ marginTop: "5px" }} className="text-danger">
              {error}
            </small>
          </div>
        )}
      </div>
    </div>
  );
}

export default Checkbox;
