import fire from "../../../services/fire";
import { getOrgByCode } from "../../../services/organisation";
import {
  getDay,
  mapToDay,
  getMassTime as getRoomTime,
  getMassDate as getRoomDate,
} from "../../../utils/utils";

export async function isBookingEnabled(org, month) {
  let result = false;
  //do logic here to check if visible or not

  return result;
}

export async function getMonthlyRoomTimings(org, firstDay, lastDay) {
  let roomtimings = [];
  const db = fire.firestore();

  const snapshot = await db
    .collection(`rooms/${org}/orgrooms`)
    .where("isActive", "==", true)
    .where("date", ">=", firstDay)
    .where("date", "<=", lastDay)
    .orderBy("date", "asc")
    .get();

  snapshot.forEach(function (doc) {
    let data = doc.data();
    if (data.hasOwnProperty("type") === false) {
      roomtimings.push(doc.data());
    }
  });
  return roomtimings;
}

export async function getRoomDetails(org, orgcode, preferredschedule) {
  if (!preferredschedule) {
    return null;
  }
  let chosenroom = [];
  const db = fire.firestore();

  const snapshot = await db
    .collection(`rooms/${orgcode}/orgrooms`)
    .where("isActive", "==", true)
    .where("id", "==", preferredschedule)
    .where("org", "==", parseInt(org))
    .get();

  snapshot.forEach(function (doc) {
    chosenroom.push(doc.data());
  });

  return chosenroom;
}

export async function getRoomBookingsByTenant(tenantId) {
  if (!tenantId) {
    return null;
  }
  const db = fire.firestore();

  const bookings = await db
    .collection("roombookings")
    .where("tenantId", "==", tenantId)
    .orderBy("created", "desc")
    .get();

  var list = [];
  bookings.forEach((doc) => {
    list.push(doc.data());
  });

  return list;
}

export function isAllowedToBook(org, preferredorg) {
  const orgobj = getOrgByCode(preferredorg);
  //ALLOW TO BOOK WHEN ORG MATCHES
  if (orgobj && isOrgMatch(org, orgobj._id)) {
    return true;
  }
  return false;
}

export function isOrgMatch(userorg, roomorg) {
  // console.log(`User Org: ${userorg} Chosen Org: ${roomorg}`);
  if (parseInt(userorg) === parseInt(roomorg)) {
    return true;
  }
  return false;
}

export async function hasBookWithinTimeframe(userdata) {
  var result = false;
  //TODO: do logic if user has booked in certain timeframe
  if (userdata) {
    let lastroombooking = await userdata.data();
    if (lastroombooking.hasOwnProperty("lastroombooking")) {
      // console.log("Last Room:", lastroombooking.lastroombooking);
      result = true;
    } else {
      // console.log("Have not booked");
      result = false;
    }
  }

  return result;
}

export function getMonthlyBookings(bookings, preferredYear, preferredMonth) {
  const actualdate = `${preferredMonth} 01 ${preferredYear}`;
  let date = new Date(actualdate);
  let y = date.getFullYear();
  let m = date.getMonth();
  let firstDay = new Date(y, m, 1).getTime() / 1000;
  let lastDay = new Date(y, m + 1, 0, 23, 59, 59).getTime() / 1000;

  let list = [];
  // console.log(`First Day: ${firstDay} Last Day: ${lastDay}`);

  for (let item = 0; item < bookings.length; item++) {
    let roomDate = bookings[item].roomDate.seconds;
    // console.log("Booking: ", bookings[item]);
    //skip if beyond the range
    if (roomDate >= firstDay && roomDate <= lastDay) {
      list.push(bookings[item]);
    }
  }
  return list;
}

export function isRoomFull(available) {
  if (available <= 0) {
    return true;
  }
  return false;
}

export function isRoomTimeExpired(date, localtime) {
  const roomtime = date.seconds * 1000;
  let roomclosed = false;

  if (localtime > roomtime) {
    roomclosed = true;
  }

  return roomclosed;
}

export function allowSelfCheckin(date, localtime, checkinclose = 45) {
  const time = localtime;
  const eventtime = date.seconds * 1000;
  const cutoff = checkinclose;
  const start = eventtime - cutoff * 60 * 1000;
  const end = eventtime + cutoff * 60 * 1000;

  if (start <= time && time <= end) {
    return true;
  }
  return false;
}

export function allowRoomCancel(date, localtime, cancelcutoff = 48) {
  const roomtime = date.seconds * 1000;
  let allowcancel = true;
  let hourstoclose = cancelcutoff;
  let hourstoclosesec = hourstoclose * 60 * 60 * 1000;

  //NOT EXPLICITLY CLOSED, just to estimate if closed or not
  if (localtime > roomtime - hourstoclosesec) {
    allowcancel = false;
  }
  return allowcancel;
}

export function getRoomObject(bookings, id) {
  return bookings.filter((booking) => booking.roomId === id);
}

export function removeRoomObject(bookings, id) {
  return bookings.filter((booking) => booking.roomId !== id);
}

export function getRoomLineDescription(booking) {
  let day = getDay(booking.roomDate);
  let labelday = mapToDay(day);
  let roomtime = getRoomTime(booking.roomDate);
  let labeldate = getRoomDate(booking.roomDate);
  return `${labelday}, ${labeldate} at ${roomtime}`;
}

export function isRoomClosed(room, localtime, cutoff = 48) {
  const { closed, date } = room;
  const roomtime = date.seconds * 1000;
  let roomclosed = false;
  let hourstoclose = cutoff;
  let hourstoclosesec = hourstoclose * 60 * 60 * 1000;
  if (closed) {
    //this room is marked as "closed" = true in the back end or code
    roomclosed = true;
  } else {
    //NOT EXPLICITLY CLOSED, just to estimate if closed or not
    if (localtime > roomtime - hourstoclosesec) {
      // console.log(`Date: ${date}`);
      // console.log(
      //   `Localtime: ${localtime} Roomtime: ${roomtime} Hours to Close: ${hourstoclosesec}`
      // );
      roomclosed = true;
    }
  }
  return roomclosed;
}
