import { getEventDate, getEventTimeAMPM } from "../../../utils/date";

export const VENDOR_COLUMNS = [
  { Header: "Name", accessor: "name" },
  {
    Header: "Date Added",
    accessor: "created",
    Cell: (props) => {
      return props.value
        ? getEventDate(props.value) +
            " " +
            getEventTimeAMPM(new Date(props.value.seconds * 1000))
        : "-";
    },
  },
  { Header: "Email", accessor: "email" },
  { Header: "Contact", accessor: "contact" },
  { Header: "Status", accessor: "isActive" },
  { Header: "Action", accessor: "action" },
];

export const INIT_VENDOR_STATE = {
  searchKey: "",
  volunteerList: [],
  isVolunteerListReady: false,
  isConfirmModalOpen: false,
  isVolunteerModalOpen: false,
  addVolunteerField: "",
  isBtnLoading: false,
  isError: false,
  confirmModalMessage: "",
  confirmCallback: () => {},
  isAssignmentsModalOpen: false,
  selectedVendorUid: "",
  unassignedCampaigns: [],
  assignedCampaigns: [],
  selectedCampaign: { campaignName: "Select Campaign" },
  isAssignmentsLoading: true,
  assignmentsSearchKey: "",
  isAssignmentsConfirmModalOpen: false,
  confirmAssignmentsModalMessage: "",
  isAssignmentsBtnLoading: false,
  isAssignmentsError: false,
  assignmentsConfirmCallback: () => {},
};

export const vendorDashboardReducer = (state, action) => {
  const { type = "", payload = {} } = action;
  switch (type) {
    case "SET_SEARCH_KEY": {
      const { searchKey } = payload;
      return {
        ...state,
        searchKey,
      };
    }
    case "SET_ASSIGNMENTS_CONFIRM_MODAL_OPEN": {
      const { isAssignmentsConfirmModalOpen } = payload;
      return {
        ...state,
        isAssignmentsConfirmModalOpen,
      };
    }
    case "SET_CONFIRM_ASSIGNMENTS_MODAL_MESSAGE": {
      const { confirmAssignmentsModalMessage } = payload;
      return {
        ...state,
        confirmAssignmentsModalMessage,
      };
    }
    case "SET_IS_ASSIGNMENTS_BTN_LOADING": {
      const { isAssignmentsBtnLoading } = payload;
      return {
        ...state,
        isAssignmentsBtnLoading,
      };
    }
    case "SET_ASSIGNMENTS_CONFIRM_CALLBACK": {
      const { assignmentsConfirmCallback } = payload;
      return {
        ...state,
        assignmentsConfirmCallback,
      };
    }
    case "SET_ASSIGNMENTS_SEARCH_KEY": {
      const { assignmentsSearchKey } = payload;
      return {
        ...state,
        assignmentsSearchKey,
      };
    }
    case "RESET_ASSIGNMENTS_MODAL": {
      return {
        ...state,
        isAssignmentsConfirmModalOpen: false,
        confirmAssignmentsModalMessage: "",
        isAssignmentsBtnLoading: false,
        isAssignmentsError: false,
        assignmentsSearchKey: "",
        assignmentsConfirmCallback: () => {},
        selectedCampaign: { campaignName: "Select Campaign" },
        isAssignmentsLoading: false,
      };
    }
    case "SET_UNASSIGNED_CAMPAIGNS": {
      const { unassignedCampaigns } = payload;
      return {
        ...state,
        unassignedCampaigns,
      };
    }
    case "SET_ASSIGNED_CAMPAIGNS": {
      const { assignedCampaigns } = payload;
      return {
        ...state,
        assignedCampaigns,
        isAssignmentsLoading: false,
      };
    }
    case "CLOSE_ASSIGNMENTS_MODAL": {
      return {
        ...state,
        isAssignmentsModalOpen: false,
        selectedVendorUid: "",
        assignedCampaigns: [],
        unassignedCampaigns: [],
        selectedCampaign: { campaignName: "Select Campaign" },
        assignmentsSearchKey: "",
        isAssignmentsLoading: false,
      };
    }
    case "SET_SELECTED_CAMPAIGN": {
      const { selectedCampaign } = payload;
      return {
        ...state,
        selectedCampaign,
      };
    }
    case "SET_ASSIGNMENTS_MODAL_OPEN": {
      const { isAssignmentsModalOpen } = payload;
      return {
        ...state,
        isAssignmentsModalOpen,
      };
    }
    case "SET_ADD_CAMPAIGN_STATE": {
      const { currentState } = payload;
      return {
        ...state,
        ...currentState,
      };
    }
    case "SET_IS_ASSIGNMENTS_ERROR": {
      const { isAssignmentsError } = payload;
      return {
        ...state,
        isAssignmentsError,
      };
    }
    case "SET_SELECTED_VENDOR_UID": {
      const { selectedVendorUid } = payload;
      return {
        ...state,
        selectedVendorUid,
      };
    }
    case "SET_ADD_VOLUNTEER_FIELD": {
      const { addVolunteerField } = payload;
      return {
        ...state,
        addVolunteerField,
      };
    }
    case "SET_IS_BTN_LOADING": {
      const { isBtnLoading } = payload;
      return {
        ...state,
        isBtnLoading,
      };
    }
    case "SET_IS_CONFIRM_MODAL_OPEN": {
      const { isConfirmModalOpen } = payload;
      return {
        ...state,
        isConfirmModalOpen,
      };
    }
    case "SET_CONFIRM_MODAL_MESSAGE": {
      const { confirmModalMessage } = payload;
      return {
        ...state,
        confirmModalMessage,
      };
    }
    case "SET_IS_ERROR": {
      const { isError } = payload;
      return {
        ...state,
        isError,
      };
    }
    case "SET_ADD_VOLUNTEER_STATE": {
      const { currentState } = payload;
      return {
        ...state,
        ...currentState,
      };
    }
    case "RESET_ADD_VOLUNTEER_STATE": {
      return {
        ...state,
        addVolunteerField: "",
        isConfirmModalOpen: false,
        isBtnLoading: false,
        isError: false,
        confirmModalMessage: "",
        confirmCallback: () => {},
      };
    }
    case "SET_VOLUNTEER_LIST": {
      const { volunteerList = [] } = payload;
      return {
        ...state,
        volunteerList,
        isVolunteerListReady: true,
      };
    }
    case "SET_IS_VOLUNTEER_MODAL_OPEN": {
      const { isVolunteerModalOpen } = payload;
      return {
        ...state,
        isVolunteerModalOpen,
      };
    }
    case "SET_CONFIRM_CALLBACK": {
      const { confirmCallback } = payload;
      return {
        ...state,
        confirmCallback,
      };
    }
    case "SET_READY": {
      return {
        ...state,
        isVolunteerListReady: true,
      };
    }
    default: {
      return state;
    }
  }
};
