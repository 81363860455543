import React, { useEffect, useState } from "react";
import Header from "../../common/header";
import PapalMassBallotPlaceholder from "../component/papalMassPlaceholder";
import ReloadModal from "../../common/reloadModal";
import { getObjectSettingsDB } from "../../../services/objects";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import PageNotFound from "../../common/pageNotFound";
import LoginMiniForm from "../../account/page/loginMini";
import { Modal } from "react-bootstrap";

function PapalMassBallot({ user }) {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(!user);

  useEffect(() => {
    async function getData() {
      const obj = await getObjectSettingsDB();
      const show = obj?.frontbanner?.show || false;
      setShow(show);
      setLoading(false);
    }

    getData();
  }, []);

  if (loading === true) {
    return (
      <LoadingSpinnerNav user={user} smallpic={user?.photoURL} hide="profile" />
    );
  }

  if (!show) {
    //if banner is not shown
    return <PageNotFound user={user} />;
  }

  return (
    <div>
      <Header user={user} smallpic={user?.photoURL} hide="profile" />
      <ReloadModal />
      <PapalMassBallotPlaceholder user={user} />
      <Modal
        show={showModal}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
      >
        <div className="text-left mx-4">
          <LoginMiniForm
            source="papalmassballot"
            successCallback={() => {
              setShowModal(false);
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

export default PapalMassBallot;
