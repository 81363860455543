import { RegistrationDatum } from "../hooks/hooks";
import { parishes } from "../services/parishes";

/**
 * @typedef Level
 * @prop {number} id
 * @prop {string} name
 */

/** @type {Level[]} */
export const levelRef = [
  { name: "K1", id: 5 },
  { name: "K2", id: 6 },
  { name: "L1", id: 7 },
  { name: "L2", id: 8 },
  { name: "L3", id: 9 },
  { name: "L4", id: 10 },
  { name: "L5", id: 11 },
  { name: "L6", id: 12 },
  { name: "L7", id: 13 },
  { name: "L8", id: 14 },
  { name: "L9", id: 15 },
  { name: "L10", id: 16 },
];

/**
 * @param {number} _id
 */
export function levelIdToName(_id) {
  return levelRef.find(({ id }) => _id === id)?.name;
}

/**
 * @param {RegistrationDatum}
 * @param {Date} date
 */
export function getLevelAtDate({ level: { id, year } }, date) {
  const computedLevel = id + date.getFullYear() - +year;
  // console.log(computedLevel);
  return computedLevel;
}

/** @param {RegistrationDatum} */
export function getCurrentLevel({ level: { id, year }, selectedParishId }) {
  const parish = parishes.find(({ parishId }) => parishId == selectedParishId);

  if (!parish) return null;
  const computedLevel = id + +parish.registrationYear - +year;
  // console.log(computedLevel);
  return computedLevel;
}
