import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncCheckBranchCode({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/checkBranchCode"));
  console.log("Loading", AsyncCheckBranchCode.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncCheckBranchCode;
