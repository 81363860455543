import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Header from "../../common/header";
import fire from "../../../services/fire";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import {
  getCurrentUserData as getCurrentUserDataFromDb,
  // isChild,
  // overrideParish,
} from "../../../services/user";
import {
  getRoomDetails,
  // isAllowedToBook,
  isRoomFull,
  isRoomTimeExpired,
  isRoomClosed,
  // getMonthlyBookings,
} from "../service/room";
import SimpleMessage from "../../blocks/simpleMessageBlock";
import {
  getOrgLinkById,
  getOrgBookingCutoffTimeById,
  getOrgIdByCode,
} from "../../../services/organisation";
import LinkButton from "../../blocks/linkButtonBlock";
import {
  getLocalTime,
  getMassDate as getRoomDate,
  // isWeekEndMass,
  // isSaturdayMass,
  // isSundayMass,
} from "../../../utils/utils";
import {
  disableBooking,
  // enableWarningPETVaccinationButton,
  getAllowedCurrentDates,
  // getNextMonth,
  getWebVersion,
  // maxNoPETVaccinationCapacity,
} from "../../../services/settings";
import { logEvent, logMessage } from "../../../services/log";
import SpinnerText from "../../common/spinnerwithText";
import { getLatestVersion } from "../../../services/getversion";
import DemoBar from "../../common/demoBar";
import RoomDetail from "../../common/roomDetail";
// import md5 from "md5";
// import { getMaxKidCountAllowed } from "../../../services/limits";
import BookingSteps from "../components/bookingSteps";
import NameClock from "../../common/nameClock";
// import { excludeList } from "../../../services/bookanyorg";

function ReviewRoomBooking({ user, history, setView, restoreView, viewProps }) {
  const {
    schedule: selectedschedule,
    org: preferredorg,
    id: identifier,
    bookerRole,
    bookerOrg,
    roomUsageDetails,
  } = viewProps;
  const [state, setState] = useState({
    preferredMonth: viewProps.month.toLowerCase(),
    preferredYear: viewProps.year,
    data: {
      fullname: "",
      email: "",
      identification: "",
      org: 0,
      orgcode: 0,
      subid: "",
    },
    preferredorg,
    roomtiming: [],
    preferredschedule: selectedschedule,
    chosenroom: "",
    user,
    loading: true,
    submitting: false,
    roomfull: false,
    errorfound: false,
    errmsg: "",
    lastroombooking: "",
    errcode: 0,
    identifier,
    declared: false,
    disableagree: true,
  });

  const doSubmit = async () => {
    setState((oldState) => ({ ...oldState, submitting: true }));
    const {
      preferredschedule,
      preferredYear,
      preferredMonth,
      data,
      user,
      // chosenroom, //commented so we get the latest schedule, see below
      // totalweekendbookingallowed,
      // totalweekdaybookingallowed,
      preferredorg,
      bookingcutofftime,
      // iskid,
      // kidpetenabled,
      // maxkidcount,
      // maxkidcountpercent,
    } = state;

    logMessage("reviewRoomBooking", {
      email: user.email,
      code: "submit",
      message: `Room ID: ${preferredschedule} User Agent ${navigator.userAgent}`,
    });
    //get latest data again
    // const tenant = await getCurrentUserData(user.uid);
    // const { lastroombooking, org: org } = overrideParish(
    //   tenant,
    //   preferredorg
    // ); //tenant;
    const org = getOrgIdByCode(preferredorg);
    // //make sure same org, else, redirect to home
    // if (!isAllowedToBook(org, preferredorg)) {
    //   window.location.reload();
    //   history.push("/home");
    //   return;
    // }
    /** Make sure before calling the DB, we got the latest status of schedule to determine if still bookable */
    const chosenroom = await getRoomDetails(
      org,
      data.orgcode,
      preferredschedule
    );
    let roombookingobj = null;

    let bookedroomIdlist;
    if (roombookingobj) {
      bookedroomIdlist = roombookingobj.map((b) => b.roomId);
    }
    let hasbookedtheroom = false;
    if (bookedroomIdlist && bookedroomIdlist.length > 0) {
      // console.log("Booked Mass ID: ", bookedroomIdlist[0]);
      hasbookedtheroom = bookedroomIdlist.includes(preferredschedule);
    }

    //calculate again to make sure we got the latest time
    const isfull = isChosenRoomFull(chosenroom);
    const isvalid = isValidRoom(chosenroom);
    const isclosed = isClosedRoom(chosenroom, bookingcutofftime);
    //REDIRECT IF HE HAS BOOKED THE SAME MASS
    if (hasbookedtheroom === true) {
      logEvent("roombooking_confirm_fail", {
        email: user.email,
        org: data.org,
        message: "User has already booked this slot",
      });
      logMessage("reviewRoomBooking", {
        email: user.email,
        code: "bookedtheroom",
        message: `Room ID: ${preferredschedule} User has already booked this slot`,
      });
      setState((oldState) => ({
        ...oldState,
        errmsg: "You have already booked this room.",
        errorfound: true,
        errcode: 8,
      }));
      // history.push("/home");
      return;
    }

    // let disallowed = true;

    if (isfull || !isvalid || isclosed) {
      setState((oldState) => ({ ...oldState, isfull, isvalid, isclosed }));
      return;
    }

    const userdetails = data;
    const bookobj = {
      tenantId: user.uid,
      tenantRole: bookerRole,
      tenantOrg: bookerOrg,
      roomId: preferredschedule,
      roomDate: chosenroom[0].date,
      roomDuration: chosenroom[0].duration,
      roomSlotType: chosenroom[0].slotType,
      roomPaxLimit: chosenroom[0].paxLimit,
      roomNotes: chosenroom[0].notes,
      roomOrgId: chosenroom[0].org,
      roomName: chosenroom[0].name,
      roomLocation: chosenroom[0].location,
      roomFunction: chosenroom[0].function,
      roomVenue: chosenroom[0].venue,
      roomRate: chosenroom[0].config[bookerRole].rate,
      roomUsageDetails: roomUsageDetails,
      useragent: navigator.userAgent,
    };

    try {
      const bookForRoom = fire
        .functions("asia-east2")
        .httpsCallable("bookForRoom");
      const bookstatus = await bookForRoom({
        timing: { month: preferredMonth, year: preferredYear },
        details: userdetails,
        bookobj,
        webversion: getWebVersion(),
      });
      const bookresult = bookstatus.data;
      if (bookresult.status === 0) {
        //let us double check if really successful here
        const db = fire.firestore();

        let roombookings = db
          .collection("roombookings")
          .where("roomId", "==", preferredschedule)
          .where("tenantId", "==", user.uid);

        const result = await roombookings.get();

        let duplicateid = null;
        let resultcounter = 0;
        if (result) {
          result.forEach((doc) => {
            resultcounter++;
            duplicateid = doc.id;
            //TODO: write this to db so we can trace why this happens
            logMessage("reviewRoomBooking", {
              email: user.email,
              code: "booking_result",
              message: `User: ${user.uid} Room ID: ${duplicateid}  Booking Count: ${resultcounter}`,
            });
          });
        }
        //resultcounter must be > 0 to confirm booking exists
        const booked = await getRoomDetails(
          org,
          data.orgcode,
          preferredschedule
        );
        if (booked && booked.length >= 1) {
          const available = booked[0].available;
          if (resultcounter >= 1) {
            //wohooo!!!!
            logMessage("reviewRoomBooking", {
              email: user.email,
              code: "success_booking",
              message: `User: ${user.uid} Room ID: ${preferredschedule} Available: ${available} Booking Count: ${resultcounter}`,
            });
            // console.log(
            //   `User: ${user.uid} Room ID: ${preferredschedule} Available: ${available} Booking Count: ${resultcounter}`
            // );
            logEvent("roombooking_confirm", {
              email: user.email,
              org: data.org,
            });
            //this is verified booking
            // history.replace(
            //   `/event/room/booking/success/${preferredYear}/${preferredMonth}/${preferredschedule}`
            // );
            setView({
              name: "success",
              props: {
                year: preferredYear,
                month: preferredMonth,
                schedule: preferredschedule,
              },
            });
            return;
          } else {
            //no result found, this MUST THROW PROPER MESSAGE
            //we don't expect this to happen!!!
            //because the bookForMass return success
            //but we can't find the booking in the DB,
            //THERE SOMETHING WRONG HERE THAT WE NEED TO DEBUG
            logMessage("roombooking_exception", {
              email: user.email,
              code: "roombooking_exception",
              message: `User: ${user.uid} Mass ID: ${preferredschedule} Available: ${available} Booking Count: ${resultcounter}`,
            });
            logEvent("roombooking_exception", {
              email: user.email,
              org: data.org,
            });
            setState((oldState) => ({
              ...oldState,
              errmsg: "The booking was not successful. Please try again.",
              errorfound: true,
              errcode: 5,
            }));
          }
        } else {
          logMessage("reviewRoomBooking", {
            email: user.email,
            code: "no_booking_found",
            message: `Mass ID: ${preferredschedule} No Booking Found`,
          });
          history.replace(`/home`);
          return;
        }
      } else {
        logEvent("roombooking_confirm_fail", {
          email: user.email,
          org: data.org,
          message: bookresult.message,
        });
        console.log("Error:", bookresult.message);
        if (bookresult.status === 4) {
          setState((oldState) => ({
            ...oldState,
            errmsg: bookresult.message,
            roomfull: true,
            errorfound: true,
            errcode: bookresult.status,
          }));
        } else {
          setState((oldState) => ({
            ...oldState,
            errmsg: bookresult.message,
            errorfound: true,
            errcode: bookresult.status,
          }));
        }
      }
    } catch (error) {
      console.log("Error:", error);
      setState((oldState) => ({
        ...oldState,
        errorfound: true,
        errmsg: "Something went wrong.",
      }));
    }
  };

  async function getCurrentUserData() {
    try {
      const uid = state.user.uid;
      const { preferredYear, preferredMonth, preferredorg, preferredschedule } =
        state;
      const user = await getCurrentUserDataFromDb(uid);

      if (user) {
        const origorg = user.org;
        // const {
        //   fullname,
        //   email,
        //   mobile,
        //   identification,
        //   org,
        //   subid,
        //   lastroombooking,
        //   dob,
        // } = overrideParish(user, preferredorg); //user;
        const org = getOrgIdByCode(preferredorg);
        const {
          fullname,
          email,
          mobile,
          identification,
          subid,
          // lastroombooking,
          dob,
        } = user;

        // const allowedcurdate = getAllowedCurrentDates();
        const curdate = `${preferredMonth}${preferredYear}`;
        // console.log(`year: ${preferredYear} month: ${preferredMonth}`);

        if (disableBooking()) {
          setState((oldState) => ({
            ...oldState,
            errmsg:
              "We are currently updating the Room Schedules. Please reload the page at 9:00am to get the latest updates.",
            errorfound: true,
            errcode: 7,
            loading: false,
            isvalid: true,
          }));
          return;
        }
        // //if invalid month and year, redirect home
        // if (!allowedcurdate.includes(curdate)) {
        //   //NOT ALLOWED, REDIRECT TO HOME FOR NOW
        //   logEvent("roombooking_load_user", {
        //     email: user.email,
        //     org,
        //     message: "Invalid date",
        //   });
        //   console.error("Invalid date");
        //   history.push("/home");
        //   return;
        // }

        if (!org) {
          history.push("/account/setup");
          return;
        }

        if (curdate === "may2022") {
          history.push("/commune");
          return;
        }

        const bookingcutofftime = getOrgBookingCutoffTimeById(org);

        const version = await getLatestVersion();
        let roombookingobj = null;
        let uniqbookeddates = null;
        let bookedroomIdlist;
        if (roombookingobj) {
          bookedroomIdlist = roombookingobj.map((b) => b.roomId);
        }
        // console.log("Room Bookings List: ", bookedroomIdlist);
        let hasbookedtheroom = false;
        if (bookedroomIdlist && bookedroomIdlist.length > 0) {
          // console.log("Booked Room ID: ", bookedroomIdlist[0]);
          hasbookedtheroom = bookedroomIdlist.includes(preferredschedule);
        }
        // console.log("Room Booked? ", hasbookedtheroom);

        // const allowed = isAllowedToBook(org, preferredorg);
        const allowed = true;

        if (!allowed || hasbookedtheroom === true) {
          //NOT ALLOWED, REDIRECT TO HOME FOR NOW
          //1. Org Mismatch
          //2. One has already booked the room
          let message = "";
          if (!allowed) {
            message = "Org mismatch";
          }
          if (hasbookedtheroom) {
            message = "User tried to access a previously booked room.";
          }
          console.error("Not allowed");
          logEvent("roombooking_load_user_redirect", {
            email: user.email,
            org,
            message: message,
          });
          history.push("/home");
          return;
        }

        const orgcode = getOrgLinkById(org);

        const chosenroom = await getRoomDetails(
          org,
          orgcode,
          preferredschedule
        );

        // console.log({ chosenroom });
        const isfull = isChosenRoomFull(chosenroom);
        const isvalid = isValidRoom(chosenroom);
        const isclosed = isClosedRoom(chosenroom, bookingcutofftime);
        // let hasbookedmasstoday = false;
        let labeldate = "";

        if (!isclosed && isvalid && !isfull) {
          labeldate = getRoomDate(chosenroom[0].date);
        }
        setState((oldState) => ({
          ...oldState,
          data: {
            fullname,
            email,
            mobile,
            identification,
            org,
            orgcode,
            subid,
            dob,
          },
          version: version?.version,
          isfull,
          isvalid,
          isclosed,
          chosenroom,
          bookedroomIdlist,
          hasbookedtheroom,
          uniqbookeddates,
          bookingcutofftime,
          loading: false,
        }));
      }
    } catch (error) {
      //USER NOT FOUND
      //LOGOUT!!!
      console.error(error);
      // await userLogout();
      // window.location.reload();
    }
  }

  function isChosenRoomFull(chosenroom) {
    let result = false;
    if (chosenroom && chosenroom.length > 0) {
      result = isRoomFull(chosenroom[0].available);
    }
    return result;
  }

  function isValidRoom(chosenroom) {
    let result = false;
    if (chosenroom && chosenroom.length > 0) {
      result = true;
    }
    return result;
  }

  function isClosedRoom(chosenroom, cutoff = 48) {
    let result = false;
    if (chosenroom && chosenroom.length > 0) {
      const localtime = getLocalTime();

      let massexpired = isRoomTimeExpired(chosenroom[0], localtime);
      let massclosed = isRoomClosed(chosenroom[0], localtime, cutoff);
      if (massexpired || massclosed) {
        result = true;
      }
    }
    return result;
  }

  function displayErrorMessage(header, body, errcode) {
    let errmsg = "";
    let head = header;
    if (errcode === 7) {
      head = ""; //Updating in progress...";
      errmsg = (
        <>
          <button
            type="button"
            onClick={() => window.location.reload()}
            className="btn btn-danger mt-4 btn-block btn-lg"
          >
            Press to Reload
          </button>
          <div className="mx-auto py-2 text-center">
            *To get latest version to book a room
          </div>
          <button
            type="button"
            onClick={() => restoreView("roomSchedules")}
            className="btn btn-link mt-4 btn-block btn-lg"
          >
            Go Back
          </button>
        </>
      );
    } else if (errcode === 2) {
      errmsg = (
        <div className="py-5">
          <LinkButton link="/home" label="Back to Home" />
        </div>
      );
    } else if (errcode === 5) {
      errmsg = (
        <>
          <button
            type="button"
            onClick={() => restoreView("roomSchedules")}
            className="btn btn-primary mt-4 btn-block btn-lg"
          >
            Try again
          </button>
        </>
      );
    } else {
      errmsg = (
        <>
          <h4 className=" text-muted py-3 mb-5">Please select another room.</h4>
          <button
            type="button"
            onClick={() => restoreView("roomSchedules")}
            className="btn btn-primary mt-4 btn-block btn-lg"
          >
            Select other room
          </button>
        </>
      );
    }
    return (
      <>
        <h1 className="py-4">{head}</h1>
        <h4
          className="my-0 pb-4 text-left text-dark"
          style={{ lineHeight: "1.4em" }}
        >
          {body}
        </h4>
        <div style={{ lineHeight: "1.4em" }}>{errmsg}</div>
      </>
    );
  }

  function getErrorMessage() {
    const { errmsg, errorfound, roomfull, errcode } = state;

    let content = null;
    if (errorfound) {
      if (roomfull) {
        content = displayErrorMessage(
          "Room Full",
          "The room you have chosen is already fully booked.",
          errcode
        );
      } else {
        content = displayErrorMessage("Sorry", errmsg, errcode);
      }
    }
    return content;
  }

  const displayPage = (page) => {
    const { user, submitting } = state;
    return (
      <>
        <Header
          type="backandlogo"
          disablecontrol={submitting}
          smallpic={user.photoURL}
        />
        <DemoBar />
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-6 px-1">
            <main className="container">{page}</main>
          </div>
        </div>
      </>
    );
  };

  const roomClosed = () => {
    return (
      <>
        <h1 className="py-4">Room Closed</h1>
        <h4
          className="my-0 pb-4 text-left text-muted"
          style={{ lineHeight: "1.4em" }}
        >
          The room you have chosen is already closed for bookings.
        </h4>
        <h4 className=" text-muted py-4 mb-5">Please select another room.</h4>

        <button
          type="button"
          onClick={() => restoreView("roomSchedules")}
          className="btn btn-primary mt-4 btn-block btn-lg"
        >
          Select other room
        </button>
      </>
    );
  };

  const roomFull = () => {
    return (
      <>
        <h1 className="py-4">Room Full</h1>
        <h4
          className="my-0 pb-4 text-left text-muted"
          style={{ lineHeight: "1.4em" }}
        >
          The room you have chosen is already fully booked.
        </h4>
        <h4 className=" text-muted py-4 mb-5">Please select another room.</h4>

        <button
          type="button"
          onClick={() => restoreView("roomSchedules")}
          className="btn btn-primary mt-4 btn-block btn-lg"
        >
          Select other room
        </button>
      </>
    );
  };

  const incorrectRoom = () => {
    return (
      <SimpleMessage
        title="Ooops"
        content="Seems like you selected an incorrect room schedule."
        link="/home"
        label="Back to Home"
      />
    );
  };

  useEffect(() => {
    getCurrentUserData();
  }, []);

  function render() {
    const {
      data,
      loading,
      chosenroom,
      submitting,
      isfull,
      isvalid,
      isclosed,
      user,
      version,
    } = state;
    let org = null;

    if (loading === true) {
      return (
        <LoadingSpinnerNav
          type="backandlogo"
          disablecontrol={submitting}
          smallpic={user.photoURL}
        />
      );
    }
    if (data.org) {
      org = data.org;
    }

    const match = getWebVersion() === version ? true : false;
    // console.log({ maxkidcount });
    if (isclosed) {
      return displayPage(roomClosed());
    }
    if (isfull) {
      return displayPage(roomFull());
    }
    const errmsg = getErrorMessage();
    return displayPage(
      <div className="pb-3 pt-0">
        {errmsg}
        {!isclosed && isvalid && !isfull && !errmsg && (
          <>
            <div className="mt-2">
              <BookingSteps step={2} />
            </div>
            <div className="center-content mt-2 mb-0 pb-0">
              <h2 className="mb-0">Review</h2>
            </div>
            <div className="clearfix"></div>
            <div className="text-center">
              <NameClock name={data.fullname} />
            </div>
            <div
              className="my-2 text-center pb-3 card"
              style={{ border: "1px solid #f5c6cb" }}
            >
              <div className="pt-2 mb-0 alert-danger">
                <h3 className="my-0">Room</h3>
                {chosenroom[0].notes && (
                  <p className="pb-0 mb-2">{chosenroom[0].notes}</p>
                )}
              </div>
              <RoomDetail
                notes={chosenroom[0].notes}
                name={chosenroom[0].name}
                function={chosenroom[0].function}
                location={chosenroom[0].location}
                venue={chosenroom[0].venue}
                date={chosenroom[0].date}
                duration={chosenroom[0].duration}
                slotType={chosenroom[0].slotType}
                paxLimit={chosenroom[0].paxLimit}
                rate={chosenroom[0].config[bookerRole].rate}
                bookerOrg={bookerOrg}
                roomUsageDetails={roomUsageDetails}
              />
            </div>
            {match && !submitting && (
              <Button
                variant="danger"
                className="mt-0 btn-block btn-lg"
                onClick={doSubmit}
              >
                Click to Confirm*
              </Button>
            )}
            {match && submitting && (
              <button disabled className="btn btn-lg btn-danger btn-block">
                <SpinnerText text="Checking for Availability..." />
              </button>
            )}
            {match && (
              <p className="text-center pt-2 text-muted">
                <span className="text-danger">*</span>Booking subject to
                availability
              </p>
            )}
            {match && !submitting && (
              <button
                type="button"
                onClick={() => restoreView("roomSchedules")}
                className="btn btn-link mt-4 btn-block btn-lg"
              >
                Select a different room
              </button>
            )}
            {match === false && (
              <>
                <Button
                  variant="danger"
                  className="btn-block btn-lg mt-2"
                  onClick={() => window.location.reload()}
                >
                  Press to Reload
                </Button>
                <div className="mx-auto py-2 text-center">
                  *To get latest version to book a room
                </div>
              </>
            )}
          </>
        )}
        {isclosed === true && roomClosed()}
        {!isclosed && isvalid && isfull && !errmsg && roomFull()}
        {!isclosed && !isvalid && incorrectRoom()}
      </div>
    );
  }

  return render();
}

export default ReviewRoomBooking;
