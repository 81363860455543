import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncAnnouncementSetup({ ...rest }) {
  const AsyncLoad = loadable(() => import("../admin/setupAnnouncement"));
  console.log("Loading", AsyncAnnouncementSetup.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncAnnouncementSetup;
