import React from "react";
import moment from "moment";

import { timeslotRef } from "./references";
import { formatMultiRate } from "./currency"

export function getCalResources(events) {
    return [...new Set(events.map((event) => event.name))].map(
        (id) => ({ id })
    );
}

export function getDailyCalEvents(events, bookerRole) {
    return events.map((event) => ({
        ...event,
        // shortTitle: `${event.name} ${timeslotRef[event.slotType].name}`,
        title: (
            <p>
                {event.function}
                <br />
                <small className="bold">
                    {timeslotRef[event.slotType].name}
                    <br />
                    {event.available === 0 ? "FULL" : `${event.available} Available`}
                    <br />
                    <em>{formatMultiRate(event.config[bookerRole].rate)}</em>
                </small>
            </p>
        ),
        startDate: moment(event.date.seconds * 1000).toDate(),
        endDate: moment(event.date.seconds * 1000)
            .add(event.duration, "minutes")
            .toDate(),
        resourceId: event.name,
    }));
}

export function getMonthlyCalEvents(events) {
    // console.log("getMonthlyCalEvents")
    return Object.values(
        events.reduce((acc, event) => {
            const eventStartMoment = moment(event.date.seconds * 1000);
            const eventEndMoment = eventStartMoment.add(
                event.duration,
                "minutes"
            );
            const eventDateOfMonth = eventStartMoment.date();
            if (!(eventDateOfMonth in acc))
                acc[eventDateOfMonth] = {
                    available: 0,
                    availableDesks: 0,
                    availableRooms: 0,
                    booked: 0,
                    startDate: eventStartMoment.toDate(),
                    endDate: eventEndMoment.toDate(),
                };
            const monthlyEvent = acc[eventDateOfMonth];
            if (event.available > 0) {
                monthlyEvent.available++;
                if (event.function === "Hotdesk")
                    monthlyEvent.availableDesks += event.available;
                else
                    monthlyEvent.availableRooms += event.available;
            } // Reduce "available" as count
            if (event.booked) monthlyEvent.booked++; // Reduce "booked" as count
            if (eventStartMoment < monthlyEvent.startDate)
                monthlyEvent.startDate = eventStartMoment; // Reduce "startDate" as earliest
            if (eventEndMoment > monthlyEvent.endDate)
                monthlyEvent.endDate = eventEndMoment; // Reduce "endDate" as latest
            monthlyEvent.title = (
                <small className="bold">
                    Desks: {monthlyEvent.availableDesks}
                    <br />
                    Rooms: {monthlyEvent.availableRooms}
                </small>
            );
            return acc;
        }, {})
    )
}

export function calReducer(state, { type, payload }) {
    switch (type) {
        case "dayViewFromEventSelect":
            return {
                ...state,
                curView: "day",
                date: state.selectedEvent?.startDate ?? state.firstEventDate,
                events: state.dailyCalEvents,
            };
        case "dayViewFromNavigate":
            // console.log("Navigate", { date: payload?.date });
            return {
                ...state,
                curView: "day",
                date: payload?.date ?? state.firstEventDate,
                events: state.dailyCalEvents,
            };
        case "monthView":
            return {
                ...state,
                curView: "month",
                date: state.firstEventDate,
                events: state.monthlyCalEvents,
            };
        case "selectEvent":
            const { event } = payload;
            if (state.curView === "day") {
                if (!event.disabled && event.available > 0) {
                    state.onEventSelect(event);
                    return { ...state, selectedEvent: event };
                }
            } else {
                if (event.available > 0)
                    return {
                        ...state,
                        curView: "day",
                        date: event.startDate,
                        events: state.dailyCalEvents,
                    };
            }
            return state;
        case "deselectEvent":
            state.onEventSelect({ id: null })
            return { ...state, selectedEvent: null }
        case "reset":
            return { ...payload };
        default:
            console.error("Unrecognised calendar reducer action");
    }
}