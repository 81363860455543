/*
 * Detect Browser
 * See: https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser?page=1&tab=votes#tab-top
 */
export async function getBrowser() {
  const enable = true;

  if (enable) {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: "IE", version: tem[1] || "" };
    }

    if (M[1] === "Chrome") {
      //detect for opera
      tem = ua.match(/\bOPR\/(\d+)/);
      if (tem != null) {
        return { name: "Opera", version: tem[1] };
      }
      //detect for edge
      tem = ua.match(/\bEdg\/(\d+)/);
      if (tem != null) {
        return { name: "Edge", version: tem[1] };
      }
    }
    //detect for brave
    //See: https://stackoverflow.com/questions/36523448/how-do-i-tell-if-a-user-is-using-brave-as-their-browser
    if ((navigator.brave && (await navigator.brave.isBrave())) || false) {
      M[1] = "Brave";
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  } else {
    return { name: "Disabled", version: "?" };
  }
}
