import React from "react";
import { getEventTypeColor } from "../utils/const";

function PillType({ type, css = "" }) {
  const pill = getEventTypeColor(type);
  return (
    <div className={css}>
      <span
        className="badge badge-pill"
        style={{ backgroundColor: pill.background, color: pill.color }}
      >
        {type}
      </span>
    </div>
  );
}

export default PillType;
