import React from "react";

function AgreeComs() {
  return (
    <span className="text-left pointer">
      I consent to receive email communication from myCatholicSG. You can manage
      your email preferences at any time on your myCatholicSG Profile.
    </span>
  );
}

export default AgreeComs;
