export function compareEventDate(a, b) {
  if (a.eventDate.seconds < b.eventDate.seconds) {
    return 1;
  }
  if (a.eventDate.seconds > b.eventDate.seconds) {
    return -1;
  }
  return 0;
}

export function compareEventName(a, b) {
  if (a.eventName.toUpperCase() < b.eventName.toUpperCase()) {
    return 1;
  }
  if (a.eventName.toUpperCase() > b.eventName.toUpperCase()) {
    return -1;
  }
  return 0;
}

export function compareRoomObjForBookingList(a, b) {
  const dateCheck = b.roomDate.seconds - a.roomDate.seconds;
  return dateCheck || a.roomFunction.localeCompare(b.roomFunction);
}

export function compareRoomObjForRoomSchedules(a, b) {
  const dateCheck = a.date.seconds - b.date.seconds;
  return dateCheck || a.function.localeCompare(b.function);
}
