import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncMassIntentionDashboardContainer({ ...rest }) {
  const AsyncLoad = loadable(() =>
    import("../admin/MassIntentionDashboardContainer")
  );
  console.log("Loading", AsyncMassIntentionDashboardContainer.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncMassIntentionDashboardContainer;
