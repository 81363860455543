export function getNoMassList() {
  return [
    13, 22, 29, 30, 32, 21, 4, 31, 6, 18, 20, 3, 27, 8, 5, 15, 12, 24, 19,
  ];
}

export function getCommunionServiceNotes(details) {
  const noMass = getNoMassList();
  if (!noMass.includes(details.parish)) {
    return details.notes;
  }
  //   console.log(details);
  const start = new Date("3 July 2023");
  start.setHours(4);
  const end = new Date("7 July 2023");
  end.setHours(21);
  let updatedNotes = details.notes;
  if (
    details.type === "Mass" &&
    details.start > start.getTime() &&
    details.start < end.getTime()
  ) {
    //check if schedule is within 3 Jul - 7 Jul
    // const temp = updatedNotes.toLowerCase().trim();
    if (updatedNotes.trim().length === 0) {
      //   if (!temp.includes("communion"))
      // updatedNotes = `${updatedNotes} <br/>⚑ Communion Service`;
      //   } else {
      updatedNotes = `⚑ Communion Service`;
    }
  }
  return updatedNotes;
}
