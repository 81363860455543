import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncArchdioceseAnnouncementSetup({ ...rest }) {
  const AsyncLoad = loadable(() =>
    import("../admin/archdioceseSetupAnnouncement")
  );
  console.log("Loading", AsyncArchdioceseAnnouncementSetup.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncArchdioceseAnnouncementSetup;
