import React, { useEffect, useState, useReducer } from "react";
import fire from "../../../services/fire";
import Header from "../../common/header";
import ParishLogo from "../../parish/parishLogoBlock";
import LoadingSpinner from "../../common/loadingSpinner";
import LinkButton from "../../blocks/linkButtonBlock";
import NameClock from "../../common/nameClock";
import { getCurrentUserData } from "../../../services/user";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import PayplusContext from "../payplusContext";
import { INIT_PAYPLUS_STATE, payplusReducer } from "../reducers/payplusReducer";
import OrgPaymentSelector from "../component/OrgPaymentSelector";
import PaymentSelector from "../component/PaymentSelector";
import CampaignSelector from "../component/CampaignSelector";
import QRPaymentDisplay from "../component/QRPaymentDisplay";
import GenPayPlusGuide from "../component/GenPayPlusGuide";
import ReloadModal from "../../common/reloadModal";

import "../stylesheets/payplus-home.css";

const PayPlusHome = ({ user }) => {
  const [payplusState, payplusDispatch] = useReducer(
    payplusReducer,
    INIT_PAYPLUS_STATE
  );

  const { isLoading, userObj, step, userHasProductCampaign } = payplusState;
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const userFullName = userObj.fullname;
  const userParishId = userObj.parish;

  useEffect(() => {
    getCurrentUserData(user.uid).then((newUserObj) => {
      payplusDispatch({
        type: "SET_USER_READY",
        payload: { userObj: newUserObj },
      });
      getPayPlusCampaigns(newUserObj);
      getUserProductCampaigns(newUserObj);
    });
  }, [user]);

  const getUserProductCampaigns = (user) => {
    fire
      .firestore()
      .collection("membership")
      .where("userid", "==", user.userid)
      .where("membershipType", "==", "EVENT_VENDOR")
      .where("isActive", "==", true)
      .get()
      .then((resultSnapshot) => {
        if (resultSnapshot.docs.length > 0) {
          resultSnapshot.docs.forEach((doc) => {
            if (doc.data().otherDetails.length > 0) {
              payplusDispatch({ type: "SET_HAS_PRODUCT_CAMPAIGN" });
            }
          });
        }
      });
  };
  const getPayPlusCampaigns = (newUserObj) => {
    const now = new Date().getTime();
    const parishId = newUserObj.parish;
    fire
      .firestore()
      .collection("paypluscampaigns")
      .get()
      .then((resultSnapshot) => {
        const newParishCampaigns = [];
        const newOrgCampaigns = [];
        if (resultSnapshot.docs.length > 0) {
          resultSnapshot.docs.forEach((doc) => {
            const obj = doc.data();
            let isValid = false;
            fire
              .firestore()
              .collection("payplusorgs")
              .where("orgId", "==", obj.orgId)
              .get()
              .then((orgResultSnapshot) => {
                if (!orgResultSnapshot.empty) {
                  orgResultSnapshot.docs.forEach((orgDoc) => {
                    const orgObj = orgDoc.data();
                    obj.orgName = orgObj.orgName;
                    obj.orgShortName = orgObj.orgShortName;
                    obj.payplusUEN = orgObj.payplusUEN;
                  });
                }

                // Check if campaign is approved, active and is user type
                if (
                  obj.approvalStatus === 1 &&
                  obj.qrType === "U" &&
                  !obj.isPrivate
                ) {
                  const campaignStart = obj.campaignStart?.toDate().getTime();
                  if (campaignStart && campaignStart < now) {
                    if (obj.expiry) {
                      if (obj.expiry.toDate().getTime() > now) {
                        isValid = true;
                      }
                    } else {
                      isValid = true;
                    }
                  }
                }
                // Classify campaigns accordingly if valid
                if (isValid) {
                  // Check if campaign is single-use and if user has successful payment
                  if (obj.isPayOnce) {
                    fire
                      .firestore()
                      .collection("payplusrequests")
                      .where("createdBy", "==", newUserObj.userid)
                      .where("isCompleted", "==", true)
                      .where("campaignRef", "==", obj.campaignRef)
                      .get()
                      .then((payRequestSnapshot) => {
                        if (payRequestSnapshot.docs.length > 0) {
                          obj.isPaidDisabled = true;
                          obj.campaignName += " (Paid)";
                        } else {
                          obj.isPaidDisabled = false;
                        }
                      });
                  } else {
                    obj.isPaidDisabled = false;
                  }
                  if (obj.orgId === parishId) {
                    newParishCampaigns.push(obj);
                  } else if (obj.isGlobal) {
                    newOrgCampaigns.push(obj);
                  }
                }

                // Order campaign alphanumerically by campaignName
                newParishCampaigns.sort((a, b) =>
                  a.campaignName.localeCompare(b.campaignName, "en", {
                    numeric: true,
                  })
                );

                newOrgCampaigns.sort((a, b) =>
                  a.campaignName.localeCompare(b.campaignName, "en", {
                    numeric: true,
                  })
                );

                payplusDispatch({
                  type: "SET_CAMPAIGN_READY",
                  payload: {
                    parishCampaigns: newParishCampaigns,
                    orgCampaigns: newOrgCampaigns,
                  },
                });
              });
          });
        } else {
          payplusDispatch({
            type: "SET_CAMPAIGN_READY",
            payload: {
              parishCampaigns: newParishCampaigns,
              orgCampaigns: newOrgCampaigns,
            },
          });
        }
      });
  };

  return isLoading ? (
    <>
      <Header type="backandlogo" />
      <LoadingSpinner />
    </>
  ) : (
    <>
      <PayplusContext.Provider
        value={{
          payplusState,
          payplusDispatch,
        }}
      >
        <Header type="backandlogo" />
        <Modal
          show={isHelpOpen}
          dialogClassName="modal-dialog-xlg"
          onHide={() => setIsHelpOpen(false)}
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }} />
          <Modal.Body>
            <GenPayPlusGuide />
            <div className="text-center my-4">
              <div
                className="btn btn-primary"
                onClick={() => setIsHelpOpen(false)}
              >
                Close this Guide
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {step === 2 ? (
          <PaymentSelector />
        ) : step === 3 ? (
          <QRPaymentDisplay />
        ) : (
          <>
            <ReloadModal />
            <ParishLogo parishId={userParishId} />
            <div className="row justify-content-center mx-0">
              <div className="col-lg-6 px-1">
                <main className="container">
                  <div className="text-center">
                    <NameClock name={userFullName} />
                    <div className="row pt-0 px-2 pb-1 justify-content-center text-center defaultfontsize">
                      <Link to="/mypayplus">
                        <i>my</i>Pay✝
                      </Link>
                    </div>
                    {userHasProductCampaign ? (
                      <>
                        <div className="row m-2 pt-0 px-2 pb-1 justify-content-center text-center defaultfontsize">
                          <Button
                            variant="outline-primary"
                            href="/payplus/vendor"
                          >
                            Vendor page
                          </Button>
                        </div>
                      </>
                    ) : null}
                    <div className="row pt-0 px-2 pb-1 my-2 justify-content-center text-center defaultfontsize">
                      <div
                        className="btn text-primary defaultfontsize"
                        onClick={() => setIsHelpOpen(true)}
                      >
                        <i>How do I use Pay✝?</i>
                      </div>
                    </div>
                  </div>
                  {step === 0 && <OrgPaymentSelector />}
                  {step === 1 && <CampaignSelector />}
                  <div className="pt-2 form-group">
                    <LinkButton type="link" link="/home" label="Back to Home" />
                  </div>
                </main>
              </div>
            </div>
          </>
        )}
      </PayplusContext.Provider>
    </>
  );
};

export default PayPlusHome;
