import React from "react";
import "../../stylesheets/finance-qr-modal.css";

const QRFormConfirmation = ({ modalProps, getCampaignRef }) => {
  const {
    campaignName,
    amount,
    isEditable,
    isGlobal,
    isPayOnce,
    isPrivate,
    campaignStart,
    expiry,
    comments,
    qrType,
  } = modalProps;

  return (
    <div className="create-qr-modal__body">
      <div className="create-qr-modal__confirmation">
        <table className="create-qr-modal__confirmation-table">
          <tbody>
            <tr className="create-qr-modal__confirmation--row-space">
              <td>Campaign Name:</td>
              <td>{campaignName}</td>
            </tr>
            <tr className="create-qr-modal__confirmation--row-space">
              <td>Amount:</td>
              <td>S$ {amount}</td>
            </tr>
            <tr className="create-qr-modal__confirmation--row-space">
              <td>Amount Editable:</td>
              <td>{isEditable ? "Yes" : "No"}</td>
            </tr>
            <tr className="create-qr-modal__confirmation--row-space">
              <td>Start Date:</td>
              <td>{campaignStart ? campaignStart.toString() : "-"}</td>
            </tr>
            <tr className="create-qr-modal__confirmation--row-space">
              <td>Expiry:</td>
              <td>{expiry ? expiry.toString() : "-"}</td>
            </tr>
            <tr className="create-qr-modal__confirmation--row-space">
              <td>Reference:</td>
              <td>{getCampaignRef()}</td>
            </tr>
            <tr className="create-qr-modal__confirmation--row-space">
              <td>Comments:</td>
              <td>{comments}</td>
            </tr>
            <tr className="create-qr-modal__confirmation--row-space">
              <td>QR Type:</td>
              <td>
                {qrType === "U" ? "User Specific QR" : "Product Specific QR"}
              </td>
            </tr>
            <tr className="create-qr-modal__confirmation--row-space">
              <td>Is Private Campaign:</td>
              <td>{isPrivate ? "Yes" : "No"}</td>
            </tr>
            <tr className="create-qr-modal__confirmation--row-space">
              <td>Allowed to Only Pay Once:</td>
              <td>{isPayOnce ? "Yes" : "No"}</td>
            </tr>
            <tr className="create-qr-modal__confirmation--row-space">
              <td>Publish Outside of Parish / Organization:</td>
              <td>{isGlobal ? "Yes" : "No"}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="create-qr-modal__confirmation-notice">
        Your Parish Priest / Organization Head will have to approve this
        campaign before it can go live
      </div>
    </div>
  );
};

export default QRFormConfirmation;
