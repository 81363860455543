import React from "react";
import {
  getOrgShortNameById,
  getOrgCompleteNameById,
} from "../../../services/organisation";

import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function OrgBadge({ orgId }) {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{getOrgCompleteNameById(orgId)}</Tooltip>}
    >
      <Badge
        pill
        className="bg-danger text-light mx-1"
        style={{ cursor: "pointer" }}
      >
        {getOrgShortNameById(orgId)}
      </Badge>
    </OverlayTrigger>
  );
}
