import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncContributors({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/Contributors"));
  console.log("Loading", AsyncContributors.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncContributors;
