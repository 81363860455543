import React, { useReducer, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import GuardianControlSteps from "../components/GuardianControlSteps";
import withLayout from "../components/Layout";
import { useGuardian } from "../hooks/hooks";

import AppGlobalContext from "../../../../AppGlobalContext";

import fire from "../../../../services/fire";

import { useForm } from "react-hook-form";

const generateGuardianCode = fire
  .functions("asia-east2")
  .httpsCallable("generateGuardianCode");
const consumeGuardianCode = fire
  .functions("asia-east2")
  .httpsCallable("consumeGuardianCode");

/**
 * @typedef AccessCodeState
 * @prop {string} accessCode
 * @prop {boolean} isProcessing
 */

/**
 * @param {AccessCodeState} state
 * @param {{type: string, payload: Partial<AccessCodeState>}} action
 * @returns {AccessCodeState}
 */
function accessCodeReducer(state, { type, payload }) {
  switch (type) {
    case "startGenerate":
      return { ...state, isProcessing: true };
    case "endGenerate":
      return { accessCode: payload.accessCode, isProcessing: false };
    case "startConsume":
      return { accessCode: payload.accessCode, isProcessing: true };
    case "endConsume":
      return { ...state, isProcessing: false };
    default:
      return { ...state };
  }
}

function WardControl() {
  const { user } = useContext(AppGlobalContext);
  const { isGuardian } = useGuardian();

  const [copiedCode, setCopiedCode] = useState("");

  /** @type {AccessCodeState} */
  const initialAccessCodeState = {
    accessCode: "",
    isProcessing: false,
  };
  const [accessCodeState, accessCodeDispatch] = useReducer(
    accessCodeReducer,
    initialAccessCodeState
  );

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { code: "" } });

  async function handleGenerate() {
    accessCodeDispatch({ type: "startGenerate" });

    const {
      data: { ok, message },
    } = await generateGuardianCode({ fullname: user.fullname });

    ok
      ? accessCodeDispatch({
        type: "endGenerate",
        payload: { accessCode: message },
      })
      : console.error(message);
  }

  async function handleConsume() {
    const enteredCode = getValues("code").toUpperCase();
    accessCodeDispatch({
      type: "startConsume",
      payload: { accessCode: enteredCode },
    });

    // Test entered code if valid
    const {
      data: { message },
    } = await consumeGuardianCode({ code: enteredCode });

    accessCodeDispatch({ type: "endConsume" });
    alert(message);
  }

  return (
    <>
      <p className="text-center">
        You are on a{" "}
        <span className="font-weight-bold">
          {isGuardian ? "Guardian" : "Child"}
        </span>{" "}
        account.
      </p>
      <h5>Instructions</h5>
      <p className="text-center">
        This utility allows you to link a Guardian account with a Child account.
        <br />
        Please follow the steps below to get started.
      </p>
      <GuardianControlSteps />
      <hr className="w-100" />
      <Container>
        {isGuardian ? (
          <>
            <h5>Check Access Code</h5>
            <p>
              Please enter the access code generated from the Child account
              below.
            </p>
            <Form onSubmit={handleSubmit(handleConsume)}>
              <Row>
                <Col style={{ minWidth: 200 }}>
                  <Form.Control
                    size="lg"
                    className="text-center w-100"
                    style={{
                      fontFamily: "monospace",
                      textTransform: "uppercase",
                    }}
                    isInvalid={!!errors?.code}
                    {...register("code", {
                      required: true,
                      pattern: /^[A-Z0-9]{8}$/i,
                    })}
                  />
                  <Form.Text className="text-danger text-center font-weight-bold">
                    {!!errors?.code ? "Invalid format!" : ""}
                  </Form.Text>
                </Col>
                <Col>
                  <Button
                    size="lg"
                    type="submit"
                    disabled={accessCodeState.isProcessing}
                    className="w-100"
                  >
                    {accessCodeState.isProcessing ? (
                      <Spinner animation="border" />
                    ) : (
                      <>Check</>
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        ) : (
          <>
            <h5>Generate Access Code</h5>
            <p>
              Please copy the code once it is generated. This is the only time
              it will be visible.
            </p>
            <Row>
              <Col style={{ minWidth: 200 }}>
                <Form.Control
                  size="lg"
                  value={accessCodeState.accessCode}
                  readOnly={!accessCodeState.accessCode}
                  className="text-center w-100"
                  style={{
                    fontFamily: "monospace",
                  }}
                  onFocus={(e) => {
                    if (!!accessCodeState.accessCode) {
                      e.target.select();
                      if (accessCodeState.accessCode !== copiedCode) {
                        navigator.clipboard.writeText(
                          accessCodeState.accessCode
                        );
                        setCopiedCode(accessCodeState.accessCode);
                      }
                    }
                  }}
                />
                {!accessCodeState.accessCode ? (
                  <Form.Text className="text-muted text-center font-weight-bold">
                    Code not generated.
                  </Form.Text>
                ) : accessCodeState.accessCode === copiedCode ? (
                  <Form.Text className="text-success text-center font-weight-bold">
                    Code copied!
                  </Form.Text>
                ) : (
                  <Form.Text className="text-danger text-center font-weight-bold">
                    Select code to copy.
                  </Form.Text>
                )}
              </Col>
              <Col>
                <Button
                  size="lg"
                  onClick={handleGenerate}
                  disabled={accessCodeState.isProcessing}
                  className="w-100"
                >
                  {accessCodeState.isProcessing ? (
                    <Spinner animation="border" />
                  ) : (
                    <>Generate</>
                  )}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <div>
        <Col>
          <Link to="/catch">
            <Button className="my-3 w-100" variant="secondary" size="lg">
              Back to Menu
            </Button>
          </Link>
        </Col>
      </div>
    </>
  );
}

export default withLayout(WardControl, "wardControl");
