import React, { useContext, useRef, useState } from "react";
import fire from "../../../../../services/fire";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import AppGlobalContext from "../../../../../AppGlobalContext";

import { useForm } from "react-hook-form";
import { getBase64 } from "../../services/fileUtils";

const catchUpload = fire.functions("asia-east2").httpsCallable("catchUpload");

async function handleUploadCustodyDoc(
  uploadpath,
  fileName,
  file,
  imageDataType,
  imageFileName,
  now,
  setUploadDate,
  setValue,
  setIsUploading
) {
  try {
    const base64File = await getBase64(file);

    const {
      data: { ok, error, message },
    } = await catchUpload({
      file: base64File,
      uploadpath,
      fileName,
      imageDataType,
    });

    if (ok) {
      const custodyDocUrl = `${uploadpath}/${fileName}`;

      setValue("custodyDoc", custodyDocUrl);
      setValue("custodyDocFileName", imageFileName);

      setUploadDate(now);

      alert(`Custody Documentation ${message}`);

      setIsUploading(false);
    } else {
      console.error("handleUploadCustodyDoc::error:", error);

      alert(message);

      setIsUploading(false);
    }
  } catch (e) {
    console.error("handleUploadCustodyDoc::error:", e);

    alert("An error has occured");

    setIsUploading(false);
  }
}

export default function GuardianParticulars({
  guardianSetupPart,
  setGuardianSetupPart,
  setGuardianDetails,
}) {
  const { user } = useContext(AppGlobalContext);

  const {
    register,
    watch,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      guardianName: user.fullname || "",
      guardianRelationship: "",
      guardianRelationshipOthers: "",
      custodyDoc: "",
      custodyDocFileName: "",
    },
  });

  const [uploadDate, setUploadDate] = useState(Date.now());
  const [isUploading, setIsUploading] = useState(false);

  const guardianRelationship = watch("guardianRelationship");
  const custodyDoc = watch("custodyDoc");
  const custodyDocFileName = watch("custodyDocFileName");

  function onSubmit() {
    const guardianDetails = getValues();

    if (guardianDetails.custodyDoc !== "") {
      clearErrors("custodyDoc");

      guardianDetails.custodyDocUploadDate = uploadDate;

      setGuardianSetupPart(guardianSetupPart + 1);
      setGuardianDetails(guardianDetails);
    } else {
      setError("custodyDoc");
    }
  }

  const custodyRef = useRef();

  function custodyHandleClick() {
    custodyRef.current.click();
  }

  function custodyOnFileChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      setIsUploading(true);

      const file = e.target.files[0];
      const imageDataType = file.type;
      const imageFileName = file.name;
      const uploadpath = `user/${user.userid}/private/custody`;
      const now = Date.now();

      let fileName = "";

      switch (imageDataType) {
        case "application/pdf":
          fileName = `${user.userid}${now}.pdf`;
          break;
        case "image/jpeg":
          fileName = `${user.userid}${now}.jpeg`;
          break;
        case "image/png":
          fileName = `${user.userid}${now}.png`;
          break;
        default:
      }

      if (file.size > 4000000) {
        alert("You have exceeded the max file size of 4MB");

        setError("custodyDoc");

        setIsUploading(false);
      } else if (fileName) {
        clearErrors("custodyDoc");

        handleUploadCustodyDoc(
          uploadpath,
          fileName,
          file,
          imageDataType,
          imageFileName,
          now,
          setUploadDate,
          setValue,
          setIsUploading
        );
      }
    } else {
      setError("custodyDoc");
    }
  }

  return (
    <Container className="p-4 border-bottom">
      <b className={guardianSetupPart === 0 ? "text-primary" : "text-dark"}>
        Part 1 — Guardian Particulars
      </b>
      {guardianSetupPart === 0 ? (
        <>
          <br />
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-1"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">Name</Form.Label>
                  <Form.Control
                    size="lg"
                    className="w-100"
                    placeholder="Enter name"
                    {...register("guardianName", { required: true })}
                    isInvalid={!!errors.guardianName}
                  />
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="d-flex flex-row w-100">
                    What's your relationship to the child?
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    as="select"
                    size="lg"
                    {...register("guardianRelationship", { required: true })}
                    isInvalid={!!errors.guardianRelationship}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    <option key="mainFather" value="Father">
                      Father
                    </option>
                    <option key="mainMother" value="Mother">
                      Mother
                    </option>
                    <option key="mainOthers" value="Others">
                      Others
                    </option>
                  </Form.Control>
                  {guardianRelationship === "Others" ? (
                    <Form.Control
                      size="lg"
                      className="w-100 mt-3"
                      placeholder="e.g. Grandparent"
                      {...register("guardianRelationshipOthers", {
                        required: guardianRelationship === "Others",
                      })}
                    />
                  ) : null}
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="d-flex flex-row w-100 mb-0">
                    Please upload documentation certifying that you are the
                    Legal Guardian of this child.
                  </Form.Label>
                  <span className="text-muted">
                    Max file size is 4MB. Supported file types: jpg, png, pdf.
                  </span>
                  <Row className="w-100 mt-3 mx-0">
                    <Col className="px-0" xs="auto">
                      {custodyDoc === "" ? (
                        <Button
                          variant={
                            !!errors.custodyDoc ? "danger" : "outline-primary"
                          }
                          size="lg"
                          className="w-100 d-flex align-items-center"
                          onClick={custodyHandleClick}
                          disabled={isUploading}
                        >
                          {isUploading ? (
                            <>
                              <Spinner className="mr-1" animation="border" />
                              Uploading
                            </>
                          ) : (
                            <>
                              <ArrowUpwardOutlinedIcon className="mr-1" />
                              Upload file
                            </>
                          )}
                        </Button>
                      ) : (
                        <Button
                          variant="secondary"
                          size="lg"
                          className="w-100 text-wrap text-dark"
                          style={{
                            backgroundColor: "#f3f3f3",
                            borderColor: "#f3f3f3",
                          }}
                          onClick={() => {
                            setValue("custodyDoc", "");
                            setValue("custodyDocFileName", "");

                            custodyRef.current.value = "";
                          }}
                          disabled={isUploading}
                        >
                          <span>{custodyDocFileName}</span>
                          <DeleteOutlinedIcon className="text-danger ml-1" />
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <input
                    style={{ height: "0px" }}
                    id="file-input"
                    type="file"
                    onChange={custodyOnFileChange}
                    accept="application/pdf,image/jpeg,image/png"
                    ref={custodyRef}
                  />
                </Form.Row>
                <Form.Text muted>E.g. Birth Certificate, Court Document</Form.Text>
              </Form.Group>
            </Row>
          </Form>
          <Button
            size="lg"
            onClick={handleSubmit(onSubmit)}
            style={{ marginLeft: "-2px" }}
            disabled={isUploading}
          >
            Next
          </Button>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
}
