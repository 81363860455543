import React, { Component } from "react";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
// import { getGoogleMapSearchUrl } from "../../../utils/utils";
import { getParishPriestImageById } from "../service/priest";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

class PriestCard extends Component {
  render() {
    const { details, border, imageonly, nolink } = this.props;
    let { completename, priest, phone, email, link } = details;

    // let parishlink = `/parish/${link}`;
    let photo = getParishPriestImageById(details._id);
    if (photo === "temp") {
      photo = `../img/parish/200/${details.link}.jpg`;
      console.log("Photo:", photo);
    }
    if (imageonly) {
      return (
        <Image src={photo} width="20px" height="20px" alt="" roundedCircle />
      );
    }
    const cardStyle = border ? "" : "roundcorner";
    return (
      <div className={`card text-center my-3 ${cardStyle}`}>
        <div className="card-body">
          <h5 className="card-title text-center">
            {/* <Link to={parishlink}>{name}</Link> */}
            <Image
              src={photo}
              width="120px"
              height="120px"
              alt=""
              roundedCircle
            />
            <div className="pt-3 clearfix"></div>
            {priest}
          </h5>
          <div className="clearfix"></div>
          <h6 className="card-subtitle mb-1 text-muted">
            {!nolink ? (
              <>
                {!link ? (
                  completename
                ) : (
                  <Link to={`/parish/${link}`}>{completename}</Link>
                )}
              </>
            ) : (
              completename
            )}
          </h6>
          <p className="mt-3 card-text">
            <OverlayTrigger overlay={<Tooltip>{email}</Tooltip>}>
              <a
                target="_blank"
                className="btn-primary btn text-center mx-2 "
                rel="noopener noreferrer"
                href={`mailto:${email}`}
              >
                <MailOutlineIcon
                  style={{
                    fontSize: "20px",
                    marginTop: "-3px",
                  }}
                />
              </a>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>{phone}</Tooltip>}>
              <a
                target="_blank"
                className="btn-success btn text-center mx-2"
                rel="noopener noreferrer"
                href={`tel:${phone}`}
              >
                <CallOutlinedIcon
                  style={{
                    fontSize: "20px",
                    marginTop: "-3px",
                  }}
                />
              </a>
            </OverlayTrigger>
            {/* <AddLocationOutlinedIcon style={{ color: "#277AF5" }} />{" "} */}
            {/* <br /> */}
            {/* <AccountBoxOutlinedIcon style={{ color: "#277AF5" }} />  */}
            {/* <a target="_blank" rel="noopener noreferrer" href={`tel:${phone}`}>
              {phone}
            </a>
            <br /> */}
            {/* <MailOutlineIcon style={{ color: "#277AF5" }} />{" "} */}
            {/* <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${email}`}
            >
              {email}
            </a>{" "} */}
            {/* <br />
            <a target="_blank" rel="noopener noreferrer" href={mapUrl}>
              {address}
            </a> */}
            {/* <CallOutlinedIcon style={{ color: "#277AF5" }} />{" "} */}
            {/* {facebook && (
              <React.Fragment>
                <br />
                <a target="_blank" rel="noopener noreferrer" href={facebook}>
                  {facebook.replace("https://www.facebook", "fb")}
                </a>
              </React.Fragment>
            )}
            {website && (
              <React.Fragment>
                <br />
                {/* <PublicIcon style={{ color: "#277AF5" }} />{" "} */}
            {/* <a target="_blank" rel="noopener noreferrer" href={website}>
                  {website}
                </a>
              </React.Fragment>
            )}  */}
          </p>
        </div>
      </div>
    );
  }
}

export default PriestCard;
