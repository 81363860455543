import React from "react";
import {
  getDayString,
  getMassScheduleDate as getRoomScheduleDate,
  getMassTime as getRoomTime,
  getSGDateObj,
} from "../../utils/utils.js";
import { timeslotRef } from "../coworking/service/references.js";
import OrgBadge from "../organisation/components/OrgBadge.jsx";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import AccessibilityNewOutlinedIcon from "@material-ui/icons/AccessibilityNewOutlined";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import { formatMultiRate } from "../coworking/service/currency.js";

const RoomDetail = ({
  notes,
  name,
  function: func,
  location,
  venue,
  date,
  duration,
  slotType,
  paxLimit,
  rate,
  bookerOrg,
  roomUsageDetails,
  referenceNo = "",
}) => {
  const sgdatestart = getSGDateObj(date);
  const sgdateend = getSGDateObj({
    seconds: date.seconds + duration * 60,
    nanoseconds: date.nanoseconds,
  });
  const time = slotType ? (
    <>
      {timeslotRef[slotType].name} ({getRoomTime(sgdatestart)} &ndash;{" "}
      {getRoomTime(sgdateend)})
    </>
  ) : (
    <>
      {getRoomTime(sgdatestart)} &ndash; {getRoomTime(sgdateend)}
    </>
  );

  return (
    <>
      <p className={`defaultfontsize text-dark mb-0 py-2 text-center bold`}>
        {getRoomScheduleDate(sgdatestart)}, {getDayString(sgdatestart)}
        <br />
        {time}
      </p>
      <p className={`biggerfontsize text-muted mb-0 text-center mx-3`}>
        <LocalOfferOutlinedIcon
          style={{ fontSize: "18px", marginTop: "-4px" }}
        />{" "}
        {formatMultiRate(rate)}
      </p>
      <p className={`biggerfontsize text-muted mb-0 text-center mx-3`}>
        <LocationOnOutlinedIcon
          style={{ fontSize: "18px", marginTop: "-4px" }}
        />{" "}
        {name} ({func}) &bull; {location}
        <br />
        <small>{venue}</small>
      </p>
      {paxLimit > 1 && (
        <>
          <hr />
          <span className="bold text-dark">Used by</span>
          {bookerOrg && (
            <p className={`biggerfontsize mb-0 text-center`}>
              <OrgBadge key={"org-" + bookerOrg} orgId={bookerOrg} />
            </p>
          )}
          <p className={`text-muted mb-0 text-center mx-3`}>
            <NoteOutlinedIcon style={{ fontSize: "18px", marginTop: "-4px" }} />{" "}
            {roomUsageDetails.purpose}
          </p>
          <p className={`text-muted mb-0 text-center mx-3`}>
            <AccessibilityNewOutlinedIcon
              style={{ fontSize: "18px", marginTop: "-4px" }}
            />{" "}
            <strong>{roomUsageDetails.contactName}</strong> (
            <em>{roomUsageDetails.contactMobile}</em>)
          </p>
        </>
      )}
      {referenceNo !== "" && (
        <>
          <hr className="my-2" />
          <p
            className={`defaultstandardfontsize font-weight-bold my-0 text-center`}
          >
            Reference No.: {referenceNo}
          </p>
        </>
      )}
    </>
  );
};

export default RoomDetail;
