import React from "react";
import Joi from "joi-full";
import { Button } from "react-bootstrap";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";

import Form from "../../common/form";
import FloatingLabelInput from "../../common/FloatingLabelInput";

import {
  getShortMonths,
} from "../../../utils/utils";

class RegistrationFormBaptism extends Form {
	constructor(props) {
		super(props);

		const { draft, parishes } = props;
		
		let isBaptized = "", isBaptizedinSG = "", dateOfBaptism = "", churchOfBaptism = 0;

		if (draft.hasOwnProperty("isBaptized")) {
			isBaptized = draft.isBaptized.toString();
		}

		if (draft.hasOwnProperty("isBaptizedinSG")) {
			isBaptizedinSG = draft.isBaptizedinSG.toString();
		}

		if (draft.dateOfBaptism) {
			dateOfBaptism = new Date(draft.dateOfBaptism.seconds * 1000 + draft.dateOfBaptism.nanoseconds/1000000);
		}

		if (draft.churchOfBaptism) {
			const [ item ] = parishes.filter((g) => g.name === draft.churchOfBaptism);

			if (item) {
				if (Object.keys(item) !== 0) {
					churchOfBaptism = item._id;
				} 
			} else {
				churchOfBaptism = draft.churchOfBaptism;
			}
		}

		this.state = {
			data: {
				isBaptized: isBaptized || "",
				isBaptizedinSG: isBaptizedinSG || "",
				dateOfBaptism: dateOfBaptism || new Date(props.dob),
				churchOfBaptism: churchOfBaptism || 0,
			},
			parishes,
			errors: {},
		};
	}

	schema = {
		churchOfBaptism: Joi.string().trim().required().label("Church of Baptism"),
	};

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	handleRadioChange = (type) => ({ currentTarget: input }) => {
		const { data } = this.state;
		const newData = { ...data };

		if (type === "isBaptizedinSG") {
			if (input.value.toString() === "false") {
				newData.churchOfBaptism = "";
			} else if (input.value.toString() === "true") {
				newData.churchOfBaptism = 0;
			}
		}

		newData[type] = input.value;

		this.setState({ data: newData });
	}

	handleDateChange = (date) => {
		const { data } = this.state;
		const newData = { ...data };

		newData.dateOfBaptism = date;

		this.setState({
			data: newData,
		});
	}

	displayDateFormat = (date) => {
		if (date === "") {
			return "";
		}

		const shortMonths = getShortMonths();
		const monthStr = shortMonths[date.getMonth()];
		const day = date.getDate();
		const year = date.getFullYear();
		const dateStr = day + " " + monthStr + " " + year;

		return dateStr;
	}

	doSubmit = async () => {
		const { setRegistrationBaptismDetails, dob } = this.props;
		const { data } = this.state;
		const {
			isBaptized = "",
			isBaptizedinSG = "",
			dateOfBaptism = new Date(dob),
			churchOfBaptism = 0,
		} = data;

		if (isBaptized.toString() === "false") {
			setRegistrationBaptismDetails(data);
		} else if (isBaptized && isBaptizedinSG && dateOfBaptism && churchOfBaptism) {
			setRegistrationBaptismDetails(data);
		}
	}

	renderChurchOfBaptism = () => {
		const { parishes, data: { isBaptized = "", isBaptizedinSG = "" } } = this.state;
		let content = "";

		if (isBaptized.toString() === "true") {
			if (isBaptizedinSG.toString() === "false") {
				content = (
					<div className="d-flex">
						<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
							style={{ height: "25px", minWidth: "25px" }}>
							4
						</div>
						<div className="container">
							<div>
								<strong>Which church is was your child baptised in?</strong>
							</div>
							<div className="ml-2 mt-2 pl-0 container">
								{ this.renderInput(
									"churchOfBaptism",
									"",
									"text",
									"Church, Country, State"
								)}
							</div>
						</div>
					</div>
				);
			} else {
				content = (
					<div className="d-flex mt-2">
						<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
							style={{ height: "25px", minWidth: "25px" }}>
							4
						</div>
						<div className="container">
							<div>
								<strong>What was the Church of Baptism?</strong>
							</div>
							<div className="ml-2 mt-2 pl-0 container">
								{ parishes.length ?
									this.renderSelect(
										"churchOfBaptism",
										"",
										parishes,
									) : null
								}
							</div>
						</div>
					</div>
				);
			}
		}

		return content;
	}

	renderNextButton = () => {
		const { dob } = this.props;
		const { data } = this.state;
		const {
			isBaptized = "",
			isBaptizedinSG = "",
			dateOfBaptism = new Date(dob),
			churchOfBaptism = 0,
		} = data;

		let isDisabled = true;

		if (isBaptized.toString() === "false") {
			isDisabled = false;
		} else if (isBaptized && isBaptizedinSG && dateOfBaptism && churchOfBaptism) {
			isDisabled = false;
		}

		return (
			<Button
				className="btn-block mb-3"
				size="lg"
				onClick={ this.doSubmit }
				disabled={ isDisabled }
			>
				Fill in education details
			</Button>
		);
	}

	handleOnClick = () => {
		const { saveDraft } = this.props;
		const { data } = this.state;

		saveDraft(data);
	}

	handlePressBack = () => {
		const { pressBack } = this.props;

		pressBack();
	}

	render() {
		const { data } = this.state;
		const { isBaptized } = data;

		return (
			<div>
				<strong>Baptism Details</strong>
				<br />
				<br />
				<React.Fragment>
					<form onSubmit={ this.handleSubmit}>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								1
							</div>
							<div className="ml-3">
								<div>
									<strong>Has your child received the sacrament of baptism?</strong>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="isBaptized"
										type="radio"
										id="isBaptized1"
										value={ true }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("isBaptized") }
										checked={ data.isBaptized === "true" }
									/>
									<label className="form-check-label ml-2" htmlFor="isBaptized1">Yes</label>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="isBaptized"
										type="radio"
										id="isBaptized2"
										value={ false }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("isBaptized") }
										checked={ data.isBaptized === "false" }
									/>
									<label className="form-check-label ml-2" htmlFor="isBaptized2">No</label>
								</div>
							</div>
						</div>
						{ isBaptized.toString() === "true" ?
							<div className="d-flex mt-2">
								<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
									style={{ height: "25px", minWidth: "25px" }}>
									2
								</div>
								<div className="ml-3">
									<div>
										<strong>Was your child baptised in Singapore?</strong>
									</div>
									<div className="ml-4 mt-2 mb-3">
										<input
											className="form-check-input"
											name="isBaptizedinSG"
											type="radio"
											id="isBaptizedinSG1"
											value={ true }
											style={{ transform: "scale(1.5)" }}
											onChange={ this.handleRadioChange("isBaptizedinSG") }
											checked={ data.isBaptizedinSG === "true" }
										/>
										<label className="form-check-label ml-2" htmlFor="isBaptizedinSG1">Yes</label>
									</div>
									<div className="ml-4 mt-2 mb-3">
										<input
											className="form-check-input"
											name="isBaptizedinSG"
											type="radio"
											id="isBaptizedinSG2"
											value={ false }
											style={{ transform: "scale(1.5)" }}
											onChange={ this.handleRadioChange("isBaptizedinSG") }
											checked={ data.isBaptizedinSG === "false" }
										/>
										<label className="form-check-label ml-2" htmlFor="isBaptizedinSG2">No</label>
									</div>
								</div>
							</div> : null
						}
						{ isBaptized.toString() === "true" ?
							<div className="d-flex">
								<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
									style={{ height: "25px", minWidth: "25px" }}>
									3
								</div>
								<div className="container">
									<div>
										<strong>When was your child baptised?</strong>
									</div>
									<div className="ml-2 mt-2 mb-3 pr-2">
										<div className="d-flex com-event__date-input">
											<div className="d-flex flex-column justify-content-center">
												<CalendarTodayIcon />
											</div>
											<DatePicker
												value={ data.dateOfBaptism }
												onChange={ this.handleDateChange }
												maxDate={new Date()}
												openToDate={ data.dateOfBaptism }
												customInput={
												<div>
													<FloatingLabelInput
													// label="Start Date*"
													customWrapperClass="com-event__date-wrapper com-event__input-wrapper"
													customClassName="mb-4"
													value={ this.displayDateFormat(data.dateOfBaptism)}
													readOnly
													/>
												</div>
												}
											/>
										</div>
									</div>
								</div>
							</div> : null
						}
						{ this.renderChurchOfBaptism() }
					</form>
					<br />
					<br />
					{ this.props.coordinatorEmail ?
						<>
							If you have enquiries, plase contact your Parish Catechist Coordinator at{" "}
							<a href={ `mailto:${ this.props.coordinatorEmail }` }>{ this.props.coordinatorEmail }</a>
							<br />
							<br />
						</> : null
					}
					{ this.renderNextButton() }
					<Button className="btn-block mb-3" variant="outline-primary" size="lg"
						onClick={ this.handlePressBack }>
						Back
					</Button>
					<button
						className="btn-block mb-3 btn outline-primary btn-lg"
						style={{ border: "none", color: "#007bff" }}
						onClick={ this.handleOnClick }
					>
						Save as draft
					</button>
				</React.Fragment>
			</div>
		);
	}
}

export default RegistrationFormBaptism;
