import React from "react";
import Joi from "joi-full";
import Form from "../../common/form";
import fire from "../../../services/fire";
import { withRouter } from "react-router-dom";
import {
  getLocalTime,
  checkNRICisValid,
  getLastDigitId,
} from "../../../utils/utils";
import {
  DEFAULT_PARISH_CODE,
  UNAFFILIATED_PARISH_CODE,
  getParishes,
  getParishesWithUnaffiliated,
} from "../../../services/parish";
import { getMappedCountries } from "../../../utils/country";
import AgreeTerms from "../../consent/component/agreeTerms";
import AgreeComs from "../../consent/component/agreeComs";
import AgreeMyCatholicSG from "../../consent/component/agreeMyCatholicSG";
import { isEnableUnaffiliated } from "../../../services/settings";
import WhyNeeded from "./whyNeeded";
import { Button } from "react-bootstrap";
import { userLogout } from "../../../services/user";
import { logEvent } from "../../../services/log";
import ReportBug from "../../report/page/reportBug";

class ParishionerDetails extends Form {
  constructor(props) {
    super(props);
    const {
      fullname,
      email,
      dob,
      mobile,
      identification,
      confirmIdentification,
      citizenship,
      parish,
      catholic,
      country,
    } = this.props.userdetails;

    const mappedCountries = getMappedCountries(["SG"]);
    this.state = {
      data: {
        identification: identification || "",
        confirmIdentification: confirmIdentification || "",
        mobile: mobile || "+65",
        parish: parish || 0,
        citizenship: citizenship || "1",
        dob: dob || "",
        agreeterms: false,
        agreecoms: false,
        agreemcsg: false,
        catholic: catholic,
        country: country || "SG",
      },
      fullname,
      email,
      idType: "NRIC",
      errors: {},
      parishes: [],
      previousParish: parish || 0,
      loading: false,
      year: new Date().getFullYear(),
      countryList: mappedCountries,
    };
  }

  schema = {
    identification: Joi.string().trim().required().label("Identification"),
    confirmIdentification: Joi.string()
      .trim()
      .required()
      .label("Identification Confirmation"),
    mobile: Joi.string()
      .trim()
      .regex(/^\+[1-9?\s]{1}[0-9?\s]{9,14}$/)
      .min(10)
      .required()
      .label("Mobile Number"),
    dob: Joi.number()
      .min(1890)
      .max(new Date().getFullYear())
      .required()
      .label("Year of Birth"),
    citizenship: Joi.string().trim().required().label("Citizenship"),
    parish: Joi.string().trim().required().label("Parish"),
    agreeterms: Joi.boolean()
      .invalid(false)
      .required()
      .label("Terms and Conditions"),
    agreemcsg: Joi.boolean().invalid(false).required().label("myCatholicSG"),
    agreecoms: Joi.boolean().optional(),
    catholic: Joi.string().trim().required().label("Catholic Status"),
    country: Joi.string().trim().required().label("Country"),
  };

  componentDidUpdate(prevProp, prevState) {
    const prevIdentification = prevState?.data?.identification ?? false;
    const prevCitizenship = prevState?.data?.citizenship ?? false;
    const prevConfirmIdentification =
      prevState?.data?.confirmIdentification ?? false;
    const { identification, confirmIdentification, citizenship } =
      this.state?.data ?? false;
    const identificationErr = this.state?.errors?.identification ?? false;
    const confirmIdentificationErr =
      this.state?.errors?.confirmIdentification ?? false;

    // Change idType text + Remove errors + fields on citizenship change
    if (citizenship !== prevCitizenship) {
      const newIdType = this.getIdType();
      let countryStr = "";
      if (citizenship !== "4") {
        countryStr = "SG";
      }
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          identification: "",
          confirmIdentification: "",
          country: countryStr,
        },
        idType: newIdType,
        errors: {
          ...this.state.errors,
          identification: "",
          confirmIdentification: "",
        },
      });
    }

    // Custom Validation for ID Fields
    // Check if NRIC/FIN is valid length
    if (identification.length === 9) {
      let isValid = true;
      if (citizenship !== "4") {
        isValid = checkNRICisValid(identification);
        // Check if NRIC/FIN is valid format
        if (!isValid && !identificationErr) {
          this.setState({
            ...this.state,
            errors: {
              ...this.state.errors,
              identification: "Invalid NRIC/FIN",
            },
          });
        }
      }
      if (isValid || citizenship === "4") {
        // Valid and no errors, check that confirmation matches
        // Compares prevInput and currentInput as onBlur triggers state update
        if (confirmIdentification.length > 0) {
          if (
            identification !== confirmIdentification &&
            !confirmIdentificationErr &&
            prevConfirmIdentification === confirmIdentification
          ) {
            this.setState({
              ...this.state,
              errors: {
                ...this.state.errors,
                confirmIdentification: "ID confirmation input must match",
              },
            });
          }
        }
      }
    } else if (
      identification.length > 0 &&
      !identificationErr &&
      identification === prevIdentification
    ) {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          identification: "Identification must be 9 characters long",
        },
      });
    }
  }

  componentDidMount() {
    let parishes;
    if (isEnableUnaffiliated()) {
      parishes = getParishesWithUnaffiliated();
    } else {
      parishes = getParishes();
    }
    this.setState({
      parishes,
    });
    window.scrollTo(0, 0);
  }

  getIdType = () => {
    const { citizenship } = this.state.data;
    switch (citizenship) {
      case "1":
        return "NRIC";
      case "2":
        return "NRIC";
      case "3":
        return "FIN";
      default:
        return "Passport";
    }
  };

  doSubmit = async () => {
    const { fullname, email, userid, created } = this.props.userdetails;
    const { data, errors } = this.state;
    // Check for custom validation errors before proceeding
    const hasErrors = Object.keys(errors).length !== 0;
    if (hasErrors) {
      return;
    }
    this.setState({
      errors,
      loading: true,
    });
    // Call the server
    try {
      //remove any spaces inside the id
      let id = data.identification.toUpperCase().trim().replace(/\s+/g, "");
      let phone = data.mobile.replace(/\s+/g, "");
      let subId = getLastDigitId(id);
      const agreeterms = data.agreeterms;
      const agreecoms = data.agreecoms;
      const agreemcsg = data.agreemcsg;
      const catholic = data.catholic;
      const country = data.country;

      let secureid;
      try {
        const secureId = fire.functions("asia-east2").httpsCallable("secureId");

        secureid = await secureId({ input: id });
      } catch (err) {
        logEvent("secure_id_fail", {
          email: email,
          message: err.data.message,
        });
        this.setState({
          data: {
            identification: data.identification.trim(),
            confirmIdentification: data.confirmIdentification.trim(),
            mobile: phone,
            parish: data.parish.trim(),
            citizenship: data.citizenship.trim(),
            dob: data.dob.trim(),
            agreeterms: agreeterms,
            agreecoms: agreecoms,
            agreemcsg: agreemcsg,
            catholic: catholic,
            country: country,
          },
          errors: err,
          loading: false,
        });
        return;
      }

      //call cloud function
      try {
        const checkExistIdentification = fire
          .functions("asia-east2")
          .httpsCallable("checkExistIdentification");

        let result = await checkExistIdentification({ input: id });
        let error;
        if (result.data.status !== 0) {
          if (result.data.status === 1) {
            error = {
              identification: `Identification already in use, please login to your existing account. Need assistance? Contact mycatholicsg@catholic.org.sg with your full name, year of birth & last 2 characters of NRIC/FIN`,
            };
          } else if (result.data.status === 2) {
            error = { identification: "Incorrect input." };
          } else {
            error = { identification: "Unable to validate." };
          }
          logEvent("check_exist_identification_fail", {
            email: email,
            message: result.data.message,
          });
          this.setState({
            data: {
              identification: data.identification.trim(),
              confirmIdentification: data.confirmIdentification.trim(),
              mobile: phone,
              parish: data.parish.trim(),
              dob: data.dob.trim(),
              citizenship: data.citizenship.trim(),
              agreeterms: agreeterms,
              agreecoms: agreecoms,
              agreemcsg: agreemcsg,
              catholic: catholic,
              country: country,
            },
            errors: error,
            loading: false,
          });
          return;
        }
      } catch (error) {
        console.log("Error:", error);
        logEvent("user_account_set_fail", {
          email: email,
          message: error.message,
        });
        this.setState({
          data: {
            identification: data.identification.trim(),
            confirmIdentification: data.confirmIdentification.trim(),
            mobile: phone,
            parish: data.parish.trim(),
            dob: data.dob.trim(),
            citizenship: data.citizenship.trim(),
            agreeterms: agreeterms,
            agreecoms: agreecoms,
            agreemcsg: agreemcsg,
            catholic: catholic,
            country: country,
          },
          errors: error,
          loading: false,
        });
        return;
      }
      const currentUser = fire.auth().currentUser;
      const citizenshipInt = parseInt(data.citizenship);
      const emailUnsub = agreecoms
        ? { archdiocese: true, parish: true }
        : { archdiocese: false, parish: false };
      const userObj = {
        userid,
        created,
        fullname: fullname.trim(),
        email: email.trim(),
        mobile: phone.trim(),
        dob: data.dob.trim(),
        subid: subId.trim(),
        // subid2: subId.trim(), //new field subid2 for 2 digit
        identification: secureid.data.message.trim(),
        citizenship: citizenshipInt,
        parish: data.parish.trim(),
        agreeterms: agreeterms,
        agreemcsg: agreemcsg,
        emailUnsub: emailUnsub,
        catholic: catholic === "Yes" ? true : false,
        country: country.trim(),
      };
      //check if parish is unaffiliated
      if (parseInt(data.parish) === UNAFFILIATED_PARISH_CODE) {
        userObj.parish = DEFAULT_PARISH_CODE.toString();
        userObj.unaffiliated = true;
      }
      await fire.firestore().doc(`users/${currentUser.uid}`).update(userObj);
      logEvent("user_account_set_success", {
        email: data.email,
        parish: data.parish,
      });
      this.props.history.push("/account/verify");
    } catch (ex) {
      logEvent("user_account_set_fail", {
        email: email,
        message: ex.message,
      });
      console.error("Unable to Set Data");
    }
  };

  forceLogout = async () => {
    try {
      await userLogout();
      // signed out
      window.location.reload();
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  render() {
    const { loading, fullname } = this.state;
    const localtime = getLocalTime();

    return (
      <div>
        <h3 className="text-left text-muted">{fullname}</h3>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput(
            "mobile",
            "Mobile Number",
            "text",
            "(E.g. +65 91234567)"
          )}

          {this.renderSelect("citizenship", "Singapore Immigration Status", [
            { _id: 1, name: "Singapore Citizen" },
            { _id: 2, name: "Permanent Resident" },
            { _id: 3, name: "FIN / Pass Holder" },
            { _id: 4, name: "Singapore Visitor" },
          ])}
          {this.renderInput(
            "identification",
            <>
              {this.state.idType}
              <WhyNeeded />
            </>,
            "text",
            "(E.g. S1234567Z)"
          )}
          {this.state?.data?.citizenship === "4" && (
            <p className="mb-4 alert alert-info" style={{ fontSize: "14px" }}>
              Up to 9 characters. If your passport number does not make up to 9
              characters, please add "0"s to the end
            </p>
          )}
          {/* <p
            className="mb-4 alert alert-warning"
            style={{ fontSize: "14px", lineHeight: "140%" }}
          >
            Your {getIDText(this.state?.data?.citizenship)} prevents duplicate
            accounts and will not be stored. You may need to show your ID for
            verification when attending events published on myCatholicSG.
          </p> */}
          {this.renderInput(
            "confirmIdentification",
            "Confirm " + this.state.idType,
            "text",
            "(E.g. S1234567Z)"
          )}
          {this.state?.data?.citizenship === "4" ? (
            this.renderSelect("country", "Country", this.state.countryList)
          ) : (
            <input type="hidden" value="SG" name="country" id="country" />
          )}
          {this.renderInput("dob", "Year of Birth", "text", "(Ex. 1980)")}
          {this.renderSelect("catholic", "Are you a Catholic?", [
            { _id: "Yes", name: "Yes" },
            { _id: "No", name: "No" },
          ])}
          {this.renderSelect("parish", "Parish", this.state.parishes)}
          <div className="px-2 py-0 d-flex justify-content-start">
            {this.renderCheckBox("agreeterms", <AgreeTerms />)}
          </div>
          <div className="px-2 py-0 d-flex justify-content-start">
            {this.renderCheckBox("agreemcsg", <AgreeMyCatholicSG />)}
          </div>
          <div className="px-2 pt-0 pb-4 d-flex justify-content-start">
            {this.renderCheckBox("agreecoms", <AgreeComs />)}
          </div>
          {!loading
            ? this.state.data["agreeterms"] === false ||
              this.state.data["agreemcsg"] === false
              ? this.renderDisabledBlockButton("Set Up")
              : this.renderBlockButton("Set Up")
            : this.state.data["agreeterms"] === true &&
              this.state.data["agreemcsg"] === true &&
              this.renderLoadingBlockButton("Please wait...")}
        </form>
        <div className="text-center py-4">
          <ReportBug
            context={this.state}
            rendertime={localtime}
            page="AccountSetup"
            component="parishionerDetails"
          />
        </div>
        <div className="text-center">
          <Button
            variant="link"
            className="my-4 btn-lg text-center "
            onClick={this.forceLogout}
          >
            <span className="normalsmallfontsize">Logout</span>
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(ParishionerDetails);
