import { getMassDateTime } from "../utils/utils";
import fire from "./fire";

export const EVENT_HUB_BOOK_START = 0;
export const EVENT_HUB_BOOK_END = 1;
export const EVENT_HUB_DELETE_START = 2;
export const EVENT_HUB_DELETE_END = 3;
export const EVENT_HUB_DELETE_SELECTED = 4;
export const EVENT_HUB_DELETE_CONFIRMED = 5;
export const EVENT_HUB_DELETE_CANCELLED = 6;

export async function logEvent(event, data) {
  fire.analytics().logEvent(event, data);
}

export async function logAction(uid, actionType, actionObj) {
  const logRef = fire.firestore().collection("actions").doc();
  const uniqNum = new Date().getTime();

  const docData = {
    id: uid,
    actionType,
    created: uniqNum,
    obj: actionObj,
  };

  await logRef.set(docData);
}

export async function logMessage(where, data) {
  const logMessage = fire.functions("asia-east2").httpsCallable("logMessage");

  const date = new Date();
  logMessage({
    email: data.email,
    code: data.code,
    message: data.message,
    where: where,
    timestamp: `${date.toDateString()} ${getMassDateTime(date)}`,
  });
}
