import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncBarcodeScanMassAttendance({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/barcodeScanMassAttendance"));
  console.log("Loading", AsyncBarcodeScanMassAttendance.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncBarcodeScanMassAttendance;
