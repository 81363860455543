import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form";
import fire from "../../../services/fire";
import { logEvent } from "../../../services/log";
import { Link } from "react-router-dom";
import ArchdioceseLogo from "../../blocks/archdioceseLogoBlock";
import { checkUser } from "../../../services/checkUser";

class LoginMiniForm extends Form {
  constructor(props) {
    super(props);
    const redirectPathName = props?.location?.state?.pathname;
    const redirectSearch = props?.location?.state?.search ?? "";

    this.state = {
      data: { email: "", password: "" },
      errors: {},
      loading: false,
      source: this.props.source || "unknown",
      redirectPathName,
      redirectSearch,
      passwordValid: true,
    };
  }

  schema = {
    email: Joi.string().email().trim().required().label("Email"),
    password: Joi.string().min(8).trim().required().label("Password"),
  };

  doSubmit = async () => {
    // Call the server
    const { data, errors } = this.state;
    this.setState({
      errors,
      loading: true,
    });
    let lowercaseemail = data.email.trim().toLowerCase();
    try {
      await checkUser(lowercaseemail, this.state.source || "loginmini");
    } catch (e) {
      console.log(e);
    }
    logEvent("login_click", { email: lowercaseemail });
    fire
      .auth()
      .signInWithEmailAndPassword(lowercaseemail, data.password.trim())
      .then((u) => {
        logEvent("login_success", { email: lowercaseemail });
        if (this.state.redirectPathName) {
          this.props.history.push({
            pathname: this.state.redirectPathName,
            search: this.state.redirectSearch,
            state: { user: u.user },
          });
        } else if (this.props.successCallback) {
          this.props.successCallback();
        } else {
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        let errors;
        if (error.code === "auth/too-many-requests") {
          errors = { email: error.message };
        } else {
          errors = {
            email: "Incorrect email or password",
          };
        }
        logEvent("login_fail", {
          email: lowercaseemail,
          message: error.message,
        });
        this.setState({
          loading: false,
          errors,
        });
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { passwordValid, data, loading } = this.state;

    return (
      <React.Fragment>
        <main className="container">
          <div className="pb-3 pt-0">
            <ArchdioceseLogo />
            <h1 className="pb-2">Hi, please log in</h1>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput("email", "Email", "text", "Your email")}
              {this.renderPasswordInput(
                "password",
                "Password",
                "password",
                "8 characters or more"
              )}

              {passwordValid && !loading && this.renderBlockButton("Log In")}
              {!passwordValid &&
                !loading &&
                this.renderDisabledBlockButton("Log In")}
              {loading && this.renderLoadingBlockButton("Logging in...")}
            </form>
          </div>

          <div className="text-center">
            <p className="pt-0 pb-0 defaultfontsize text-danger">
              New user registration? <Link to="/register">Sign up</Link>
            </p>
            <hr />
            <p className="pt-0 pb-2 defaultfontsize">
              <Link to="/forgot">Forgot password?</Link>
            </p>
            <p className="pt-2 pb-2 defaultfontsize">
              <Link to="/home">Back to Home</Link>
            </p>
          </div>
          <div className="col-12 mt-5 mb-4 text-center">
            <p className="mt-3 mb-0 text-muted">
              &copy; {new Date().getFullYear()} Roman Catholic Archdiocese of
              Singapore. All Rights Reserved.
            </p>
            <p className="my-0 text-muted">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.catholic.sg/terms/"
                className="text-muted"
              >
                Terms & Conditions
              </a>
            </p>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default LoginMiniForm;
