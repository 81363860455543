import React, { useState, useEffect, useContext, useRef } from "react";
import fire from "../../../../../services/fire";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import Select from "react-select";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import ExpandMoreOutlined from "@material-ui/icons/ExpandMoreOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import "../../css/index.css";

import { useForm, Controller } from "react-hook-form";

import AppGlobalContext from "../../../../../AppGlobalContext";

import {
  useWardSelection,
  useGuardian,
  useRegistrationDetails,
  useParishConfig,
} from "../../hooks/hooks";
import { registrationCollectionName } from "../../services/collections";
import { levelRef } from "../../services/levels";
import { sortedDistricts } from "../../services/parishes";
import { registrationStatusNameToStatus } from "../../services/registrationStatus";

import SaveDraftConfirmationModal from "./SaveDraftConfirmationModal";

import _ from "lodash";
import { getBase64 } from "../../services/fileUtils";

const catchUpload = fire.functions("asia-east2").httpsCallable("catchUpload");

function handleSaveData(
  registrationCollection,
  registrationDetails,
  registrationData,
  inputFields,
  ward,
  uid,
  setRegistrationStep,
  jumpTo,
  fromReview,
  setFromReview
) {
  if (!uid) {
    return () => {};
  }

  let registrationDoc;

  if (!!registrationData && registrationData.programmeType?.id) {
    registrationData.registrationYear =
      registrationData.programmeType?.registrationYear;
    registrationData.programmeType = registrationData.programmeType?.id;
  }

  if (registrationDetails.id) {
    registrationDoc = registrationCollection.doc(registrationDetails.id);

    const docData = {
      ...registrationDetails,
      ...registrationData,
      siblingsDetails: inputFields,
    };

    registrationDoc.update(docData).then(() => {
      if (jumpTo > 0) {
        if (fromReview) {
          setRegistrationStep(7);
          setFromReview(false);
        } else {
          setRegistrationStep(jumpTo);
        }

        window.scrollTo(0, 0);
      }
    });
  } else {
    registrationDoc = registrationCollection.doc();

    const docData = {
      id: registrationDoc.id,
      status: registrationStatusNameToStatus("unregistered"),
      submittedAt: new Date(),
      submittedBy: uid,
      child: {
        name: ward.name,
        uid: ward.uid,
      },
      payments: [],
      ...registrationData,
      siblingsDetails: inputFields,
    };

    registrationDoc.set(docData).then(() => {
      if (jumpTo > 0) {
        if (fromReview) {
          setRegistrationStep(7);
          setFromReview(false);
        } else {
          setRegistrationStep(jumpTo);
        }

        window.scrollTo(0, 0);
      }
    });
  }
}

async function handleUploadTransferLetter(
  uploadpath,
  fileName,
  file,
  imageDataType,
  imageFileName,
  setValue,
  setIsUploadingTransferLetter
) {
  try {
    const base64File = await getBase64(file);

    const {
      data: { ok, error, message },
    } = await catchUpload({
      file: base64File,
      uploadpath,
      fileName,
      imageDataType,
    });

    if (ok) {
      const transferLetterUrl = `${uploadpath}/${fileName}`;

      setValue("transferLetterPath", transferLetterUrl);
      setValue("transferLetterName", imageFileName);

      alert(`Transfer Letter ${message}`);

      setIsUploadingTransferLetter(false);
    } else {
      console.error("handleUploadTransferLetter::error:", error);

      alert(message);

      setIsUploadingTransferLetter(false);
    }
  } catch (e) {
    console.error("handleUploadTransferLetter::error:", e);
    alert("An error has occured");
  }
}

export default function ParishDetails({
  registrationStep,
  setRegistrationStep,
  fromReview,
  setFromReview,
}) {
  const registrationDetails = useRegistrationDetails();
  const parishConfig = useParishConfig();

  const { user } = useContext(AppGlobalContext);
  const { selectedWardIndex } = useWardSelection();
  const { wardsData } = useGuardian();

  const ward = wardsData[selectedWardIndex];

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);

  const {
    register,
    watch,
    handleSubmit,
    getValues,
    setValue,
    reset,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selectedParishId: "",
      registrationType:
        registrationStatusNameToStatus("transferred") ===
        registrationDetails?.status
          ? "transfer"
          : "",
      outsideBoundaryReason: "",
      programmeType: "",
      level: "",
      timeslot: "",
      hasSiblings: "",
      previousParishInSingapore: "",
      previousParish: "",
      transferLetterPath: "",
      transferLetterName: "",
    },
  });

  // console.log(watch());

  const transferLetterFileName = watch("transferLetterName");

  const selectedParishId = watch("selectedParishId"); // Hooked, will trigger rerender when changed
  const selectedParish = parishConfig.find(({ id }) => id == selectedParishId);

  const catchParishes = parishConfig.filter(
    ({ isActive, isCatchParish }) => isActive && isCatchParish
  );

  const effectTriggeredRef = useRef(false);

  const [inputFields, setInputFields] = useState([
    { siblingFullName: "", siblingLevel: "", siblingClass: "" },
  ]);

  const boundary = registrationDetails.boundary;

  let homeParish = "";

  if (boundary) {
    const boundayParish = parishConfig.find(({ id }) => id == boundary);

    if (boundayParish) {
      homeParish = boundayParish.parish;
    }
  }

  useEffect(() => {
    if (!effectTriggeredRef.current && registrationDetails.id) {
      effectTriggeredRef.current = true;

      let hasSiblings = "",
        programmeType = "",
        timeslot = "";

      hasSiblings = registrationDetails.hasSiblings;

      if (registrationDetails.programmeType) {
        if (typeof registrationDetails.programmeType === "string") {
          const savedParish = parishConfig.find(
            ({ id }) => id == registrationDetails.selectedParishId
          );

          const savedProgramme = savedParish?.programmes?.find(
            ({ id }) => id == registrationDetails.programmeType
          );

          if (!!savedProgramme) {
            programmeType = _.cloneDeep(savedProgramme);
          }
        } else if (typeof registrationDetails.programmeType !== "string") {
          programmeType = registrationDetails.programmeType;
        }
      }

      if (!!programmeType && registrationDetails.timeslot) {
        const savedTimeslot = programmeType?.timeslots?.find(
          ({ id, name }) =>
            id == registrationDetails.timeslot ||
            name == registrationDetails.timeslot
        );

        if (!!savedTimeslot) {
          timeslot = savedTimeslot?.id;
        }
      }

      reset({
        selectedParishId: registrationDetails.selectedParishId || "",
        registrationType: registrationDetails.registrationType || "",
        outsideBoundaryReason: registrationDetails.outsideBoundaryReason || "",
        programmeType: programmeType,
        level: registrationDetails.level || "",
        timeslot,
        hasSiblings,
        previousParishInSingapore:
          registrationDetails.previousParishInSingapore || "",
        previousParish: registrationDetails.previousParish || "",
        transferLetterName: registrationDetails.transferLetterName || "",
        transferLetterPath: registrationDetails.transferLetterPath || "",
      });
      setInputFields(
        registrationDetails.siblingsDetails || [
          { siblingFullName: "", siblingLevel: "", siblingClass: "" },
        ]
      );
    }

    return () => {
      effectTriggeredRef.current = false;
      reset();
      setInputFields([
        { siblingFullName: "", siblingLevel: "", siblingClass: "" },
      ]);
    };
  }, [registrationDetails]);

  // const [selectParishCount, setSelectParishCount] = useState(0);

  // useEffect(() => {
  //   if (selectParishCount > 1) {
  //     setValue("registrationType", "");
  //     setValue("outsideBoundaryReason", "");
  //   } else {
  //     setSelectParishCount(selectParishCount + 1);
  //   }

  //   return () => {};
  // }, [selectedParishId]);

  function onSubmit() {
    const registrationData = getValues();

    handleSaveData(
      registrationCollection,
      registrationDetails,
      registrationData,
      inputFields,
      ward,
      user.uid,
      setRegistrationStep,
      registrationStep + 1,
      fromReview,
      setFromReview
    );
  }

  function onSaveDraft() {
    const registrationData = getValues();

    handleSaveData(
      registrationCollection,
      registrationDetails,
      registrationData,
      inputFields,
      ward,
      user.uid,
      setRegistrationStep,
      0,
      fromReview,
      setFromReview
    );
  }

  const outsideBoundaryReason = watch("outsideBoundaryReason");
  const programmeType = watch("programmeType");
  const hasSiblings = watch("hasSiblings");

  const typeOptions = selectedParish ? selectedParish.programmes : [];

  let showSiblings = false;

  if (hasSiblings) {
    showSiblings = true;
  }

  const [hasSiblingsCount, setHasSiblingsCount] = useState(0);

  const previousParishInSingapore = watch("previousParishInSingapore");

  const parishes = parishConfig.filter(
    ({ isDummy = false, hasCatechesis = false }) => !isDummy && hasCatechesis
  );

  const [isUploadingTransferLetter, setIsUploadingTransferLetter] =
    useState(false);

  const transferLetterRef = useRef();

  function transferLetterHandleClick() {
    transferLetterRef.current.click();
  }

  function transferLetterOnFileChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      setIsUploadingTransferLetter(true);

      const file = e.target.files[0];
      const imageDataType = file.type;
      const imageFileName = file.name;
      const uploadpath = `user/${user.userid}/private/transferletter`;

      let fileName = "";

      switch (imageDataType) {
        case "application/pdf":
          fileName = `${user.userid}${registrationDetails.id}.pdf`;
          break;
        case "image/jpeg":
          fileName = `${user.userid}${registrationDetails.id}.jpeg`;
          break;
        case "image/png":
          fileName = `${user.userid}${registrationDetails.id}.png`;
          break;
        default:
      }

      if (file.size > 4000000) {
        alert("You have exceeded the max file size of 4MB");

        setError("transferLetterPath");

        setIsUploadingTransferLetter(false);
      } else if (fileName) {
        clearErrors("transferLetterPath");

        handleUploadTransferLetter(
          uploadpath,
          fileName,
          file,
          imageDataType,
          imageFileName,
          setValue,
          setIsUploadingTransferLetter
        );
      }
    } else {
      setError("transferLetterPath");
    }
  }

  // console.log(hasSiblings);

  useEffect(() => {
    if (hasSiblingsCount > 1) {
      setInputFields([
        { siblingFullName: "", siblingLevel: "", siblingClass: "" },
      ]);
    } else {
      setHasSiblingsCount(hasSiblingsCount + 1);
    }

    return () => {};
  }, [hasSiblings]);

  const customOptions = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <Container ref={innerRef} {...innerProps}>
        <Row className="px-3 pt-2">{props.data.label}</Row>
        <Row className="px-3 pb-2 text-muted" style={{ fontSize: "16px" }}>
          {props.data.caption}
        </Row>
      </Container>
    );
  };

  function _renderSibling(data, index) {
    return (
      <Container
        className={`px-0 ${index > 0 ? "mt-3" : ""}`}
        key={`sibling-${index}`}
      >
        <Row className="w-100 mx-0 mb-1">
          <Col className="px-0" xs="auto">
            Child&nbsp;{index + 1}
          </Col>
          <Col></Col>
          {inputFields.length > 1 ? (
            <Col
              className="px-0 text-danger"
              xs="auto"
              style={{ cursor: "pointer" }}
              onClick={() => {
                let data = [...inputFields];

                data.splice(index, 1);

                setInputFields(data);
              }}
            >
              Remove
            </Col>
          ) : null}
        </Row>
        <Row className="w-100 mx-0">
          <Col className="px-0">
            <Row className="w-100 mx-0">
              <Col className="px-0 pr-sm-2" xs={12} sm={4}>
                <Form.Control
                  size="lg"
                  className="w-100"
                  placeholder="Enter name"
                  name="siblingFullName"
                  value={data.siblingFullName}
                  onChange={(e) => {
                    let data = [...inputFields];

                    data[index][e.target.name] = e.target.value;

                    setInputFields(data);
                  }}
                />
              </Col>
              <Col className="px-0 pl-sm-2 mt-3 mt-sm-0" xs={6} sm={4}>
                <Form.Control
                  as="select"
                  size="lg"
                  className="w-100"
                  name="siblingLevel"
                  value={data.siblingLevel}
                  onChange={(e) => {
                    let data = [...inputFields];

                    data[index][e.target.name] = e.target.value;

                    setInputFields(data);
                  }}
                >
                  <option
                    value=""
                    disabled
                    hidden
                    className="text-muted font-italic"
                  >
                    Select level
                  </option>
                  {levelRef.map(({ name, id }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col className="px-0 pl-sm-2 mt-3 mt-sm-0" xs={6} sm={4}>
                <Form.Control
                  as="select"
                  size="lg"
                  className="w-100"
                  name="siblingClass"
                  value={data.siblingClass}
                  onChange={(e) => {
                    let data = [...inputFields];

                    data[index][e.target.name] = e.target.value;

                    setInputFields(data);
                  }}
                >
                  <option
                    value=""
                    disabled
                    hidden
                    className="text-muted font-italic"
                  >
                    Select class
                  </option>
                  {programmeType?.timeslots
                    ?.flatMap(({ classes }) => classes)
                    ?.map((className) => (
                      <option key={className} value={className}>
                        {className}
                      </option>
                    ))}
                </Form.Control>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  const [showModal, setShowModal] = useState(false);

  let isOutsideBoundary = !!selectedParishId && +selectedParishId !== boundary;

  return (
    <>
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <h3>Parish of Interest</h3>
            Based on your address, your home parish is{" "}
            <strong>{homeParish}</strong>.
          </Container>
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">Registration Type</Form.Label>
                  <Form.Control
                    as="select"
                    size="lg"
                    {...register("registrationType", { required: true })}
                    isInvalid={!!errors.registrationType}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    <option
                      key="new"
                      value="new"
                      disabled={
                        registrationStatusNameToStatus("transferred") ===
                        registrationDetails?.status
                      }
                    >
                      New Registration
                    </option>
                    <option
                      key="existing"
                      value="existing"
                      disabled={
                        registrationStatusNameToStatus("transferred") ===
                        registrationDetails?.status
                      }
                    >
                      Onboard Existing
                    </option>
                    <option key="transfer" value="transfer">
                      External Transfer-In
                    </option>
                  </Form.Control>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">
                    Which parish are you applying to?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    size="lg"
                    {...register("selectedParishId", { required: true })}
                    isInvalid={!!errors.selectedParishId}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    {sortedDistricts.map(([districtId, districtName]) => (
                      <optgroup key={districtId} label={districtName}>
                        {catchParishes
                          .filter(
                            ({ district }) =>
                              district.charAt(0).toUpperCase() +
                                district.slice(1) ===
                              districtName
                          )
                          .map(({ id, parish }) => (
                            <option key={parish} value={id}>
                              {parish}
                            </option>
                          ))}
                      </optgroup>
                    ))}
                  </Form.Control>
                </Form.Row>
                <br />
                {isOutsideBoundary && (
                  <>
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">
                        The parish you have selected is not your home parish,
                        please share why you are applying to another parish.
                      </Form.Label>
                      <Form.Control
                        size="lg"
                        className="w-100"
                        as="textarea"
                        maxLength={500}
                        {...register("outsideBoundaryReason", {
                          required: true,
                        })}
                        isInvalid={!!errors.outsideBoundaryReason}
                      />
                      <Row className="w-100 mx-0">
                        <Col className="px-0 text-muted">e.g. Moved house</Col>
                        <Col className="px-0 text-muted" xs="auto">
                          {outsideBoundaryReason.length}/500
                        </Col>
                      </Row>
                    </Form.Row>
                    <br />
                  </>
                )}
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">Programme</Form.Label>
                  <Row className="w-100 mx-0">
                    <Controller
                      name={"programmeType"}
                      control={control}
                      rules={{
                        required: true,
                        validate: (val) => {
                          for (let x = 0; x < typeOptions.length; x++) {
                            if (
                              typeOptions[x].id === val.id &&
                              typeOptions[x].enquiryEmail === val.enquiryEmail
                            ) {
                              return true;
                            }
                          }

                          return false;
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          className={`w-100 programme-select ${
                            !!errors.programmeType ? "select-error" : "error"
                          }`}
                          value={value}
                          onChange={onChange}
                          options={typeOptions}
                          components={{
                            Option: customOptions,
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          isSearchable={false}
                          placeholder="Please select"
                          isDisabled={!selectedParishId}
                          styles={{
                            control: (base, state) => {
                              let borderColor = "#cccccc";

                              const style = {
                                ...base,
                              };

                              if (!!errors.programmeType) {
                                borderColor = "#dc3545";
                                if (state.isFocused) {
                                  style.boxShadow = "0 0 3px 1px #dc3545";
                                }
                              }

                              style.borderColor = borderColor;

                              return style;
                            },
                            menu: (base) => ({
                              ...base,
                              marginTop: "0px",
                              borderRadius: "0px",
                              boxShadow: "0 0 0 1px #555555",
                            }),
                          }}
                        />
                      )}
                    />
                    {!!errors.programmeType ? (
                      <ErrorOutline className="text-danger programme-select-error-icon" />
                    ) : null}
                    <ExpandMoreOutlined className="programme-select-expand-icon" />
                  </Row>
                  {parishConfig.length &&
                  programmeType !== "" &&
                  programmeType.notice ? (
                    <Alert
                      variant="primary"
                      className="w-100 d-flex justify-content-start align-items-center px-2 mt-2 mb-0"
                    >
                      <InfoOutlinedIcon className="text-primary mr-1" />
                      <span className="text-dark">{programmeType.notice}</span>
                    </Alert>
                  ) : (
                    <></>
                  )}
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">Level</Form.Label>
                  <Form.Control
                    as="select"
                    size="lg"
                    {...register("level", { required: true })}
                    isInvalid={!!errors.level}
                    disabled={!programmeType}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    {levelRef
                      .filter(
                        ({ id }) =>
                          id >= programmeType?.startLevel &&
                          id <= programmeType?.endLevel
                      )
                      .map(({ name, id }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">Timeslot</Form.Label>
                  <Form.Control
                    as="select"
                    size="lg"
                    disabled={!programmeType}
                    {...register("timeslot", {
                      required: true,
                      validate: (val) => {
                        const timeslot = programmeType?.timeslots?.find(
                          ({ id }) => id == val
                        );

                        return !!programmeType && !!timeslot;
                      },
                    })}
                    isInvalid={!!errors.timeslot}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    {programmeType?.timeslots?.map(({ name, id }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Row>
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <br />
      {watch("registrationType") === "transfer" && (
        <>
          <Card className="d-flex flex-column">
            <Card.Body className="d-flex flex-column align-items-center py-4">
              <br />
              <Container>
                <h3>Previous Parish</h3>
              </Container>
              <br />
              <Form
                className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
                style={{ gap: "1.5rem" }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row className="w-100">
                  <Form.Group className="w-100">
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100 text-wrap">
                        Is your previous parish located in Singapore?
                      </Form.Label>
                      <Row className="w-100">
                        <Controller
                          control={control}
                          name="previousParishInSingapore"
                          rules={{ validate: (v) => v === true || v === false }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <>
                              <Col xs="auto">
                                <Form.Check
                                  className="w-100"
                                  type="radio"
                                  name="previousParishInSingapore"
                                  label="Yes"
                                  id="previousParishInSingaporeTrue"
                                  onBlur={onBlur}
                                  onChange={() => onChange(true)}
                                  checked={value === true}
                                  ref={ref}
                                  isInvalid={!!errors.previousParishInSingapore}
                                />
                              </Col>
                              <Col xs="auto">
                                <Form.Check
                                  className="w-100"
                                  type="radio"
                                  name="previousParishInSingapore"
                                  label="No"
                                  id="previousParishInSingaporeFalse"
                                  onBlur={onBlur}
                                  onChange={() => onChange(false)}
                                  checked={value === false}
                                  ref={ref}
                                  isInvalid={!!errors.previousParishInSingapore}
                                />
                              </Col>
                            </>
                          )}
                        />
                      </Row>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="d-flex flex-row w-100">
                        Which parish is your child transferring from?
                      </Form.Label>
                      <Row className="w-100 mx-0">
                        <Col className="px-0" xs={12} sm={6}>
                          {previousParishInSingapore ? (
                            <Form.Control
                              className="w-100"
                              as="select"
                              size="lg"
                              {...register("previousParish", {
                                required: previousParishInSingapore,
                              })}
                              isInvalid={!!errors.previousParish}
                            >
                              <option
                                value=""
                                disabled
                                hidden
                                className="text-muted font-italic"
                              >
                                Please select
                              </option>
                              {sortedDistricts
                                .filter(
                                  ([_, districtName]) =>
                                    parishes.filter(
                                      ({
                                        district,
                                        id,
                                        hasCatechesis,
                                        isCatchParish,
                                      }) =>
                                        district.charAt(0).toUpperCase() +
                                          district.slice(1) ===
                                          districtName &&
                                        hasCatechesis &&
                                        !isCatchParish &&
                                        id !== watch("selectedParishId")
                                    ).length > 0
                                )
                                .map(([districtId, districtName]) => (
                                  <optgroup
                                    key={districtId}
                                    label={districtName}
                                  >
                                    {parishes
                                      .filter(
                                        ({
                                          district,
                                          id,
                                          hasCatechesis,
                                          isCatchParish,
                                        }) =>
                                          district.charAt(0).toUpperCase() +
                                            district.slice(1) ===
                                            districtName &&
                                          hasCatechesis &&
                                          !isCatchParish &&
                                          id !== watch("selectedParishId")
                                      )
                                      .map(({ id, parish }) => (
                                        <option key={parish} value={id}>
                                          {parish}
                                        </option>
                                      ))}
                                  </optgroup>
                                ))}
                            </Form.Control>
                          ) : (
                            <Form.Control
                              size="lg"
                              className="w-100"
                              placeholder="Please enter full address"
                              {...register("previousParish", {
                                required: !previousParishInSingapore,
                              })}
                              isInvalid={!!errors.previousParish}
                            />
                          )}
                          <Form.Text className="text-muted">
                            Parish not here? Please ensure that it offers a
                            Catechetical Programme.
                          </Form.Text>
                        </Col>
                      </Row>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="d-flex flex-row w-100 mb-0">
                        <b>Letter of Transfer</b>
                      </Form.Label>
                      <Row className="w-100 mt-3 mx-0">
                        <Col className="px-0" xs="auto">
                          {transferLetterFileName === "" ? (
                            <Button
                              // variant={!!errors.sacraments?.transferLetter?.cert?.path ? "danger" : "outline-primary"}
                              variant="outline-primary"
                              size="lg"
                              className="w-100 d-flex align-items-center"
                              onClick={transferLetterHandleClick}
                              disabled={isUploadingTransferLetter}
                            >
                              {isUploadingTransferLetter ? (
                                <>
                                  <Spinner
                                    className="mr-1"
                                    animation="border"
                                  />
                                  Uploading
                                </>
                              ) : (
                                <>
                                  <ArrowUpwardOutlinedIcon className="mr-1" />
                                  Upload file
                                </>
                              )}
                            </Button>
                          ) : (
                            <Button
                              variant="secondary"
                              size="lg"
                              className="w-100 text-wrap text-dark"
                              style={{
                                backgroundColor: "#f3f3f3",
                                borderColor: "#f3f3f3",
                              }}
                              onClick={() => {
                                setValue("transferLetterPath", "");
                                setValue("transferLetterName", "");

                                transferLetterRef.current.value = "";
                              }}
                              disabled={isUploadingTransferLetter}
                            >
                              <span>{transferLetterFileName}</span>
                              <DeleteOutlinedIcon className="text-danger ml-1" />
                            </Button>
                          )}
                        </Col>
                      </Row>
                      <input
                        style={{ height: "0px" }}
                        id="file-input"
                        type="file"
                        onChange={transferLetterOnFileChange}
                        accept="application/pdf,image/jpeg,image/png"
                        ref={transferLetterRef}
                      />
                      <Form.Text muted className="w-100">
                        Max file size is 4MB. Supported file types: jpg, png,
                        pdf.
                      </Form.Text>
                    </Form.Row>
                  </Form.Group>
                </Row>
              </Form>
            </Card.Body>
          </Card>
          <br />
        </>
      )}
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <h3>Sibling's Details (Optional)</h3>
          </Container>
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100 text-wrap">
                    Does your child have siblings attending catechesis at
                    the&nbsp;<u>same parish</u>?
                  </Form.Label>
                  <Row className="w-100">
                    <Controller
                      control={control}
                      name="hasSiblings"
                      rules={{ validate: (v) => v === true || v === false }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              name="hasSiblings"
                              label="Yes"
                              id="hasSiblingsTrue"
                              onBlur={onBlur}
                              onChange={() => onChange(true)}
                              checked={value === true}
                              ref={ref}
                              isInvalid={!!errors.hasSiblings}
                            />
                          </Col>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              name="hasSiblings"
                              label="No"
                              id="hasSiblingsFalse"
                              onBlur={onBlur}
                              onChange={() => onChange(false)}
                              checked={value === false}
                              ref={ref}
                              isInvalid={!!errors.hasSiblings}
                            />
                          </Col>
                        </>
                      )}
                    />
                  </Row>
                </Form.Row>
                {showSiblings ? (
                  <>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">
                        <Row className="mx-0 text-muted">
                          Please leave the class field blank for siblings
                          registering this year.
                        </Row>
                      </Form.Label>
                      <br />
                      {inputFields.map((data, index) => {
                        return _renderSibling(data, index);
                      })}
                    </Form.Row>
                  </>
                ) : null}
              </Form.Group>
            </Row>
          </Form>
          {showSiblings ? (
            <Container>
              <button
                className="btn outline-primary"
                style={{ border: "none", color: "#007bff" }}
                onClick={() => {
                  let newfield = {
                    siblingFullName: "",
                    siblingLevel: "",
                    siblingClass: "",
                  };

                  setInputFields([...inputFields, newfield]);
                }}
              >
                Add Another Sibling
              </button>
            </Container>
          ) : null}
        </Card.Body>
      </Card>
      <Container className="px-0 mt-4">
        <Row>
          <Col xs={12} sm="auto">
            <Button
              variant="outline-primary"
              size="lg"
              className="w-100"
              onClick={() => {
                setRegistrationStep(registrationStep - 1);

                window.scrollTo(0, 0);
              }}
            >
              Back
            </Button>
          </Col>
          <Col className="d-none d-sm-flex" />
          <Col className="mt-3 mt-sm-0" xs="auto">
            <Button
              variant="outline-primary"
              size="lg"
              className="w-100"
              onClick={() => setShowModal(true)}
            >
              Save Draft
            </Button>
          </Col>
          <Col className="col mt-3 mt-sm-0" sm="auto">
            <Button
              variant="primary"
              size="lg"
              className="w-100"
              onClick={handleSubmit(onSubmit)}
            >
              Next
            </Button>
          </Col>
        </Row>
      </Container>
      {programmeType !== "" ? (
        <Container className="px-0 mt-4 text-wrap text-center">
          If you have enquiries, please contact your Parish Catechist
          Coordinator at&nbsp;
          <span className="text-primary" style={{ cursor: "pointer" }}>
            {programmeType.enquiryEmail}
          </span>
        </Container>
      ) : (
        <></>
      )}
      <SaveDraftConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        onSaveDraft={onSaveDraft}
      />
    </>
  );
}
