import React from "react";
import _ from "lodash";
import Button from "react-bootstrap/Button";

import {
  guardianRegistrationStatusDictionary,
  registrationStatusToStatusName,
} from "../../services/registrationStatus";
import {
  paymentStatusDictionary,
  getPaymentStatus,
  getWaiverReason,
} from "../../services/payments";

import { Column, UseSortByState } from "react-table";
import LevelPill from "../components/LevelPill";
import StatusPill from "../components/StatusPill";
import SacramentsPill from "../components/SacramentsPill";

import { levelIdToName } from "../../services/levels";
import {
  StaticActionProps,
  StatusActionDictionary,
} from "../components/AbstractList";

import formatTimestamp from "./formatTimestamp";
import { RegistrationStatus, RegistrationDatum } from "../../hooks/hooks";

import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import MailIcon from "@material-ui/icons/Mail";
import RepeatOneIcon from "@material-ui/icons/RepeatOne";
import SwapHorizSharpIcon from "@material-ui/icons/SwapHorizSharp";

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/** @type {Column<RegistrationDatum & {catecheticalYear: number}>[]} */ 
const columnProps = [
  {
    id: "submittedAt",
    Header: "Submitted at",
    accessor: ({ submittedAt }) => submittedAt,
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">{formatTimestamp(value)}</div>
    ),
    width: 150,
    sortType: (rowA, rowB, columnId) => {
      const timestampA = rowA.original.submittedAt;
      const timestampB = rowB.original.submittedAt;

      // Convert to JavaScript Date objects
      let dateA;

      if (Object.hasOwn(timestampA, "_seconds")) {
        dateA = new Date(
          timestampA._seconds * 1000 + timestampA._nanoseconds / 1000000
        );
      } else {
        dateA = new Date(
          timestampA.seconds * 1000 + timestampA.nanoseconds / 1000000
        );
      }

      let dateB;

      if (Object.hasOwn(timestampB, "_seconds")) {
        dateB = new Date(
          timestampB._seconds * 1000 + timestampB._nanoseconds / 1000000
        );
      } else {
        dateB = new Date(
          timestampB.seconds * 1000 + timestampB.nanoseconds / 1000000
        );
      }

      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    },
  },
  {
    id: "name",
    Header: "Name",
    accessor: ({ name }) => name,
    Cell: ({ value }) => <div className="text-wrap pt-1 pb-1">{value}</div>,
    width: 150,
  },
  {
    id: "programme",
    Header: "Programme",
    accessor: (registrationDatum) => registrationDatum,
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">
        {!_.isEmpty(value.programmeName)
          ? value.programmeName
          : value.programmeType.name}
      </div>
    ),
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "level",
    Header: "Level",
    accessor: ({ level }) => parseInt(level),
    Cell: ({ value }) => <LevelPill id={value} />,
    width: 100,
  },
  {
    id: "assignedClass",
    Header: "Class",
    accessor: ({ assignedClass }) => assignedClass,
    Cell: ({ value }) => <div className="text-wrap pt-1 pb-1">{value}</div>,
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "timeslot",
    Header: "Timeslot",
    accessor: (registrationDatum) => registrationDatum,
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">
        {!_.isEmpty(value.timeslotName) ? value.timeslotName : value.timeslot}
      </div>
    ),
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "sacraments",
    Header: "Sacraments",
    accessor: (registrationDatum) => registrationDatum,
    Cell: ({ value }) => <SacramentsPill registrationDatum={value} />,
    width: 600,
    disableGlobalFilter: true,
  },
  {
    id: "classFund",
    Header: "Class Fund",
    accessor: (registrationDatum) => ({
      paymentStatus: getPaymentStatus({ registrationDatum, catecheticalYear: registrationDatum.catecheticalYear }),
      waiverReason: getWaiverReason({ registrationDatum, catecheticalYear: registrationDatum.catecheticalYear })
    }),
    Cell: ({ value }) => <div>
      {
        value.paymentStatus !== "waiverRequested" ?
        <StatusPill statusDictionary={paymentStatusDictionary} value={value.paymentStatus} /> :
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="waiverTooltip"><span style={{fontWeight: "bold"}}>Reason</span>: {value.waiverReason}</Tooltip>}
        >
          <div>
            <StatusPill statusDictionary={paymentStatusDictionary} value={value.paymentStatus} />
          </div>
        </OverlayTrigger>
      }
    </div>,
    width: 120,
  },
  {
    id: "status",
    Header: "Status",
    accessor: ({ status }) => registrationStatusToStatusName(status),
    Cell: ({ value }) => (
      <StatusPill
        statusDictionary={guardianRegistrationStatusDictionary}
        value={value}
      />
    ),
    width: 100,
    disableGlobalFilter: true,
  },
  {
    id: "view",
    Header: "",
    Cell: ({ row }) => (
      <Button
        variant="outline-dark"
        className="w-100"
        onClick={() => {
          let programmeType = row.original.programmeType;

          if (typeof programmeType === "object") {
            programmeType = programmeType.id;
          }

          const name = `${row.original.id || ""}-${programmeType || ""}-${
            row.original.timeslot || ""
          }`;

          const rowButton = document.getElementById(`button-${name}`);

          if (rowButton) {
            rowButton.click();
          }
        }}
        style={{ minWidth: 50 }}
      >
        View
      </Button>
    ),
    width: 100,
    disableGlobalFilter: true,
    sticky: "right",
  },
];

/** @type {UseSortByState<RegistrationDatum>} */
const sortByProps = [
  {
    id: "submittedAt",
    desc: true,
  },
];

/**
 * @typedef {"unassign" | "accept" | "reject" | "waive" | "email" | "retain"} RegistrationAction
 */

/**
 * @type {Record<RegistrationAction, StaticActionProps<RegistrationAction>>}
 */
const actions = {
  unassign: {
    action: "unassign",
    Icon: RemoveCircleIcon,
    title: "Set as Pending",
    variant: "outline-secondary",
  },
  accept: {
    action: "accept",
    Icon: CheckCircleIcon,
    title: "Accept",
    variant: "outline-success",
  },
  reject: {
    action: "reject",
    Icon: CancelIcon,
    title: "Reject",
    variant: "outline-danger",
  },
  waive: {
    action: "waive",
    Icon: MoneyOffIcon,
    title: "Waive Payment",
    variant: "warning",
  },
  rejectwaiver: {
    action: "rejectwaiver",
    Icon: CancelOutlinedIcon,
    title: "Reject Waiver",
    variant: "danger",
  },
  email: {
    action: "email",
    Icon: MailIcon,
    title: "Send Email",
    variant: "primary",
  },
  retain: {
    action: "retain",
    Icon: RepeatOneIcon,
    title: "Retain Once",
    variant: "warning",
  },
  moveTo: {
    action: "moveTo",
    Icon: SwapHorizSharpIcon,
    title: "Move Class",
    variant: "info",
  },
};
/**
 * @type {Record<TransfersAction, StaticActionProps<TransfersAction>>}
 */
const disabledActions = Object.fromEntries(
  Object.keys(actions).map((key) => [key, { ...actions[key], disabled: true }])
);

/**
 * @type {StatusActionDictionary<RegistrationStatus, RegistrationAction>}
 */
const statusActionDictionary = {
  1: [
    [disabledActions.unassign, actions.accept, actions.reject],
    [actions.moveTo, actions.email],
  ],
  0: [
    [actions.unassign, disabledActions.accept, disabledActions.reject],
    [
      actions.waive,
      // actions.retain, // TODO: Uncomment for Dec release
      actions.rejectwaiver,
      actions.moveTo,
      actions.email,
    ],
  ],
  2: [
    [actions.unassign, disabledActions.accept, disabledActions.reject],
    [actions.email],
  ],
};

const listProps = { columnProps, sortByProps, statusActionDictionary };

export { listProps };
