import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncAdminFormView({ ...rest }) {
  const AsyncLoad = loadable(() => import("../AdminFormView"));
  console.log("Loading", AsyncAdminFormView.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncAdminFormView;
