import React, { useState, useEffect } from "react";
import fire from "../../../services/fire";
import Header from "../../common/header";
import { Image } from "react-bootstrap";
import LoadingSpinnerRelative from "../../common/LoadingSpinnerRelative";
import BookingSchedules from "../components/bookingSchedules";
import { isSuperAdminRole, isSystemAdminRole } from "../../roles/service/roles";
import {
  hasCommuneOverallAdminMembership,
  getCommuneOrgBookerMembershipDocs,
} from "../../roles/service/membership";
import { getRoleName } from "../service/references";
import { Form } from "react-bootstrap";
import ReloadModal from "../../common/reloadModal";

const CoworkLandingView = ({ user, setView, history }) => {
  const [coworkState, setCoworkState] = useState({
    isLoading: true,
    isInterested: false,
  });

  const { isInterested, isLoading } = coworkState;

  const initialBookerRole = { type: "pub", orgs: [] };
  const [bookerRoleDocs, setBookerRoleDocs] = useState([initialBookerRole]);
  const [bookerRoleDoc, setBookerRoleDoc] = useState(initialBookerRole);

  async function getBookerRoleDocs(userId) {
    const roleDocs = [initialBookerRole];

    const memberships = await getCommuneOrgBookerMembershipDocs(userId);
    const orgs = memberships.map((data) => data.orgId);
    if (memberships.length) roleDocs.push({ type: "org", orgs });

    if (
      (await hasCommuneOverallAdminMembership(userId)) ||
      isSuperAdminRole(user) ||
      isSystemAdminRole(user)
    )
      roleDocs.push({ type: "su", orgs: [] });

    return roleDocs;
  }

  useEffect(() => {
    if (user) {
      fire
        .firestore()
        .collection("likes")
        .where("objid", "==", "COWORKING_INTEREST")
        .where("userid", "==", user.userid)
        .get()
        .then((resultSnap) => {
          getBookerRoleDocs(user.userid).then((bookerRoleDocs) =>
            setBookerRoleDocs(bookerRoleDocs)
          );
          if (!resultSnap.empty) {
            setCoworkState({
              ...coworkState,
              isLoading: false,
              isInterested: true,
            });
          } else {
            setCoworkState({
              ...coworkState,
              isLoading: false,
              isInterested: false,
            });
          }
        });
    }
  }, [user]);

  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100%",
          backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/org%2F0%2Fpublic%2Fcommune%2Fcowork-bg.png?alt=media&token=3e2631e1-d6f5-43e7-a721-adac02060a07")`,
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
          backgroundAttachment: "fixed",
          position: "fixed",
          zIndex: -1,
        }}
      ></div>
      <Header type="backandlogo" smallpic={user.photoURL} />
      <ReloadModal />

      <div className="container mt-4">
        <h2 className="mb-2">Commune Coworking</h2>
        <div
          className="rounded-top"
          style={{
            objectFit: "cover",
            overflow: "hidden",
            maxHeight: "40vh",
            minHeight: "100%",
          }}
        >
          <Image
            style={{ width: "100%" }}
            src="https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/org%2F0%2Fpublic%2Fcommune%2Fcommune-photo.png?alt=media&token=c3228f05-c6f4-4663-bf15-8fe9afe0aad3"
          />
        </div>
        <div
          className="text-center rounded-bottom px-2 py-4"
          style={{
            backgroundColor: "rgb(255, 255, 255)",
          }}
        >
          <h4 className="text-success">Peace be with you!</h4>
          <div>
            <p>
              This is a service for you to book our Catholic co-working spaces!
            </p>
            <hr />
            {isLoading ? (
              <LoadingSpinnerRelative />
            ) : (
              <>
                <Form inline className="justify-content-center">
                  <Form.Group>
                    <Form.Label htmlFor="role">
                      <h5 className="m-0">
                        I am booking as{" "}
                        {["A", "E", "I", "O", "U"].includes(
                          getRoleName(bookerRoleDoc.type)[0]
                        )
                          ? "an"
                          : "a"}
                      </h5>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="role"
                      className="mx-2"
                      defaultValue={bookerRoleDoc}
                      onChange={(e) => {
                        setBookerRoleDoc(bookerRoleDocs[e.target.value]);
                      }}
                    >
                      {bookerRoleDocs.map((roleDoc, roleIndex) => (
                        <option key={"roleIdx-" + roleIndex} value={roleIndex}>
                          {getRoleName(roleDoc.type)}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Label htmlFor="location">
                      <h5 className="m-0">for</h5>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="location"
                      className="mx-2"
                      defaultValue="waterloo"
                      disabled
                    >
                      <option key={"locationIdx-waterloo"} value={"waterloo"}>
                        Commune@waterloo
                      </option>
                    </Form.Control>
                    <Form.Label>
                      <h5 className="m-0">.</h5>
                    </Form.Label>
                  </Form.Group>
                </Form>
                <BookingSchedules
                  user={user}
                  history={history}
                  setView={setView}
                  bookerRoleDoc={bookerRoleDoc}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className="text-muted float-right"
        style={{
          position: "sticky",
          bottom: 0,
          left: 0,
          fontSize: "10px",
        }}
      >
        Background image by pch.vector
      </div>
    </>
  );
};

export default CoworkLandingView;
