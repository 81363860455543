import React from "react";
import { Link } from "react-router-dom";
import { Form, Button, Modal, Card } from "react-bootstrap";
import OrgBadge from "../../organisation/components/OrgBadge";
import { getWebVersion } from "../../../services/settings";
import { getMassTime as getRoomTime } from "../../../utils/utils";
import { timeslotRef } from "../service/references";
import { formatMultiRate } from "../service/currency";

import { useForm } from "react-hook-form";

import "./bottomPrompt.css";

function BottomPrompt({
  label,
  selected,
  selectedItem,
  onHide,
  handleButtonContinue,
  version,
  detailsRequired,
  bookerOrg,
  bookerRole,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (roomUsageDetails) => {
    handleButtonContinue(roomUsageDetails);
  };

  const webversion = getWebVersion();
  let match = false;
  if (webversion === version) {
    match = true;
  }

  const notes = (
    <span>
      I acknowledge the{" "}
      <Link to="commune/termsofuse" target="_blank" rel="noopener noreferrer">
        Terms of use
      </Link>{" "}
      of the selected room
    </span>
  );

  const roomDetailsCard = !!selected && (
    <Card
      className="w-100 text-center"
      bg="light"
      style={{ minWidth: "18rem" }}
    >
      <Card.Header>ROOM</Card.Header>
      <Card.Body>
        <Card.Title>{selectedItem.name}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {selectedItem.function}
        </Card.Subtitle>
        <Card.Text className="bold">
          {new Date(selectedItem.date.seconds * 1000).toDateString()}
          <br />
          {timeslotRef[selectedItem.slotType].name}
          <br />({getRoomTime(selectedItem.date)} &ndash;{" "}
          {getRoomTime({
            seconds: selectedItem.date.seconds + selectedItem.duration * 60,
            nanoseconds: selectedItem.date.nanoseconds,
          })}
          )
          <br />
          {selectedItem.paxLimit} {selectedItem.paxLimit > 1 ? "Seats" : "Seat"}
        </Card.Text>
      </Card.Body>
      <Card.Footer className="bold biggerfontsize text-secondary">
        {formatMultiRate(selectedItem.config[bookerRole].rate)}
        {bookerOrg && (
          <>
            <br />
            <small>
              To account of:
              <OrgBadge key={"org-" + bookerOrg} orgId={bookerOrg} />
            </small>
          </>
        )}
      </Card.Footer>
    </Card>
  );

  return (
    <>
      <div className="my-5"></div>
      <Modal
        show={!!selected}
        onHide={onHide}
        id="bottomPrompt"
        dialogClassName="fixed-bottom p-0 m-0 mw-100"
        style={{
          borderTop: "1px solid #efefef",
          boxShadow: "1px 1px 4px #cacaca",
        }}
      >
        <div
          className="row mx-auto col justify-content-center"
          style={
            detailsRequired
              ? {
                  minHeight: "100vh",
                  overflowY: "scroll",
                }
              : {}
          }
        >
          <div className="container">
            {detailsRequired ? (
              <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                {match && (
                  <>
                    <Modal.Header closeButton className="sticky-top bg-white">
                      <span>
                        <h3>Before you proceed</h3>
                        <h5 className="mb-3 text-danger">
                          We'll need some additional details first
                        </h5>
                      </span>
                    </Modal.Header>
                    <Modal.Body className="row">
                      <div className="d-flex justify-content-center align-items-center card-holder">
                        {roomDetailsCard}
                      </div>
                      <div className="col" style={{ minWidth: "18rem" }}>
                        <Form.Group className="mb-3" controlId="pax">
                          <Form.Label className="bold">
                            How many people will be using the room?
                          </Form.Label>
                          <Form.Control
                            size="lg"
                            placeholder="Enter number of pax"
                            isInvalid={!!errors?.pax}
                            {...register("pax", {
                              required: true,
                              max: {
                                value: selectedItem?.paxLimit,
                                message: `Must not exceed number of seats (${selectedItem?.paxLimit})`,
                              },
                            })}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.pax?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="purpose">
                          <Form.Label className="bold">
                            What will this room be used for?
                          </Form.Label>
                          <Form.Control
                            size="lg"
                            placeholder="Enter purpose of use"
                            isInvalid={!!errors?.purpose}
                            {...register("purpose", { required: true })}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="contactName">
                          <Form.Label className="bold">
                            Who will be the main user of this room?
                          </Form.Label>
                          <Form.Control
                            size="lg"
                            placeholder="Enter user's name"
                            isInvalid={!!errors?.contactName}
                            {...register("contactName", { required: true })}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="contactMobile">
                          <Form.Label className="bold">
                            How can we contact this person?
                          </Form.Label>
                          <Form.Control
                            size="lg"
                            type="email"
                            placeholder="Enter user's phone number"
                            isInvalid={!!errors?.contactMobile}
                            {...register("contactMobile", {
                              required: true,
                              pattern: {
                                value: /^[986]\d{7}$/,
                                message:
                                  "Must be a valid Singapore phone number",
                              },
                            })}
                          />
                          <Form.Text className="text-muted">
                            We'll only share this with the room's management.
                          </Form.Text>
                          <Form.Control.Feedback type="invalid">
                            {errors.contactMobile?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </Modal.Body>
                  </>
                )}
                <Modal.Footer className="justify-content-center">
                  <span className="text-center">
                    {notes && (
                      <p className="text-danger mx-auto text-center">{notes}</p>
                    )}
                    {match && (
                      <Button
                        type="submit"
                        disabled={
                          !!errors &&
                          Object.values(errors).some((error) => !!error)
                        }
                        id={selected}
                        variant="danger"
                        className="btn-block btn-lg"
                      >
                        {label}
                      </Button>
                    )}
                  </span>
                </Modal.Footer>
              </Form>
            ) : (
              <Modal.Body>
                <span className="text-center">
                  {notes && (
                    <p className="text-danger mx-auto text-center">{notes}</p>
                  )}
                  {match && (
                    <Button
                      id={selected}
                      variant="danger"
                      className="btn-block btn-lg"
                      onClick={() => handleButtonContinue(null)}
                    >
                      {label}
                    </Button>
                  )}
                </span>
              </Modal.Body>
            )}

            {match === false && (
              <Modal.Body>
                <Button
                  id={selected}
                  variant="danger"
                  className="btn-block btn-lg"
                  onClick={() => window.location.reload()}
                >
                  Press to Reload
                </Button>
                <div className="mx-auto py-2 text-center">
                  *To get latest version of room schedules
                </div>
              </Modal.Body>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default BottomPrompt;
