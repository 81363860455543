import React from "react";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

const PaymentNotice = ({
  campaignName,
  orgShortName,
  orgName,
  payplusUEN,
  paymentConsent,
  onConsent,
  additionalNotices = [],
}) => {
  const displayAdditionalNotices = () => {
    const additionalNoticeElements = [];

    additionalNotices.forEach((notice, index) => {
      additionalNoticeElements.push(
        <div key={index}>
          <br />
          <li>{notice}</li>
        </div>
      );
    });

    return additionalNoticeElements;
  };

  return (
    <>
      <div className="center-content">
        <h2 className="mb-0 text-danger">
          <ErrorOutlineOutlinedIcon
            style={{ fontSize: "40px", marginTop: "-5px" }}
          />{" "}
          Important Notice
        </h2>
      </div>
      <div className="clearfix"></div>
      <div className="normalsmallfontsize mt-4 py-4 pr-3 text-left card">
        <h5 className="px-4">You are making payment for</h5>
        <h2 className="px-4">
          {campaignName} ({orgShortName})
        </h2>
        <div className="mb-3" />
        <p className="normalsmallfontsize px-4">
          Please take note of the following:
        </p>
        <ul>
          <li>
            Check that the organization you are making payment to is{" "}
            <b>{orgName}</b>
          </li>
          <br />
          <li>
            Ensure that the UEN is <b>{payplusUEN}</b>
            <b className="text-danger"> (DO NOT manually key this in)</b>
          </li>
          <br />
          <li>
            You will have a total of <b>5 minutes</b> to make payment before the
            QR expires
          </li>
          <br />
          <li>
            Once payment has been received, it will be reflected automatically
            on myCatholicSG
          </li>
          <br />
          <li>You should also receive a confirmation email.</li>
          <br />
          <li>
            <p className="text-danger">
              NOTE: For successful payments and registration you{" "}
              <b>MUST use the QR code to pay</b>
            </p>
          </li>
          {displayAdditionalNotices()}
        </ul>
        <h5 className="mt-3 mb-3 px-4">
          Personal Data Protection Act (PDPA) Notice:
        </h5>

        <ul>
          <li>
            As this is a payment (business) transaction, your contact
            information will be made available to the organization
          </li>
          <br />
          <li>
            You may be contacted by the organization regarding the transaction
            made
          </li>
        </ul>
        <p className="py-2 px-4 mt-2 text-center">
          By proceeding, you consent to all that has been stated above
        </p>
        <>
          <div className="text-center custom-control-lg custom-control custom-checkbox">
            <input
              className="custom-control-input"
              value={paymentConsent}
              id="checkbox-large"
              type="checkbox"
              onChange={onConsent}
            />
            <label className="custom-control-label" htmlFor="checkbox-large">
              <b className="biggerfontsize pointer">Yes, I Understand</b>
            </label>
          </div>
          <div className="text-center text-muted mediumfontsize">
            Click box to proceed
          </div>
        </>
      </div>
    </>
  );
};

export default PaymentNotice;
