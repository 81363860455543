const parishes = [
	{
		"parish":"Our Lady of Lourdes",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":4,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"St Bernadette",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":4,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"St Michael",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":4,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"St Teresa",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":4,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Sts Peter and Paul",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":4,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Sacred Heart",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":4,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"St Francis Xavier",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":3,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"St Vincent de Paul",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":3,
		"coordinatorEmail":"Nathan",
		"isActive":true
	},
	{
		"parish":"Immaculate Heart of Mary",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":3,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Nativity of the BVM",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":3,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Transfiguration",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":3,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"St Anne",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":3,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Christ the King",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":0,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Our Lady Star of the Sea",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":0,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"St Anthony",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":0,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Holy Spirit",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":0,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Risen Christ",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":0,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"St Joseph (Bukit Timah)",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":0,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Divine Mercy",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":1,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Our Lady of Perpetual Succour",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":1,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Our Lady Queen of Peace",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":1,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"St Stephen",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":1,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Holy Family",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":1,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Holy Trinity",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":1,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Blessed Sacrament",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":2,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"St Francis of Assisi",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":2,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"St Ignatius",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":2,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"St Mary of the Angels",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":2,
		"coordinatorEmail":"",
		"isActive":true
	},
	{
		"parish":"Holy Cross",
		"level":1,
		"year":"2022",
		"timeslot":[
			"Sun, 2:30pm - 4pm"
		],
		"district":2,
		"coordinatorEmail":"",
		"isActive":true
	}
];

export { parishes };