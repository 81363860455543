import React, { Component } from "react";
import { Link } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import AddAlertOutlinedIcon from "@material-ui/icons/AddAlertOutlined";
import RecordVoiceOverOutlinedIcon from "@material-ui/icons/RecordVoiceOverOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import HowToRegOutlinedIcon from "@material-ui/icons/HowToRegOutlined";
import DnsOutlinedIcon from "@material-ui/icons/DnsOutlined";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import PlaylistAddCheckOutlinedIcon from "@material-ui/icons/PlaylistAddCheckOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import EventNoteIcon from "@material-ui/icons/EventNote";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import LocalLibraryOutlinedIcon from "@material-ui/icons/LocalLibraryOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import AddLocationOutlinedIcon from "@material-ui/icons/AddLocationOutlined";
import FaceIcon from "@material-ui/icons/Face";
import EventNoteOutlinedIcon from "@material-ui/icons/EventNoteOutlined";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import BugReportIcon from "@material-ui/icons/BugReport";
import AddToPhotosOutlinedIcon from "@material-ui/icons/AddToPhotosOutlined";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import FlareOutlinedIcon from "@material-ui/icons/FlareOutlined";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import ListAltIcon from "@material-ui/icons/ListAlt";
import HearingIcon from "@material-ui/icons/Hearing";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import BeachAccessRoundedIcon from "@material-ui/icons/BeachAccessRounded";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import ShowChartIcon from "@material-ui/icons/ShowChart";

class MainMenuCardSimplePanel extends Component {
  constructor(props) {
    super(props);
    const {
      handler,
      handlerPrimary,
      external,
      menulink,
      menulinklabel,
      menuicon,
      menuDescription,
      badgelabel,
      menutype,
      size,
      isDisabled,
      // isSmall = false,
    } = this.props;

    this.state = {
      handler,
      handlerPrimary,
      external,
      menutype,
      menulink,
      menulinklabel,
      menuicon,
      menuDescription,
      badgelabel,
      size,
      isDisabled,
      badge: {
        height: "25px",
        weight: "normal",
        width: "110px",
        type: "light",
      },
      icon: {
        color: "#277AF5",
        size: "45px",
        paddingright: "10px",
        paddingleft: "0",
      },
    };
  }

  displayBadge() {
    const { badgelabel } = this.state;
    return (
      <span
        className={`defaultsmallfontsize font-weight-normal badge badge-pill badge-danger`}
      >
        {badgelabel}
      </span>
    );
  }

  displayMenuLogo() {
    const { menuicon, icon, size } = this.state;
    let fontsize = icon.size;
    if (size === "xs") {
      fontsize = "30px";
    } else if (this.props.isSmall) {
      fontsize = "40px";
      icon.paddingleft = "5px";
    }
    let logo = null;
    switch (menuicon) {
      case "registermass":
        logo = (
          <EditOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "parishinfo":
        logo = (
          <AccountBalanceOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "confession":
        logo = (
          <RecordVoiceOverOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "location":
        logo = (
          <AddCircleOutlineIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "adoration":
        logo = (
          <AddToPhotosOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "feedback":
        logo = (
          <FeedbackOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "payment":
        logo = (
          <LocalAtmIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "addnote":
        logo = (
          <PostAddIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "support":
        logo = (
          <FavoriteBorderOutlinedIcon
            style={{
              // color: "red",
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "announcement":
        logo = (
          <AddAlertOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "postannouncement":
        logo = (
          <RecordVoiceOverOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "parishioners":
        logo = (
          <PeopleOutlineOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "massregistrations":
        logo = (
          <HowToRegOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "bulletin":
        logo = (
          <ListAltIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "dashboard":
        logo = (
          <DnsOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "post":
        logo = (
          <CommentOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "catechesis":
        logo = (
          <FaceIcon
            style={{
              fontSize: icon.size,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "priests":
        logo = (
          <GroupAddOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "mymassbookings":
        logo = (
          <PlaylistAddCheckOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "roles":
        logo = (
          <LockOpenOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "configuremass":
        logo = (
          <BackupOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "massbyparish":
        logo = (
          <AddBoxOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "accountmanager":
        logo = (
          <BallotOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "mybookings":
        logo = (
          <EventNoteIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "stats":
        logo = (
          <AssessmentOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "safeentry":
        logo = (
          <ExitToAppOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "currentcalendar":
        logo = (
          <EventAvailableOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "pastcalendar":
        logo = (
          <CalendarTodayOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "addmassschedule":
        logo = (
          <PostAddOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "checkschedule":
        logo = (
          <DateRangeIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "eventdashboard":
        logo = (
          <EventNoteOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "devotion":
        logo = (
          <LocalLibraryOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "unverifiedid":
        logo = (
          <VisibilityOffOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "bugreport":
        logo = (
          <BugReportIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "stationofcross":
        logo = (
          <AddLocationOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "barcode":
        logo = (
          <ViewHeadlineIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "location":
        logo = (
          <LocationOnOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "type":
        logo = (
          <LocalOfferOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "organisation":
        logo = (
          <PeopleOutlineIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "celebration":
        logo = (
          <FlareOutlinedIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "faq":
        logo = (
          <HelpOutlineIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "catholic200":
        logo = (
          <AcUnitIcon
            className="iconrotate45"
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingTop: "15px",
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "synod":
        logo = (
          <HearingIcon
            style={{
              fontSize: icon.size,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "deletedbookings":
        logo = (
          <CancelPresentationIcon
            style={{
              fontSize: icon.size,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "invalidaccount":
        logo = (
          <PersonAddDisabledIcon
            style={{
              fontSize: icon.size,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "email":
        logo = (
          <MailOutlineIcon
            style={{
              fontSize: icon.size,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "work":
        logo = (
          <WorkOutlineIcon
            style={{
              fontSize: icon.size,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "publicholiday":
        logo = (
          <BeachAccessRoundedIcon
            style={{
              fontSize: icon.size,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "commune":
        logo = (
          <BusinessOutlinedIcon
            style={{
              fontSize: icon.size,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "communegenerator":
        logo = (
          <AddLocationOutlinedIcon
            style={{
              fontSize: icon.size,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      case "appanalytics":
        logo = (
          <ShowChartIcon
            style={{
              fontSize: icon.size,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
        break;
      default:
        logo = (
          <HelpOutlineIcon
            style={{
              fontSize: fontsize,
              paddingRight: icon.paddingright,
              paddingLeft: icon.paddingleft,
            }}
          />
        );
    }
    return logo;
  }

  render() {
    const {
      handler,
      handlerPrimary,
      external,
      menulink,
      menulinklabel,
      menutype,
      menuDescription,
      menuDetail,
      badgelabel,
      size,
      isDisabled,
      isSmall,
      // menuicon,
    } = this.props;
    let cardsize = isSmall ? "my-2" : "py-4 my-3";
    let thickness = "font-weight-bold";
    let top = "pt-2 text-truncate";
    if (size === "xs") {
      cardsize = "py-2 my-1";
      thickness = "";
      top = "pt-0 text-truncate";
    }
    let style = `text-truncate ${cardsize} text-left btn-lg btn btn-block`;
    if (menutype === "light") {
      style += " lightframecolor btn-outline-primary";
    } else if (menutype === "red") {
      style += " lightframecolor btn-outline-danger";
    }

    let boxContent = (
      <>
        <div className="float-left">{this.displayMenuLogo()}</div>
        <div className={top}>
          <span className={thickness}>{menulinklabel}</span>{" "}
          {menuDetail && <span className="float-right">{menuDetail}</span>}
          {badgelabel && this.displayBadge()}
        </div>
        {menuDescription && <div>{menuDescription}</div>}
      </>
    );
    if (isSmall) {
      boxContent = (
        <div className="d-flex h-100">
          <div className="float-left d-flex flex-column justify-content-center">
            {this.displayMenuLogo()}
          </div>
          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex flex-column justify-content-center text-truncate">
              <div
                className="text-wrap font-weight-bold text-break"
                style={isSmall && { fontSize: "16px" }}
              >
                {menulinklabel}
                {badgelabel && (
                  <span className="ml-2 float-right">
                    {badgelabel && this.displayBadge()}
                  </span>
                )}
              </div>{" "}
              {menuDetail && <span className="float-right">{menuDetail}</span>}
            </div>
            {menuDescription && (
              <div className="text-muted" style={{ fontSize: "16px" }}>
                {menuDescription}
              </div>
            )}
          </div>
        </div>
      );
    }
    /** this redirects out of the site */
    if (external === true) {
      return (
        <a
          target="_blank"
          className={style}
          rel="noopener noreferrer"
          href={menulink}
        >
          {boxContent}
        </a>
      );
    }
    /** override the link behaviour with primary style */
    // Also has option to be disabled
    if (handlerPrimary) {
      return isDisabled ? (
        <button
          className={`${style} text-muted btn-outline-secondary`}
          style={isSmall && { height: "95px" }}
          disabled
          onClick={() => {}}
        >
          {boxContent}
        </button>
      ) : (
        <button
          className={`${style}`}
          style={isSmall && { height: "95px" }}
          onClick={handlerPrimary}
        >
          {boxContent}
        </button>
      );
    }
    /** override the link behaviour */
    if (handler) {
      return (
        <button className={`${style} btn-outline-danger`} onClick={handler}>
          {boxContent}
        </button>
      );
    }
    /** default behaviour */
    return (
      <Link
        className={style}
        role="button"
        to={menulink}
        style={isSmall && { height: "95px", padding: "5px" }}
      >
        {boxContent}
      </Link>
    );
  }
}

export default MainMenuCardSimplePanel;
