import React, { useContext } from 'react';
import MainMenuCardSimplePanel from '../../blocks/mainMenuCardPanelSimpleBlock';
import PayplusContext from '../payplusContext';

const OrgPaymentSelector = () => {
  const { payplusState, payplusDispatch } = useContext(PayplusContext);
  const { isCampaignLoading, parishCampaigns, orgCampaigns } = payplusState;

  return (
    <>
      <h2>Select Organization</h2>
      <div className="pb-3 pt-0 px-0">
        {isCampaignLoading ?
          <p className="defaultfontsize text-center">Loading ...</p>
          :
          parishCampaigns.length === 0 && orgCampaigns.length === 0 ?
            <p className="defaultfontsize text-center text-danger">
              No Pay✝ collections currently ongoing
            </p>
            :
            <>
              {parishCampaigns.length > 0 &&
                <MainMenuCardSimplePanel
                  handlerPrimary={(e) => {
                    e.preventDefault();
                    payplusDispatch({
                      type: "SET_SELECTED_LIST",
                      payload: { selectedCampaignList: parishCampaigns }
                    });
                  }}
                  menulinklabel="My Parish"
                  menuicon="payment"
                  menutype="light"
                />
              }
              {orgCampaigns.length > 0 &&
                <MainMenuCardSimplePanel
                  handlerPrimary={(e) => {
                    e.preventDefault();
                    payplusDispatch({
                      type: "SET_SELECTED_LIST",
                      payload: { selectedCampaignList: orgCampaigns }
                    });
                  }}
                  menulinklabel="Other Organizations"
                  menuicon="payment"
                  menutype="light"
                />
              }
            </>
        }
      </div>
    </>
  )
};

export default OrgPaymentSelector;