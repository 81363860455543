import React from "react";

const LoadingSpinnerRelative = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          zIndex: "1000",
        }}
      >
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingSpinnerRelative;
