import React, { useEffect, useContext, useState } from "react";
import fire from "../../../services/fire";
import PayplusContext from "../payplusContext";
import QRCode from "qrcode";
import { createCanvas, loadImage } from "canvas";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { generateQRDataStr, generatePayPlusId } from "../financeHelpers";
import Countdown from "react-countdown";

const QRPaymentDisplay = ({
  isGeneral = false,
  genState = {
    userObj: {},
  },
  genSelectedCampaign = {},
  genSettings = {
    showSuccessBtn: true,
    showFailureBtn: true,
    showReturnBtn: true,
  },
  genCallbacks = {
    onSuccessCB: () => {},
    onReturnBtnCB: () => {},
    onSuccessBtnCB: () => {},
    onFailureBtnCB: () => {},
  },
}) => {
  const { payplusState, payplusDispatch } = useContext(PayplusContext);
  let userObj,
    selectedCampaign,
    paymentConsent,
    step,
    campaignName,
    orgName,
    orgId,
    payplusUEN,
    orgShortName,
    campaignRef,
    amount,
    qrType;

  if (isGeneral) {
    userObj = genState.userObj;
    selectedCampaign = genSelectedCampaign;
  } else {
    ({ userObj, selectedCampaign, paymentConsent, step } = payplusState);
  }
  ({
    campaignName,
    orgName,
    orgId,
    payplusUEN,
    orgShortName,
    campaignRef,
    amount,
    qrType,
  } = selectedCampaign);
  // Note QR Src is stored on this component
  const [QRsrc, setQRsrc] = useState("");
  const [isComplete, setComplete] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    let unsubObserver = () => {};

    const generateUserRunningNum = (cb) => {
      fire
        .firestore()
        .collection("payplusrequests")
        .where("createdBy", "==", userObj.userid)
        .where("campaignRef", "==", campaignRef)
        .where("isCompleted", "==", true)
        .get()
        .then((resultSnapshot) => {
          const completedRequestList = [];
          resultSnapshot.docs.map((doc) =>
            completedRequestList.push(doc.data())
          );
          const nextNum = completedRequestList.length;
          let userRunningNum = "";
          if (nextNum >= 100) {
            // In the (rare) occurence that user has successfully paid at least 100 times
            // for the same campaign, consolidate running number to XX
            userRunningNum = "XX";
          } else {
            if (nextNum < 10) {
              userRunningNum = "0" + nextNum;
            } else {
              userRunningNum = nextNum.toString();
            }
          }
          cb(userRunningNum);
        });
    };

    const createPayPlusRequest = (fullRef) => {
      // First check if there was an existing request with similar fullRef
      fire
        .firestore()
        .collection("payplusrequests")
        .where("fullRef", "==", fullRef)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            // No duplicate, create an observe
            const newRequestRef = fire
              .firestore()
              .collection("payplusrequests")
              .doc();

            newRequestRef
              .set({
                id: newRequestRef.id,
                campaignName,
                campaignRef,
                orgName,
                orgId,
                payplusUEN,
                amount,
                fullRef,
                userEmail: userObj.email,
                userMobile: userObj.mobile,
                paymentReceived: [],
                paymentAttempts: [new Date()],
                isCompleted: false,
                createdBy: userObj.userid,
                createdAt: new Date(),
              })
              .then(() => {
                // Set up observer
                unsubObserver = newRequestRef.onSnapshot((documentSnapshot) => {
                  const payplusRequestObj = documentSnapshot.data();
                  if (payplusRequestObj.isCompleted) {
                    setComplete(true);
                    if (isGeneral) {
                      genCallbacks.onSuccessCB(payplusRequestObj);
                    }
                  }
                });
                const qrDataStr = generateQRDataStr(selectedCampaign, fullRef);
                displayQR(qrDataStr);
              });
          } else {
            // Observe paymentReceived List
            if (querySnapshot.size > 1) {
              // Should not happen, mark as error to withhold payment
              console.log("ERR: qsSz1");
              setError("ERR: qsSz1");
            } else {
              const payplusRequestObj = querySnapshot.docs[0].data();
              const payplusRequestId = payplusRequestObj.id;
              const existingRequestRef = fire
                .firestore()
                .collection("payplusrequests")
                .doc(payplusRequestId);

              if (payplusRequestObj.paymentAttempts?.length > 0) {
                const newAttemptList = [...payplusRequestObj.paymentAttempts];
                // Update attempts to make payment for this reference
                newAttemptList.push(new Date());
                existingRequestRef.update({
                  paymentAttempts: newAttemptList,
                  amount: amount,
                });
                unsubObserver = existingRequestRef.onSnapshot(
                  (documentSnapshot) => {
                    const observeredRequestObj = documentSnapshot.data();
                    if (observeredRequestObj.isCompleted) {
                      setComplete(true);
                      if (isGeneral) {
                        genCallbacks.onSuccessCB(payplusRequestObj);
                      }
                    }
                  }
                );
                const qrDataStr = generateQRDataStr(selectedCampaign, fullRef);
                displayQR(qrDataStr);
              } else {
                // No record of previous payment attempts, mark as error
                console.log("ERR: pAsl0");
                setError("ERR: pAsl0");
              }
            }
          }
        });
    };

    const generateUserFullRef = (newPayPlusId, cb) => {
      generateUserRunningNum((userRunningNum) => {
        if (userRunningNum) {
          const fullRef = campaignRef + qrType + newPayPlusId + userRunningNum;
          cb(fullRef);
        } else {
          // Error
          console.log("ERR: RNmGn");
          setError("ERR: RNmGn");
        }
      });
    };

    const generateProductFullRef = (cb) => {
      fire
        .firestore()
        .collection("payplusrequests")
        .where("campaignRef", "==", campaignRef)
        .get()
        .then((resultSnapshot) => {
          const requestList = [];
          resultSnapshot.docs.map((doc) => requestList.push(doc.data()));
          const nextNum = requestList.length.toString();
          const fullRef =
            campaignRef + qrType + nextNum.padStart(8, "0") + "00";
          cb(fullRef);
        });
    };

    const beginQRGenerationProcess = () => {
      if ((isGeneral && userObj.userid) || (step === 3 && paymentConsent)) {
        // Get full reference if user is involved
        // 210929: Currently only "user" type campaigns allowed
        if (qrType === "U") {
          const userId = userObj.userid;
          const userPayplusId = userObj.payplusid;
          if (!userPayplusId) {
            // User does not have an existing payplusId. Generate.
            generatePayPlusId(userObj.userid, (newPayPlusId) => {
              const userRef = fire.firestore().collection("users").doc(userId);
              userRef
                .update({
                  payplusid: newPayPlusId,
                })
                .then(() => {
                  // Generate fullRef
                  generateUserFullRef(newPayPlusId, (fullRef) => {
                    if (fullRef) {
                      createPayPlusRequest(fullRef);
                    } else {
                      console.log("ERR: FNmG1");
                      setError("ERR: FNmG1");
                    }
                  });
                });
            });
          } else {
            // User has payplusId. Generate FullRef.
            generateUserFullRef(userPayplusId, (fullRef) => {
              if (fullRef) {
                createPayPlusRequest(fullRef);
              } else {
                console.log("ERR: FNmG2");
                setError("ERR: FNmG2");
              }
            });
          }
        } else if (qrType === "P") {
          generateProductFullRef((fullRef) => {
            if (fullRef) {
              createPayPlusRequest(fullRef);
            } else {
              console.log("ERR: FNmG2");
              setError("ERR: FNmG2");
            }
          });
        }
      }
    };

    // Process begins here: check for campaign expiry one more time
    // FUTURE TODO: Add one more check to server to see if campaign remains approved (not retired)
    if (selectedCampaign.expiry) {
      const now = new Date().getTime();
      const campaignExpiry = selectedCampaign.expiry.toDate().getTime();
      if (campaignExpiry > now) {
        beginQRGenerationProcess();
      } else {
        setError("ERR: Campaign has Expired");
      }
    } else {
      beginQRGenerationProcess();
    }

    // Unsub observer on component dismount
    return () => {
      unsubObserver();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedCampaign)]);

  const displayQR = (qrDataStr) => {
    const width = 250;
    const canvas = createCanvas(width, width);
    const ctx = canvas.getContext("2d");
    QRCode.toCanvas(canvas, qrDataStr, {
      width,
      margin: 1,
      version: 7,
      color: {
        dark: "#7C1A78",
        light: "#FFFFFF",
      },
    }).then(() => {
      loadImage("/archdiocese_sg-192x192.png").then((dioceseImage) => {
        loadImage("/payplus.png").then((payPlusLogo) => {
          ctx.drawImage(dioceseImage, 100, 85, 50, 50);
          ctx.drawImage(payPlusLogo, 85, 135, 75, 35);
          setQRsrc(canvas.toDataURL());
        });
      });
    });
    setQRsrc(canvas.toDataURL());
  };

  const countdownTimer = ({ hours, minutes, seconds, completed }) => {
    let secStr = seconds < 10 ? "0" + seconds : seconds;
    let minStr = minutes < 10 ? "0" + minutes : minutes;

    return (
      <span>
        {minStr}:{secStr}
      </span>
    );
  };

  const selectDisplay = () => {
    if (error && !isComplete) {
      return (
        <div className="text-center mb-2">
          <SentimentVeryDissatisfiedIcon
            style={{
              fontSize: "150px",
              color: "grey",
              marginBottom: "5px",
            }}
          />
          <h3>An Error has Occurred</h3>
          <h5>We apologise for the inconvenience caused</h5>
        </div>
      );
    }
    if (QRsrc !== "") {
      if (isComplete) {
        return (
          <div className="text-center mb-2">
            <CheckCircleOutlineIcon
              style={{
                fontSize: "150px",
                color: "green",
                marginBottom: "5px",
              }}
            />
            <h3>Payment Successful</h3>
          </div>
        );
      }
      if (isExpired) {
        return (
          <div className="text-center mb-2">
            <SentimentVeryDissatisfiedIcon
              style={{
                fontSize: "150px",
                color: "grey",
                marginBottom: "5px",
              }}
            />
            <h3>Your QR Code has Expired</h3>
            <h5>Please try again</h5>
          </div>
        );
      }
      return (
        <>
          <div className="text-center mb-2">
            <img src={QRsrc} alt="QR Code"></img>
          </div>
          <div className="text-center mb-3 text-danger">
            QR Valid for:{" "}
            <Countdown
              date={Date.now() + 299000}
              zeroPadTime={2}
              onComplete={() => setIsExpired(true)}
              renderer={countdownTimer}
            />
          </div>
        </>
      );
    } else {
      return <div className="text-center mb-2">Loading ...</div>;
    }
  };

  const selectText = () => {
    if (error && !isComplete) {
      return `You may contact the myCatholicSG Team on the error message "${error}"`;
    }
    if (QRsrc !== "") {
      if (isComplete) {
        return "The Transaction has been completed, you may now leave this page";
      }
      if (isExpired) {
        return "";
      }
      return "This page will automatically refresh once your payment has been received";
    } else {
      return "This page will automatically refresh once your payment has been received";
    }
  };

  const displayGenBtns = () => {
    let genBtnText = "Cancel and Return";
    let shouldDisplayBtn = genSettings.showReturnBtn;
    let genBtnFn = genCallbacks.onReturnBtnCB;
    if (isComplete) {
      genBtnText = "Proceed";
      shouldDisplayBtn = genSettings.showSuccessBtn;
      genBtnFn = genCallbacks.onSuccessBtnCB;
    } else if (isExpired || error) {
      genBtnText = "Return";
      shouldDisplayBtn = genSettings.showFailureBtn;
      genBtnFn = genCallbacks.onFailureBtnCB;
    }

    return shouldDisplayBtn ? (
      <button
        type="button"
        onClick={() => genBtnFn()}
        className="btn bg-white text-primary mt-3 btn-block btn-lg roundcornernocolor"
      >
        {genBtnText}
      </button>
    ) : (
      ""
    );
  };

  return (
    <div className="row justify-content-center mx-0">
      <div className="col px-1">
        <main className="container">
          <div className="pb-3 pt-3">
            <>
              <div className="center-content w-100">
                <h2 className="mb-0 text-danger">Pay ✝</h2>
              </div>
              <div className="clearfix"></div>
              <div className="normalsmallfontsize mt-4 py-4 pr-3 text-left card">
                <h5 className="text-center px-4">You are making payment for</h5>
                <h2 className="text-center px-4">{campaignName}</h2>
                <h3 className="text-center px-4">({orgShortName})</h3>
                <div className="mb-3" />
                {selectDisplay()}
                <h5 className="mt-3 mb-2 px-4">Details of your Transaction</h5>
                <div className="px-4">Please verify after scanning:</div>
                <br />
                <ul>
                  <li>
                    Organization Name : <b>{orgName}</b>
                  </li>
                  <li>
                    Organization UEN :{" "}
                    <b>
                      {payplusUEN}{" "}
                      <span className="text-danger">
                        (DO NOT manually key this in)
                      </span>
                    </b>
                  </li>
                </ul>
                <p className="py-2 px-4 mt-2 text-center">{selectText()}</p>
              </div>
              {isGeneral ? (
                displayGenBtns()
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => payplusDispatch({ type: "SET_STEP_ONE" })}
                    className="btn bg-white text-primary mt-3 btn-block btn-lg roundcornernocolor"
                  >
                    Select a different payment
                  </button>
                  <button
                    type="button"
                    onClick={() => payplusDispatch({ type: "SET_STEP_ONE" })}
                    className="btn bg-white text-primary mt-3 btn-block btn-lg roundcornernocolor"
                  >
                    Return Home
                  </button>
                </>
              )}
            </>
          </div>
        </main>
      </div>
    </div>
  );
};

export default QRPaymentDisplay;
