import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form";
import fire from "../../../services/fire";
import ArchdioceseLogo from "../../blocks/archdioceseLogoBlock";
import { logEvent } from "../../../services/log";
import { Link } from "react-router-dom";
import DemoBar from "../../common/demoBar";
import { getLatestVersion } from "../../../services/getversion";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { checkUser } from "../../../services/checkUser";
// import StrongPasswordChecklist from "../../common/strongPasswordChecklist";

class LoginForm extends Form {
  constructor(props) {
    super(props);
    const redirectPathName = props?.location?.state?.pathname;
    const redirectSearch = props?.location?.state?.search ?? "";

    this.state = {
      data: { email: "", password: "" },
      errors: {},
      version: null,
      loading: false,
      redirectPathName,
      redirectSearch,
      passwordValid: true,
    };
  }

  schema = {
    email: Joi.string().email().trim().required().label("Email"),
    password: Joi.string().min(8).trim().required().label("Password"),
  };

  doSubmit = async () => {
    // Call the server
    const { data, errors } = this.state;
    this.setState({
      errors,
      loading: true,
    });
    let lowercaseemail = data.email.trim().toLowerCase();
    try {
      await checkUser(lowercaseemail, "login");
    } catch (e) {
      console.log(e);
    }
    logEvent("login_click", { email: lowercaseemail });
    fire
      .auth()
      .signInWithEmailAndPassword(lowercaseemail, data.password.trim())
      .then((u) => {
        logEvent("login_success", { email: lowercaseemail });
        if (this.state.redirectPathName) {
          this.props.history.push({
            pathname: this.state.redirectPathName,
            search: this.state.redirectSearch,
          });
        }
      })
      .catch((error) => {
        let errors;
        if (error.code === "auth/too-many-requests") {
          errors = { email: error.message };
        } else {
          errors = {
            email: "Incorrect email or password",
          };
        }
        logEvent("login_fail", {
          email: lowercaseemail,
          message: error.message,
        });
        this.setState({
          loading: false,
          errors,
        });
      });
  };

  async componentDidMount() {
    const version = await getLatestVersion();
    this.setState({ version });
    window.scrollTo(0, 0);
  }

  render() {
    const { passwordValid, data, loading, version } = this.state;

    return (
      <React.Fragment>
        <DemoBar />
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-6">
            <div className="pb-2 pointer text-left d-flex justify-content-start align-items-center ">
              <Link
                to="/welcome"
                style={{ textDecoration: "none", marginLeft: "-5px" }}
                className="mt-3 text-dark d-flex align-items-center justify-content-start"
              >
                <ArrowBackIosRoundedIcon fontSize="small" className=" mr-1" />
                <span className="text-dark">Home</span>
              </Link>
            </div>
            <ArchdioceseLogo />
          </div>
        </div>
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-6">
            <main className="container">
              <div className="pb-3 pt-3">
                <h1 className="pb-2">Hi, please log in</h1>
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput("email", "Email", "text", "Your email")}
                  {this.renderPasswordInput(
                    "password",
                    "Password",
                    "password",
                    "8 characters or more"
                  )}
                  {/* <StrongPasswordChecklist
                    password={data["password"]?.trim()}
                    validHandler={(valid) => {
                      this.setState({ passwordValid: valid });
                    }}
                  /> */}
                  {passwordValid &&
                    !loading &&
                    this.renderBlockButton("Log In")}
                  {!passwordValid &&
                    !loading &&
                    this.renderDisabledBlockButton("Log In")}
                  {loading && this.renderLoadingBlockButton("Logging in...")}
                </form>
              </div>

              <div className="text-center">
                <p className="pt-0 pb-0 defaultfontsize text-danger">
                  New user registration? <Link to="/register">Sign up</Link>
                </p>
                <hr />
                <p className="pt-0 pb-2 defaultfontsize">
                  <Link to="/forgot">Forgot password?</Link>
                </p>
              </div>
              <div className="col-12 mt-5 mb-4 text-center">
                <div className="defaultfontsize">
                  <Link to="/schedule">Schedule</Link>{" "}
                  {/* <span className="text-secondary">&bull;</span>{" "}
                  <Link to="/help">User Guide</Link>{" "} */}
                  <span className="text-secondary">&bull;</span>{" "}
                  <Link to="/faq">FAQ</Link>
                </div>
                <p className="mt-3 mb-0 text-muted">
                  &copy; {new Date().getFullYear()} Roman Catholic Archdiocese
                  of Singapore. All Rights Reserved.
                </p>
                <p className="my-0 text-muted">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.catholic.sg/terms/"
                    className="text-muted"
                  >
                    Terms & Conditions
                  </a>
                </p>
                {version !== null && (
                  <p className="my-4 text-muted">Version: {version?.version}</p>
                )}
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LoginForm;
