import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncAdminEventHubCreate({ ...rest }) {
  const AsyncLoad = loadable(() =>
    import("../admin/create/AdminEventHubCreate")
  );
  console.log("Loading", AsyncAdminEventHubCreate.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncAdminEventHubCreate;
