import React from "react";
import Joi from "joi-full";
import { Button } from "react-bootstrap";

import Form from "../../common/form";

import { getDistricts } from "../services/districts";
import { detectedParishNameToId } from "../services/resources";

class PreRegistrationForm extends Form {
	constructor(props) {
		super(props);

		const { draft, parishesFromDB } = props;
		const districts = getDistricts();
		const parishes = [];
		const finalParishes = [];

		let district = "", selectedParish = 0, selectedParishId = "", selectedParishName = "", timeslot = "",
			parishId = "", coordinatorEmail;

		if (draft.selectedParish) {
			selectedParishId = draft.selectedParish;

			const [ item ] = parishesFromDB.filter((g) => g.id === draft.selectedParish);

			district = item.district;
		}

		if (district) {
			parishesFromDB.forEach((item) => {
				if (item.district === district) {
					parishes.push(item);
				}
			});
		}

		if (parishes.length) {
			parishes.forEach((item2, index) => {
				if (item2.id === draft.selectedParish) {
					coordinatorEmail = item2.coordinatorEmail;
					selectedParish = index + 1;

					let noticeElement = document.getElementById("notice_element");

					if (noticeElement) {
						if (item2.notice) {
							noticeElement.innerHTML = item2.notice;
						} else {
							noticeElement.innerHTML = "";
						}
					}
				}

				const newItem = { ...item2 };
				
				newItem._id = index + 1;
				newItem.name = item2.parish;

				finalParishes.push(newItem);
			});
		}

		if (draft.selectedParishName) {
			selectedParishName = draft.selectedParishName;
		}

		if (draft.timeslot) {
			timeslot = draft.timeslot;
		}

		if (draft.parishId) {
			parishId = draft.parishId;
		}

		this.state = {
			data: {
				district: district || "",
				selectedParish: selectedParish || 0,
				selectedParishId: selectedParishId || "",
				selectedParishName: selectedParishName || "",
				timeslot: timeslot || "",
				parishId: parishId || "",
				coordinatorEmail: coordinatorEmail || "",
			},
			districts,
			parishes: [],
			timeslots: [],
			errors: {},
		};
	}

	schema = {
		selectedParish: Joi.string().trim().required().label("Selected Parish"),
	};

	async componentDidMount() {
		await this.initGetDistricts();
		window.scrollTo(0, 0);
	}

	componentDidUpdate(prevProps, prevState) {
		const { data: { selectedParish = 0 } } = this.state;

		if (selectedParish !== prevState.data.selectedParish) {
			this.setSelectedParishId();
		} 
	}

	initGetDistricts = () => {
		const { draft, parishesFromDB } = this.props;
		const { data } = this.state;
		const districts = getDistricts();
		const newData = { ...data };
		const parishes = [];
		const finalParishes = [];

		let district = "", timeslots = [];

		if (draft.selectedParish) {
			newData.selectedParishId = draft.selectedParish;

			const [ item ] = parishesFromDB.filter((g) => g.id === draft.selectedParish);

			district = item.district;
		}

		if (district) {
			newData.district = district;

			parishesFromDB.forEach((item) => {
				if (item.district === district) {
					parishes.push(item);
				}
			});
		}

		if (parishes.length) {
			parishes.forEach((item2, index) => {
				if (item2.id === draft.selectedParish) {
					newData.selectedParish = index + 1;
					newData.coordinatorEmail = item2.coordinatorEmail;
					timeslots = item2.timeslot;

					let noticeElement = document.getElementById("notice_element");

					if (noticeElement) {
						if (item2.notice) {
							noticeElement.innerHTML = item2.notice;
						} else {
							noticeElement.innerHTML = "";
						}
					}
				}

				const newItem = { ...item2 };
				
				newItem._id = index + 1;
				newItem.name = item2.parish;

				finalParishes.push(newItem);
			});
		}

		if (draft.selectedParishName) {
			newData.selectedParishName = draft.selectedParishName;
		}

		if (draft.timeslot) {
			newData.timeslot = draft.timeslot;
		}

		if (draft.parishId) {
			newData.parishId = draft.parishId;
		}

		this.setState({
			data: newData,
			districts,
			parishes: finalParishes,
			timeslots,
		});
	}

	setSelectedParishId = () => {
		const { parishes, data } = this.state;
		const { selectedParish } = data;
		const newData = { ...data };
		const finalParishes = [];

		let selectedParishId = "", selectedParishName = "", timeslots = [], parishId = "",
			coordinatorEmail = "";

		if (selectedParish) {
			if (parishes.length) {
				parishes.forEach((item2, index) => {
					const newItem = { ...item2 };
					
					newItem._id = index + 1;
					newItem.name = item2.parish;
	
					finalParishes.push(newItem);
				});

				const [ item ] = finalParishes.filter((g) => g._id === parseInt(selectedParish));

				selectedParishId = item.id;
				selectedParishName = item.parish;
				coordinatorEmail = item.coordinatorEmail;
				parishId = detectedParishNameToId(item.parish);
				timeslots = item.timeslot;

				let noticeElement = document.getElementById("notice_element");

				if (noticeElement) {
					if (item.notice) {
						noticeElement.innerHTML = item.notice;
					} else {
						noticeElement.innerHTML = "";
					}
				}
			}
		}

		newData.selectedParishId = selectedParishId;
		newData.selectedParishName = selectedParishName;
		newData.timeslot = "";
		newData.parishId = parishId;
		newData.coordinatorEmail = coordinatorEmail;

		this.setState({
			data: newData,
			timeslots,
		})
	}

	doSubmit = async () => {
		const { setPreRegistration } = this.props;
		const { data } = this.state;

		if (data.selectedParish && data.district && data.timeslot && data.parishId) {
			setPreRegistration(data);
		}
	}

	handleRadioChange = (type) => ({ currentTarget: input }) => {
		const { parishesFromDB } = this.props;
		const { data } = this.state;
		const newState = { ...this.state };
		const newData = { ...data };
		const parishes = []
		const finalParishes = [];

		if (type === "district") {
			parishesFromDB.forEach((item) => {
				if (item.district === parseInt(input.value)) {
					parishes.push(item);
				}
			});

			newData.selectedParish = 0;
			newData.selectedParishId = "";
			newData.selectedParishName = "";
			newData.timeslot = "";
			newData.parishId = "";
			newData.coordinatorEmail = "";

			let noticeElement = document.getElementById("notice_element");

			if (noticeElement) {
				noticeElement.innerHTML = "";
			}
		}

		if (parishes.length) {
			parishes.forEach((item2, index) => {
				const newItem = { ...item2 };
				
				newItem._id = index + 1;
				newItem.name = item2.parish;

				finalParishes.push(newItem);
			});

			newState.parishes = finalParishes;
		}
		
		newData[type] = input.value;
		newState.data = newData;

		this.setState(newState);
	}

	renderNextButton = () => {
		const { data } = this.state;

		let isDisabled = true;

		if (data.selectedParish && data.district && data.timeslot && data.parishId) {
			isDisabled = false;
		}

		return (
			<Button
				className="btn-block mb-3"
				size="lg"
				onClick={ this.doSubmit }
				disabled={ isDisabled }
			>
				Fill in Child's Details
			</Button>
		);
	}

	handleOnClick = () => {
		const { saveDraft } = this.props;
		const { data } = this.state;

		saveDraft(data);
	}

	render() {
		const { userDetails: { fullname = "" } } = this.props;
		const { districts, parishes, timeslots, data } = this.state;

		return (
			<div>
				<br />
				<br />
				<h5>
					Before we proceed, select where you would like to enroll{` ${fullname} `}for Catechesis.
				</h5>
				<br />
				<React.Fragment>
					<form onSubmit={ this.handleSubmit}>
						<div className="d-flex">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								1
							</div>
							<div className="ml-3">
								<div>
									<strong>Select a district</strong>
								</div>
								{ districts.length ?
									districts.map((district) => {
										const { _id, name, active } = district;

										return (active ?
											<div className="ml-4 mt-2 mb-3" key={_id}>
												<input
													className="form-check-input"
													name="district"
													type="radio"
													id={_id}
													value={ _id }
													style={{ transform: "scale(1.5)" }}
													onChange={ this.handleRadioChange("district") }
													checked={ parseInt(data.district) === _id }
												/>
												<label className="form-check-label ml-2" htmlFor={_id}>{ name }</label>
											</div> : null
										);
									}) : null
								}
							</div>
						</div>
						{ data.district ?
							<div className="d-flex mt-2">
								<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
									style={{ height: "25px", minWidth: "25px" }}>
									2
								</div>
								<div className="container">
									<div>
										<strong>Select a parish</strong>
									</div>
									<div className="ml-2 mt-2 pl-0 container">
										{ this.renderSelect(
											"selectedParish",
											"",
											parishes,
										)}
									</div>
									<div id="notice_element" />
								</div>
							</div> : null
						}
						{ data.selectedParish ?
							<div className="d-flex mt-2">
								<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
									style={{ height: "25px", minWidth: "25px" }}>
									3
								</div>
								<div className="ml-3">
									<div>
										<strong>Pick a timeslot</strong>
									</div>
									{ timeslots.length ?
										timeslots.map((timeslot, index) => {
											return (
												<div className="ml-4 mt-2 mb-3" key={ index }>
													<input
														className="form-check-input"
														name="timeslot"
														type="radio"
														id={ `timeslot${index}` }
														value={ timeslot }
														style={{ transform: "scale(1.5)" }}
														onChange={ this.handleRadioChange("timeslot") }
														checked={ data.timeslot === timeslot }
													/>
													<label className="form-check-label ml-2" htmlFor={ `timeslot${index}` }>{ timeslot }</label>
												</div>
											);
										}) : null
									}
								</div>
							</div> : null
						}
					</form>
					<br />
					<br />
					{ this.renderNextButton() }
					<button
						className="btn-block mb-3 btn outline-primary btn-lg"
						style={{ border: "none", color: "#007bff" }}
						onClick={ this.handleOnClick }
					>
						Save as draft
					</button>
				</React.Fragment>
			</div>
		);
	}
};

export default PreRegistrationForm;
