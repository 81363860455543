import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

function Socials() {
  return (
    <div>
      <div className="mx-4 mb-4">
        <h5>Connect with Us</h5>
      </div>
      <div className="row mx-auto text-center">
        <div className="col mb-4">
          <a
            href="https://www.facebook.com/Catholic.Sg"
            rel="noopener noreferrer"
            target="_blank"
            className="text-dark"
            title="Catholic.sg Facebook"
          >
            <FacebookIcon
              style={{ color: "#1877f2" }}
              className="xlargefontsize"
            />
            <br />
            Facebook
          </a>
        </div>
        <div className="col mb-4">
          <a
            href="https://www.youtube.com/user/CatholicsSg"
            rel="noopener noreferrer"
            target="_blank"
            className="text-dark"
            title="Catholic.sg YouTube"
          >
            <YouTubeIcon
              style={{ color: "#ff0000" }}
              className="xlargefontsize"
            />
            <br />
            YouTube
          </a>
        </div>
        <div className="col mb-4">
          <a
            href="https://twitter.com/catholicssg"
            rel="noopener noreferrer"
            target="_blank"
            className="text-dark"
            title="CatholicSG Twitter"
          >
            <TwitterIcon
              style={{ color: "#1f9bef" }}
              className="xlargefontsize"
            />
            <br />
            Twitter
          </a>
        </div>
        <div className="col mb-4">
          <a
            href="https://www.instagram.com/catholic.sg/"
            rel="noopener noreferrer"
            target="_blank"
            className="text-dark"
            title="CatholicSG Instagram"
          >
            <InstagramIcon
              style={{ color: "#d300c5" }}
              className="xlargefontsize"
            />
            <br />
            Instagram
          </a>
        </div>

        <div className="col mb-4">
          <a
            href="https://www.linkedin.com/company/catholicsg"
            rel="noopener noreferrer"
            target="_blank"
            className="text-dark"
            title="CatholicSG LinkedIn"
          >
            <LinkedInIcon
              style={{ color: "#1f9bef" }}
              className="xlargefontsize"
            />
            <br />
            LinkedIn
          </a>
        </div>
        <div className="col mb-4">
          <a
            href="https://radio.catholic.sg/"
            rel="noopener noreferrer"
            target="_blank"
            className="text-dark"
            title="CatholicSG Radio"
          >
            <WifiTetheringIcon
              style={{ color: "#F15B28" }}
              className="xlargefontsize"
            />
            <br />
            CatholicSG Radio
          </a>
        </div>
      </div>
    </div>
  );
}

export default Socials;
