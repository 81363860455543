import React, { useState } from "react";
import YoutubeEmbed from "../../../common/youtubeEmbed";
import { Tabs, Tab, Table, Button } from "react-bootstrap";
import GenericModal from "../../../common/GenericModal";
import { useParishConfig } from "../hooks/hooks";
import HelpIcon from "@material-ui/icons/Help";

function formatDate(date, name) {
  if (!date) return date;

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = date.getMonth();
  const monthStr = months[month];

  return {
    [`${name}Date`]: `${day} ${monthStr}`,
    [`${name}Idx`]: month * 100 + day,
  };
}

function QuickGuide({ data }) {
  const parishConfig = useParishConfig();

  const [quickGuideIsVisible, setQuickGuideIsVisible] = useState(false);
  const showQuickGuide = () => setQuickGuideIsVisible(true);
  const hideQuickGuide = () => setQuickGuideIsVisible(false);

  const registrationOpenList = parishConfig
    ?.filter(({ isActive, isCatchParish }) => isActive && isCatchParish)
    ?.map(({ parish, programmes }) => ({
      parish,
      programmes: programmes
        .map(({ name, registrationOpen, registrationClose, enquiryEmail }) => ({
          name,
          ...formatDate(registrationOpen.toDate(), "open"),
          ...formatDate(registrationClose?.toDate(), "close"),
          enquiryEmail,
        }))
        .sort(({ openIdx: idx1 }, { openIdx: idx2 }) => idx1 - idx2),
    }));

  return (
    <>
      <Button
        variant="info"
        size="sm"
        className="my-auto d-flex"
        style={{ gap: 2 }}
        onClick={showQuickGuide}
      >
        <HelpIcon className="my-auto" /> <span className="my-auto">Help</span>
      </Button>
      <GenericModal
        size="lg"
        show={quickGuideIsVisible}
        closehandler={hideQuickGuide}
        buttoncanceltext="Close"
        title={"User Guide"}
        body={
          <Tabs
            className="d-flex"
            defaultActiveKey="videoguide"
            id="quickguide-tabs"
          >
            <Tab
              className="flex-grow-1"
              eventKey="videoguide"
              title="Video Guide"
            >
              <div className="">
                <div className="alert-success alert">
                  <h4>Link Parent</h4>
                  <p>
                    The parent and child account must first be linked, and a{" "}
                    <b>Proof of Custody</b> uploaded.
                    <br />
                    This is to verify to the Catechist Ministry that you are the{" "}
                    <b>Legal Guardian</b> of the child.
                  </p>
                </div>
                <YoutubeEmbed youtubeid="ky0JWQxYfo4" />

                <div className="mt-4 alert-success alert">
                  <h4>Register Child</h4>
                  <p>
                    Once the <b>Parent Link</b> is completed, you may proceed to
                    register your child for Catechesis via the{" "}
                    <b>Registration Form</b>.
                  </p>
                </div>
                <YoutubeEmbed youtubeid="kZX7bQck8tQ" />
              </div>
            </Tab>
            <Tab
              className="flex-grow-1"
              eventKey="parishcontact"
              title="Parish Contact"
            >
              <Table bordered responsive size="sm" className="m-2">
                <thead>
                  <tr>
                    <th>Parish</th>
                    <th>Programme</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {!!registrationOpenList &&
                    registrationOpenList.flatMap(({ parish, programmes }) =>
                      programmes.map(({ name, enquiryEmail }, idx) => (
                        <tr key={parish + " " + name}>
                          {idx === 0 ? (
                            <th scope="row" rowSpan={programmes.length}>
                              {parish}
                            </th>
                          ) : null}
                          <td>{name}</td>
                          <td style={{ minWidth: 60 }}>
                            <a href={`mailto:${enquiryEmail}`}>
                              {enquiryEmail}
                            </a>
                          </td>
                        </tr>
                      ))
                    )}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        }
      />
    </>
  );
}

export default QuickGuide;
