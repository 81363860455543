import React, { useState, useContext, useEffect, useRef } from "react";
import FinanceContext from "./financeContext";
import {
  Modal,
  Dropdown,
  Button,
  Tooltip,
  OverlayTrigger,
  Alert,
} from "react-bootstrap";
import fire from "../../../services/fire";
import Table from "../../common/table";
import AddIcon from "@material-ui/icons/Add";

const ASSIGNMENT_COLUMNS = [
  { Header: "Campaign Name", accessor: "campaignName" },
  { Header: "Campaign Reference", accessor: "campaignRef" },
  { Header: "Amount (SGD)", accessor: "amount" },
  { Header: "Action", accessor: "action" },
];

const AssignmentsModal = () => {
  const {
    vendorDashboardState,
    vendorDashboardDispatch,
    financeDashboardState,
  } = useContext(FinanceContext);
  const { org } = financeDashboardState;
  const {
    selectedVendorUid,
    assignedCampaigns,
    unassignedCampaigns,
    isAssignmentsModalOpen,
    assignmentsSearchKey,
    isAssignmentsLoading,
    selectedCampaign,
    isAssignmentsConfirmModalOpen,
    isAssignmentsBtnLoading,
    confirmAssignmentsModalMessage,
    isAssignmentsError,
    assignmentsConfirmCallback,
  } = vendorDashboardState;
  const [dropdownItems, setDropdownItems] = useState([]);
  const [showAlert, setShowAlert] = useState(0);
  const vendorDashboardStateRef = useRef();
  vendorDashboardStateRef.current = vendorDashboardState;

  const closeAssignmentsConfirmationModal = () => {
    vendorDashboardDispatch({ type: "RESET_ASSIGNMENTS_MODAL" });
  };

  const deleteCampaign = async (obj) => {
    await vendorDashboardDispatch({
      type: "SET_SELECTED_CAMPAIGN",
      payload: {
        selectedCampaign: obj,
      },
    });
    await updateCampaign("deleteCampaign");
  };
  const renderAlert = () => {
    switch (showAlert) {
      case 0: {
        return;
      }
      case 1: {
        return (
          <Alert
            variant="info"
            show={showAlert === 1 ? true : false}
            onClose={() => setShowAlert(2)}
            dismissible
          >
            <Alert.Heading>Updating assignment...</Alert.Heading>
          </Alert>
        );
      }
      case 2: {
        return (
          <Alert
            variant="success"
            show={showAlert === 2 ? true : false}
            onClose={() => setShowAlert(0)}
            dismissible
          >
            <Alert.Heading>Assignment successfully updated!</Alert.Heading>
          </Alert>
        );
      }
      case 3: {
        return (
          <Alert
            variant="danger"
            show={showAlert === 3 ? true : false}
            onClose={() => setShowAlert(0)}
            dismissible
          >
            <Alert.Heading>
              Assignment update unsuccessful. Please try again.
            </Alert.Heading>
          </Alert>
        );
      }
      default: {
        return;
      }
    }
  };
  const updateCampaign = async (actionType) => {
    setShowAlert(1);
    const currentState = vendorDashboardStateRef.current;
    vendorDashboardDispatch({
      type: "SET_ADD_CAMPAIGN_STATE",
      payload: {
        currentState: currentState,
      },
    });
    vendorDashboardDispatch({
      type: "SET_IS_ASSIGNMENTS_BTN_LOADING",
      payload: {
        isAssignmentsBtnLoading: true,
      },
    });
    const updateVendorCampaigns = fire
      .functions("asia-east2")
      .httpsCallable("updateVendorCampaigns");
    const data = {
      type: actionType,
      uid: selectedVendorUid,
      orgId: org?.orgId,
      campaignRef: currentState.selectedCampaign.campaignRef,
    };
    const result = await updateVendorCampaigns(data);
    if (result?.data?.status === 1 || result?.data?.status === 2) {
      closeAssignmentsConfirmationModal();
      await fetchCampaigns();
      setShowAlert(2);
    } else {
      const nextCurrentState = vendorDashboardStateRef.current;
      await vendorDashboardDispatch({
        type: "SET_ADD_CAMPAIGN_STATE",
        payload: {
          currentState: nextCurrentState,
        },
      });
      vendorDashboardDispatch({
        type: "SET_CONFIRM_ASSIGNMENTS_MODAL_MESSAGE",
        payload: {
          confirmAssignmentsModalMessage: `An error has occurred. Please check whether the campaign name is correct. Error message: "${result?.data?.message}"`,
        },
      });
      vendorDashboardDispatch({
        type: "SET_IS_ASSIGNMENTS_ERROR",
        payload: {
          isAssignmentsError: true,
        },
      });
      setShowAlert(3);
    }
  };

  const fetchUserAssignedCampaigns = async () => {
    return new Promise((res, rej) => {
      const vendorCampaignSet = new Set();
      fire
        .firestore()
        .collection("membership")
        .where("userid", "==", selectedVendorUid)
        .where("membershipType", "==", "EVENT_VENDOR")
        .where("orgId", "==", org?.orgId)
        .get()
        .then((resultSnapshot) => {
          if (resultSnapshot.docs.length > 0) {
            resultSnapshot.docs.forEach((doc) => {
              doc.data().otherDetails.forEach((campaignRef) => {
                vendorCampaignSet.add(campaignRef);
              });
            });
          }
          res(vendorCampaignSet);
        });
    });
  };

  const fetchOrgCampaigns = async (vendorCampaignSet) => {
    return new Promise((res, rej) => {
      if (vendorCampaignSet && !vendorCampaignSet.empty) {
        const vendorUnassignedCampaigns = [];
        const vendorAssignedCampaigns = [];
        const now = new Date().getTime();
        const resultSnapshotObj = [];
        fire
          .firestore()
          .collection("paypluscampaigns")
          .where("qrType", "==", "P")
          .where("orgId", "==", org?.orgId)
          .get()
          .then((resultSnapshot) => {
            resultSnapshot.docs.forEach((doc) => {
              resultSnapshotObj.push(doc.data());
            });
          })
          .then(() => {
            resultSnapshotObj.forEach(async (obj) => {
              await fire
                .firestore()
                .collection("payplusorgs")
                .where("orgId", "==", obj.orgId)
                .get()
                .then(async (orgResultSnapshot) => {
                  if (!orgResultSnapshot.empty) {
                    orgResultSnapshot.docs.forEach((orgDoc) => {
                      const orgObj = orgDoc.data();
                      obj.orgName = orgObj.orgName;
                      obj.orgShortName = orgObj.orgShortName;
                      obj.payplusUEN = orgObj.payplusUEN;
                    });
                  }

                  if (obj.approvalStatus === 1 && !obj.isPrivate) {
                    const campaignStart = new Date(
                      obj.campaignStart?.seconds
                    ).getTime();
                    if (campaignStart && campaignStart < now) {
                      if (obj.expiry) {
                        if (new Date(obj.expiry).getTime() > now) {
                          obj.isValid = true;
                        }
                      } else {
                        obj.isValid = true;
                      }
                    }
                  }

                  if (obj.isValid) {
                    if (obj.isPayOnce) {
                      await fire
                        .firestore()
                        .collection("payplusrequests")
                        .where("createdBy", "==", selectedVendorUid)
                        .where("isCompleted", "==", true)
                        .where("campaignRef", "==", obj.campaignRef)
                        .get()
                        .then((payRequestSnapshot) => {
                          if (payRequestSnapshot.docs.length > 0) {
                            obj.isPaidDisabled = true;
                            obj.campaignName += " (Paid)";
                          } else {
                            obj.isPaidDisabled = false;
                          }
                        });
                    } else {
                      obj.isPaidDisabled = false;
                    }
                  }
                });
            });
            resultSnapshotObj.forEach((obj) => {
              if (!vendorCampaignSet.has(obj.campaignRef)) {
                vendorUnassignedCampaigns.push({
                  campaignName: obj.campaignName,
                  campaignRef: obj.campaignRef,
                  amount: obj.amount,
                });
              } else {
                vendorAssignedCampaigns.push({
                  campaignName: obj.campaignName,
                  campaignRef: obj.campaignRef,
                  amount: obj.amount,
                  action: (
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="outline-danger"
                        onClick={() => {
                          vendorDashboardDispatch({
                            type: "SET_ASSIGNMENTS_CONFIRM_MODAL_OPEN",
                            payload: {
                              isAssignmentsConfirmModalOpen: true,
                            },
                          });
                          vendorDashboardDispatch({
                            type: "SET_CONFIRM_ASSIGNMENTS_MODAL_MESSAGE",
                            payload: {
                              confirmAssignmentsModalMessage: `Are you sure you would like to delete ${selectedCampaign?.campaignName} for this volunteer?`,
                            },
                          });
                          vendorDashboardDispatch({
                            type: "SET_ASSIGNMENTS_CONFIRM_CALLBACK",
                            payload: {
                              assignmentsConfirmCallback: () =>
                                deleteCampaign(obj),
                            },
                          });
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  ),
                });
              }
            });
            res([vendorAssignedCampaigns, vendorUnassignedCampaigns]);
          });
      }
    });
  };

  const createDropdownItems = () => {
    const currDropdownItems = [];
    if (unassignedCampaigns && unassignedCampaigns.length > 0) {
      unassignedCampaigns.forEach((item) => {
        currDropdownItems.push(
          <Dropdown.Item
            key={item.campaignRef}
            onClick={() => {
              vendorDashboardDispatch({
                type: "SET_SELECTED_CAMPAIGN",
                payload: {
                  selectedCampaign: item,
                },
              });
            }}
          >
            {item.campaignName}
          </Dropdown.Item>
        );
      });
    }
    setDropdownItems(currDropdownItems);
  };
  const fetchCampaigns = async () => {
    const vendorCampaignSet = await fetchUserAssignedCampaigns();
    const vendorCampaigns = await fetchOrgCampaigns(vendorCampaignSet);
    vendorDashboardDispatch({
      type: "SET_ASSIGNED_CAMPAIGNS",
      payload: {
        assignedCampaigns: vendorCampaigns[0],
      },
    });
    vendorDashboardDispatch({
      type: "SET_UNASSIGNED_CAMPAIGNS",
      payload: {
        unassignedCampaigns: vendorCampaigns[1],
      },
    });
  };

  useEffect(() => {
    fetchCampaigns();
  }, [selectedVendorUid]);

  useEffect(() => {
    createDropdownItems();
  }, [unassignedCampaigns]);

  useEffect(() => {
    if (showAlert !== 0) {
      setTimeout(() => {
        setShowAlert(0);
      }, 2500);
    }
  }, [showAlert]);
  return (
    <>
      <Modal
        show={isAssignmentsModalOpen}
        keyboard={false}
        size="xl"
        onHide={() => {
          vendorDashboardDispatch({
            type: "RESET_ASSIGNMENTS_MODAL",
          });
        }}
      >
        <Modal
          show={isAssignmentsConfirmModalOpen}
          onHide={closeAssignmentsConfirmationModal}
          style={{ zIndex: "1070" }}
        >
          <Modal.Header>
            <h4>{isAssignmentsError ? "Error" : "Confirmation"}</h4>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="mb-4">{confirmAssignmentsModalMessage}</div>
              {isAssignmentsError ? (
                <div className="d-flex justify-content-end">
                  <div
                    className="btn btn-secondary"
                    onClick={closeAssignmentsConfirmationModal}
                  >
                    Cancel
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-between">
                  <div
                    className="btn btn-secondary"
                    onClick={closeAssignmentsConfirmationModal}
                  >
                    Cancel
                  </div>
                  <div
                    className="btn ml-2"
                    style={{
                      backgroundColor: isAssignmentsBtnLoading
                        ? "#6c757d"
                        : "#0347ad",
                      color: "#ffffff",
                    }}
                    onClick={() =>
                      isAssignmentsBtnLoading
                        ? {}
                        : assignmentsConfirmCallback()
                    }
                  >
                    {isAssignmentsBtnLoading ? "Loading..." : "Confirm"}
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>

        <div className="container">
          <Modal.Header>
            <div className="xlargefontsize font-weight-bold">
              Product Campaigns
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-between align-items-center">
              <div className="defaultfontsize font-weight-bold">
                Add Campaign:
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-dropdown">
                      Select the campaign to be added for this vendor.
                    </Tooltip>
                  }
                >
                  <Dropdown className="pr-2 py-2">
                    <Dropdown.Toggle variant="outline-primary">
                      {!isAssignmentsBtnLoading
                        ? selectedCampaign?.campaignName
                        : "Select Campaign"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>{dropdownItems}</Dropdown.Menu>
                  </Dropdown>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-addbutton">
                      Press this button after selecting a campaign to be added.
                    </Tooltip>
                  }
                >
                  <div
                    className="btn py-1 ml-1 mr-4 flex flex-column justify-content-center"
                    style={{
                      height: "38px",
                      backgroundColor:
                        selectedCampaign?.campaignName &&
                        selectedCampaign?.campaignName !== "Select Campaign" &&
                        !isAssignmentsBtnLoading
                          ? "#0347ad"
                          : "#6c757d",
                      color: "#ffffff",
                    }}
                  >
                    <AddIcon
                      className="my-1"
                      onClick={() => {
                        if (
                          selectedCampaign?.campaignName &&
                          selectedCampaign?.campaignName !==
                            "Select Campaign" &&
                          !isAssignmentsBtnLoading
                        ) {
                          vendorDashboardDispatch({
                            type: "SET_ASSIGNMENTS_CONFIRM_MODAL_OPEN",
                            payload: {
                              isAssignmentsConfirmModalOpen: true,
                            },
                          });
                          vendorDashboardDispatch({
                            type: "SET_CONFIRM_ASSIGNMENTS_MODAL_MESSAGE",
                            payload: {
                              confirmAssignmentsModalMessage: `Are you sure you would like to add ${selectedCampaign?.campaignName} for this volunteer?`,
                            },
                          });
                          vendorDashboardDispatch({
                            type: "SET_ASSIGNMENTS_CONFIRM_CALLBACK",
                            payload: {
                              assignmentsConfirmCallback: () =>
                                updateCampaign("addCampaign"),
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </OverlayTrigger>
              </div>
            </div>

            <div className="d-flex justify-content-around align-items-center py-3">
              <input
                placeholder="Search campaign..."
                className="form-control"
                value={assignmentsSearchKey}
                onChange={(e) => {
                  vendorDashboardDispatch({
                    type: "SET_ASSIGNMENTS_SEARCH_KEY",
                    payload: {
                      assignmentsSearchKey: e.target.value,
                    },
                  });
                }}
              />
            </div>
            {renderAlert()}
            <Table
              columnProps={ASSIGNMENT_COLUMNS}
              isLoading={isAssignmentsLoading}
              dataProps={assignedCampaigns}
              filterProps={assignmentsSearchKey}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => {
                setShowAlert(0);
                vendorDashboardDispatch({
                  type: "CLOSE_ASSIGNMENTS_MODAL",
                });
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default AssignmentsModal;
