import React from "react";
import Joi from "joi-full";
import { Button } from "react-bootstrap";

import Form from "../../common/form";

class RegistrationFormEducation extends Form {
	constructor(props) {
		super(props);

		const { draft } = this.props;
		
		this.state = {
			data: {
				nameOfSchool: draft.nameOfSchool || "",
				levelInSchool: draft.levelInSchool || "",
			},
			// schools: [],
			// levels: [],
			errors: {},
		};
	}

	schema = {
		nameOfSchool: Joi.string().trim().required().label("Name of School"),
		levelInSchool: Joi.string().trim().required().label("Level in School"),
	};

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	doSubmit = async () => {
		const { setRegistrationEducationDetails } = this.props;
		const { data } = this.state;
		const {
			nameOfSchool = "",
			levelInSchool = "",
		} = data;

		if (nameOfSchool && levelInSchool) {
			setRegistrationEducationDetails(data);
		}
	}

	renderNextButton = () => {
		const { data } = this.state;
		const {
			nameOfSchool = "",
			levelInSchool = "",
		} = data;

		let isDisabled = true;

		if (nameOfSchool && levelInSchool) {
			isDisabled = false;
		}

		return (
			<Button
				className="btn-block mb-3"
				size="lg"
				onClick={ this.doSubmit }
				disabled={ isDisabled }
			>
				Fill in miscellaneous details
			</Button>
		);
	}

	handleOnClick = () => {
		const { saveDraft } = this.props;
		const { data } = this.state;

		saveDraft(data);
	}

	handlePressBack = () => {
		const { pressBack } = this.props;

		pressBack();
	}

	render() {
		return (
			<div>
				<strong>Education Details</strong>
				<br />
				<br />
				<React.Fragment>
					<form onSubmit={ this.handleSubmit}>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								1
							</div>
							<div className="container">
								<div>
									<strong>Name of school as of 2023</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderInput(
										"nameOfSchool",
										"",
										"text",
										""
									)}
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								2
							</div>
							<div className="container">
								<div>
									<strong>Level at school as of 2023</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderInput(
										"levelInSchool",
										"",
										"text",
										"K2/P1/P2",
									)}
								</div>
							</div>
						</div>
					</form>
					<br />
					<br />
					{ this.props.coordinatorEmail ?
						<>
							If you have enquiries, plase contact your Parish Catechist Coordinator at{" "}
							<a href={ `mailto:${ this.props.coordinatorEmail }` }>{ this.props.coordinatorEmail }</a>
							<br />
							<br />
						</> : null
					}
					{ this.renderNextButton() }
					<Button className="btn-block mb-3" variant="outline-primary" size="lg"
						onClick={ this.handlePressBack }>
						Back
					</Button>
					<button
						className="btn-block mb-3 btn outline-primary btn-lg"
						style={{ border: "none", color: "#007bff" }}
						onClick={ this.handleOnClick }
					>
						Save as draft
					</button>
				</React.Fragment>
			</div>
		);
	}
}

export default RegistrationFormEducation;
