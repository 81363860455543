import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { enableVerifyEmail } from "../../services/settings";
import AccountVerify from "../account/page/accountVerify";

const ProtectedRoute = ({ component: Component, path, user }) => {
  const history = useHistory();
  const { pathname, search } = history.location;
  const userObj = user || history.location?.state?.user || false;

  return (
    <Route
      path={path}
      render={(props) => {
        if (!userObj) {
          return (
            <Redirect
              to={{ pathname: "/welcome", state: { pathname, search } }}
            />
          );
        } else {
          if (enableVerifyEmail() && !userObj.emailVerified) {
            return <AccountVerify {...props} user={userObj} />;
          }
        }
        return <Component {...props} user={userObj} />;
      }}
    />
  );
};

export default ProtectedRoute;
