import React from "react";
import { useHistory } from "react-router-dom";
import IconByField from "./iconByField";
import SmallDirections from "./smallDirections";

function LineParishIconShortcuts({ item }) {
  const history = useHistory();
  let {
    address,
    website,
    facebook,
    phone,
    bulletin,
    // schedule,
    more,
  } = item;

  let origwebsite = website;
  if (website) {
    website = website.split("/");
    website = website[2];
  }

  return (
    <div className=" text-center px-2">
      <div className="d-flex justify-content-between text-center">
        {bulletin && (
          <div className="text-truncate text-center pointer px-0">
            <div
              className="text-center text-dark mx-auto d-flex justify-content-center"
              onClick={() => history.push(bulletin)}
            >
              <div className="pt-1 text-truncate defaultstandardfontsize text-primary font-weight-bold">
                BULLETIN
              </div>
              <div className="text-center ml-1">
                <IconByField field="bulletin" size="16px" />
              </div>
            </div>
          </div>
        )}
        {address && <SmallDirections address={address} />}
        {more && (
          <div className="text-truncate text-center pointer px-0">
            <div
              className="text-center text-dark mx-auto  d-flex justify-content-center"
              onClick={more}
            >
              <div className="pt-1 text-truncate defaultstandardfontsize font-weight-bold text-primary">
                MORE
              </div>
              <div className="ml-0 text-center">
                <IconByField field="arrowright" size="16px" />
              </div>
            </div>
          </div>
        )}
        {facebook && (
          <div className="text-truncate  text-center px-0">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-truncate text-center"
              href={facebook}
            >
              <div className="text-center roundborder">
                <IconByField field={"facebook"} />
              </div>
              <div className="pt-1 text-truncate defaultstandardfontsize ">
                FB
              </div>
            </a>
          </div>
        )}

        {website && (
          <div className="text-truncate text-center  px-0">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-center mx-auto"
              href={origwebsite}
            >
              <div className="text-center roundborder">
                <IconByField field={"website"} />
              </div>
              <div className="pt-1 text-truncate defaultstandardfontsize ">
                WEBSITE
              </div>
            </a>
          </div>
        )}

        {phone && (
          <div className="text-truncate text-center  px-0">
            <a
              target="_blank"
              className="text-center"
              rel="noopener noreferrer"
              href={`tel:${phone}`}
            >
              <div className="text-center roundborder">
                <IconByField field={"phone"} />
              </div>
              <div className="pt-1 text-truncate defaultstandardfontsize ">
                CALL
              </div>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default LineParishIconShortcuts;
