import React from "react";
import Joi from "joi-full";
import Form from "../../common/form";
import fire from "../../../services/fire";
import { withRouter } from "react-router-dom";
import { getParishes, getParishNameById } from "../../../services/parish";
import { logEvent } from "../../../services/log";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Button, Media, Modal } from "react-bootstrap";
import { getCurrentUserData } from "../../../services/user";
import { isAllowToChangeParish } from "../../../services/massbookings";
import { getLastParishChangeHistory } from "../../../services/changeparish";
import SpinnerText from "../../common/spinnerwithText";
import { getChangeParishDate } from "../../../services/settings";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

class ParishEdit extends Form {
  constructor(props) {
    super(props);
    const { fullname, email, parish, hasbooking } = this.props.userdetails;

    this.state = {
      data: {
        parish: parish || 0,
      },
      fullname,
      email,
      hasbooking,
      errors: {},
      parishes: [],
      previousParish: parish || 0,
      loading: false,
      profilesave: false,
      showmodal: false,
      allowchange: false,
    };
  }

  schema = {
    parish: Joi.string().trim().required().label("Parish"),
  };

  componentDidMount() {
    const { email, parish } = this.props.userdetails;

    const parishes = getParishes();

    this.setState({
      parishes,
    });
    logEvent("user_parish_update_load", {
      email: email,
      parish: parish,
    });
    window.scrollTo(0, 0);
  }

  showModal = () => {
    this.setState({ showmodal: true });
  };

  handleClose = () => {
    this.setState({ showmodal: false });
  };

  goBack = () => {
    this.props.history.push("/home");
    window.location.reload();
  };

  doSubmit = async () => {
    const { data, errors, previousParish } = this.state;
    this.setState({
      errors,
      loading: true,
      updating: true,
    });
    const currentUser = fire.auth().currentUser;

    //get live data here
    const user = await getCurrentUserData(currentUser.uid);

    if (user) {
      const { fullname, email, lastmassbooking, lastdevotionbooking } = user;

      const startdate = new Date(getChangeParishDate()).getTime();
      const changelog = await getLastParishChangeHistory(
        startdate,
        currentUser.uid
      );
      if (
        !isAllowToChangeParish(lastmassbooking) ||
        !isAllowToChangeParish(lastdevotionbooking) ||
        changelog
      ) {
        this.props.history.push("/profile");
        return;
      }
      // Call the server
      try {
        await fire.firestore().doc(`users/${currentUser.uid}`).update({
          parish: data.parish.trim(),
          unaffiliated: false, //set to false since we have already change the parish
        });
        //add history here
        let docData = {
          id: currentUser.uid,
          author: fullname,
          timestamp: new Date().getTime(),
          to: data.parish.trim(),
          from: previousParish,
          created: new Date(),
          field: "parish",
          remarks: `Parish changed from ${getParishNameById(
            previousParish
          )} to ${getParishNameById(data.parish.trim())}`,
        };

        await fire.firestore().collection("changelogs").add(docData);

        logEvent("user_parish_update_success", {
          email: email,
          parish: data.parish,
        });
        this.setState({ profilesave: true, updating: false });
      } catch (ex) {
        logEvent("user_parish_update_fail", {
          email: email,
          message: ex.message,
        });
        console.error("Unable to Set Data");
      }
    } else {
      this.props.history.push("/home");
      return;
    }
  };

  render() {
    const { loading, profilesave, showmodal, updating, previousParish, data } =
      this.state;
    let disabled = false;
    if (previousParish === data.parish || data.parish === "") {
      disabled = true;
    }
    return (
      <React.Fragment>
        {!profilesave && (
          <React.Fragment>
            <div className="text-center">
              <WarningRoundedIcon
                className="text-danger text-center"
                style={{ fontSize: "60px" }}
              />
            </div>
            <Media className="text-center pb-4 mb-4">
              <Media.Body>
                <h4 className="text-danger">
                  You can only change your Parish <b>ONCE</b>
                </h4>
                No changes can be made until the Archdiocese announce the next
                one-time Parish change period
              </Media.Body>
            </Media>
            <form onSubmit={this.handleSubmit}>
              {this.renderSelect(
                "parish",
                "Select Parish",
                this.state.parishes
              )}

              <div className="py-2"></div>
              {disabled && (
                <Button
                  variant="primary"
                  className="btn-block btn-lg"
                  disabled={disabled}
                >
                  Continue
                </Button>
              )}
              {!disabled && !loading && (
                <Button
                  variant="primary"
                  className="btn-lg btn-block"
                  onClick={() => this.showModal()}
                >
                  Continue
                </Button>
              )}
              {!disabled &&
                loading &&
                this.renderLoadingBlockButton("Saving...")}
              <Modal
                show={showmodal}
                onHide={this.handleClose}
                animation={false}
                centered
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header>
                  <Modal.Title className="text-danger font-weight-bold">
                    Change Parish?
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                  At the moment, this is a{" "}
                  <b className="text-danger">one-time change</b>. <br />
                  <br />
                  Are you sure you want to change your parish from{" "}
                  <span className="text-danger font-weight-bold">
                    {getParishNameById(previousParish)}
                  </span>{" "}
                  to{" "}
                  <span className="text-danger  font-weight-bold">
                    {getParishNameById(data.parish ? data.parish : "1")}
                  </span>
                  ?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    disabled={updating}
                    variant="danger"
                    onClick={this.handleClose}
                  >
                    No
                  </Button>
                  {updating !== true && (
                    <Button
                      variant="outline-secondary"
                      onClick={() => this.doSubmit()}
                    >
                      Yes, I am sure
                    </Button>
                  )}
                  {updating === true && (
                    <Button disabled variant="outline-secondary">
                      <SpinnerText text="Please wait..." />
                    </Button>
                  )}
                </Modal.Footer>
              </Modal>
            </form>
            <div className="pt-4">
              <button
                type="button"
                onClick={() => this.props.history.goBack()}
                className="btn btn-link mt-5 btn-block btn-lg"
              >
                Go Back
              </button>
            </div>
          </React.Fragment>
        )}
        {profilesave && (
          <React.Fragment>
            <div className="text-center">
              <CheckCircleOutlineIcon
                style={{ fontSize: "150px", color: "green" }}
              />
              <h3 className="my-4">
                Your parish has been successfully updated!
              </h3>

              <button
                type="button"
                onClick={() => this.goBack()}
                className="btn btn-primary mt-4 btn-block btn-lg"
              >
                Visit Home Page
              </button>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ParishEdit);
