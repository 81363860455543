import React from "react";
import "../common/stylesheets/stepper.css";
import CheckIcon from "@material-ui/icons/Check";

function Steps({ steps, current }) {
  const enableIcon = false;
  const getColumn = (count = 4) => {
    let columns = 4;

    const stepConfig = [
      { step: 2, col: 6 },
      { step: 3, col: 4 },
      { step: 4, col: 3 },
      //5 steps? is this too many steps
    ];

    const found = stepConfig.filter((item) => item.step === count);

    if (found) {
      columns = found[0].col;
    }

    return columns;
  };

  const cols = getColumn(steps.length);

  return (
    <div className="text-center col-12 pt-2 pb-0">
      <div className="pearls pearls-xs row mb-0">
        {steps.map((item) => {
          let style = "";
          let fontStyle = "";
          let step = item.step;

          if (current > item.step) {
            style = "done";
            if (enableIcon) {
              step = <CheckIcon className="biggerfontsize adjustcheckicon" />;
            }
          } else if (current === item.step) {
            style = "current";
            fontStyle = "bold text-dark";
          }

          if (current === item.step + 1 && current > steps.length) {
            //make last step as bold
            fontStyle = "bold";
          }

          return (
            <div key={item.step} className={`pearl ${style} col-${cols}`}>
              <span className="pearl-number bold">{step}</span>
              <span className={`pearl-title ${fontStyle}`}>{item.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Steps;
