import React from "react";
import Header from "../../common/header";
import AskCatholic from "../component/askCatholic";
import ReloadModal from "../../common/reloadModal";

function Catholic({ user }) {
  return (
    <div>
      <Header user={user} smallpic={user.photoURL} hide="profile" />
      <ReloadModal />
      <AskCatholic user={user} />
    </div>
  );
}

export default Catholic;
