import React, { useRef, useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { TransfersDatum, RegistrationStatus } from "../../hooks/hooks";
import {
  useExternalTransfers,
  TransfersStatus,
  QueriedAdminContext,
} from "../hooks/hooks";

import {
  listProps,
  extFsmEdges,
  TransfersAction,
} from "../services/transfersTable";
import { transfersStatusDictionary } from "../../services/transfersStatus";

import { getParishNameById } from "../../../../../services/parish";
import fire from "../../../../../services/fire";
import AppGlobalContext from "../../../../../AppGlobalContext";
import AbstractList from "../components/AbstractList";
import {
  registrationCollectionName,
  transfersCollectionName,
} from "../../services/collections";

import RegistrationDetails from "../components/RegistrationDetails";
import TransferDetails from "../components/TransferDetails";
import { registrationStatusNameToStatus } from "../../services/registrationStatus";

export default function TransfersListExt() {
  const queriedAdminContext = useContext(QueriedAdminContext);
  const transfersState = useExternalTransfers();

  const { user } = useContext(AppGlobalContext);

  const [selectedNotes, setSelectedNotes] = useState([]);

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);
  const transfersCollection = db.collection(transfersCollectionName);

  /** @type {{value: TransfersStatus}[]} */
  const radios = [
    { value: "pendingOut" },
    { value: "rejectedOut" },
    { value: "pendingIn" },
    { value: "rejectedIn" },
    { value: "accepted" },
  ];

  /** @type {TransfersStatus} */
  const initialStatus = radios[0].value;

  /** @type {TransfersDatum[]} */
  const initialSelectedRows = [];
  const selectedRowsRef = useRef(initialSelectedRows);

  /**
   * @param {TransfersAction} action
   * @param {TransfersStatus} currentStatus
   */
  async function handleAction(action, currentStatus) {
    const selectedCount = selectedRowsRef.current.length;

    if (selectedCount === 0) {
      alert("Please select some rows first!");
      return Promise.resolve();
    }

    /** @type {TransfersStatus} */
    let newStatus;
    /** @type {RegistrationStatus} */
    let currentRegistrationStatus;
    /** @type {RegistrationStatus} */
    let newRegistrationStatus;
    /** @type {Record<TransfersStatus, RegistrationStatus>} */
    const transferToRegistrationStatus = {
      pendingOut: "pending",
      rejectedOut: "rejected",
      pendingIn: "pending",
      rejectedIn: "rejected",
      accepted: "transferred",
    };

    switch (action) {
      case "accept":
      case "reject":
      case "unassign":
        newStatus = extFsmEdges[action][currentStatus];
        currentRegistrationStatus = transferToRegistrationStatus[currentStatus];
        newRegistrationStatus = transferToRegistrationStatus[newStatus];

        const batch = db.batch();
        let updatedCount = 0;

        for (const row of selectedRowsRef.current) {
          let registrationUpdate = {};
          let transferUpdate = {};

          if (
            action === "reject" ||
            action === "accept" ||
            !transfersState.data.some(
              ({ submittedBy, status, child = {} }) =>
                status === newStatus &&
                submittedBy === row.submittedBy &&
                child.uid === row.child.uid
            )
          ) {
            if (currentStatus === "pendingOut" && action === "accept") {
              transferUpdate = {
                ...transferUpdate,
                status: "accepted",
              };

              registrationUpdate = {
                ...registrationUpdate,
                status: registrationStatusNameToStatus("transferred"),
                // selectedParishId: row.to.id,
                // programmeType: row.to.programmeId ?? "",
                // timeslot: row.to.timeslotId ?? "",
                // assignedClass: "",
              };
            } else {
              transferUpdate = {
                ...transferUpdate,
                status: newStatus,
              };
            }

            if (action === "accept" && newStatus === "accepted") {
              if (currentStatus === "pendingOut") {
                transferUpdate = {
                  ...transferUpdate,
                  outProcessedAt: new Date(),
                  outProcessedBy: user.uid,
                  outProcessedByName: user.displayName,
                };
              } else if (currentStatus === "pendingIn") {
                registrationUpdate = {
                  ...registrationUpdate,
                  status: registrationStatusNameToStatus("accepted"),
                };

                const registration = row.registration;
                const transferTo = row.to;

                // const { data: { ok, message, error } = {} } =
                //   await createCatchRegistration({
                //     uid: user.uid,
                //     parish: user.parish,
                //     registration,
                //     transferTo,
                //   });

                // if (ok) {
                //   // console.log(message);
                // } else {
                //   console.error(error);
                // }
              }
            }

            transfersState.data.find(({ id }) => id === row.id).status =
              currentStatus === "pendingOut" && action === "accept"
                ? ""
                : newStatus;

            updatedCount++;
          }

          if (Object.keys(transferUpdate).length > 0)
            batch.update(transfersCollection.doc(row.id), transferUpdate);
          if (Object.keys(registrationUpdate).length > 0)
            batch.update(
              registrationCollection.doc(row.registration.id),
              registrationUpdate
            );
        }

        if (updatedCount === 0) {
          alert(
            `There are ${newStatus} transfers in conflict with your selected transfers.`
          );
        } else {
          await batch.commit();
          alert(
            updatedCount === selectedCount
              ? "Update successful!"
              : `Update partially successful!\nThere are ${newStatus} transfers in conflict with some of your selected transfers.`
          );
        }

        break;
      case "email":
        let emails = "mailto:";
        if (selectedRowsRef.current.length === 1) {
          emails += selectedRowsRef.current[0].registration.mainContact.email;

          if (
            selectedRowsRef.current[0].registration.secondaryContact.exists &&
            selectedRowsRef.current[0].registration.secondaryContact
              .emergencies === false
          ) {
            emails += `;${selectedRowsRef.current[0].registration.secondaryContact.email}`;
          }
        } else {
          emails += "?bcc=";
          selectedRowsRef.current.forEach((row, index) => {
            emails += row.registration.mainContact.email;

            if (
              row.registration.secondaryContact.exists &&
              row.registration.secondaryContact.emergencies === false
            ) {
              emails += `;${row.registration.secondaryContact.email}`;
            }

            if (index < selectedRowsRef.current.length - 1) {
              emails += ";";
            }
          });
        }

        window.location.href = emails;
        break;
    }
    return Promise.resolve();
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(initialStatus);

  const openModal = (item) => {
    setModalIsOpen(true);
    setSelectedItem(item);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedItem(null);
  };

  return (
    <>
      <AbstractList
        {...listProps}
        id="external-transfers-table"
        statusDictionary={transfersStatusDictionary}
        radios={radios}
        defaultStatus={initialStatus}
        rawDataProps={transfersState.data}
        selectedRowsRef={selectedRowsRef}
        handleAction={handleAction}
        handleSelectedStatus={(status) => {
          setSelectedStatus(status);
        }}
      />

      {transfersState.exists
        ? transfersState.data.map((item) => {
            const name = `${item.id || ""}-${item.from.id || ""}-${
              item.to.id || ""
            }`;

            return (
              <button
                key={name}
                id={`button-${name}`}
                style={{ display: "none" }}
                disabled={modalIsOpen}
                onClick={() => {
                  if (!modalIsOpen) {
                    // console.log(item);
                    openModal(item);
                  }
                }}
              />
            );
          })
        : null}

      <Modal
        size="lg"
        show={modalIsOpen}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable="true"
      >
        <Modal.Body>
          <div style={{ padding: "30px" }}>
            <TransferDetails {...{ user, selectedItem, external: true }} />
          </div>
          <hr />
          <div style={{ padding: "30px" }}>
            <RegistrationDetails
              {...{
                user,
                registrationCollection,
                selectedItem: selectedItem?.registration,
                setSelectedItem: (registration) =>
                  setSelectedItem({ ...selectedItem, registration }),
                selectedNotes,
                setSelectedNotes,
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
