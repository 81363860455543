// Returns [month (0-indexed), date]
// Source: https://www.mom.gov.sg/employment-practices/public-holidays
/** @type {{[year: number]: [month: number, date: number][]}} */
const yearlyHolidays = {
  2022: [
    [0, 1], // New Year's Day
    [1, 1], // Chinese New Year
    [1, 2], // Chinese New Year
    [3, 15], // Good Friday
    [4, 2], // Labour Day
    [4, 3], // Hari Raya Puasa
    [4, 16], // Vesak Day
    [6, 11], // Hari Raya Haji
    [7, 9], // National Day
    [9, 24], // Deepavali
    [11, 26], // Christmas Day
  ],
  2023: [
    [0, 2], // New Year's Day
    [0, 23], // Chinese New Year
    [0, 24], // Chinese New Year
    [3, 7], // Good Friday
    [3, 22], // Hari Raya Puasa
    [4, 1], // Labour Day
    [5, 2], // Vesak Day
    [5, 29], // Hari Raya Haji
    [7, 9], // National Day
    [10, 13], // Deepavali
    [11, 25], // Christmas Day
  ],
  2024: [
    [0, 1], // New Year's Day
    [1, 12], // Chinese New Year
    [2, 29], // Good Friday
    [3, 10], // Hari Raya Puasa
    [4, 1], // Labour Day
    [4, 22], // Vesak Day
    [5, 17], // Hari Raya Haji
    [7, 9], // National Day
    [9, 31], // Deepavali
    [11, 25], // Christmas Day
  ],
};

export const years = Object.keys(yearlyHolidays);

/**
 * Function to detect whether a date is a Public Holiday in Singapore.
 * @param {number} year The year to be checked.
 * @param {number} month The month to be checked. 0-indexed.
 * @param {number} date The day of the month to be checked. 1-indexed.
 * @return {boolean} `true` if the input date is a Public Holiday.
 **/
export function isHoliday(year, month, date) {
  // const isHoliday = !!yearlyHolidays[year].find(([hMonth, hDate]) => hMonth === month && hDate === date);
  // console.log([year, month, date], isHoliday ? "IS HOLIDAY" : "NOT HOLIDAY");
  // return isHoliday;
  return !!yearlyHolidays[year].find(
    ([hMonth, hDate]) => hMonth === month && hDate === date
  );
}
