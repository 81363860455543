import React from "react";
import Badge from "react-bootstrap/Badge";

import { levelIdToName } from "../../services/levels";

/**
 * @typedef LevelPillProps
 * @prop {number} id
 */

/**
 * @param {LevelPillProps} props
 */
export default function LevelPill({ id }) {
  try {
    const name = levelIdToName(id);
    return (
      <Badge pill variant="dark">
        {name}
      </Badge>
    );
  } catch (e) {
    // console.log({ level: id });
    throw e;
  }
}
