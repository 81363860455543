import fire from "./fire";

export async function getObjectSettingsDB() {
  const db = fire.firestore();
  const obj = await db.doc(`objects/app`).get();

  if (obj && obj.exists) {
    return obj.data();
  }
  return null;
}
