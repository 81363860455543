import React from 'react';
import './stylesheets/floating-label-input.css';

const FloatingLabelInput = ({
  label = "",
  customClassName = "",
  customWrapperClass = "",
  onChange = () => { },
  value = '',
  children = false,
  readOnly = false,
}) => {
  return <div className={"floating-input-field " + customWrapperClass}>
    {children ?
      children
      :
      <input
        className={customClassName}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={label}
        readOnly={readOnly}
      />}
    <span>{label}</span>
  </div>;
}

export default FloatingLabelInput;