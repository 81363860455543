export function getRandomWaitMessage() {
  const alternatives = [
    "May God be with you...",
    "Please wait...",
    "Hang on...",
    "One moment...",
    "Just a sec...",
    "May God bless you...",
    "God bless you...",
    "Kindly hold on...",
    "Hang on tight...",
    "Just a moment...",
    "Give us a second...",
    "Almost there...",
    "Just a little longer...",
    "We're working on it...",
  ];

  // Get a random entry
  return alternatives[Math.floor(Math.random() * alternatives.length)];
}

export function getRandomButtonMessage() {
  const alternatives = [
    "Please wait...",
    "Hang on...",
    "One moment...",
    "Just a sec...",
    "Please hold...",
    "Almost there...",
    "Processings...",
  ];

  // Get a random entry
  return alternatives[Math.floor(Math.random() * alternatives.length)];
}
