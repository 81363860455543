import React from "react";
import Button from "react-bootstrap/Button";

import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";

import { useParishConfig } from "../../hooks/hooks";

import { downloadCert } from "../services/downloadCert";

export default function TransferDetails({ user, selectedItem, external }) {
  const parishConfig = useParishConfig();

  console.log(selectedItem);

  if (selectedItem == null) return null;

  const fromParish = selectedItem.from.inSingapore
    ? parishConfig.find(({ id }) => id === selectedItem.from.id)
    : { parish: selectedItem.from.id };
  const fromProgramme = selectedItem.from.inSingapore
    ? fromParish !== null
      ? fromParish.programmes.find(
          ({ id }) => id === selectedItem.from.programmeId
        )
      : null
    : {
        name: <span className="text-muted font-italic">(Overseas Parish)</span>,
      };
  const fromTimeslot =
    selectedItem.from.inSingapore && fromProgramme !== null
      ? fromProgramme.timeslots.find(
          ({ id }) => id === selectedItem.from.timeslotId
        )
      : null;

  const toParish = selectedItem.to.inSingapore
    ? parishConfig.find(({ id }) => id === selectedItem.to.id)
    : { parish: selectedItem.to.id };
  const toProgramme = selectedItem.to.inSingapore
    ? toParish !== null
      ? toParish.programmes.find(({ id }) => id === selectedItem.to.programmeId)
      : null
    : {
        name: <span className="text-muted font-italic">(Overseas Parish)</span>,
      };
  const toTimeslot =
    selectedItem.to.inSingapore && toProgramme !== null
      ? toProgramme.timeslots.find(
          ({ id }) => id === selectedItem.to.timeslotId
        )
      : null;

  return (
    <>
      <div className="mb-sm-3">
        <div className="mb-sm-3">
          <h5>Transfer Details</h5>
        </div>
        <strong>Name</strong>
        <div className="mb-sm-3">
          {selectedItem ? selectedItem.registration.name : "-"}
        </div>
        <strong>From</strong>
        <div>{fromParish.parish}</div>
        {fromProgramme != null && <div>{fromProgramme.name}</div>}
        {fromTimeslot != null && <div>{fromTimeslot.name}</div>}
        <br />
        <strong>To</strong>
        <div>{toParish.parish}</div>
        {toProgramme != null && <div>{toProgramme.name}</div>}
        {toTimeslot != null && <div>{toTimeslot.name}</div>}
      </div>
      <div className="mb-sm-3">
        <strong>Outside Boundary Reason</strong>
        <div className="mb-sm-3">
          {selectedItem ? selectedItem.outsideBoundaryReason : "-"}
        </div>
        <strong>Reason for Transfer</strong>
        <div className="mb-sm-3">
          {selectedItem ? selectedItem.transferReason : "-"}
        </div>
        {external && (
          <>
            <strong>Transfer Letter</strong>
            <br />
            {selectedItem && (
              <Button
                variant="secondary"
                className="text-wrap text-dark"
                style={{
                  backgroundColor: "#f3f3f3",
                  borderColor: "#f3f3f3",
                }}
                onClick={() => {
                  downloadCert(
                    {
                      path: selectedItem.registration.transferLetterPath,
                      name: selectedItem.registration.transferLetterName,
                    },
                    user
                  );
                }}
              >
                <AttachFileOutlinedIcon
                  className="mr-1"
                  style={{ color: "#7c7c7c" }}
                />
                {selectedItem.registration.transferLetterPath
                  ? selectedItem.registration.transferLetterName
                  : "—"}
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
}
