import React from "react";

function AgreeMyCatholicSG() {
  return (
    <span className="text-left pointer">
      I consent to provide my personal data to myCatholicSG for the purpose of
      unique identification.
    </span>
  );
}

export default AgreeMyCatholicSG;
