import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form";
import fire from "../../../services/fire";
import ArchdioceseLogo from "../../blocks/archdioceseLogoBlock";
import ResetSent from "../component/resetSent";
import LoadingSpinner from "../../common/loadingSpinner";
import { logEvent } from "../../../services/log";
import { Link } from "react-router-dom";
import DemoBar from "../../common/demoBar";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

class ForgotPassword extends Form {
  state = {
    data: { email: "" },
    errors: {},
    emailsent: false,
    loading: false,
    noemail: false,
  };

  schema = {
    email: Joi.string().email().required().label("Email"),
  };

  goBack = () => {
    this.setState({ emailsent: false });
  };

  doSubmit = async () => {
    const { email } = this.state.data;
    this.setState({ loading: true });

    let auth = fire.auth();
    try {
      // Call the server

      await auth.sendPasswordResetEmail(email);
      logEvent("send_password_reset_success", {
        email: email,
      });
      this.setState({ loading: false, noemail: false, emailsent: true });
    } catch (error) {
      logEvent("send_password_reset_fail", {
        email: email,
        message: error.message,
      });
      this.setState({ noemail: true, loading: false, emailsent: true });
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { data, emailsent, loading, noemail } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }

    return (
      <React.Fragment>
        <DemoBar />
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-6">
            <div className="pb-2 pointer text-left d-flex justify-content-start align-items-center ">
              <Link
                to="/welcome"
                style={{ textDecoration: "none", marginLeft: "-5px" }}
                className="mt-3 text-dark d-flex align-items-center justify-content-start"
              >
                <ArrowBackIosRoundedIcon fontSize="small" className=" mr-1" />
                <span className="text-dark">Home</span>
              </Link>
            </div>
            <ArchdioceseLogo />
          </div>
        </div>
        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-6">
            <main className="container">
              {emailsent === false && (
                <React.Fragment>
                  <div className="pb-5 pt-3">
                    <h1 className="pb-2">Forgot password?</h1>
                    <form onSubmit={this.handleSubmit}>
                      {this.renderInput("email", "Email", "text", "Your email")}

                      {!loading && this.renderBlockButton("Submit")}
                      {loading &&
                        this.renderLoadingBlockButton("Submitting...")}
                    </form>
                    <div className="my-4 alert alert-primary">
                      Can't remember your email address?
                      <br />
                      <br /> Please write to{" "}
                      <a href={`mailto:mycatholicsg@catholic.org.sg`}>
                        mycatholicsg@catholic.org.sg
                      </a>{" "}
                      with your full name, year of birth & last 2 characters of
                      NRIC/FIN for further assistance.
                    </div>
                  </div>

                  <p className="defaultfontsize text-center">
                    <Link to="/login">Back to Login</Link>
                  </p>
                </React.Fragment>
              )}
              {emailsent === true && noemail === false && (
                <React.Fragment>
                  <ResetSent />
                  <p className="defaultfontsize text-center">
                    <Link to="/login">Back to Login</Link>
                  </p>
                </React.Fragment>
              )}
              {emailsent === true && noemail === true && (
                <React.Fragment>
                  <div className="pb-2 pt-5 text-center">
                    <h1 className="pb-2">Ooops...</h1>

                    <h5 className="pt-2 pb-0">
                      <span className="text-danger">{data.email}</span> not
                      found.
                    </h5>
                    <h5 className="pt-0 pb-2">
                      Please double check if you have used the correct email
                      address.
                    </h5>
                  </div>{" "}
                  <button
                    type="button"
                    onClick={() => this.goBack()}
                    className="btn btn-primary btn-block btn-lg"
                  >
                    Try Again
                  </button>
                  <div className="text-center my-4">
                    <Link to="/login">Log In</Link>
                  </div>
                </React.Fragment>
              )}
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
