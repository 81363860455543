import React, {
  useMemo,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
} from "react";
import { useSticky } from "react-table-sticky";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import FormCheck from "react-bootstrap/FormCheck";

import {
  useTable,
  useResizeColumns,
  useGlobalFilter,
  useFlexLayout,
  useSortBy,
  usePagination,
  useRowSelect,
} from "react-table";
import { Column, UseSortByState } from "react-table";
import "./table.css";

const IndeterminateCheck = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <FormCheck custom ref={resolvedRef} {...rest} />
    </>
  );
});

/**
 * @typedef TableProps
 * @prop {string} [id] Unique ID for the table. Necessary if using multiple tables at once with row selection.
 * @prop {readonly Column<object>[]} columnProps
 * @prop {object[]} dataProps
 * @prop {string[]} hiddenColumnsProps
 * @prop {UseSortByState} sortByProps
 * @prop {string} filterProps
 * @prop {boolean} sortByEnabled
 * @prop {boolean} isLoading
 * @prop {boolean} setFilteredData
 * @prop {boolean} overrideColumnHash
 * @prop {boolean} rowSelectionEnabled
 * @prop {(rows: any[]) => void} updateSelectedRows
 */

/**
 * @param {TableProps} props
 */
const Table = ({
  id = "",
  columnProps = [],
  dataProps = [],
  hiddenColumnsProps = [],
  sortByProps = [],
  filterProps = "",
  styleProps = {},
  sortByEnabled = false,
  isLoading = false,
  setFilteredData = false,
  overrideColumnHash = false,
  rowSelectionEnabled = false,
  updateSelectedRows = () => {},
}) => {
  const columns = useMemo(
    () => [...columnProps],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [overrideColumnHash ? columnProps : JSON.stringify(columnProps)]
  );

  const data = useMemo(
    () => [...dataProps],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(dataProps)]
  );

  const defaultColumn = useMemo(
    () => ({
      width: 100,
      minWidth: 25,
      maxWidth: 150,
    }),
    []
  );

  const updateSelectedRowsMemoised = useCallback(updateSelectedRows, []);

  const hiddenColumns = hiddenColumnsProps;

  const sortBy = useMemo(() => [...sortByProps], [sortByProps]);

  const headerProps = (props, { column }) => getStyles(props, column.align);

  const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

  const getStyles = (props, align = "left") => [
    props,
    {
      style: {
        justifyContent: align === "right" ? "flex-end" : "flex-start",
        display: "flex",
        height: "auto",
        minHeight: "50px",
        ...styleProps,
      },
    },
  ];

  const plugins = [
    useFlexLayout,
    useResizeColumns,
    useGlobalFilter,
    useSortBy,
    usePagination,
  ];

  if (rowSelectionEnabled)
    plugins.push(useRowSelect, (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          width: 50,
          sticky: "left",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheck
                {...getToggleAllPageRowsSelectedProps()}
                color="danger"
                variant="danger"
                className="ml-1"
                id={`${id}-select-all`}
              />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheck
                {...row.getToggleRowSelectedProps()}
                className="ml-2"
                id={`${id}-select-${row.id}`}
                style={{ transform: "scale(1.5)" }}
              />
            </div>
          ),
        },
        ...columns,
      ]);
    });

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns,
        sortBy,
      },
    },
    useSticky,
    ...plugins
  );

  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    setPageSize,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = tableInstance;

  // ScrollTop on page change
  useEffect(() => {
    document.getElementsByClassName("table__body")[0].scrollTop = 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  useEffect(() => {
    if (setFilteredData) {
      const filteredRows = rows;
      if (filteredRows.length > 0) {
        const dataList = [];
        filteredRows.forEach((row) => {
          dataList.push(row.values);
        });
        setFilteredData(dataList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    updateSelectedRowsMemoised(selectedFlatRows.map((row) => row.original));
  }, [selectedFlatRows]);

  const totalEntries = rows.length;
  const currentStartEntry = 1 + pageIndex * pageSize;
  let currentEndEntry = pageSize + pageIndex * pageSize;
  currentEndEntry =
    currentEndEntry > totalEntries ? totalEntries : currentEndEntry;

  useEffect(() => {
    setGlobalFilter(filterProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps]);

  return (
    <div className="table__wrapper sticky">
      <div {...getTableProps()} className="table__container">
        <div className="table__header-container">
          {headerGroups.map((headerGroup) => (
            <div
              {...headerGroup.getHeaderGroupProps({
                // style: { paddingRight: '15px' },
              })}
            >
              {headerGroup.headers.map((column) => (
                <div
                  {...column.getHeaderProps(headerProps)}
                  onClick={() =>
                    sortByEnabled &&
                    !column.disableSortBy &&
                    column.toggleSortBy &&
                    column.toggleSortBy()
                  }
                  className="table__header"
                >
                  <div className="table__header-text">
                    {column.render("Header")}
                  </div>
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDropDownIcon />
                      ) : (
                        <ArrowDropUpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? "isResizing" : ""
                      }`}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="table__body">
          {isLoading ? (
            <div className="table__body-no-results">Loading ...</div>
          ) : totalEntries > 0 ? (
            page.map((row) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="table__row">
                  {row.cells.map((cell) => {
                    return (
                      <div
                        {...cell.getCellProps(cellProps)}
                        className="table__data"
                      >
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <div className="table__body-no-results">No results available</div>
          )}
        </div>
      </div>
      <div className="table__pagination">
        <div className="table__pagination-size">
          <div>Rows per page:</div>
          <select
            className="table__pagination-select"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 25, 50, 100, 200].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="table__pagination-count">
          {currentStartEntry} - {currentEndEntry} of {totalEntries} entries
        </div>
        <div className="table__pagination-direction">
          <ArrowBackIosIcon
            className={
              canPreviousPage
                ? "table__pagination-btn"
                : "table__pagination-btn--disabled"
            }
            onClick={() => canPreviousPage && previousPage()}
          />
          <ArrowForwardIosIcon
            className={
              canNextPage
                ? "table__pagination-btn"
                : "table__pagination-btn--disabled"
            }
            onClick={() => canNextPage && nextPage()}
          />
        </div>
      </div>
    </div>
  );
};

export default Table;
