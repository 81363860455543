import {
  getEventDate,
  getValidSaturdayDate,
  getValidSundayDate,
  isSaturdayMass,
  isWeekEndMass,
  getFirstLastDay,
} from "../utils/date";
import { getParishByCode } from "./parish";
import { getOrgByCode } from "./organisation";
import fire from "./fire";

export async function getUserBookingById(parishionerid, eventid) {
  const db = fire.firestore();
  const parishionerbookings = db
    .collection("parishionerbookings")
    .where("massid", "==", eventid)
    .where("parishionerid", "==", parishionerid);

  const result = await parishionerbookings.get();
  const booking = [];
  let counter = 0;
  result.forEach(function (doc) {
    const data = doc.data();
    // console.log(
    //   `getUserBookingById -- User ID: ${parishionerid} Event ID: ${eventid} Count: ${counter}`
    // );
    if (counter === 0) {
      //only save the first one in case there's duplicate booking
      booking.push(data);
    }
    counter++;
  });
  return booking;
}

export function isParishMatch(userparish, massparish) {
  if (parseInt(userparish) === parseInt(massparish)) {
    return true;
  }
  return false;
}

export function isOrgMatch(userorg, roomorg) {
  if (parseInt(userorg) === parseInt(roomorg)) {
    return true;
  }
  return false;
}

export function isEventClosed(event, localtime, cutoff = 48) {
  const { closed, date } = event;
  const eventtime = date.seconds * 1000;
  const hourstoclose = cutoff;
  const hourstoclosesec = hourstoclose * 60 * 60 * 1000;
  let eventclosed = false;
  if (closed) {
    //this mass is marked as "closed" = true in the back end or code
    eventclosed = true;
  } else {
    //NOT EXPLICITLY CLOSED, just to estimate if closed or not
    if (localtime > eventtime - hourstoclosesec) {
      eventclosed = true;
    }
  }
  return eventclosed;
}

export function isEventStillBookable(event, localtime, cutoff = 48) {
  const { date } = event;
  const eventtime = date.seconds * 1000;
  const hourstoclose = cutoff;
  const hourstoclosesec = hourstoclose * 60 * 60 * 1000;
  let bookable = true;
  //NOT EXPLICITLY CLOSED, just to estimate if closed or not
  if (localtime > eventtime - hourstoclosesec) {
    bookable = false;
  }
  return bookable;
}

export function isAllowedToBook(parish, preferredparish) {
  const parishobj = getParishByCode(preferredparish);
  //ALLOW TO BOOK WHEN PARISH MATCHES
  if (parishobj && isParishMatch(parish, parishobj._id)) {
    return true;
  }
  return false;
}

export function isAllowedToBookRoom(org, preferredorg) {
  const orgobj = getOrgByCode(preferredorg);
  //ALLOW TO BOOK WHEN ORG MATCHES
  if (orgobj && isOrgMatch(org, orgobj._id)) {
    return true;
  }
  return false;
}

export function getUniqueWeekdayBookings(bookings) {
  let uniqueBookedDates = [];
  //iterate the entire bookings and get the dates
  if (bookings && bookings.length > 0) {
    for (let i = 0; i < bookings.length; i++) {
      const date = bookings[i].massdate;
      //skip weekend
      if (isWeekEndMass(date)) {
        continue;
      }
      //only process weekday
      const labeldate = getEventDate(date);
      if (uniqueBookedDates.indexOf(labeldate) === -1) {
        //push ONLY if it does not exists
        uniqueBookedDates.push(labeldate);
      }
    }
  }
  //get the unique dates
  return uniqueBookedDates;
}

export function getUniqueWeekendBookings(bookings) {
  let uniqueBookedDates = [];
  //iterate the entire bookings and get the dates
  if (bookings && bookings.length > 0) {
    for (let i = 0; i < bookings.length; i++) {
      const date = bookings[i].massdate;
      //skip weekday
      if (!isWeekEndMass(date)) {
        continue;
      }
      //only process weekend
      /*
       ** NOTE: if the booking is Saturday, do some magic
       ** try to add 1 day and if +1 day is still the same month,
       ** it is Sunday, also add Sunday
       ** OR
       ** if Sunday, also add Saturday
       */
      const labeldate = getEventDate(date);
      if (uniqueBookedDates.indexOf(labeldate) === -1) {
        //push ONLY if it does not exists
        uniqueBookedDates.push(labeldate);
        //if Saturday
        if (isSaturdayMass(date)) {
          //try +1 and check if still the same month
          const validsunday = getValidSundayDate(date);
          if (validsunday) {
            //we force to add Sunday
            //this means, if he has booked weekendmass that falls on a SATURDAY, ALSO MARK SUNDAY (+1)
            //because we don't allow to go on a mass on both saturday and sunday on the same weekend
            uniqueBookedDates.push(validsunday);
          }
        } else {
          //this is a Sunday
          //try -1 and check if still the same month
          const validsaturday = getValidSaturdayDate(date);
          if (validsaturday) {
            //we force to add Saturday
            //this means, if he has booked weekendmass that falls on a SUNDAY, ALSO MARK SATURDAY (-1)
            //because we don't allow to go on a mass on both saturday and sunday on the same weekend
            uniqueBookedDates.push(validsaturday);
          }
        }
      }
    }
  }
  //get the unique dates
  return uniqueBookedDates;
}

export function getTotalBookedWeekendMass(bookings) {
  let count = 0;
  for (let item = 0; item < bookings.length; item++) {
    let weekend = isWeekEndMass(bookings[item].massdate);
    if (weekend === true) {
      count++;
    }
  }
  return count;
}

export function getTotalBookedWeekdayMass(bookings) {
  let count = 0;
  for (let item = 0; item < bookings.length; item++) {
    let weekend = isWeekEndMass(bookings[item].massdate);
    if (weekend === false) {
      count++;
    }
  }
  return count;
}

export function getMonthlyEventBookings(
  bookings,
  preferredYear,
  preferredMonth
) {
  const actualdate = `${preferredMonth} 01 ${preferredYear}`;
  let date = new Date(actualdate);
  let y = date.getFullYear();
  let m = date.getMonth();
  let firstDay = getFirstLastDay(m, y);
  let lastDay = getFirstLastDay(m, y, 1);
  firstDay = firstDay.getTime() / 1000;
  lastDay = lastDay.getTime() / 1000;

  let list = [];

  for (let item = 0; item < bookings.length; item++) {
    let massdate = bookings[item].massdate.seconds;
    //skip if beyond the range
    if (massdate >= firstDay && massdate <= lastDay) {
      list.push(bookings[item]);
    }
  }
  return list;
}

export function getSortedEventBookings(eventbookings) {
  let bookingobj = {};
  if (eventbookings.hasOwnProperty("massparishid")) {
    /*
     ** This means parishioner has booked before we allow multiple bookings, NO NEED TO SORT
     */
    bookingobj = [eventbookings];
  } else {
    //SORT BOOKINGS HERE
    bookingobj = eventbookings.sort(datecompare);
  }
  return bookingobj;
}

function datecompare(a, b) {
  if (a.massdate.seconds > b.massdate.seconds) {
    return -1;
  }
  if (a.massdate.seconds < b.massdate.seconds) {
    return 1;
  }
  return 0;
}
