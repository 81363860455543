export function getAllParishes() {
  return [
    { _id: "All Churches", name: "All Churches" },
    {
      _id: "Blessed Sacrament Church",
      name: "Blessed Sacrament Church",
    },
    {
      _id: "Cathedral of the Good Shepherd",
      name: "Cathedral of the Good Shepherd",
    },
    {
      _id: "Christ the King",
      name: "Christ the King",
    },
    {
      _id: "Divine Mercy",
      name: "Divine Mercy",
    },
    {
      _id: "Holy Cross",
      name: "Holy Cross",
    },
    {
      _id: "Holy Family",
      name: "Holy Family",
    },
    {
      _id: "Holy Spirit",
      name: "Holy Spirit",
    },
    {
      _id: "Holy Trinity",
      name: "Holy Trinity",
    },
    {
      _id: "Immaculate Heart of Mary",
      name: "Immaculate Heart of Mary",
    },
    {
      _id: "Lady of Lourdes",
      name: "Lady of Lourdes",
    },
    {
      _id: "Nativity of the Blessed Virgin Mary",
      name: "Nativity of the Blessed Virgin Mary",
    },
    {
      _id: "Our Lady Queen of Peace",
      name: "Our Lady Queen of Peace",
    },
    {
      _id: "Our Lady Star of the Sea",
      name: "Our Lady Star of the Sea",
    },
    {
      _id: "Our Lady of Perpetual Succour",
      name: "Our Lady of Perpetual Succour",
    },
    {
      _id: "Risen Christ",
      name: "Risen Christ",
    },
    {
      _id: "Sacred Heart",
      name: "Sacred Heart",
    },
    {
      _id: "St Alphonsus (Novena Church)",
      name: "St Alphonsus (Novena Church)",
    },
    {
      _id: "St Anne's Church",
      name: "St Anne's Church",
    },
    {
      _id: "St Anthony",
      name: "St Anthony",
    },
    {
      _id: "St Bernadette",
      name: "St Bernadette",
    },
    {
      _id: "St Francis Xavier",
      name: "St Francis Xavier",
    },
    {
      _id: "St Francis of Assisi",
      name: "St Francis of Assisi",
    },
    {
      _id: "St Ignatius",
      name: "St Ignatius",
    },
    {
      _id: "St Joseph's Church (Bukit Timah)",
      name: "St Joseph's Church (Bukit Timah)",
    },
    {
      _id: "St Joseph's Church (Victoria Street)",
      name: "St Joseph's Church (Victoria Street)",
    },
    {
      _id: "St Mary of the Angels",
      name: "St Mary of the Angels",
    },
    {
      _id: "St Michael",
      name: "St Michael",
    },
    {
      _id: "St Stephen",
      name: "St Stephen",
    },
    {
      _id: "St Teresa",
      name: "St Teresa",
    },
    {
      _id: "St Vincent de Paul",
      name: "St Vincent de Paul",
    },
    {
      _id: "Sts Peter and Paul",
      name: "Sts Peter and Paul",
    },
    {
      _id: "Transfiguration",
      name: "Transfiguration",
    },
  ];
}

export function getParishMap() {
  return [
    {
      _id: "Blessed Sacrament Church",
      name: "Blessed Sacrament Church",
    },
    {
      _id: "Cathedral of the Good Shepherd",
      name: "Cathedral of the Good Shepherd",
    },
    {
      _id: "Christ the King",
      name: "Christ the King",
    },
    {
      _id: "Divine Mercy",
      name: "Divine Mercy",
    },
    {
      _id: "Holy Cross",
      name: "Holy Cross",
    },
    {
      _id: "Holy Family",
      name: "Holy Family",
    },
    {
      _id: "Holy Spirit",
      name: "Holy Spirit",
    },
    {
      _id: "Holy Trinity",
      name: "Holy Trinity",
    },
    {
      _id: "Immaculate Heart of Mary",
      name: "Immaculate Heart of Mary",
    },
    {
      _id: "Lady of Lourdes",
      name: "Lady of Lourdes",
    },
    {
      _id: "Nativity of the Blessed Virgin Mary",
      name: "Nativity of the Blessed Virgin Mary",
    },
    {
      _id: "Our Lady Queen of Peace",
      name: "Our Lady Queen of Peace",
    },
    {
      _id: "Our Lady Star of the Sea",
      name: "Our Lady Star of the Sea",
    },
    {
      _id: "Our Lady of Perpetual Succour",
      name: "Our Lady of Perpetual Succour",
    },
    {
      _id: "Risen Christ",
      name: "Risen Christ",
    },
    {
      _id: "Sacred Heart",
      name: "Sacred Heart",
    },
    {
      _id: "St Alphonsus (Novena Church)",
      name: "St Alphonsus (Novena Church)",
    },
    {
      _id: "St Anne's Church",
      name: "St Anne's Church",
    },
    {
      _id: "St Anthony",
      name: "St Anthony",
    },
    {
      _id: "St Bernadette",
      name: "St Bernadette",
    },
    {
      _id: "St Francis Xavier",
      name: "St Francis Xavier",
    },
    {
      _id: "St Francis of Assisi",
      name: "St Francis of Assisi",
    },
    {
      _id: "St Ignatius",
      name: "St Ignatius",
    },
    {
      _id: "St Joseph's Church (Bukit Timah)",
      name: "St Joseph's Church (Bukit Timah)",
    },
    {
      _id: "St Joseph's Church (Victoria Street)",
      name: "St Joseph's Church (Victoria Street)",
    },
    {
      _id: "St Mary of the Angels",
      name: "St Mary of the Angels",
    },
    {
      _id: "St Michael",
      name: "St Michael",
    },
    {
      _id: "St Stephen",
      name: "St Stephen",
    },
    {
      _id: "St Teresa",
      name: "St Teresa",
    },
    {
      _id: "St Vincent de Paul",
      name: "St Vincent de Paul",
    },
    {
      _id: "Sts Peter and Paul",
      name: "Sts Peter and Paul",
    },
    {
      _id: "Transfiguration",
      name: "Transfiguration",
    },
  ];
}

export function getMapAllParishes() {
  return {
    1: "Blessed Sacrament Church",
    2: "Cathedral of the Good Shepherd",
    3: "Christ the King",
    4: "Divine Mercy",
    5: "Holy Cross",
    6: "Holy Family",
    7: "Holy Spirit",
    8: "Holy Trinity",
    9: "Immaculate Heart of Mary",
    10: "Lady of Lourdes",
    11: "Nativity of the Blessed Virgin Mary",
    12: "Our Lady of Perpetual Succour",
    13: "Our Lady Queen of Peace",
    14: "Our Lady Star of the Sea",
    15: "Risen Christ",
    16: "Sacred Heart",
    17: "St Alphonsus (Novena Church)",
    18: "St Anne's Church",
    19: "St Anthony",
    20: "St Bernadette",
    21: "St Francis of Assisi",
    22: "St Francis Xavier",
    23: "St Ignatius",
    24: "St Joseph's Church (Bukit Timah)",
    25: "St Joseph's Church (Victoria Street)",
    26: "St Mary of the Angels",
    27: "St Michael",
    28: "St Stephen",
    29: "St Teresa",
    30: "St Vincent de Paul",
    31: "Sts Peter and Paul",
    32: "Transfiguration",
  };
}
