import React, { useContext, useEffect } from "react";
import fire from "../../../services/fire";
import { QR_COLUMNS } from "../reducers/qrReducer";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CreateQRModal from "./qrmodal/CreateQRModal";
import QRDetailModal from "./qrmodal/QRDetailModal";
import Table from "../../common/table";

import "../stylesheets/qr-page.css";
import FinanceContext from "./financeContext";

const QRPage = () => {
  const { qrDashboardState, qrDashboardDispatch, financeDashboardState } =
    useContext(FinanceContext);
  const { campaignList, filterSelection, isCampaignListReady, searchKey } =
    qrDashboardState;
  const { org } = financeDashboardState;
  useEffect(() => {
    const getUserName = async (userId) => {
      const userRef = await fire
        .firestore()
        .collection("users")
        .doc(userId)
        .get();
      const userObj = userRef.data();
      return userObj.fullname;
    };

    const retrieveCampaign = async (doc, cb) => {
      const campaignData = doc.data();
      let createdByName,
        approvedByName = "";
      const { createdBy, approvalBy } = campaignData;

      createdByName = await getUserName(createdBy);
      if (approvalBy) {
        approvedByName = await getUserName(approvalBy);
      }

      const campaign = {
        ...campaignData,
        createdByName,
        approvedByName,
        action: (
          <VisibilityIcon
            className="qr-page__table-view-icon"
            onClick={() =>
              qrDashboardDispatch({
                type: "SET_DETAIL_MODAL_OPEN",
                payload: {
                  campaignModalDetails: campaignData,
                },
              })
            }
          />
        ),
      };
      cb(campaign);
    };

    const payplusCampaignSub = fire
      .firestore()
      .collection("paypluscampaigns")
      .where("orgId", "==", org.orgId)
      .onSnapshot((documentSnapshot) => {
        const newCampaignList = [];
        const promiseList = [];
        if (!documentSnapshot.empty) {
          documentSnapshot.docs.forEach((doc) => {
            promiseList.push(
              retrieveCampaign(doc, (result) => {
                newCampaignList.push(result);
              })
            );
          });
        }

        Promise.allSettled(promiseList).then(() => {
          qrDashboardDispatch({
            type: "SET_CAMPAIGN_LIST",
            payload: {
              campaignList: newCampaignList,
            },
          });
        });
      });

    return () => payplusCampaignSub();
    // Run effect once on component mount, recheck if deps updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectFilter = (filterSelection = 0) => {
    qrDashboardDispatch({
      type: "SELECT_FILTER",
      payload: { filterSelection },
    });
  };

  const isSelected = (selection) => {
    return filterSelection === selection
      ? "qr-page__table-filter--selected"
      : "qr-page__table-filter";
  };

  const getSelectedList = () => {
    const now = new Date().getTime();
    switch (filterSelection) {
      case 0:
        return campaignList;
      case 1:
        const activeList = [];
        campaignList.forEach((campaign) => {
          if (campaign.approvalStatus === 1) {
            if (!campaign.expiry) {
              activeList.push(campaign);
            } else {
              const campaignExpiry = campaign.expiry.toDate().getTime();
              if (campaignExpiry > now) {
                activeList.push(campaign);
              }
            }
          }
        });
        return activeList;
      case 2:
        const pendingList = [];
        campaignList.forEach((campaign) => {
          if (campaign.approvalStatus === 0) {
            pendingList.push(campaign);
          }
        });
        return pendingList;
      case 3:
        const expiredList = [];
        campaignList.forEach((campaign) => {
          if (
            campaign.approvalStatus === -1 ||
            campaign.approvalStatus === -2
          ) {
            expiredList.push(campaign);
          } else if (campaign.expiry) {
            const campaignExpiry = campaign.expiry.toDate().getTime();
            if (campaignExpiry < now) {
              expiredList.push(campaign);
            }
          }
        });
        return expiredList;
      default:
        return campaignList;
    }
  };

  return (
    <div className="qr-page__container">
      <CreateQRModal />
      <QRDetailModal />
      <div className="qr-page__header">
        QR Dashboard
        <div
          className="qr-page__add-qr"
          onClick={() => qrDashboardDispatch({ type: "SET_CREATE_MODAL_OPEN" })}
        >
          <AddCircleOutlineIcon className="qr-page__add-icon" />
          New QR
        </div>
      </div>
      <div className="qr-page__table-controls">
        <div className="qr-page__table-filters">
          <div className={isSelected(0)} onClick={() => selectFilter(0)}>
            All QR Campaigns
          </div>
          <div className={isSelected(1)} onClick={() => selectFilter(1)}>
            Active
          </div>
          <div className={isSelected(2)} onClick={() => selectFilter(2)}>
            Pending Approval
          </div>
          <div className={isSelected(3)} onClick={() => selectFilter(3)}>
            Expired / Rejected
          </div>
        </div>
        <div className="qr-page__table-controls-section">
          <input
            className="qr-page__search-input"
            placeholder="Search ..."
            value={searchKey}
            onChange={(e) => {
              qrDashboardDispatch({
                type: "SET_SEARCH_KEY",
                payload: {
                  searchKey: e.target.value,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="qr-page__table-body">
        <Table
          columnProps={QR_COLUMNS}
          dataProps={getSelectedList()}
          isLoading={!isCampaignListReady}
          filterProps={searchKey}
        />
      </div>
    </div>
  );
};

export default QRPage;
