import React from "react";

import OfferingGiving from "../../booking/component/offeringGiving";
import Footer from "../../common/footer";
import HomeMenu from "../component/homeMenu";
import LandingBanner from "../component/landingBanner";
import Socials from "../component/socials";
import InvisibleComponents from "../../common/InvisibleComponents";
import DownloadLinks from "../../common/downloadLinks";
// import PapalMassBanner from "../../papalmassballot/component/papalMassBanner";

function LandingPage() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        className="row text-center py-4"
        style={{ backgroundColor: "#cce5ff" }}
      >
        <LandingBanner />
      </div>
      {/* <div className="container mx-auto col-lg-6 pt-4 pb-0">
        <PapalMassBanner link="/login" />
      </div> */}

      <div className="mt-4 row justify-content-center container col-lg-6 mx-auto">
        <div className="container px-0 pb-4">
          <HomeMenu />
        </div>
      </div>
      <div className="row justify-content-center mx-auto pt-0 pb-4">
        <div className="d-flex flex-column text-center">
          <div className="pb-2">
            Download the app today!{" "}
            <span role="img" aria-label="celebrate">
              🎉
            </span>
          </div>

          <DownloadLinks />
        </div>
      </div>
      <div className="row justify-content-center mx-auto">
        <div className="container">
          <div className="row text-center mx-auto">
            <OfferingGiving />
          </div>
        </div>
      </div>
      <div className="row justify-content-center mx-auto">
        <div className="container mx-auto mt-4 pt-4">
          <Socials />
        </div>
      </div>
      <div className="row px-4">
        <InvisibleComponents />
        <Footer hidelinks={true} />
      </div>
    </div>
  );
}

export default LandingPage;
