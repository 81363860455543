import React, { useEffect, useContext, useRef, useState } from "react";
import fire from "../../../../../services/fire";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";

import AppGlobalContext from "../../../../../AppGlobalContext";

import {
  useWardSelection,
  useGuardian,
  useRegistrationDetails,
} from "../../hooks/hooks";
import { registrationCollectionName } from "../../services/collections";
import { levelRef } from "../../services/levels";
import { registrationStatusNameToStatus } from "../../services/registrationStatus";
import { religionList } from "../../services/resources";

import SaveDraftConfirmationModal from "./SaveDraftConfirmationModal";

function handleSaveData(
  registrationCollection,
  registrationDetails,
  registrationData,
  ward,
  uid,
  setRegistrationStep,
  jumpTo,
  fromReview,
  setFromReview
) {
  if (!uid) {
    return () => {};
  }

  let registrationDoc;

  if (registrationDetails.id) {
    registrationDoc = registrationCollection.doc(registrationDetails.id);

    const docData = {
      ...registrationDetails,
      ...registrationData,
    };

    registrationDoc.update(docData).then(() => {
      if (jumpTo > 0) {
        if (fromReview) {
          setRegistrationStep(7);
          setFromReview(false);
        } else {
          setRegistrationStep(jumpTo);
        }

        window.scrollTo(0, 0);
      }
    });
  } else {
    registrationDoc = registrationCollection.doc();

    const docData = {
      id: registrationDoc.id,
      status: registrationStatusNameToStatus("unregistered"),
      submittedAt: new Date(),
      submittedBy: uid,
      child: {
        name: ward.name,
        uid: ward.uid,
      },
      payments: [],
      ...registrationData,
    };

    registrationDoc.set(docData).then(() => {
      if (jumpTo > 0) {
        if (fromReview) {
          setRegistrationStep(7);
          setFromReview(false);
        } else {
          setRegistrationStep(jumpTo);
        }

        window.scrollTo(0, 0);
      }
    });
  }
}

export default function MiscellaneousDetails({
  registrationStep,
  setRegistrationStep,
  fromReview,
  setFromReview,
}) {
  const registrationDetails = useRegistrationDetails();

  const { user } = useContext(AppGlobalContext);
  const { selectedWardIndex } = useWardSelection();
  const { wardsData } = useGuardian();

  const ward = wardsData[selectedWardIndex];

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);

  const {
    register,
    watch,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fatherReligion: "",
      motherReligion: "",
      school: "",
      schoolLevel: "",
      additionalInformation: "",
    },
  });

  const effectTriggeredRef = useRef(false);

  useEffect(() => {
    if (!effectTriggeredRef.current && registrationDetails.id) {
      effectTriggeredRef.current = true;
      reset({
        fatherReligion: registrationDetails.fatherReligion || "",
        motherReligion: registrationDetails.motherReligion || "",
        school: registrationDetails.school || "",
        schoolLevel: registrationDetails.schoolLevel || "",
        additionalInformation: registrationDetails.additionalInformation || "",
      });
    }

    return () => {
      effectTriggeredRef.current = false;
      reset();
    };
  }, [registrationDetails]);

  function onSubmit() {
    const registrationData = getValues();

    handleSaveData(
      registrationCollection,
      registrationDetails,
      registrationData,
      ward,
      user.uid,
      setRegistrationStep,
      registrationStep + 1,
      fromReview,
      setFromReview
    );
  }

  function onSaveDraft() {
    const registrationData = getValues();

    handleSaveData(
      registrationCollection,
      registrationDetails,
      registrationData,
      ward,
      user.uid,
      setRegistrationStep,
      0,
      fromReview,
      setFromReview
    );
  }

  const additionalInformation = watch("additionalInformation");

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <div
              className="position-absolute p-2 border-bottom border-left"
              style={{
                right: "0px",
                top: "0px",
                borderBottomLeftRadius: "0.25rem",
                backgroundColor: "#dee2e6",
              }}
            >
              4&nbsp;<span className="text-muted">of 4</span>
            </div>
            <h3>Child's Miscellaneous</h3>
          </Container>
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Form.Label className="d-flex flex-row w-100">
                    Father's Religion&nbsp;
                    <span className="text-muted">(optional)</span>
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    as="select"
                    size="lg"
                    {...register("fatherReligion")}
                    isInvalid={!!errors.fatherReligion}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    {religionList.map((v) => (
                      <option key={`fatherreligion-${v}`} value={v}>
                        {v}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="d-flex flex-row w-100">
                    Mother's Religion&nbsp;
                    <span className="text-muted">(optional)</span>
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    as="select"
                    size="lg"
                    {...register("motherReligion")}
                    isInvalid={!!errors.motherReligion}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    {religionList.map((v) => (
                      <option key={`motherreligion-${v}`} value={v}>
                        {v}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">Name of school</Form.Label>
                  <Form.Control
                    size="lg"
                    className="w-100"
                    placeholder="E.g. Holy Innocents' Primary School"
                    {...register("school", { required: true })}
                    isInvalid={!!errors.school}
                  />
                  <span className="text-muted">
                    If not applicable, please indicate "N/A". If waiting for an
                    application for a different school next year to be approved,
                    please indicate the current school.
                  </span>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">Level in school</Form.Label>
                  <Row className="w-100 mx-0">
                    <Col className="px-0" xs={12} sm={6}>
                      <Form.Control
                        className="w-100"
                        size="lg"
                        {...register("schoolLevel", { required: true })}
                        isInvalid={!!errors.schoolLevel}
                      />
                      <span className="text-muted">e.g. P1</span>
                    </Col>
                  </Row>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100 text-wrap">
                    Do you have any additional information to share with
                    us?&nbsp;<span className="text-muted">(optional)</span>
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    className="w-100"
                    as="textarea"
                    maxLength={500}
                    {...register("additionalInformation", { required: false })}
                  />
                  <Row className="w-100 mx-0">
                    <Col className="px-0 text-muted">
                      Medical / Allergy / Diet / Family Issues / Special Needs /
                      etc.
                    </Col>
                    <Col className="px-0 text-muted" xs="auto">
                      {additionalInformation.length}/500
                    </Col>
                  </Row>
                </Form.Row>
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Container className="px-0 mt-4">
        <Row>
          <Col xs={12} sm="auto">
            <Button
              variant="outline-primary"
              size="lg"
              className="w-100"
              onClick={() => {
                setRegistrationStep(registrationStep - 1);

                window.scrollTo(0, 0);
              }}
            >
              Back
            </Button>
          </Col>
          <Col className="d-none d-sm-flex" />
          <Col className="mt-3 mt-sm-0" xs="auto">
            <Button
              variant="outline-primary"
              size="lg"
              className="w-100"
              onClick={() => setShowModal(true)}
            >
              Save Draft
            </Button>
          </Col>
          <Col className="col mt-3 mt-sm-0" sm="auto">
            <Button
              variant="primary"
              size="lg"
              className="w-100"
              onClick={handleSubmit(onSubmit)}
            >
              Next
            </Button>
          </Col>
        </Row>
      </Container>
      {registrationDetails.id && registrationDetails.programmeType ? (
        <Container className="px-0 mt-4 text-wrap text-center">
          If you have enquiries, please contact your Parish Catechist
          Coordinator at&nbsp;
          <span className="text-primary" style={{ cursor: "pointer" }}>
            {registrationDetails.programmeType.enquiryEmail}
          </span>
        </Container>
      ) : (
        <></>
      )}
      <SaveDraftConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        onSaveDraft={onSaveDraft}
      />
    </>
  );
}
