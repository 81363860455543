import React, { useContext, useEffect, useState } from "react";
import fire from "../../../../services/fire";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

import {
  useActiveRegistration,
  useGuardian,
  useWardSelection,
  RegistrationFormContext,
} from "../hooks/hooks";
import { registrationCollectionName } from "../services/collections";
import { isValidPage } from "../services/registrationStatus";

import ForbiddenRoute from "../components/ForbiddenRoute";

import Header from "../../../common/header";

import AppGlobalContext from "../../../../AppGlobalContext";

import FormProgressBar from "../components/FormProgressBar";
import PreRegistrationDetails from "../components/forms/PreRegistrationDetails";
import BasicDetails from "../components/forms/BasicDetails";
import AddressDetails from "../components/forms/AddressDetails";
import SacramentDetails from "../components/forms/SacramentDetails";
import MiscellaneousDetails from "../components/forms/MiscellaneousDetails";
import ParishDetails from "../components/forms/ParishDetails";
import ContactDetails from "../components/forms/ContactDetails";
import Review from "../components/forms/Review";
import Consent from "../components/forms/Consent";
import QuickGuide from "../components/QuickGuide";

function handleGetRegistrationDetails(
  uid,
  setRegistrationForm,
  registrationCollection
) {
  if (!uid) {
    setRegistrationForm({});
    return () => {};
  }

  registrationCollection
    .where("child.uid", "==", uid)
    .onSnapshot((snapshot) => {
      const datum = snapshot.docs[0]?.data();

      if (datum) {
        setRegistrationForm(datum);
      }
    });
}

function RegistrationForm({
  registrationStep,
  setRegistrationStep,
  fromReview,
  setFromReview,
}) {
  switch (registrationStep) {
    case 1:
      return (
        <BasicDetails
          registrationStep={registrationStep}
          setRegistrationStep={setRegistrationStep}
          fromReview={fromReview}
          setFromReview={setFromReview}
        />
      );
    case 2:
      return (
        <AddressDetails
          registrationStep={registrationStep}
          setRegistrationStep={setRegistrationStep}
          fromReview={fromReview}
          setFromReview={setFromReview}
        />
      );
    case 3:
      return (
        <SacramentDetails
          registrationStep={registrationStep}
          setRegistrationStep={setRegistrationStep}
          fromReview={fromReview}
          setFromReview={setFromReview}
        />
      );
    case 4:
      return (
        <MiscellaneousDetails
          registrationStep={registrationStep}
          setRegistrationStep={setRegistrationStep}
          fromReview={fromReview}
          setFromReview={setFromReview}
        />
      );
    case 5:
      return (
        <ParishDetails
          registrationStep={registrationStep}
          setRegistrationStep={setRegistrationStep}
          fromReview={fromReview}
          setFromReview={setFromReview}
        />
      );
    case 6:
      return (
        <ContactDetails
          registrationStep={registrationStep}
          setRegistrationStep={setRegistrationStep}
          fromReview={fromReview}
          setFromReview={setFromReview}
        />
      );
    case 7:
      return (
        <Review
          registrationStep={registrationStep}
          setRegistrationStep={setRegistrationStep}
          setFromReview={setFromReview}
        />
      );
    case 8:
      return (
        <Consent
          registrationStep={registrationStep}
          setRegistrationStep={setRegistrationStep}
        />
      );
    case 0:
    default:
      return (
        <PreRegistrationDetails setRegistrationStep={setRegistrationStep} />
      );
  }
}

export default function RegistrationFormLayout() {
  const { user } = useContext(AppGlobalContext);
  const activeRegistrationState = useActiveRegistration();
  const guardianState = useGuardian();
  const { selectedWardIndex } = useWardSelection();

  const db = fire.firestore();

  /** @type {firestore.CollectionReference<RegistrationDatum>} */
  const registrationCollection = db.collection(registrationCollectionName);

  const statusName = activeRegistrationState.status;

  const isAccessingValidPage = isValidPage({
    isGuardian: guardianState.isGuardian,
    statusName,
    page: "registrationForm",
  });

  const [registrationStep, setRegistrationStep] = useState(0);
  const [registrationDetails, setRegistrationForm] = useState({});
  const [fromReview, setFromReview] = useState(false);

  const { uid } = guardianState.wardsData[selectedWardIndex] ?? {};

  useEffect(() => {
    handleGetRegistrationDetails(
      uid,
      setRegistrationForm,
      registrationCollection
    );
  }, [registrationStep]);

  return (
    <RegistrationFormContext.Provider value={registrationDetails}>
      <div
        className="d-flex flex-column"
        style={{ minHeight: "calc(100vh - 4.5rem)" }}
      >
        <Header type="backandlogo" smallpic={user.photoURL} />
        <Container className="my-4 flex-grow-1 d-flex flex-column">
          <div className="d-flex justify-content-between">
            <h2 className="mb-2">Registration for Catechesis</h2>
            <QuickGuide />
          </div>
          {registrationStep > 0 ? (
            <FormProgressBar
              registrationStep={registrationStep}
              setRegistrationStep={setRegistrationStep}
            />
          ) : (
            <></>
          )}
          {isAccessingValidPage ? (
            <RegistrationForm
              registrationStep={registrationStep}
              setRegistrationStep={setRegistrationStep}
              fromReview={fromReview}
              setFromReview={setFromReview}
            />
          ) : (
            <Card className="flex-grow-1 d-flex flex-column">
              <Card.Body
                className="d-flex flex-column justify-content-center align-items-center py-5"
                style={{ gap: "1.5rem" }}
              >
                <ForbiddenRoute />
              </Card.Body>
            </Card>
          )}
        </Container>
      </div>
    </RegistrationFormContext.Provider>
  );
}
