import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./roomScheduleCalendar.css";

const localizer = momentLocalizer(moment);

export default function RoomScheduleCalendar({ calState, calDispatch }) {
  const minDate = new Date(
    1972,
    0,
    1,
    Math.min(
      6,
      ...calState.dailyCalEvents?.map((event) => event.startDate.getHours() - 1)
    )
  );

  function eventPropGetter(event) {
    const classes = ["rounded-5"],
      style = {
        borderWidth: "3px",
        borderStyle: "solid",
        // background: "#f0f0f0",
        cursor: "pointer",
      };

    if (event.booked) {
      classes.push("bg-success", "border-success", "text-light");
      if (calState.curView === "day") style.cursor = "auto";
    } else if (event.available === 0) {
      classes.push("bg-secondary", "border-secondary", "text-dark");
      style.cursor = "auto";
    } else {
      // Event is enabled
      classes.push(
        "bg-light",
        "border-success",
        "text-dark",
        "selectable-slot"
      );

      if (
        calState.curView === "day" &&
        event.id === calState.selectedEvent?.id
      ) {
        style.filter = "brightness(0.7)";
        style.cursor = "auto";
      }
    }

    return { className: classes.join(" "), style };
  }

  return (
    <div className="w-full">
      <Calendar
        localizer={localizer}
        startAccessor="startDate"
        endAccessor="endDate"
        date={calState.date}
        onNavigate={(date) =>
          calDispatch({ type: "dayViewFromNavigate", payload: { date } })
        }
        views={["month", "day"]}
        view={calState.curView}
        onView={(view) => {
          // console.log("View changed to", view);
          calDispatch({
            type: view === "day" ? "dayViewFromEventSelect" : "monthView",
          });
        }}
        min={minDate}
        events={calState.events}
        titleAccessor="title"
        tooltipAccessor={null}
        resources={calState.resources}
        resourceTitleAccessor="id"
        style={{ height: 800, fontWeight: "bold" }}
        eventPropGetter={eventPropGetter}
        // selectable
        onSelectEvent={(event) =>
          calDispatch({ type: "selectEvent", payload: { event } })
        }
        selected={calState.curView === "day" ? calState.selectedEvent : null}
        step={60}
      />
    </div>
  );
}
