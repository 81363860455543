import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

import { useAllProgrammes } from "../hooks/hooks";

export default function TransferClassModal({
  transferModalIsOpen,
  closeModal,
  selectedItems,
  transferClass,
}) {
  const allProgrammes = useAllProgrammes();
  const { selectedParishId = "", programmeType = "" } = selectedItems[0];
  const [selectedClass, setSelectedClass] = useState("");

  let selectedProgramme = allProgrammes.find(({ parishId, id }) => {
    if (typeof programmeType === "string") {
      return parishId == selectedParishId && id == programmeType;
    } else if (typeof programmeType === "object") {
      return parishId == selectedParishId && id == programmeType.id;
    }
  });

  if (!selectedProgramme) {
    selectedProgramme = {};
  }

  return (
    <Modal
      size="xl"
      show={transferModalIsOpen}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable="true"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Move Class</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-3">
          <div className="col-md-8">
            <span>
              Move the following {selectedItems.length} registrant(s) to:
            </span>
          </div>
          <div className="col-md-4">
            <select
              name="programme"
              id="programme"
              value={selectedClass}
              onChange={({ currentTarget: input }) => {
                setSelectedClass(input.value);
              }}
              className="form-control form-control-sm custom-select custom-select-md"
            >
              {selectedProgramme.timeslots?.map((timeslot) =>
                timeslot.classes.map((d) => {
                  return (
                    <option
                      key={`${d}---${timeslot.name}`}
                      value={`${d}---${timeslot.name}`}
                    >
                      Class {d} - Timeslot {timeslot.name}
                    </option>
                  );
                })
              )}
            </select>
          </div>
        </div>
        <table className="table ">
          <tbody>
            <tr>
              <td>
                <strong>Name</strong>
              </td>
              <td>
                <strong>Current Class</strong>
              </td>
              <td>
                <strong>New Class</strong>
              </td>
            </tr>
            {selectedItems.map((d) => {
              return (
                <tr key={d.id}>
                  <td>{d.child.name ?? ""}</td>
                  <td>{d.assignedClass}</td>
                  <td>{selectedClass.split("---")[0]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => transferClass(selectedItems, selectedClass)}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
