import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form";
import fire from "../../../services/fire";
import LoadingSpinner from "../../common/loadingSpinner";
import ArchdioceseLogo from "../../blocks/archdioceseLogoBlock";
import {
  createWithEmailAndPassword,
  isUserLoggedIn,
} from "../../../services/user";
import { logEvent, logMessage } from "../../../services/log";
import { isValidFullname } from "../../../services/validation";
import RegistrationError from "./registrationError";
import { Link } from "react-router-dom";
import DemoBar from "../../common/demoBar";
import Footer from "../../common/footer";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import AgreeTerms from "../../consent/component/agreeTerms";
import StrongPasswordChecklist from "../../common/strongPasswordChecklist";
import { checkUser } from "../../../services/checkUser";

class RegisterForm extends Form {
  state = {
    passwordValid: false,
    agree: false,
    data: {
      fullname: "",
      email: "",
      password: "",
      checkbox: false,
    },
    errors: {},
    loading: true,
    emailaccountcreationfailed: false,
    accountcreateerror:
      "Something went wrong. Please try to login, reset your password or use another account. If error persists, email us at mycatholicsg@catholic.org.sg",
    unrecoverableerror:
      "We are unable to create your account. Please email us at mycatholicsg@catholic.org.sg",
    emailsenterror:
      "There was a problem sending to your email account. Please try to reset your password or use another account. If error persists, email us at mycatholicsg@catholic.org.sg",
  };

  schema = {
    fullname: Joi.string().trim().required().label("Full Name"),
    email: Joi.string().trim().email().required().label("Email"),
    password: Joi.string().trim().required().min(8).label("Password"),
    checkbox: Joi.boolean()
      .invalid(false)
      .required()
      .label("You must agree before submitting."),
  };

  doSubmit = async () => {
    const { data, errors } = this.state;
    //trim spaces
    const email = data.email.toLowerCase().trim();
    const password = data.password.trim();
    const checkbox = data.checkbox;
    let fullname = data.fullname.trim();

    let error;
    let isvalidfullname = isValidFullname(fullname);

    //check if firstname and last name are empty
    if (fullname === "" || !isvalidfullname) {
      error =
        fullname === ""
          ? { fullname: "Please provide Full Name" }
          : { fullname: "Valid Full Name only. No NRIC or email please." };
      this.setState({
        data,
        errors: error,
        loading: false,
      });
      return;
    }

    this.setState({
      data: {
        email,
        fullname,
        password,
        checkbox,
      },
      errors,
      loading: true,
    });

    try {
      await checkUser(email, "register");
    } catch (e) {
      console.log(e);
    }
    // Call the server
    try {
      let result = await createWithEmailAndPassword(email, password);
      // console.log("Result:", result);
      if (result && result.code === "success") {
        logEvent("create_email_password_success", {
          email: email,
        });
        const user = result.user;
        let userid = user.uid;

        try {
          const uniqNum = new Date().getTime();
          // console.log("Writing to users collection ");
          //Log to user DB and indicate email verified: false
          await fire.firestore().doc(`users/${userid}`).set({
            email,
            fullname,
            created: uniqNum,
            userid: userid,
            hasresetpass: true, //once this feature is released, we assume they have used stronger password
          });
          logEvent("user_account_create_success", {
            email: email,
          });
          try {
            await user.updateProfile({ displayName: fullname });
            await user.sendEmailVerification();
            logEvent("auto_send_email_verification_success", {
              email: email,
            });
            //Route /home will check if email is verified, if not, show Account Verify page
            this.props.history.push("/home");
          } catch (error) {
            logEvent("auto_send_email_verification_fail", {
              email: email,
              message: error.message,
            });
            await logMessage("auto_send_email_verification_fail", {
              email: email,
              code: error.code,
              message: error.message,
            });
            console.error("Error sending email: ", error);

            this.setState({
              emailaccountcreationfailed: true,
              loading: false,
              errors: { email: this.state.emailsenterror },
            });
          }
        } catch (ex) {
          console.error("Error creating email and password: ", ex);
          logEvent("user_account_create_fail", {
            email: email,
            message: ex.message,
          });
          await logMessage("user_account_create_fail", {
            email: email,
            code: ex.code,
            message: ex.message,
          });
          this.setState({
            emailaccountcreationfailed: true,
            loading: false,
            errors: { email: this.state.accountcreateerror },
          });
        }
      } else {
        // console.log("create_email_and_password_fail Error:", result);

        let tempMessage = result.message;

        if (result.user) {
          tempMessage = `${result.message} User: ${result.user.uid}`;
        }
        logEvent("create_email_and_password_fail", {
          email: email,
          message: result.message,
        });
        await logMessage("create_email_and_password_fail", {
          email: email,
          message: tempMessage,
          code: result.code,
        });
        //try to delete if IDBDatabase Error
        if (result.message.indexOf("IDBDatabase") !== -1) {
          logEvent("delete_invalid_auth_account", {
            email: email,
            message: tempMessage,
          });
          await logMessage("delete_invalid_auth_account", {
            email: email,
            message: tempMessage,
            code: "idbdatabase_error",
          });
          const accountValidate = fire
            .functions("asia-east2")
            .httpsCallable("accountValidate");
          let result = await accountValidate({ email: email });
          if (result.data.status === 1) {
            //account deleted due to IDBDatabase Error
            await fire.auth().signOut();
            this.setState({
              registrationerror: true,
              loading: false,
            });
            return;
          }
        }

        this.setState({
          emailaccountcreationfailed: true,
          loading: false,
          errors: result,
        });
      }
    } catch (ex) {
      logEvent("create_email_password_exception_fail", {
        email: email,
        message: ex.message,
      });
      await logMessage("create_email_password_exception_fail", {
        email: email,
        code: ex.code,
        message: ex.message,
      });

      if (ex.hasOwnProperty("code")) {
        // console.log("Errors:", ex);
        let errors;
        if (ex.code === "auth/too-many-requests") {
          errors = { email: ex.message };
        } else {
          errors = {
            email: ex.message,
          };
        }
        this.setState({
          loading: false,
          errors,
        });
      } else {
        this.setState({
          loading: false,
          errors: { email: this.state.unrecoverableerror },
        });
      }
    }
  };

  async componentDidMount() {
    const loggedIn = await isUserLoggedIn();
    // console.log("Logged in: ", loggedIn);
    if (loggedIn) {
      // console.log("Logged in");
      this.props.history.push("/home");
      return;
    }

    this.setState({
      loading: false,
    });
    window.scrollTo(0, 0);
  }

  handleFormSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    if (errors) {
      this.setState({ errors: errors || {} });
      return;
    }
    this.setState({ errors: errors || {} });
    this.doSubmit();
  };

  checkboxLabel = () => <AgreeTerms />;

  render() {
    const { passwordValid, data, loading, registrationerror } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }
    if (registrationerror === true) {
      return <RegistrationError />;
    }

    return (
      <React.Fragment>
        <DemoBar />
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-6">
            <div className="pb-2 pointer text-left d-flex justify-content-start align-items-center ">
              <Link
                to="/welcome"
                style={{ textDecoration: "none", marginLeft: "-5px" }}
                className="mt-3 text-dark d-flex align-items-center justify-content-start"
              >
                <ArrowBackIosRoundedIcon fontSize="small" className=" mr-1" />
                <span className="text-dark">Home</span>
              </Link>
            </div>
            <ArchdioceseLogo />
          </div>
        </div>
        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-6">
            <main className="container">
              <div className="pb-3 pt-3">
                <h1 className="pb-2">Let's sign up</h1>
                <form onSubmit={this.handleSubmit}>
                  {this.renderManualInput(
                    "fullname",
                    "FULL NAME  (as in NRIC/FIN/Passport)",
                    "text",
                    "Your FULL NAME"
                  )}

                  {this.renderManualInput(
                    "email",
                    "Email",
                    "text",
                    "Your email"
                  )}
                  {this.renderManualPasswordInput(
                    "password",
                    "Password",
                    "password",
                    "8 characters or more"
                  )}
                  <StrongPasswordChecklist
                    password={data["password"]?.trim()}
                    validHandler={(valid) => {
                      this.setState({ passwordValid: valid });
                    }}
                  />
                  <div className="p-2 pb-4 text-left">
                    {this.renderCheckBox("checkbox", this.checkboxLabel())}
                  </div>

                  {!loading &&
                    this.state.data["checkbox"] === false &&
                    this.renderDisabledBlockButton("Sign Up")}
                  {!loading &&
                    passwordValid &&
                    this.state.data["checkbox"] === true &&
                    this.renderBlockButton("Sign Up")}
                  {!loading &&
                    !passwordValid &&
                    this.state.data["checkbox"] === true &&
                    this.renderDisabledBlockButton("Sign Up")}
                  {loading &&
                    this.state.data["checkbox"] === true &&
                    this.renderLoadingBlockButton("Signing up...")}
                </form>
              </div>

              <div className="pt-2 pb-5 text-center">
                <p className="defaultfontsize">
                  <span className="text-danger">Existing user?</span>{" "}
                  <Link to="/login">Log in</Link>
                </p>
                <Footer hidelinks={true} />
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegisterForm;
