import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import CheckIcon from '@material-ui/icons/Check';

export default function FormProgressBar({ registrationStep, setRegistrationStep }) {
  let currentName = "";

  switch (registrationStep) {
    case 1:
    case 2:
    case 3:
    case 4:
      currentName = "Child's Details";
      break;
    case 5:
      currentName = "Parish of Interest";
      break;
    case 6:
      currentName = "Contact Details";
      break;
    case 7:
      currentName = "Review";
      break;
    case 8:
      currentName = "e-Consent";
      break;
    default:
  }

  return (
    <>
      <Row className="mx-0 mb-3 d-none d-sm-flex" />
      <Row className="mx-0 d-none d-sm-flex">
        <Col className="px-0">
          <Col className="d-flex justify-content-center px-0">
            <Button className={`rounded-circle p-0 ${registrationStep > 0 ? "btn-primary" : "btn-secondary"}`}
              style={{ width: "30px", height: "30px" }}
              onClick={() => {
                if (registrationStep !== 1)
                  setRegistrationStep(1);
              }}>
              {registrationStep > 4 ? <CheckIcon /> : 1}
            </Button>
          </Col>
          <div className={`position-relative ${registrationStep > 4 ? "border-primary" : "border-secondary"}`}
            style={{ zIndex: -1, borderStyle: "solid", borderWidth: "2px", left: "50%", bottom: "50%" }} />
        </Col>
        <Col className="px-0">
          <Col className="d-flex justify-content-center px-0">
            <Button className={`rounded-circle p-0 ${registrationStep > 4 ? "btn-primary" : "btn-secondary"}`}
              style={{ width: "30px", height: "30px" }}
              onClick={() => {
                if (registrationStep > 5)
                  setRegistrationStep(5);
              }}>
              {registrationStep > 5 ? <CheckIcon /> : 2}
            </Button>
          </Col>
          <div className={`position-relative ${registrationStep > 5 ? "border-primary" : "border-secondary"}`}
            style={{ zIndex: -1, borderStyle: "solid", borderWidth: "2px", left: "50%", bottom: "50%" }} />
        </Col>
        <Col className="px-0">
          <Col className="d-flex justify-content-center px-0">
            <Button className={`rounded-circle p-0 ${registrationStep > 5 ? "btn-primary" : "btn-secondary"}`}
              style={{ width: "30px", height: "30px" }}
              onClick={() => {
                if (registrationStep > 6)
                  setRegistrationStep(6);
              }}>
              {registrationStep > 6 ? <CheckIcon /> : 3}
            </Button>
          </Col>
          <div className={`position-relative ${registrationStep > 6 ? "border-primary" : "border-secondary"}`}
            style={{ zIndex: -1, borderStyle: "solid", borderWidth: "2px", left: "50%", bottom: "50%" }} />
        </Col>
        <Col className="px-0">
          <Col className="d-flex justify-content-center px-0">
            <Button className={`rounded-circle p-0 ${registrationStep > 6 ? "btn-primary" : "btn-secondary"}`}
              style={{ width: "30px", height: "30px" }}
              onClick={() => {
                if (registrationStep > 7)
                  setRegistrationStep(7);
              }}>
              {registrationStep > 7 ? <CheckIcon /> : 4}
            </Button>
          </Col>
          <div className={`position-relative ${registrationStep > 7 ? "border-primary" : "border-secondary"}`}
            style={{ zIndex: -1, borderStyle: "solid", borderWidth: "2px", left: "50%", bottom: "50%" }} />
        </Col>
        <Col className="d-flex justify-content-center px-0">
          <Button className={`rounded-circle p-0 ${registrationStep > 7 ? "btn-primary" : "btn-secondary"}`}
            style={{ width: "30px", height: "30px" }}>
            5
          </Button>
        </Col>
      </Row>
      <Row className="mx-0 mb-3 d-none d-sm-flex">
        <Col className={`d-flex justify-content-center px-0 ${registrationStep > 0 && registrationStep < 5 ? "" : "text-muted"} text-nowrap`}>
          Child's Details
        </Col>
        <Col className={`d-flex justify-content-center px-0 ${registrationStep === 5 ? "" : "text-muted"} text-nowrap`}>
          Parish of Interest
        </Col>
        <Col className={`d-flex justify-content-center px-0 ${registrationStep === 6 ? "" : "text-muted"} text-nowrap`}>
          Contact Details
        </Col>
        <Col className={`d-flex justify-content-center px-0 ${registrationStep === 7 ? "" : "text-muted"} text-nowrap`}>
          Review
        </Col>
        <Col className={`d-flex justify-content-center px-0 ${registrationStep === 8 ? "" : "text-muted"} text-nowrap`}>
          e-Consent
        </Col>
      </Row>
      {/* ----- SMALL SCREENS ----- */}
      <Row className="mx-0 mt-4 mb-3 d-flex d-sm-none">{currentName}</Row>
      <Row className="mx-0 d-flex d-sm-none">
        <Col className="px-0">
          <Button className={`rounded-circle position-relative p-0 ${registrationStep > 0 ? "btn-primary" : "btn-secondary"}`}
            style={{ width: "30px", height: "30px", zIndex: 1 }}
            onClick={() => {
              if (registrationStep !== 1)
                setRegistrationStep(1);
            }}>
            {registrationStep > 4 ? <CheckIcon /> : 1}
          </Button>
          <div className={`position-relative ${registrationStep > 4 ? "border-primary" : "border-secondary"}`}
            style={{ zIndex: -1, width: "62.5%", borderStyle: "solid", borderWidth: "2px", left: "12.5%", bottom: "50%" }} />
        </Col>
        <Col className="px-0">
          <Col className="d-flex justify-content-center px-0">
            <Button className={`rounded-circle position-relative p-0 ${registrationStep > 5 ? "btn-primary" : "btn-secondary"}`}
              style={{ width: "30px", height: "30px", zIndex: 1 }}
              onClick={() => {
                if (registrationStep > 6)
                  setRegistrationStep(6);
              }}>
              {registrationStep > 6 ? <CheckIcon /> : 3}
            </Button>
          </Col>
          <div className={`position-relative ${registrationStep > 6 ? "border-primary" : "border-secondary"}`}
            style={{ zIndex: -1, width: "62.5%", borderStyle: "solid", borderWidth: "2px", left: "55%", bottom: "50%" }} />
        </Col>
        <Col className="d-flex justify-content-end px-0">
          <Button className={`rounded-circle position-relative p-0 ${registrationStep > 7 ? "btn-primary" : "btn-secondary"}`}
            style={{ width: "30px", height: "30px", zIndex: 1 }}>
            5
          </Button>
        </Col>
      </Row>
      <Row className="mx-0 d-flex d-sm-none"
        style={{ marginTop: "-34px", paddingLeft: "15px", paddingRight: "15px" }}>
        <Col className="px-0">
          <Col className="d-flex justify-content-center px-0">
            <Button className={`rounded-circle p-0 ${registrationStep > 4 ? "btn-primary" : "btn-secondary"}`}
              style={{ width: "30px", height: "30px" }}
              onClick={() => {
                if (registrationStep > 5)
                  setRegistrationStep(5);
              }}>
              {registrationStep > 5 ? <CheckIcon /> : 2}
            </Button>
          </Col>
          <div className={`position-relative ${registrationStep > 5 ? "border-primary" : "border-secondary"}`}
            style={{ zIndex: -1, width: "50%", borderStyle: "solid", borderWidth: "2px", left: "50%", bottom: "50%" }} />
        </Col>
        <Col className="px-0">
          <Col className="d-flex justify-content-center px-0">
            <Button className={`rounded-circle p-0 ${registrationStep > 6 ? "btn-primary" : "btn-secondary"}`}
              style={{ width: "30px", height: "30px" }}
              onClick={() => {
                if (registrationStep > 7)
                  setRegistrationStep(7);
              }}>
              {registrationStep > 7 ? <CheckIcon /> : 4}
            </Button>
          </Col>
          <div className={`position-relative ${registrationStep > 7 ? "border-primary" : "border-secondary"}`}
            style={{ zIndex: -1, width: "50%", borderStyle: "solid", borderWidth: "2px", left: "50%", bottom: "50%" }} />
        </Col>
      </Row>
      <Row className="mx-0 mb-3 d-flex d-sm-none" />
    </>
  );
}
