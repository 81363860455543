import React from "react";
import MainMenuCardSimplePanel from "../../blocks/mainMenuCardPanelSimpleBlock";

function HomeMenu() {
  return (
    <div className="row">
      <div className="col-6">
        <MainMenuCardSimplePanel
          menulink="/schedules"
          menulinklabel="Mass Schedules"
          menuicon="currentcalendar"
          menutype="light"
          isSmall
        />
      </div>
      <div className="col-6">
        <MainMenuCardSimplePanel
          menulink={`/bulletin`}
          menulinklabel="Bulletin"
          menuicon="bulletin"
          menutype="light"
          isSmall
        />
      </div>
      <div className="col-6">
        <MainMenuCardSimplePanel
          menulink="/events"
          menulinklabel="Events Hub"
          menuicon="celebration"
          menutype="light"
          isSmall
        />
      </div>
      <div className="col-6">
        <MainMenuCardSimplePanel
          menulink="/parish/cathedral"
          menulinklabel="Parish Information"
          menuicon="parishinfo"
          menutype="light"
          isSmall
        />
      </div>
      <div className="col-6">
        <MainMenuCardSimplePanel
          menulink="/support/cathedral"
          menulinklabel="Offertory & Giving"
          menuicon="support"
          menutype="light"
          isSmall
        />
      </div>
    </div>
  );
}

export default HomeMenu;
