import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncAccountEdit({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/accountEdit"));
  console.log("Loading", AsyncAccountEdit.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncAccountEdit;
