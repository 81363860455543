import fire from "./fire";

export async function getLastParishChangeHistory(timestamp, uid) {
  if (!uid) {
    return null;
  }
  const db = fire.firestore();

  const snapshot = await db
    .collection(`changelogs`)
    .where("id", "==", uid)
    .orderBy("timestamp", "desc")
    .get();
  if (snapshot) {
    let changehistory = [];
    snapshot.forEach(function (doc) {
      let data = doc.data();
      if (timestamp < data.timestamp) {
        changehistory.push(data);
      }
    });
    return changehistory?.length ? changehistory[0] : null;
  } else {
    return null;
  }
}

export async function getLastParishChangeHistoryFromDate(timestamp, history) {
  if (history) {
    let changehistory = [];
    history.forEach(function (doc) {
      if (timestamp < doc.timestamp) {
        changehistory.push(doc);
      }
    });
    //return only the change parish history based on a given timestamp
    return changehistory?.length ? changehistory[0] : null;
  } else {
    return null;
  }
}

export async function getParishChangeHistory(uid) {
  if (!uid) {
    return null;
  }
  const db = fire.firestore();

  const snapshot = await db
    .collection(`changelogs`)
    .where("id", "==", uid)
    .orderBy("timestamp", "desc")
    .get();
  if (snapshot) {
    const changehistory = [];
    snapshot.forEach(function (doc) {
      changehistory.push(doc.data());
    });
    //return all the change parish history
    return changehistory?.length ? changehistory : null;
  } else {
    return null;
  }
}
