import React from "react";
import Badge from "react-bootstrap/Badge";

import { StatusDictionary } from "../../hooks/hooks";

/**
 * @template {string} Status
 * @typedef StatusPillProps
 * @prop {StatusDictionary<Status>} statusDictionary
 * @prop {Status | number | string} value
 * @prop {Status} name
 */

/**
 * @template {string} Status
 * @param {StatusPillProps<Status>} props
 */
export default function StatusPill({
  statusDictionary,
  value: statusValue,
  name: statusName,
}) {
  try {
    const { bg: variant, adminLabel } =
      statusDictionary[statusName ?? statusValue];
    return (
      <Badge pill variant={variant}>
        {adminLabel.toUpperCase()}
      </Badge>
    );
  } catch (e) {
    // console.log({ status: statusValue, dict: statusDictionary[statusValue] });
    throw e;
  }
}
