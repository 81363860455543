import React, {
  createContext,
  useContext,
  useRef,
  useCallback,
  useEffect,
} from "react";
import {
  RegistrationDatum,
  TransfersDatum,
  ParishConfig,
} from "../../hooks/hooks";

/**
 * @typedef RegistrationState
 * @prop {boolean} exists Whether the document(s) exist(s).
 * @prop {RegistrationDatum[]} data The document data.
 */

/**
 * @typedef TransfersState
 * @prop {boolean} exists Whether the document(s) exist(s).
 * @prop {TransfersDatum[]} data The document data.
 */

/**
 * @typedef MembershipDatum
 * @prop {boolean} isActive If the membership is active.
 * @prop {string} membershipType The type of membership.
 * @prop {string} userid The ID of the user.
 * @prop {string} orgId The ID of the organisation the user is a member of.
 */

/**
 * @typedef MembershipState
 * @prop {boolean} exists Whether the document(s) exist(s).
 * @prop {MembershipDatum} datum The document datum.
 */

/**
 * @typedef QueriedAdminState State of the user that is queried from Firestore. Should not be queried multiple times within CATCH.
 * @prop {RegistrationState?} registrationState Contains registrations.
 * @prop {TransfersState?} outTransfersState Contains outbound transfer requests.
 * @prop {TransfersState?} inTransfersState Contains inbound transfer requests (empty if Overall Admin).
 * @prop {TransfersState?} outExternalTransfersState Contains outbound external transfer requests.
 * @prop {TransfersState?} inExternalTransfersState Contains inbound external transfer requests.
 * @prop {MembershipState?} membershipState Contains membership info.
 */

/**
 * @type {React.Context<QueriedAdminState>}
 */
export const QueriedAdminContext = createContext();

export const useRegistration = () =>
  useContext(QueriedAdminContext).registrationState;
export const useTransfers = () => {
  const queriedAdminContext = useContext(QueriedAdminContext);
  const outState = queriedAdminContext.outTransfersState;
  const inState = queriedAdminContext.inTransfersState;

  /** @type {TransfersState} */
  const transfersState = {
    exists: outState.exists || inState.exists,
    data: [...outState.data, ...inState.data],
  };
  return transfersState;
};
export const useExternalTransfers = () => {
  const queriedAdminContext = useContext(QueriedAdminContext);
  const outState = queriedAdminContext.outExternalTransfersState;
  const inState = queriedAdminContext.inExternalTransfersState;

  /** @type {TransfersState} */
  const externalTransfersState = {
    exists: outState.exists || inState.exists,
    data: [...outState.data, ...inState.data],
  };
  return externalTransfersState;
};
export const useMembership = () =>
  useContext(QueriedAdminContext).membershipState;
export const useAllProgrammes = () =>
  useContext(QueriedAdminContext).allProgrammes;

export function useIsMounted() {
  const isMountedRef = useRef(true);
  const isMounted = useCallback(() => isMountedRef.current, []);

  useEffect(() => {
    return () => void (isMountedRef.current = false);
  }, []);

  return isMounted;
}

export const TableFiltersContext = createContext();
