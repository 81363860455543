import React from "react";
import ScheduleEntry from "./scheduleEntry";
import PreviewScheduleEntry from "./previewScheduleEntry";

function DailySchedules(props) {
  const { details, labelday, labeldate, separator, usage } = props;

  let schedule = <ScheduleEntry {...props} details={details} />;
  if (usage && usage === "preview") {
    schedule = <PreviewScheduleEntry {...props} details={details} />;
  }
  return (
    <>
      {separator}
      <h4>
        <span className="float-left">{labelday}</span>
        <span className="float-right">{labeldate}</span>
      </h4>

      <div className="clearfix"></div>
      {schedule}
    </>
  );
}

export default DailySchedules;
