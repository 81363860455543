import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncCoworkAdminPanel({ ...rest }) {
  const AsyncLoad = loadable(() => import("../admin/page/CoworkAdminPanel"));
  console.log("Loading", AsyncCoworkAdminPanel.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncCoworkAdminPanel;
