import React, { useState, useEffect } from "react";
import fire from "../../../services/fire";
import LoadingSpinner from "../../common/loadingSpinner";
import { getCurrentUserData } from "../../../services/user";
import Header from "../../common/header";
import ParishLogo from "../../parish/parishLogoBlock";
import LinkButton from "../../blocks/linkButtonBlock";
import NameClock from "../../common/nameClock";

const DEFAULT_STATE = {
  isLoading: true,
  userObj: {},
  payments: [],
};

const MyPayPlus = ({ user }) => {
  const [state, setState] = useState({
    ...DEFAULT_STATE,
  });

  const { isLoading, userObj } = state;

  useEffect(() => {
    getCurrentUserData(user.uid).then((newUserObj) => {
      fire
        .firestore()
        .collection("payplusrequests")
        .where("isCompleted", "==", true)
        .where("createdBy", "==", newUserObj.userid)
        .get()
        .then((resultSnapshot) => {
          const successfulPayments = [];
          if (!resultSnapshot.empty) {
            resultSnapshot.docs.forEach((doc) => {
              const paymentObj = doc.data();
              if (paymentObj.paymentReceived.length > 0) {
                const receivedPayments = paymentObj.paymentReceived;
                receivedPayments.forEach((payment) => {
                  let dateReceived = payment.date.toDate().toString();
                  dateReceived = dateReceived.substring(0, 25);
                  successfulPayments.push({
                    dateReceived,
                    campaignName: paymentObj.campaignName,
                    orgName: paymentObj.orgName,
                    fullRef: paymentObj.fullRef,
                    amount: paymentObj.amount,
                  });
                });
              }
            });
          }

          setState({
            ...state,
            isLoading: false,
            userObj: newUserObj,
            payments: successfulPayments,
          });
        });
    });
    // Run effect once on component mount, recheck if deps updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const displayPayments = () => {
    const paymentList = state.payments ?? [];
    const elements = [];
    paymentList.forEach((payment, i) => {
      elements.push(
        <div className="card mb-3 text-center px-4" key={i}>
          <div className="py-4">
            <h5 className="mt-1 mb-0 d-flex flex-row">
              <p className="w-50 mb-1 text-left">{payment.campaignName}</p>
              <p className="w-50 mb-0 text-right">S${payment.amount}</p>
            </h5>
            <h5 className="text-left pb-0 mb-1">{payment.orgName}</h5>
            <div className="float-left text-left text-secondary defaultfontsize">
              <h6 className="pb-0 mb-2">{payment.dateReceived}</h6>
              <p className="pb-0 mb-0">Reference: {payment.fullRef}</p>
            </div>
          </div>
        </div>
      );
    });
    return elements;
  };

  return isLoading ? (
    <>
      <Header type="backandlogo" />
      <LoadingSpinner />
    </>
  ) : (
    <>
      <Header type="backandlogo" />
      <ParishLogo parishId={userObj.parish} />
      <div className="row justify-content-center mx-0">
        <div className="col-lg-6 px-1">
          <main className="container">
            <div className="text-center">
              <NameClock name={userObj.fullname} />
            </div>
            <h3 className="text-left py-3">My Pay✝ History</h3>
            {displayPayments()}
            <div className="pt-2 form-group">
              <LinkButton type="link" link="/home" label="Back to Home" />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default MyPayPlus;
