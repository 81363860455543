import { compareRoomObjForRoomSchedules } from "../utils/compare";
import fire from "./fire";

export async function getMonthlyEventTimings(parish, firstDay, lastDay, type) {
  let masstimings = [];
  const db = fire.firestore();

  const snapshot = await db
    .collection(`mass${parish}`)
    .where("type", "==", type)
    .where("date", ">=", firstDay)
    .where("date", "<=", lastDay)
    .orderBy("date", "asc")
    .get();

  snapshot.forEach(function (doc) {
    masstimings.push(doc.data());
  });

  return masstimings;
}

export async function getMonthlyMassTimings(parish, firstDay, lastDay) {
  let masstimings = [];
  const db = fire.firestore();

  const snapshot = await db
    .collection(`mass${parish}`)
    .where("date", ">=", firstDay)
    .where("date", "<=", lastDay)
    .orderBy("date", "asc")
    .get();
  snapshot.forEach(function (doc) {
    let data = doc.data();
    if (data.hasOwnProperty("type") === false) {
      masstimings.push(doc.data());
    }
  });
  return masstimings;
}

export async function getCurrentEvent(eventArg) {
  var list = [];
  const { masscheckinopen, masscheckinclose, timestamp, parishCode } = eventArg;
  const [start, end] = getValidTimeRange(
    masscheckinopen,
    masscheckinclose,
    timestamp
  );
  //call admin cloud function here
  const db = fire.firestore();
  const collection = `mass${parishCode}`;

  const massScheduleObj = await db
    .collection(collection)
    .where("timestamp", ">=", start)
    .where("timestamp", "<=", end)
    .get();

  massScheduleObj.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function getCurrentEventById(eventArg) {
  var list = [];
  const { eventId, parishCode } = eventArg;

  //call admin cloud function here
  const db = fire.firestore();
  const collection = `mass${parishCode}`;

  const massScheduleObj = await db
    .collection(collection)
    .where("id", "==", eventId)
    .get();

  massScheduleObj.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function getMonthlyRoomSlotTimings(org, firstDay, lastDay, type) {
  let roomtimings = [];
  const db = fire.firestore();

  const snapshot = await db
    .collection(`rooms/${org}/orgrooms`)
    .where("isActive", "==", true)
    .where("type", "==", type)
    .where("date", ">=", firstDay)
    .where("date", "<=", lastDay)
    .orderBy("date", "asc")
    .get();

  snapshot.forEach(function (doc) {
    roomtimings.push(doc.data());
  });

  return roomtimings;
}

export async function getMonthlyRoomTimings(
  org,
  firstDay,
  lastDay,
  bookerRole,
  bookerOrgs // not used now, may be used in the future to restrict org access
) {
  // console.log("Get monthly timings", { bookerRole });
  let roomtimings = [];
  const db = fire.firestore();

  let query = db
    .collection(`rooms/${org}/orgrooms`)
    .where("isActive", "==", true)
    .where("date", ">=", firstDay)
    .where("date", "<=", lastDay);
  if (bookerRole !== "su")
    query = query.where("allowedTenantRoles", "array-contains", bookerRole);

  const snapshot = await query.get();
  snapshot.forEach(function (doc) {
    let data = doc.data();
    if (data.hasOwnProperty("type") === false) {
      roomtimings.push(doc.data());
    }
  });
  roomtimings.sort(compareRoomObjForRoomSchedules);
  return roomtimings;
}

export async function getCurrentRoom(roomArg) {
  var list = [];
  const { roomcheckinopen, roomcheckinclose, timestamp, orgCode } = roomArg;
  const [start, end] = getValidTimeRange(
    roomcheckinopen,
    roomcheckinclose,
    timestamp
  );
  //call admin cloud function here
  const db = fire.firestore();

  const roomScheduleObj = await db
    .collection(`rooms/${orgCode}/orgrooms`)
    .where("isActive", "==", true)
    .where("timestamp", ">=", start)
    .where("timestamp", "<=", end)
    .get();

  roomScheduleObj.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function getCurrentRoomById(roomArg) {
  var list = [];
  const { eventId, orgCode } = roomArg;

  //call admin cloud function here
  const db = fire.firestore();

  const roomScheduleObj = await db
    .collection(`rooms/${orgCode}/orgrooms`)
    .where("isActive", "==", true)
    .where("id", "==", eventId)
    .get();

  roomScheduleObj.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export function getSortedEventBookings(eventbooking) {
  let eventbookingobj = {};
  if (eventbooking.hasOwnProperty("massparishid")) {
    //this means parishioner has booked before we allow multiple bookings, NO NEED TO SORT
    eventbookingobj = [eventbooking];
  } else {
    //SORT BOOKINGS HERE
    let tmpobj = eventbooking.sort(datecompare);

    eventbookingobj = tmpobj;
  }
  return eventbookingobj;
}

export function getSortedEventBookingsByType(eventbooking, type) {
  let eventbookingobj = {};
  if (eventbooking.hasOwnProperty("massparishid")) {
    //this means parishioner has booked before we allow multiple bookings, NO NEED TO SORT
    eventbookingobj = [eventbooking];
  } else {
    //GET EVENT BOOKINGS BY TYPE
    eventbooking = eventbooking.filter((event) => event.type === type);
    //SORT BOOKINGS HERE
    let tmpobj = eventbooking.sort(datecompare);

    eventbookingobj = tmpobj;
  }
  return eventbookingobj;
}

function getValidTimeRange(masscheckinopen, masscheckinclose, localtime) {
  const start = localtime - masscheckinopen * 60 * 1000;
  const end = localtime + masscheckinclose * 60 * 1000;
  return [start, end];
}

function datecompare(a, b) {
  if (a.massdate.seconds > b.massdate.seconds) {
    return -1;
  }
  if (a.massdate.seconds < b.massdate.seconds) {
    return 1;
  }
  return 0;
}
