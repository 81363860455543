import { createContext } from "react";

const FinanceContext = createContext({
  financeDashboardState: {},
  transactionDashboardState: {},
  statisticsDashboardState: {},
  qrDashboardState: {},
  financeDashboardDispatch: () => { },
  transactionDashboardDispatch: () => { },
  statisticDashboardDispatch: () => { },
  qrDashboardDispatch: () => { },
});

export default FinanceContext;