import React, { createContext } from "react";
import firebase from "firebase";

/**
 * @typedef AppGlobalState
 * @prop {firebase.User | {}} user
 */

/**
 * @type {React.Context<AppGlobalState>}
 */
const AppGlobalContext = createContext({
  user: {},
});

export default AppGlobalContext;
