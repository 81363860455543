import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncAccountManagerParishEdit({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/accountManagerParishEdit"));
  console.log("Loading", AsyncAccountManagerParishEdit.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncAccountManagerParishEdit;
