import React, { Component } from "react";
import Header from "../../common/header";

class UpdateInProgress extends Component {
  goBackHome() {
    window.location.replace(window.location.origin);
  }

  render() {
    return (
      <React.Fragment>
        <div className="row justify-content-center mx-0">
          <div className="col-lg-6 px-1">
            <main className="container">
              <Header type="backandlogo" />
              <h1 className="py-3">Update in progress...</h1>
              <h4 className="my-4 pb-4 text-left text-muted font-weight-light">
                Mass Booking is disabled for system updates. All bookings will
                resume tomorrow at the same staggered times. We are currently
                working on to restore the regular bookings.
              </h4>
              <h4 className="my-4 pb-4 text-left text-muted font-weight-light">
                We apologise for this inconvenience caused and ask for your
                patience and prayers.
              </h4>

              <button
                type="button"
                onClick={() => this.goBackHome()}
                className="btn btn-primary mt-4 btn-block btn-lg"
              >
                Back to Home
              </button>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UpdateInProgress;
