import { getParishLinkById } from "../services/parish";
import { getOrgLinkById } from "../services/organisation";
import { isValidOrg, isValidParish } from "../services/validation";
import { getSubIDDigits } from "../services/settings";
/*
 * NOTE: These util functions can be broken in smaller chunks when this grows
 */
/*
 * Name-related functions
 */
export function getFirstnameFromFullname(fullname) {
  let name = fullname.split(" ");
  if (name.length > 1) {
    return name[1];
  }
  return fullname;
}
/*
 * ID-generation functions
 */
export function countDigits(number) {
  let count = 0;
  if (number >= 1) {
    count++;
  }

  while (number >= 10) {
    count++;
    number = number / 10;
  }
  return count;
}
export function getTrimmedID(text) {
  //trim to 9 characters only
  return text.trim().substring(0, 9);
}

export function getRandomId(length) {
  let start = 2; //random generate decimal (eg 0.1234...)
  let end = start + length;
  return Math.random().toString().slice(start, end);
}

export function getDefaultRandomId() {
  return getRandomId(9);
}

/*
 * Mass-related functions
 */
export function getMassUIDById(uid) {
  if (uid.length < 16) {
    return null;
  }
  return uid.substring(0, 8);
}

export function getMassScheduleString(chosenmass) {
  const { preferredmass, massdetails } = chosenmass;
  return `${massdetails.time}, ${preferredmass.day}, ${preferredmass.date}`;
}

export function getMassRegistrationLink(parishId) {
  let massRegistrationLink = "/event/mass";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/event/mass/${link}`;
      massRegistrationLink = link;
    }
  }
  return massRegistrationLink;
}

export function getRoomBookingLink(parishId) {
  let roomBookingLink = "/event/room";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/event/room/${link}`;
      roomBookingLink = link;
    }
  }
  return roomBookingLink;
}

export function getMassRegistrationWithDateLink(parishId, month, year) {
  let massRegistrationLink = "/event/mass";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/event/mass/${link}/${year}/${month}`;
      massRegistrationLink = link;
    }
  }
  return massRegistrationLink;
}

export function getRoomBookingWithDateLink(orgId, month, year) {
  let roomBookingLink = "/event/room";
  if (orgId) {
    if (isValidOrg(orgId)) {
      //get link
      let link = getOrgLinkById(orgId);
      link = `/event/room/${link}/${year}/${month}`;
      roomBookingLink = link;
    }
  }
  return roomBookingLink;
}

export function getRoomBookingWithDateHandler(
  setView,
  orgId,
  month,
  year,
  bookerRoleDoc
) {
  let roomBookingHandler = () => {};
  if (orgId) {
    if (isValidOrg(orgId)) {
      //get link
      const orgLink = getOrgLinkById(orgId);
      roomBookingHandler = () =>
        setView({
          name: "roomSchedules",
          props: { orgLink, year, month, bookerRoleDoc },
        });
    }
  }
  return roomBookingHandler;
}

export function getAdorationRegistrationWithDateLink(parishId, month, year) {
  let registrationLink = "/adoration";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/adoration/${link}/${year}/${month}`;
      registrationLink = link;
    }
  }
  return registrationLink;
}

export function getConfessionRegistrationWithDateLink(parishId, month, year) {
  let registrationLink = "/confessions";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/confessions/${link}/${year}/${month}`;
      registrationLink = link;
    }
  }
  return registrationLink;
}

export function getDevotionRegistrationLink(parishId) {
  let massRegistrationLink = "/devotion";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/devotion/${link}`;
      massRegistrationLink = link;
    }
  }
  return massRegistrationLink;
}

export function getDevotionRegistrationWithDateLink(parishId, month, year) {
  let registrationLink = "/devotion";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/devotion/${link}/${year}/${month}`;
      registrationLink = link;
    }
  }
  return registrationLink;
}

export function getStationofCrossRegistrationLink(parishId) {
  let registrationlink = "/stationofcross";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/stationofcross/${link}`;
      registrationlink = link;
    }
  }
  return registrationlink;
}

export function getStationofCrossRegistrationWithDateLink(
  parishId,
  month,
  year
) {
  let registrationLink = "/stationofcross";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/stationofcross/${link}/${year}/${month}`;
      registrationLink = link;
    }
  }
  return registrationLink;
}

export function getMassBookingsWithDateLink(parishId, month, year) {
  let massRegistrationLink = "/massbookings";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/massbookings/${link}/${year}/${month}`;
      massRegistrationLink = link;
    }
  }
  return massRegistrationLink;
}

export function getMassBookingsLink(parishId) {
  let massRegistrationLink = "/massbookings";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/massbookings/selector/${link}`;
      massRegistrationLink = link;
    }
  }
  return massRegistrationLink;
}

export function getDevotionBookingsWithDateLink(parishId, month, year) {
  let eventlink = "/devotionbookings";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/devotionbookings/${link}/${year}/${month}`;
      eventlink = link;
    }
  }
  return eventlink;
}

export function getDevotionBookingsLink(parishId) {
  let eventlink = "/devotionbookings";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/devotionbookings/selector/${link}`;
      eventlink = link;
    }
  }
  return eventlink;
}

export function getStationofCrossWithDateLink(parishId, month, year) {
  let eventlink = "/stationofcrossbookings";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/stationofcrossbookings/${link}/${year}/${month}`;
      eventlink = link;
    }
  }
  return eventlink;
}

export function getStationofCrossBookingsLink(parishId) {
  let eventlink = "/stationofcrossbookings";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/stationofcrossbookings/selector/${link}`;
      eventlink = link;
    }
  }
  return eventlink;
}
/*
 * Google Map related
 */
export function getGoogleMapSearchUrl(address) {
  const googleMapSearchUrl = "https://www.google.com/maps/search/?api=1&query=";
  return googleMapSearchUrl + address;
}
/*
 * ID-related functions
 */
export function maskIdentification(id) {
  let maskId;
  let strlen = id.length;

  maskId = "* * * * * " + id.substr(strlen - 4, strlen);
  return maskId;
}

export function mask4DigitId(id) {
  let maskId = "";
  if (id) {
    maskId = "* * * * * " + id;
  } else {
    maskId = "* * * * * ";
  }
  return maskId;
}

export function get4DigitId(id) {
  let maskId;
  let strlen = id.length;

  maskId = id.substr(strlen - 4, strlen);
  return maskId;
}

export function get2DigitId(id) {
  let maskId;
  let strlen = id.length;

  maskId = id.substr(strlen - 2, strlen);
  return maskId;
}

export function getLastDigitId(id) {
  if (getSubIDDigits() === 2) {
    //get only 2 Digits
    return get2DigitId(id);
  } else {
    //get only 4 Digits
    return get4DigitId(id);
  }
}

export function createAnnouncementID(prefix, id) {
  let number = parseInt(prefix);
  return `${number}${id}`;
}

export function createPrayerID(prefix, id) {
  let number = parseInt(prefix);
  return `${number}${id}`;
}

export function maskDOB(dob) {
  let maskdob;
  const usecomplete = false;
  if (!usecomplete) {
    maskdob = dob;
  } else {
    let items = dob.split("/");
    maskdob = `* * / * * / ${items[2]}`;
    // maskdob = `${items[0]} / ${items[1]} / * * * *`;
  }

  return maskdob;
}
/*
 * Time-related
 */

export function formatTime(timeCreated) {
  var diff = Date.now() - timeCreated;

  let periods = {
    month: 30 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
  };

  if (diff > periods.month) {
    // it was at least a month ago
    return Math.floor(diff / periods.month) + "mon ago";
  } else if (diff > periods.week) {
    return Math.floor(diff / periods.week) + "w ago";
  } else if (diff > periods.day) {
    return Math.floor(diff / periods.day) + "d ago";
  } else if (diff > periods.hour) {
    return Math.floor(diff / periods.hour) + "h ago";
  } else if (diff > periods.minute) {
    return Math.floor(diff / periods.minute) + "m ago";
  }
  return "Just now";
}

export function getMassDateTime(date) {
  const d = date;
  // Hours part from the timestamp
  let hours = d.getHours();
  // Minutes part from the timestamp
  let seconds = d.getSeconds();
  let minutes = d.getMinutes();
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  hours = (hours + 24) % 24;
  let mid = "am";
  if (hours === 0) {
    //At 00 hours we need to show 12 am
    hours = 12;
  } else if (hours > 12) {
    hours = hours % 12;
    mid = "pm";
  } else if (hours === 12) {
    hours = 12;
    mid = "pm";
  }

  return `${hours}:${minutes}:${seconds} ${mid}`;
}

export function humanReadableDate(date) {
  let masstime = getMassDateTime(date);

  return date.toDateString() + ", " + masstime.toUpperCase();
}

export function formatAnyDate(date) {
  let formattedtime = date;
  if (!isNaN(formattedtime)) {
    if (countDigits(formattedtime) > 10) {
      //cut, get 10 only
      formattedtime = parseInt(formattedtime / 1000);
    }

    let t = new Date(formattedtime * 1000);

    formattedtime = humanReadableDate(t);
  } else {
    let t = new Date(formattedtime);
    formattedtime = humanReadableDate(t);
  }
  return formattedtime;
}

export function getMassTime(date) {
  const d = new Date(date.seconds * 1000);
  // // });
  // Hours part from the timestamp
  let hours = d.getHours();
  // Minutes part from the timestamp

  let minutes = d.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  hours = (hours + 24) % 24;
  let mid = "am";
  if (hours === 0) {
    //At 00 hours we need to show 12 am
    hours = 12;
  } else if (hours > 12) {
    hours = hours % 12;
    mid = "pm";
  } else if (hours === 12) {
    hours = 12;
    mid = "pm";
  }

  return `${hours}:${minutes}${mid}`;
}

export function convertTo24Hour(time) {
  var hours = parseInt(time.substr(0, 2));
  if (time.indexOf("am") !== -1 && hours === 12) {
    time = time.replace("12", "0");
  }
  if (time.indexOf("pm") !== -1 && hours < 12) {
    time = time.replace(hours, hours + 12);
  }
  return time.replace(/(am|pm)/, "");
}

export function getMassTimeAMPM(date) {
  const d = new Date(date);
  // // });
  // Hours part from the timestamp
  let hours = d.getHours();
  // Minutes part from the timestamp

  let minutes = d.getMinutes();
  if (minutes < 10) {
    // minutes += "0";
    minutes = `0${minutes}`;
  }

  hours = (hours + 24) % 24;
  let mid = "am";
  if (hours === 0) {
    //At 00 hours we need to show 12 am
    hours = 12;
  } else if (hours > 12) {
    hours = hours % 12;
    mid = "pm";
  } else if (hours === 12) {
    hours = 12;
    mid = "pm";
  }

  return `${hours}:${minutes}${mid}`;
}

export function getMassDate(date) {
  const a = new Date(date.seconds * 1000);
  const months = getShortMonths();
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const day = a.getDate();

  return day + " " + month + " " + year;
}

export function isWeekEnd(date) {
  let a = new Date(date.seconds * 1000);

  return a.getDay() % 6 === 0;
}

export function isWeekEndMass(date) {
  let a = new Date(date.seconds * 1000);
  let weekEndMass = false;
  //if sunday
  let isSunday = a.getDay() === 0;
  let isConsideredSunday = false;
  //saturday 2pm onwards is considered sunday mass according to Fr Richards
  if (a.getDay() === 6 && a.getHours() >= 14) {
    isConsideredSunday = true;
  }

  if (isSunday || isConsideredSunday) {
    weekEndMass = true;
  }
  return weekEndMass;
}

export function isChristmas(date) {
  let a = new Date(date.seconds * 1000);
  let christmass = false;
  //if 24 and time >= 2pm, or 25, considered christmass :)
  if (
    a.getMonth() === 11 &&
    ((a.getDate() === 24 && a.getHours() >= 14) || a.getDate() === 25)
  ) {
    christmass = true;
  }
  return christmass;
}

export function isDec24(date) {
  let a = new Date(date.seconds * 1000);
  return a.getDate() === 24;
}

export function isSaturdayWeekendMass(date) {
  let a = new Date(date.seconds * 1000);
  let result = false;
  //if saturday
  if (a.getDay() === 6 && a.getHours() >= 14) {
    result = true;
  }
  return result;
}

export function isSundayWeekendMass(date) {
  let a = new Date(date.seconds * 1000);
  let result = false;
  //if sunday
  if (a.getDay() === 0) {
    result = true;
  }
  return result;
}

export function isSaturdayMass(date) {
  let a = new Date(date.seconds * 1000);

  //if saturday
  return a.getDay() === 6;
}

export function isSundayMass(date) {
  let a = new Date(date.seconds * 1000);

  //if saturday
  return a.getDay() === 0;
}

export function getDay(date) {
  let a = new Date(date.seconds * 1000);

  return a.getDay();
}
export function getMonthFromDate(date) {
  const a = new Date(date);
  const months = getShortMonths();
  const month = months[a.getMonth()];

  return month;
}

export function getDayMonthYear(date) {
  const a = new Date(date);
  const months = getShortMonths();
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const day = a.getDate();

  return day + " " + month + " " + year;
}

export function isDayWeekEnd(date) {
  let a = new Date(date);

  return a.getDay() % 6 === 0;
}

export function getDayFromDate(date) {
  let a = new Date(date);

  return a.getDay();
}

export function getLongMonths() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months;
}

export function getShortMonths() {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months;
}

export function getShortDayConst() {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return days;
}

export function getShortDay(date = new Date()) {
  const days = getShortDayConst();
  return days[date.getDay()];
}

export function getLongMonth(date) {
  let a = new Date(date);

  const months = getLongMonths();
  return months[a.getMonth()];
}

export function getShortMonthDateObj(date = new Date()) {
  const months = getShortMonths();
  return months[date.getMonth()];
}

export function getShortMonth(date) {
  let a = new Date(date.seconds * 1000);

  const months = getShortMonths();
  return months[a.getMonth()];
}

export function mapToDay(value) {
  if (value < 0 || value > 7) {
    return "Sunday";
  }
  let weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";
  return weekday[value];
}

export function createDate(date, time) {
  let ampm = time.substr(time.length - 2, time.length);
  console.log({ ampm });
  let timeonly = time.substr(0, time.length - 2);
  console.log({ timeonly });
  //force to replace . with :
  timeonly = timeonly.replace(".", ":");
  let timing = `${date} ${timeonly} ${ampm}`;
  console.log("Timing: ", timing);

  let newdate = new Date(timing);
  console.log("tmpnewdate: ", newdate);
  return newdate;
}

export function getNormalDate(date) {
  const d = new Date(date);
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("en", { month: "long" }).format(d);
  const da = new Intl.DateTimeFormat("en", { day: "numeric" }).format(d);
  const newdate = `${mo} ${da}, ${ye}`;
  // console.log(newdate);
  return newdate;
}

export function getSelectOptionDate(date) {
  const d = new Date(date);
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
  const da = new Intl.DateTimeFormat("en", { day: "numeric" }).format(d);
  const newdate = `${da} ${mo} ${ye}`;
  // console.log(newdate);
  return newdate;
}

export function getMassFullDate(date) {
  const a = new Date(date.seconds * 1000);
  const year = a.getFullYear();
  const month = getShortMonth(date);
  const day = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  const sec = a.getSeconds();
  return day + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
}

export function getDayString(date) {
  const a = new Date(date.seconds * 1000);

  return mapToDay(a.getDay());
}

export function getMassScheduleDate(date) {
  const a = new Date(date.seconds * 1000);
  const year = a.getFullYear();
  const month = getShortMonth(date);
  const day = a.getDate();
  return day + " " + month + " " + year;
}

export function isWithinCheckInRange(
  massdate,
  masscheckinopen,
  masscheckinclose,
  localtime
) {
  let result = false;
  const opencheckin = (massdate.seconds - masscheckinopen * 60) * 1000;
  const closecheckin = (massdate.seconds + masscheckinclose * 60) * 1000;
  console.log(`Open: ${opencheckin} Close: ${closecheckin}`);
  if (localtime >= opencheckin && localtime <= closecheckin) {
    result = true;
  }
  return result;
}

export function getSGTime(date = new Date()) {
  // This function gets the current/input time and MODIFIES the offset to +8GMT
  // This is done because date checks done app-wide are affected by client-side timezones
  const utcDate = date.getTime() + date.getTimezoneOffset() * 60000;
  const sgDate = new Date(utcDate + 3600000 * 8);
  return sgDate;
}

export function getSGDateObj(eventDateObj) {
  // This function receives an eventDateObj = {seconds, nanoseconds} and returns the same
  // Note that this MODIFIES the date seconds value because checks are affected by client-side timezones
  const sgDate = getSGTime(new Date(eventDateObj.seconds * 1000));
  const sgSeconds = sgDate.getTime() / 1000;
  const newEventdate = {
    seconds: sgSeconds,
    nanoseconds: 0,
  };
  return newEventdate;
}

export function getLocalTime() {
  // const sgtime = new Date().toLocaleString("en-US", {
  //   timeZone: "Asia/Singapore",
  // });
  // return new Date(sgtime).getTime();
  return new Date().getTime();
}

export function getStartofMonth() {
  const now = new Date();
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);

  return firstDay;
}

export function getEndofMonth() {
  const now = new Date();
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  lastDay.setSeconds(lastDay.getSeconds() - 1);

  return lastDay;
}

/*
 * Some constants
 */

export function getSuperAdminCode() {
  return "9999";
}

export function getArchdioceseCode() {
  return "99999";
}

export function checkNRICisValid(str) {
  if (str.length !== 9) return false;

  str = str.toUpperCase();

  let i,
    icArray = [];
  for (i = 0; i < 9; i++) {
    icArray[i] = str.charAt(i);
  }

  icArray[1] = parseInt(icArray[1], 10) * 2;
  icArray[2] = parseInt(icArray[2], 10) * 7;
  icArray[3] = parseInt(icArray[3], 10) * 6;
  icArray[4] = parseInt(icArray[4], 10) * 5;
  icArray[5] = parseInt(icArray[5], 10) * 4;
  icArray[6] = parseInt(icArray[6], 10) * 3;
  icArray[7] = parseInt(icArray[7], 10) * 2;
  let weight = 0;
  for (i = 1; i < 8; i++) {
    weight += icArray[i];
  }

  let offset = icArray[0] === "T" || icArray[0] === "G" ? 4 : 0;
  if (icArray[0] === "M") {
    offset = 3;
  }
  let temp = (offset + weight) % 11;

  let st = ["J", "Z", "I", "H", "G", "F", "E", "D", "C", "B", "A"];
  let fg = ["X", "W", "U", "T", "R", "Q", "P", "N", "M", "L", "K"];
  let m = ["X", "W", "U", "T", "R", "Q", "P", "N", "J", "L", "K"];

  let theAlpha;
  if (icArray[0] === "S" || icArray[0] === "T") {
    theAlpha = st[temp];
  } else if (icArray[0] === "F" || icArray[0] === "G") {
    theAlpha = fg[temp];
  } else if (icArray[0] === "M") {
    theAlpha = m[temp];
  }

  return icArray[8] === theAlpha;
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Generates a string of YYMMDDTTTT
export function getDateTimeNumStr(date = new Date()) {
  let yearStr = date.getFullYear();
  yearStr = yearStr.toString();
  yearStr = yearStr.substring(2, 4);
  let monthStr = date.getMonth() + 1;
  if (monthStr < 10) {
    monthStr = "0" + monthStr;
  }
  let dateStr = date.getDate();
  if (dateStr < 10) {
    dateStr = "0" + dateStr;
  }
  let hourStr = date.getHours();
  if (hourStr < 10) {
    hourStr = "0" + hourStr;
  }
  let minStr = date.getMinutes();
  if (minStr < 10) {
    minStr = "0" + minStr;
  }
  return yearStr + monthStr + dateStr + hourStr + minStr;
}

export function generateFirebaseID() {
  // Credits: https://gist.github.com/mikelehen/3596a30bd69384624c11
  var PUSH_CHARS =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
  var lastPushTime = 0;

  // We generate 72-bits of randomness which get turned into 12 characters and appended to the
  // timestamp to prevent collisions with other clients.  We store the last characters we
  // generated because in the event of a collision, we'll use those same characters except
  // "incremented" by one.
  var lastRandChars = [];

  return (function () {
    var now = new Date().getTime();
    var duplicateTime = now === lastPushTime;
    lastPushTime = now;

    var timeStampChars = new Array(8);
    for (var i = 7; i >= 0; i--) {
      timeStampChars[i] = PUSH_CHARS.charAt(now % 62);
      // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
      now = Math.floor(now / 62);
    }
    if (now !== 0)
      throw new Error("We should have converted the entire timestamp.");

    var id = timeStampChars.join("");

    if (!duplicateTime) {
      for (i = 0; i < 12; i++) {
        lastRandChars[i] = Math.floor(Math.random() * 62);
      }
    } else {
      // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
      for (i = 11; i >= 0 && lastRandChars[i] === 61; i--) {
        lastRandChars[i] = 0;
      }
      lastRandChars[i]++;
    }
    for (i = 0; i < 12; i++) {
      id += PUSH_CHARS.charAt(lastRandChars[i]);
    }
    if (id.length !== 20) throw new Error("Length should be 20.");

    return id;
  })();
}
