import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import _ from "lodash";

import AnalyticsDashboard from "../tab/AnalyticsDashboard";
import RegistrationList from "../tab/RegistrationList";
import TransfersListInt from "../tab/TransfersListInt";
import TransfersListExt from "../tab/TransfersListExt";

import Header from "../../../../common/header";
import { getParishNameById } from "../../../../../services/parish";
import { levelRef } from "../../services/levels";
import { registrationStatusNameToStatus } from "../../services/registrationStatus";

import LoadingSpinner from "../../../../common/loadingSpinner";

import fire from "../../../../../services/fire";
import AppGlobalContext from "../../../../../AppGlobalContext";
import {
  RegistrationDatum,
  TransfersDatum,
  ParishConfigContext,
} from "../../hooks/hooks";
import {
  QueriedAdminState,
  QueriedAdminContext,
  TableFiltersContext,
} from "../hooks/hooks";
import {
  isSuperAdminRole,
  isCATCHAdminRole,
  isCATCHParishAdminRole,
} from "../../../../roles/service/roles";
import { getCATCHParishAdminMembershipDocs } from "../../../../roles/service/membership";
import {
  registrationCollectionName,
  transfersCollectionName,
  parishConfigCollectionName,
  catchContactUpdatesCollectionName,
} from "../../services/collections";

const getCatchRegistrations = fire
  .functions("asia-east2")
  .httpsCallable("getCatchRegistrations");
const getCatchParishTransfers = fire
  .functions("asia-east2")
  .httpsCallable("getCatchParishTransfers");
const getCatchContactUpdates = fire
  .functions("asia-east2")
  .httpsCallable("getCatchContactUpdates");

function parishIdToProgrammes(orgId, parishConfig, setQueriedAdminState) {
  const allProgrammes =
    parishConfig
      .find(({ id }) => id === orgId)
      ?.programmes?.map?.(({ parishId: id, parishName: parish }) => ({
        id,
        parish,
      })) ?? [];

  setQueriedAdminState((state) => ({ ...state, allProgrammes }));
}

function parishIdToParish(parishId, parishConfig) {
  return parishConfig.find(({ id }) => id === parishId);
}

/**
 * @template Datum
 * @callback AdminQueryHandler
 * @param {string?} orgId
 * @param {string?} programmeType
 * @param {string?} level
 * @param {React.Dispatch<React.SetStateAction<QueriedAdminState>>} setQueriedAdminState
 * @param {firestore.CollectionReference<Datum>} collection
 * @return {() => void} Unsubscribe method
 */

/**
 * @type {AdminQueryHandler<RegistrationDatum>}
 */
async function handleRegistrationQuery(
  programmeType,
  level,
  setQueriedAdminState,
  parishConfig,
  user,
  isParishAdmin,
  filterState
) {
  try {
    if (!isParishAdmin) {
      const registrationState = {
        exists: false,
        data: [],
      };

      setQueriedAdminState((state) => ({
        ...state,
        registrationState,
      }));

      return;
    }

    const { data: { ok, data: { registrationQuery = [] } = {} } = {} } =
      await getCatchRegistrations({
        uid: user.uid,
        programmeType,
        level,
        status: filterState.status
          ? registrationStatusNameToStatus(filterState.status)
          : filterState.status,
      });

    if (ok) {
      const data = registrationQuery.map(async (docData) => {
        if (
          typeof docData.programmeType !== "object" &&
          !_.isEmpty(docData.selectedParishId)
        ) {
          const parishData = parishIdToParish(
            docData.selectedParishId,
            parishConfig
          );

          if (parishData) {
            const programmes = parishData.programmes;
            const selectedProgramme = programmes.find(
              (item) => item.id === docData.programmeType
            );

            if (selectedProgramme) {
              const timeslots = selectedProgramme.timeslots;
              const selectedTimeslot = timeslots.find(
                (item) =>
                  item.id === docData.timeslot || item.name === docData.timeslot
              );
              docData.programmeName = selectedProgramme.name;
              docData.timeslotName = selectedTimeslot.name;
            }
          }
        }

        return docData;
      });

      const resolvedData = await Promise.all(data);

      const filteredData = resolvedData.filter(
        ({ registrationType }) => registrationType !== "transfer"
      );

      const registrationState = {
        exists:
          filterState.status === "accepted"
            ? resolvedData.length > 0
            : filteredData.length > 0,
        data: filterState.status === "accepted" ? resolvedData : filteredData,
      };

      setQueriedAdminState((state) => ({ ...state, registrationState }));

      return () => {};
    } else {
      const registrationState = {
        exists: false,
        data: [],
      };

      setQueriedAdminState((state) => ({ ...state, registrationState }));

      return () => {};
    }
  } catch (error) {
    // Handle any potential errors here
    console.error("Error fetching data:", error);
    const registrationState = {
      exists: false,
      data: [],
    };

    setQueriedAdminState((state) => ({ ...state, registrationState }));

    return () => {};
  }
}

async function handleAdminRegistrationQuery(
  programmeType,
  level,
  orgId,
  setQueriedAdminState,
  registrationCollection,
  parishConfig,
  isAdminCATCH,
  isSuperAdmin,
  filterState
) {
  try {
    if (!isAdminCATCH && !isSuperAdmin) {
      const registrationState = {
        exists: false,
        data: [],
      };

      setQueriedAdminState((state) => ({
        ...state,
        registrationState,
      }));

      return;
    }

    let registrationQuery = registrationCollection;

    // if (orgId !== null) {
    //   registrationQuery = registrationQuery.where(
    //     "selectedParishId",
    //     "==",
    //     orgId
    //   );
    // }

    if (programmeType !== "All") {
      const programmeTypeValueArray = programmeType.split("-");
      registrationQuery = registrationQuery
        .where("programmeType", "==", programmeTypeValueArray[0])
        .where("selectedParishId", "==", programmeTypeValueArray[1]);
    }

    if (level !== "All") {
      registrationQuery = registrationQuery.where("level", "==", level);
    }

    if (filterState.status != null) {
      registrationQuery = registrationQuery.where(
        "status",
        "==",
        registrationStatusNameToStatus(filterState.status)
      );

      if (filterState.status !== "accepted") {
        registrationQuery = registrationQuery
          .where("registrationType", "!=", "transfer")
          .orderBy("registrationType");
      }
    }

    registrationQuery = registrationQuery
      .orderBy("submittedAt", "desc")
      .orderBy("status");

    const snapshot = await registrationQuery.get();
    const data = snapshot.docs.map(async (doc) => {
      const docData = doc.data();
      if (
        typeof docData.programmeType !== "object" &&
        !_.isEmpty(docData.selectedParishId)
      ) {
        const parishData = parishIdToParish(
          docData.selectedParishId,
          parishConfig
        );

        if (parishData) {
          const programmes = parishData.programmes;
          const selectedProgramme = programmes.find(
            (item) => item.id === docData.programmeType
          );

          if (selectedProgramme) {
            const timeslots = selectedProgramme.timeslots;
            const selectedTimeslot = timeslots.find(
              (item) =>
                item.id === docData.timeslot || item.name === docData.timeslot
            );
            docData.programmeName = selectedProgramme.name;
            docData.timeslotName = selectedTimeslot.name;
          }
        }
      }

      return docData;
    });

    const resolvedData = await Promise.all(data);

    const filteredData = resolvedData.filter(
      ({ registrationType }) => registrationType !== "transfer"
    );

    const registrationState = {
      exists:
        filterState.status === "accepted"
          ? resolvedData.length > 0
          : filteredData.length > 0,
      data: filterState.status === "accepted" ? resolvedData : filteredData,
    };

    setQueriedAdminState((state) => ({ ...state, registrationState }));

    return () => {};
  } catch (error) {
    // Handle any potential errors here
    console.error("Error fetching data:", error);

    const registrationState = {
      exists: false,
      data: [],
    };

    setQueriedAdminState((state) => ({ ...state, registrationState }));

    return () => {};
  }
}

/**
 * @type {AdminQueryHandler<TransfersDatum>}
 */
async function handleTransfersQuery(
  programmeType,
  level,
  orgId,
  setQueriedAdminState,
  parishConfig,
  user,
  isParishAdmin
) {
  if (!isParishAdmin) {
    const transfersState = {
      exists: false,
      data: [],
    };

    setQueriedAdminState((state) => ({
      ...state,
      outTransfersState: transfersState,
      inTransfersState: transfersState,
    }));

    return;
  }

  const catchParishes = parishConfig.filter(
    ({ isActive, hasCatechesis, isCatchParish }) =>
      isActive && hasCatechesis && isCatchParish
  );

  if (catchParishes.length === 0) catchParishes.push(-1);

  const getCatchParishTransfersData = async (type) => {
    const { data: { ok, data: { transfersQuery = [] } = {} } = {} } =
      await getCatchParishTransfers({
        uid: user.uid,
        isExternal: false,
        catchParishes,
        type,
        programmeType,
        level,
      });

    // console.log(type, transfersQuery);

    return ok ? transfersQuery : [];
  };

  const handleTransferData = async (type, stateKey) => {
    const transfersData = await getCatchParishTransfersData(type);

    const transfersState = {
      exists: transfersData.length > 0,
      data: transfersData,
    };

    setQueriedAdminState((state) => ({
      ...state,
      [stateKey]: transfersState,
    }));
  };

  if (orgId !== null) {
    // Is Parish admin
    handleTransferData("out", "outTransfersState");
    handleTransferData("in", "inTransfersState");

    return () => {};
  }
  // else {
  //   setQueriedAdminState((state) => ({
  //     ...state,
  //     inTransfersState: { exists: false, data: [] },
  //   }));
  //   // Is Overall admin
  //   const unsubOut = await handleTransferData("out", "outTransfersState");

  //   return () => {
  //     unsubOut();
  //   };
  // }
}

async function handleAdminTransfersQuery(
  programmeType,
  level,
  orgId,
  setQueriedAdminState,
  parishConfig,
  transfersCollection,
  isAdminCATCH,
  isSuperAdmin
) {
  if (!isAdminCATCH && !isSuperAdmin) {
    const transfersState = {
      exists: false,
      data: [],
    };

    setQueriedAdminState((state) => ({
      ...state,
      outTransfersState: transfersState,
      inTransfersState: transfersState,
    }));

    return;
  }

  const catchParishes = parishConfig.filter(
    ({ isActive, hasCatechesis, isCatchParish }) =>
      isActive && hasCatechesis && isCatchParish
  );

  if (catchParishes.length === 0) catchParishes.push(-1);

  const getCatchParishTransfersData = async (type) => {
    let transfersQuery;
    transfersQuery = transfersCollection.where("external", "==", false);

    // if (catchParishes.length > 0) {
    //   if (type === "out") {
    //     transfersQuery = transfersCollection
    //       // .where("status", "in", ["pendingOut", "rejectedOut", "accepted"])
    //       .where("to.id", "in", catchParishes);
    //   }
    //   if (type === "in") {
    //     transfersQuery = transfersCollection
    //       // .where("status", "in", ["pendingIn", "rejectedIn"])
    //       .where("from.id", "in", catchParishes);
    //   }
    // }

    if (transfersQuery) {
      if (programmeType !== "All") {
        const programmeTypeValueArray = programmeType.split("-");
        transfersQuery = transfersQuery
          .where("registration.programmeType", "==", programmeTypeValueArray[0])
          .where(
            "registration.selectedParishId",
            "==",
            programmeTypeValueArray[1]
          );
      }

      if (level !== "All") {
        transfersQuery = transfersQuery.where(
          "registration.level",
          "==",
          level
        );
      }

      const querySnapshot = await transfersQuery.get();
      const transferData = querySnapshot.docs.map((doc) => {
        return doc.data();
      });

      const filteredTransfers = transferData.filter(
        ({ external = false }) => external !== true
      );

      transfersQuery = filteredTransfers;
    }

    return transfersQuery ?? [];
  };

  const handleTransferData = async (type, stateKey) => {
    const transfersData = await getCatchParishTransfersData(type);

    const transfersState = {
      exists: transfersData.length > 0,
      data: transfersData,
    };

    setQueriedAdminState((state) => ({
      ...state,
      [stateKey]: transfersState,
    }));
  };

  if (orgId !== null) {
    // Is Parish admin
    const unsubOut = await handleTransferData("out", "outTransfersState");
    const unsubIn = await handleTransferData("in", "inTransfersState");

    return () => {
      unsubOut();
      unsubIn();
    };
  } else {
    setQueriedAdminState((state) => ({
      ...state,
      inTransfersState: { exists: false, data: [] },
    }));
    // Is Overall admin
    const unsubOut = await handleTransferData("out", "outTransfersState");

    return () => {
      unsubOut();
    };
  }
}

/**
 * @type {AdminQueryHandler<TransfersDatum>}
 */
async function handleExternalTransfersQuery(
  programmeType,
  level,
  orgId,
  setQueriedAdminState,
  parishConfig,
  user,
  isParishAdmin
) {
  if (!isParishAdmin) {
    const transfersState = {
      exists: false,
      data: [],
    };

    setQueriedAdminState((state) => ({
      ...state,
      outExternalTransfersState: transfersState,
      outExternalTransfersState: transfersState,
    }));

    return;
  }

  const catchParishes = parishConfig.filter(
    ({ isActive, hasCatechesis, isCatchParish }) =>
      isActive && hasCatechesis && isCatchParish
  );

  if (catchParishes.length === 0) catchParishes.push(-1);

  const getCatchParishTransfersData = async (type) => {
    const { data: { ok, data: { transfersQuery = [] } = {} } = {} } =
      await getCatchParishTransfers({
        uid: user.uid,
        isExternal: true,
        catchParishes,
        type,
        programmeType,
        level,
      });

    return ok ? transfersQuery : [];
  };

  const handleTransferData = async (type, stateKey) => {
    const transfersData = await getCatchParishTransfersData(type);

    const transfersState = {
      exists: transfersData.length > 0,
      data: transfersData,
    };

    setQueriedAdminState((state) => ({
      ...state,
      [stateKey]: transfersState,
    }));
  };

  if (orgId !== null) {
    // Is Parish admin
    const unsubOut = await handleTransferData(
      "out",
      "outExternalTransfersState"
    );
    const unsubIn = await handleTransferData("in", "inExternalTransfersState");

    return () => {
      unsubOut();
      unsubIn();
    };
  } else {
    // Is Overall admin
    const unsubOut = await handleTransferData(
      "out",
      "outExternalTransfersState"
    );
    const unsubIn = await handleTransferData("in", "inExternalTransfersState");

    return () => {
      unsubOut();
      unsubIn();
    };
  }
}

async function handleAdminExternalTransfersQuery(
  programmeType,
  level,
  orgId,
  setQueriedAdminState,
  parishConfig,
  transfersCollection,
  isAdminCATCH,
  isSuperAdmin
) {
  if (!isAdminCATCH && !isSuperAdmin) {
    const transfersState = {
      exists: false,
      data: [],
    };

    setQueriedAdminState((state) => ({
      ...state,
      outTransfersState: transfersState,
      inTransfersState: transfersState,
    }));

    return;
  }

  const catchParishes = parishConfig.filter(
    ({ isActive, hasCatechesis, isCatchParish }) =>
      isActive && hasCatechesis && isCatchParish
  );

  if (catchParishes.length === 0) catchParishes.push(-1);

  const getCatchParishTransfersData = async (type) => {
    let transfersQuery;

    transfersQuery = transfersCollection.where("external", "==", type);
    // if (catchParishes.length > 0) {
    //   if (type === "out") {
    //     transfersQuery = transfersCollection.where(
    //       "to.id",
    //       "not-in",
    //       catchParishes
    //     );
    //     // .where("external", "==", true);
    //   }

    //   if (type === "in") {
    //     transfersQuery = transfersCollection.where(
    //       "from.id",
    //       "not-in",
    //       catchParishes
    //     );
    //     // .where("external", "==", true);
    //   }
    // }

    if (transfersQuery) {
      const querySnapshot = await transfersQuery.get();
      transfersQuery = querySnapshot.docs.map((doc) => {
        return doc.data();
      });
    }

    return transfersQuery ?? [];
  };

  const handleTransferData = async (type, stateKey) => {
    let relevantStatuses = [];
    switch (type) {
      case "out":
        relevantStatuses = ["pendingOut", "rejectedOut"];
        break;
      case "in":
        relevantStatuses = ["pendingIn", "rejectedIn", "accepted"];
        break;
    }

    const transfersData = (await getCatchParishTransfersData(type)).filter(
      ({ status }) => relevantStatuses.includes(status)
    );

    // console.log({ processedData });

    const transfersState = {
      exists: transfersData.length > 0,
      data: transfersData,
    };

    setQueriedAdminState((state) => ({
      ...state,
      [stateKey]: transfersState,
    }));
  };

  if (orgId !== null) {
    // Is Parish admin
    const unsubOut = await handleTransferData(
      "out",
      "outExternalTransfersState"
    );
    const unsubIn = await handleTransferData("in", "inExternalTransfersState");

    return () => {
      unsubOut();
      unsubIn();
    };
  } else {
    // Is Overall admin
    const unsubOut = await handleTransferData(
      "out",
      "outExternalTransfersState"
    );
    const unsubIn = await handleTransferData("in", "inExternalTransfersState");

    return () => {
      unsubOut();
      unsubIn();
    };
  }
}

async function handleParishConfigQuery(parishCollection) {
  const docSnapshot = await parishCollection.get();
  const parishData = docSnapshot.docs.map((doc) => doc.data());
  return parishData;
}

async function handleContactUpdatesQuery(user, isParishAdmin) {
  if (!isParishAdmin) {
    return;
  }

  // const { data: { ok, data: { contactUpdatesQuery = [] } = {} } = {} } =
  //   await getCatchContactUpdates({
  //     uid: user.uid,
  //   });

  // if (ok) {
  //   // console.log(contactUpdatesQuery);
  // }

  return () => {};
}

async function handleAdminContactUpdatesQuery(
  orgId,
  catchContactUpdatesCollection,
  isAdminCATCH,
  isSuperAdmin
) {
  if (!isAdminCATCH && !isSuperAdmin) {
    return;
  }

  let contactUpdatesQuery = catchContactUpdatesCollection;

  if (orgId !== null) {
    contactUpdatesQuery = contactUpdatesQuery.where(
      "selectedParishId",
      "==",
      orgId
    );
  }

  const snapshot = await contactUpdatesQuery.get();
  const catchContactUpdatesData = snapshot.docs.map((doc) => doc.data());

  // console.log(catchContactUpdatesData);

  return () => {};
}

export default function AdminMenu() {
  const [selectedProgramme, setSelectedProgramme] = useState("All");
  const [selectedLevel, setSelectedLevel] = useState("All");
  const [isLoadingAdminData, setIsLoadingAdminData] = useState(false);
  const { user } = useContext(AppGlobalContext);
  const history = useHistory();
  // console.log("Render layout");

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);
  const transfersCollection = db.collection(transfersCollectionName);
  const parishCollection = db.collection(parishConfigCollectionName);
  const catchContactUpdatesCollection = db.collection(
    catchContactUpdatesCollectionName
  );

  /** @type {[QueriedAdminState, React.Dispatch<React.SetStateAction<QueriedAdminState>>]} */
  const [queriedAdminState, setQueriedAdminState] = useState({
    registrationState: null,
    outTransfersState: null,
    inTransfersState: null,
    outExternalTransfersState: null,
    inExternalTransfersState: null,
    membershipState: null,
    allProgrammes: [],
    editData: ({ data }) => {
      setQueriedAdminState((state) => ({ ...state, ...data }));
    },
  });

  // console.log(queriedAdminState);

  const [activeTab, setActiveTab] = useState("dashboard");
  const [activeRegistrationStatus, setActiveRegistrationStatus] =
    useState("pending");

  // console.log(activeTab);
  // console.log(activeRegistrationStatus);

  const [tableFiltersState, setTableFiltersState] = useState({
    registrationFilterState: { status: null },
    editData: ({ data }) => {
      setTableFiltersState((state) => ({ ...state, ...data }));
      setActiveRegistrationStatus(data.registrationFilterState.status);
    },
  });

  const [parishConfigState, setParishConfigState] = useState([]);

  // console.log(tableFiltersState);

  // useEffect(() => console.log({ queriedAdminState }), [queriedAdminState]);

  const unsubUser = useRef(() => {});

  useEffect(() => {
    unsubUser.current();

    const unsubMethods = [];

    getCATCHParishAdminMembershipDocs(user.uid).then((docs) => {
      // console.log(docs);
      const hasAccess =
        isSuperAdminRole(user) ||
        isCATCHAdminRole(user) ||
        (isCATCHParishAdminRole(user) && docs.length > 0);

      // console.log({ hasAccess });

      if (hasAccess) {
        setIsLoadingAdminData(true);
        const isSuperAdmin = isSuperAdminRole(user);
        const isAdminCATCH = isCATCHAdminRole(user);
        const isParishAdmin = isCATCHParishAdminRole(user);
        const orgId = isParishAdmin ? docs[0].orgId : null;
        const membershipState = isParishAdmin
          ? { exists: true, datum: docs[0] }
          : { exists: false, datum: null };

        setQueriedAdminState((state) => ({
          ...state,
          membershipState,
        }));

        handleParishConfigQuery(parishCollection).then((parishConfig) => {
          setParishConfigState(parishConfig);

          unsubMethods.push(
            parishIdToProgrammes(orgId, parishConfig, setQueriedAdminState)
          );

          if (isSuperAdmin || isAdminCATCH) {
            unsubMethods.push(
              handleAdminRegistrationQuery(
                selectedProgramme,
                selectedLevel,
                orgId,
                setQueriedAdminState,
                registrationCollection,
                parishConfig,
                isAdminCATCH,
                isSuperAdmin,
                tableFiltersState.registrationFilterState
              )
            );
            unsubMethods.push(
              handleAdminTransfersQuery(
                selectedProgramme,
                selectedLevel,
                orgId,
                setQueriedAdminState,
                parishConfig,
                transfersCollection,
                isAdminCATCH,
                isSuperAdmin
              )
            );
            unsubMethods.push(
              handleAdminExternalTransfersQuery(
                selectedProgramme,
                selectedLevel,
                orgId,
                setQueriedAdminState,
                parishConfig,
                transfersCollection,
                isAdminCATCH,
                isSuperAdmin
              )
            );
            unsubMethods.push(
              handleAdminContactUpdatesQuery(
                orgId,
                catchContactUpdatesCollection,
                isAdminCATCH,
                isSuperAdmin
              )
            );
          } else {
            unsubMethods.push(
              handleRegistrationQuery(
                selectedProgramme,
                selectedLevel,
                setQueriedAdminState,
                parishConfig,
                user,
                isParishAdmin,
                tableFiltersState.registrationFilterState
              )
            );
            unsubMethods.push(
              handleTransfersQuery(
                selectedProgramme,
                selectedLevel,
                orgId,
                setQueriedAdminState,
                parishConfig,
                user,
                isParishAdmin
              )
            );
            unsubMethods.push(
              handleExternalTransfersQuery(
                selectedProgramme,
                selectedLevel,
                orgId,
                setQueriedAdminState,
                parishConfig,
                user,
                isParishAdmin
              )
            );
            unsubMethods.push(handleContactUpdatesQuery(user, isParishAdmin));
          }

          setIsLoadingAdminData(false);
        });
      } else {
        history.push("/");
      }
    });

    unsubUser.current = () => {
      for (const unsub of unsubMethods) {
        if (typeof unsub != "function") {
          return;
        }
        return unsub();
      }
    };

    return unsubUser.current;
  }, [user, selectedLevel, selectedProgramme, tableFiltersState]);

  return Object.values(queriedAdminState).some((state) => state === null) ? (
    <LoadingSpinner />
  ) : (
    <>
      <Header type="backandlogo" smallpic={user.photoURL} />
      <div className="container mt-4">
        <div className="row">
          <div className="col-sm-8">
            <h2 className="mb-2">CATCH Admin Menu</h2>
            <h5 className="mb-2 text-muted">
              {queriedAdminState.membershipState.exists
                ? getParishNameById(
                    queriedAdminState.membershipState.datum.orgId
                  )
                : "All Parishes"}
            </h5>
          </div>
          <div className="col-sm-4">
            <div className="mb-2">
              <select
                name="programme"
                id="programme"
                disabled={
                  !["dashboard", "registrations"].includes(activeTab) ||
                  isLoadingAdminData
                }
                value={selectedProgramme}
                onChange={({ currentTarget: input }) => {
                  setSelectedProgramme(input.value);
                }}
                className="form-control form-control-md custom-select custom-select-md"
              >
                <option value="All">All Programmes</option>
                {queriedAdminState.allProgrammes.map((item) => (
                  <option
                    value={`${item.id}-${item.parishId}`}
                    key={`programme-select-${item.parishName}-${item.name}`}
                  >
                    {item.parishName} - {item.name}
                  </option>
                ))}
              </select>
            </div>
            <>
              <select
                name="level"
                id="level"
                disabled={!["dashboard", "registrations"].includes(activeTab)}
                value={selectedLevel}
                onChange={({ currentTarget: input }) => {
                  setSelectedLevel(input.value);
                }}
                className="form-control form-control-md custom-select custom-select-md"
              >
                <option value="All">All Levels</option>
                {levelRef.map(({ name, id }) => (
                  <option key={`level-select-${id}`} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </>
          </div>
        </div>

        <ParishConfigContext.Provider value={parishConfigState}>
          <QueriedAdminContext.Provider value={queriedAdminState}>
            <TableFiltersContext.Provider value={tableFiltersState}>
              <Tabs
                defaultActiveKey="dashboard"
                id="admin-panel-tabs"
                justify
                className="my-4"
                onSelect={(key) => {
                  if (key === "registrations") {
                    setTableFiltersState((state) => ({
                      ...state,
                      registrationFilterState: {
                        ...state.registrationFilterState,
                        status: activeRegistrationStatus,
                      },
                    }));
                  } else if (activeTab === "registrations") {
                    setTableFiltersState((state) => ({
                      ...state,
                      registrationFilterState: {
                        ...state.registrationFilterState,
                        status: null,
                      },
                    }));
                  }
                  setActiveTab(key);
                }}
              >
                <Tab
                  eventKey="dashboard"
                  title={<h4 className="my-0">Overview</h4>}
                >
                  <AnalyticsDashboard />
                </Tab>
                <Tab
                  eventKey="registrations"
                  title={<h4 className="my-0">Registrations</h4>}
                >
                  <RegistrationList />
                </Tab>
                <Tab
                  eventKey="intra-transfers"
                  title={<h4 className="my-0">Transfers (CATCH)</h4>}
                >
                  <TransfersListInt />
                </Tab>
                <Tab
                  eventKey="inter-transfers"
                  title={<h4 className="my-0">Transfers (External)</h4>}
                >
                  <TransfersListExt />
                </Tab>
              </Tabs>
            </TableFiltersContext.Provider>
          </QueriedAdminContext.Provider>
        </ParishConfigContext.Provider>
      </div>
    </>
  );
}
