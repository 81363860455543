import fire from "./fire";

export async function getPostLikedById(objid, userid) {
  const db = fire.firestore();

  return await db
    .collection("likes")
    .where("objid", "==", objid)
    .where("userid", "==", userid)
    .get();
}

export async function isLiked(objid, userid) {
  try {
    //retrieve data from firestore
    const like = await getPostLikedById(objid, userid);
    const list = [];
    like.forEach((doc) => {
      list.push(doc.data());
    });

    return list.length > 0 ? true : false;
  } catch (error) {
    console.log(error);
    console.error("Likes not yet created");
    return false;
  }
}

export async function getUserLikesById(uid) {
  const userRef = fire.firestore().doc(`users/${uid}`);
  const userData = await userRef.get();
  let likes = [];
  if (userData && userData.exists) {
    likes = userData.data()?.likes;
  }
  return likes;
}

export async function getLikesByType(uid, objtype) {
  const db = fire.firestore();
  const results = await db
    .collection("likes")
    .where("objtype", "==", objtype)
    .where("userid", "==", uid)
    .get();
  const result = [];
  results.forEach((doc) => {
    result.push(doc.data());
  });
  return result;
}

export async function getAllLikesByType(objtype) {
  const db = fire.firestore();
  const results = await db
    .collection("likes")
    .where("objtype", "==", objtype)
    .get();
  const result = [];
  results.forEach((doc) => {
    result.push(doc.data());
  });
  return result;
}
