import React from "react";
import DatePicker from "react-datepicker";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import "../../stylesheets/finance-qr-modal.css";

const QRDetailForm = ({
  modalProps,
  setModalState,
  isRefComplete,
  getCampaignRef,
}) => {
  const handleDateChange = (key, newDate) => {
    if (key === "startDate") {
      setModalState({
        ...modalProps,
        campaignStart: newDate,
        expiry: "",
      });
    }
  };

  return (
    <div className="create-qr-modal__body">
      <div className="create-qr-modal__form-details">
        <div className="create-qr-modal__side-section">
          <div className="create-qr-modal__section">
            <div className="create-qr-modal__section-title">Campaign Name</div>
            <input
              className="create-qr-modal__input-box"
              value={modalProps.campaignName}
              onChange={(e) =>
                setModalState({
                  ...modalProps,
                  campaignName: e.target.value,
                })
              }
            />
          </div>
          <div className="create-qr-modal__sub-section">
            <div className="create-qr-modal__section">
              <div className="create-qr-modal__section-title">Amount</div>
              <input
                className="create-qr-modal__input-box--padding"
                value={modalProps.amount}
                onChange={(e) =>
                  setModalState({
                    ...modalProps,
                    amount: e.target.value,
                  })
                }
                type="number"
                min="0.00"
                step="0.01"
              />
            </div>
            <div className="create-qr-modal__section--margin">
              <div className="create-qr-modal__section-title">
                Amount Editable
              </div>
              <div className="create-qr-modal__radio">
                <div className="create-qr-modal__radio-text">
                  <input
                    type="radio"
                    className="create-qr-modal__radio-input"
                    checked={modalProps.isEditable}
                    disabled
                    onChange={() =>
                      setModalState({
                        ...modalProps,
                        isEditable: true,
                      })
                    }
                  />
                  Yes
                </div>
                <div className="create-qr-modal__radio-text">
                  <input
                    type="radio"
                    className="create-qr-modal__radio-input"
                    checked={!modalProps.isEditable}
                    disabled
                    onChange={() =>
                      setModalState({
                        ...modalProps,
                        isEditable: false,
                      })
                    }
                  />
                  No
                </div>
              </div>
            </div>
          </div>
          <div className="create-qr-modal__section">
            <div className="create-qr-modal__section-title">Start Date</div>
            <div className="create-qr-modal__date-input">
              <CalendarTodayIcon className="create-qr-modal__date-icon" />
              <DatePicker
                selected={modalProps.campaignStart}
                onChange={(date) => {
                  date.setHours(0, 0, 0, 0);
                  handleDateChange("startDate", date);
                }}
                minDate={new Date()}
                dateFormat="MMMM d, yyyy h:mm aa"
                className="create-qr-modal__date-input-box"
                dayClassName={(date) => "create-qr-modal__date-picker-day"}
                timeClassName={(time) => "create-qr-modal__time-picker"}
              />
            </div>
          </div>
          <div className="create-qr-modal__section">
            <div className="create-qr-modal__section-title">Expiry</div>
            <div className="create-qr-modal__date-input">
              <CalendarTodayIcon className="create-qr-modal__date-icon" />
              <DatePicker
                selected={modalProps.expiry}
                onChange={(date) => {
                  setModalState({
                    ...modalProps,
                    expiry: date,
                  });
                }}
                minDate={
                  modalProps.campaignStart
                    ? new Date(
                        modalProps.campaignStart.getTime() + 24 * 60 * 60 * 1000
                      )
                    : new Date()
                }
                dateFormat="MMMM d, yyyy h:mm aa"
                className="create-qr-modal__date-input-box"
                dayClassName={(date) => "create-qr-modal__date-picker-day"}
                timeClassName={(time) => "create-qr-modal__time-picker"}
              />
            </div>
          </div>
          <div className="create-qr-modal__section">
            <div className="create-qr-modal__section-title">Comments</div>
            <textarea
              className="create-qr-modal__textarea"
              value={modalProps.comments}
              onChange={(e) =>
                setModalState({
                  ...modalProps,
                  comments: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="create-qr-modal__side-section">
          <div className="create-qr-modal__section-title">Reference</div>
          <div className="create-qr-modal__section">
            <div className="create-qr-modal__section-sub-title">
              Sub-Organization / Ministry Reference
            </div>
            <select className="create-qr-modal__select" disabled>
              <option>GP (General Purpose)</option>
            </select>
          </div>
          <div className="create-qr-modal__section">
            <div className="create-qr-modal__section-sub-title">
              Product Reference (2 Chars.)
            </div>
            <input
              className="create-qr-modal__input-box"
              maxLength="2"
              value={modalProps.reference.productRef}
              onChange={(e) => {
                setModalState({
                  ...modalProps,
                  reference: {
                    ...modalProps.reference,
                    productRef: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
                  },
                });
              }}
            />
          </div>
          <div className="create-qr-modal__section">
            <div className="create-qr-modal__section-sub-title">
              Sub-Reference (4 Chars.)
            </div>
            <input
              className="create-qr-modal__input-box"
              maxLength="4"
              value={modalProps.reference.subRef}
              onChange={(e) => {
                setModalState({
                  ...modalProps,
                  reference: {
                    ...modalProps.reference,
                    subRef: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
                  },
                });
              }}
            />
          </div>
          <div className="create-qr-modal__section">
            <div className="create-qr-modal__section-sub-title">
              Running Number (Auto-Generated)
            </div>
            <input
              className="create-qr-modal__input-box"
              disabled
              value={isRefComplete() ? modalProps.reference.runningNum : "-"}
            />
          </div>
          {isRefComplete() && (
            <div className="create-qr-modal__section">
              <div>Your campaign reference will be: </div>
              <div>{getCampaignRef()}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QRDetailForm;
