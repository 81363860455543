import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TelegramIcon from "@material-ui/icons/Telegram";
import { getLatestVersion } from "../../services/getversion";

const Footer = ({ hidelinks = false }) => {
  const [version, setVersion] = useState(null);

  useEffect(() => {
    getLatestVersion().then((version) => {
      setVersion(version);
    });
  }, []);

  return (
    <React.Fragment>
      <div className="clearfix"></div>
      <div className="col-12 py-4 my-4 text-center">
        {!hidelinks && (
          <div className="pt-0 mt-2 defaultfontsize">
            <Link to="/schedule">Schedule</Link>{" "}
            {/* <span className="text-secondary">&bull;</span>{" "}
            <Link to="/help">User Guide</Link>{" "} */}
            <span className="text-secondary">&bull;</span>{" "}
            <Link to="/faq">FAQ</Link>
          </div>
        )}
        <div className="normalfontsize mt-4 py-4 alert alert-warning">
          Join Telegram to get first-hand news from the Church{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://t.me/CatholicSG"
          >
            https://t.me/CatholicSG <TelegramIcon />
          </a>
        </div>
        <p className="mt-4 mb-0 text-muted">
          &copy; {new Date().getFullYear()} Roman Catholic Archdiocese of
          Singapore. All Rights Reserved.
        </p>
        <p className="my-0 text-muted">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.catholic.sg/terms/"
            className="text-muted"
          >
            Terms & Conditions
          </a>
        </p>
        <p className="my-0 text-muted">
          <Link to="/contributors" className="text-muted">
            Contributors
          </Link>
        </p>
        {version !== null && (
          <p className="my-4 text-muted">Version: {version?.version}</p>
        )}
      </div>
    </React.Fragment>
  );
};

export default Footer;
