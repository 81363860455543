import React from "react";
import { Button, Image } from "react-bootstrap";
import { getPayNowUENById } from "../../../services/parish";
import giftlogo from "../../../img/gift/gift.png";
import WeeklyMassOfferingBar from "../../common/weeklyMassOfferingBar";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import { logEvent } from "../../../services/log";

function OfferingGiving({ actor }) {
  const handlePledgeNowClick = () => {
    logEvent("ignite_ad_mybookings", {
      type: "Catholic Foundation",
    });

    const win = window.open(
      "https://catholicfoundation.sg/give?source=MARS1",
      "_blank"
    );
    win.focus();
  };

  return (
    <>
      {actor && (
        <WeeklyMassOfferingBar
          label="paynow_clicked_mybookings"
          email={actor.email}
          parish={actor.parish}
          uen={getPayNowUENById(actor.parish)}
          css="pt-2 pb-1"
        />
      )}
      <div className="mt-3">
        <div
          className="mt-0 mb-1 py-4 
              mediumfontsize"
          style={{
            borderBottom: "1px dashed #cdcdcd",
            borderTop: "1px dashed #cdcdcd",
          }}
        >
          <div className="text-center">
            <a
              className="my-0 pb-2 mb-2"
              href="https://catholicfoundation.sg/give?source=MARS1"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Image
                src={giftlogo}
                width="140px"
                style={{ marginTop: "-10px" }}
                alt=""
              />
            </a>
          </div>
          <div className="p-3 mediumfontsize">
            Let's support the future of our Catholic Church in Singapore by
            making a monthly pledge, no matter how small, so that we can
            continue to ignite and shine our faith on generations to come.
          </div>
          <Button
            variant="danger"
            className="btn-sm mt-2 mb-2"
            onClick={handlePledgeNowClick}
          >
            <span className="">
              Support Our Church{" "}
              <OpenInNewOutlinedIcon style={{ fontSize: "14px" }} />
            </span>
          </Button>
          <div className="text-center">Credit Card / PayNow</div>
        </div>
      </div>
    </>
  );
}

export default OfferingGiving;
