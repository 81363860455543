import React, { useState, useContext, useEffect } from "react";
import LoadingSpinner from "../../common/loadingSpinner";
import Header from "../../common/header";
import AppGlobalContext from "../../../AppGlobalContext";
import ParishLogo from "../../parish/parishLogoBlock";
import { useHistory } from "react-router-dom";
import NameClock from "../../common/nameClock";
import LinkButton from "../../blocks/linkButtonBlock";
import fire from "../../../services/fire";
import { getParishCompleteNameById } from "../../../services/parish";
import { isUserLoggedIn } from "../../../services/user";
import { Row, Modal } from "react-bootstrap";
import MainMenuCardSimplePanel from "../../blocks/mainMenuCardPanelSimpleBlock";
import GenQRPayment from "../component/GenQRPayment";

const DEFAULT_STATE = {
  isLoading: true,
  vendorItems: [],
  selectedCampaignRef: "",
  selectedCampaignId: "",
  isModalOpen: false,
};

const PayPlusVendorPage = () => {
  const { user } = useContext(AppGlobalContext);
  const history = useHistory();
  const [state, setState] = useState(DEFAULT_STATE);
  const {
    isLoading,
    vendorItems,
    selectedCampaignRef,
    selectedCampaignId,
    isModalOpen,
  } = state;

  const fetchUserAssignedCampaigns = async () => {
    return new Promise((res, rej) => {
      const vendorCampaignSet = new Set();

      fire
        .firestore()
        .collection("membership")
        .where("userid", "==", user.userid)
        .where("membershipType", "==", "EVENT_VENDOR")
        .where("isActive", "==", true)
        .get()
        .then((resultSnapshot) => {
          if (resultSnapshot.docs.length > 0) {
            resultSnapshot.docs.forEach((doc) => {
              doc.data().otherDetails.forEach((campaignRef) => {
                vendorCampaignSet.add(campaignRef);
              });
            });
            res(vendorCampaignSet);
          } else {
            history.push("/404");
          }
        });
    });
  };

  const fetchOrgCampaigns = async (vendorCampaignSet) => {
    return new Promise((resolve, reject) => {
      const vendorCampaigns = [];
      const now = new Date().getTime();

      const getAllCampaigns = async () => {
        return new Promise((res, rej) => {
          fire
            .firestore()
            .collection("paypluscampaigns")
            .where("qrType", "==", "P")
            .get()
            .then((resultSnapshot) => {
              const resultSnapshotObj = [];
              resultSnapshot.docs.forEach((doc) => {
                const obj = doc.data();
                resultSnapshotObj.push(obj);
              });
              res(resultSnapshotObj);
            });
        });
      };

      const getOrgDetails = async (resultSnapshotObj) => {
        return new Promise((res, rej) => {
          resultSnapshotObj.forEach((obj) => {
            fire
              .firestore()
              .collection("payplusorgs")
              .where("orgId", "==", obj.orgId)
              .get()
              .then((orgResultSnapshot) => {
                if (!orgResultSnapshot.empty) {
                  orgResultSnapshot.docs.forEach((orgDoc) => {
                    const orgObj = orgDoc.data();
                    obj.orgName = orgObj.orgName;
                    obj.orgShortName = orgObj.orgShortName;
                    obj.payplusUEN = orgObj.payplusUEN;
                  });
                }

                if (obj.approvalStatus === 1 && !obj.isPrivate) {
                  const campaignStart = new Date(
                    obj.campaignStart?.seconds
                  ).getTime();
                  if (campaignStart && campaignStart < now) {
                    if (obj.expiry) {
                      if (new Date(obj.expiry).getTime() > now) {
                        obj.isValid = true;
                      }
                    } else {
                      obj.isValid = true;
                    }
                  }
                }

                if (obj.isValid) {
                  if (obj.isPayOnce) {
                    fire
                      .firestore()
                      .collection("payplusrequests")
                      .where("createdBy", "==", user.userid)
                      .where("isCompleted", "==", true)
                      .where("campaignRef", "==", obj.campaignRef)
                      .get()
                      .then((payRequestSnapshot) => {
                        if (payRequestSnapshot.docs.length > 0) {
                          obj.isPaidDisabled = true;
                          obj.campaignName += " (Paid)";
                        } else {
                          obj.isPaidDisabled = false;
                        }
                      });
                  } else {
                    obj.isPaidDisabled = false;
                  }
                }
              });
          });
          res(resultSnapshotObj);
        });
      };

      getAllCampaigns().then((resultSnapshotObj) =>
        getOrgDetails(resultSnapshotObj).then((resultSnapshotObj) => {
          resultSnapshotObj.forEach((obj) => {
            if (vendorCampaignSet.has(obj.campaignRef)) {
              vendorCampaigns.push(obj);
            }
          });
          resolve(vendorCampaigns);
        })
      );
    });
  };

  useEffect(() => {
    if (user && isUserLoggedIn(user)) {
      fetchUserAssignedCampaigns()
        .then((vendorCampaignSet) => fetchOrgCampaigns(vendorCampaignSet))
        .then((vendorCampaigns) =>
          setState({ ...state, isLoading: false, vendorItems: vendorCampaigns })
        );
    }
  }, [user]);

  useEffect(() => {
    if (selectedCampaignRef !== "") {
      fire
        .firestore()
        .collection("paypluscampaigns")
        .where("campaignRef", "==", selectedCampaignRef)
        .get()
        .then((resultSnapshot) => {
          if (resultSnapshot.docs.length > 0) {
            const obj = resultSnapshot.docs[0].data();
            setState({ ...state, selectedCampaignId: obj.id });
          } else {
            console.log("no campaign reference found!");
          }
        });
    }
  }, [selectedCampaignRef]);

  const displayCards = () => {
    const cards = [];
    if (vendorItems && vendorItems.length > 0) {
      vendorItems.forEach((item) => {
        const { campaignName, campaignRef, orgId, amount, isPaidDisabled } =
          item;
        cards.push(
          <div className="p-1 w-auto" key={campaignRef}>
            <MainMenuCardSimplePanel
              key={campaignRef}
              handlerPrimary={(e) => {
                e.preventDefault();
                setState({
                  ...state,
                  selectedCampaignRef: campaignRef,
                  isModalOpen: true,
                });
              }}
              menulinklabel={campaignName}
              menuDetail={`S$${amount}`}
              menuDescription={getParishCompleteNameById(orgId)}
              menuicon="payment"
              menutype="light"
              isDisabled={isPaidDisabled}
            />
          </div>
        );
      });
    }

    return cards;
  };

  const handlePaymentSuccess = (payplusRequestObj) => {
    document.getElementById("bottom-text-transaction-success").hidden = false;
  };

  return isLoading ? (
    <>
      <LoadingSpinner />
    </>
  ) : (
    <>
      <>
        <Header type="backandlogo" /> <Header type="backandlogo" />
        <ParishLogo parishId={user.parish} />
        <div className="justify-content-center mx-0">
          <div className="mx-5 px-1">
            <div className="text-center">
              <NameClock name={user.fullname} />
            </div>
            {vendorItems.length < 2 ? (
              <Row xs={1} md={1} className="w-auto">
                {displayCards()}
              </Row>
            ) : (
              <Row xs={1} md={2} className="w-auto">
                {displayCards()}
              </Row>
            )}

            <div className="pt-2 form-group">
              <LinkButton type="link" link="/home" label="Back to Home" />
            </div>
          </div>
        </div>
      </>
      <Modal
        keyboard={false}
        onHide={() =>
          setState({
            ...state,
            isModalOpen: false,
            selectedCampaignRef: "",
            selectedCampaignId: "",
          })
        }
        show={isModalOpen}
        size="lg"
      >
        <Modal.Header className="border-0 pb-0" closeButton />
        <div className="px-4">
          <GenQRPayment
            userObj={user}
            selectedCampaignId={selectedCampaignId}
            onFailureCB={() => {}}
            onSuccessCB={(payplusRequestObj) =>
              handlePaymentSuccess(payplusRequestObj)
            }
            onReturnBtnCB={() => setState({ ...state, isModalOpen: false })}
            onFailureBtnCB={() => setState({ ...state, isModalOpen: false })}
            showSuccessBtn={false}
          />{" "}
          <div
            id="bottom-text-transaction-success"
            className="mt-4 mb-5"
            style={{ fontSize: "16px" }}
            hidden
          >
            <div className="mt-0 mx-3">
              <p>Payment has been successfully made!</p>
              <p>You may now safely close this page.</p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PayPlusVendorPage;
