import React from "react";

const SpinnerText = ({ text, color = "" }) => {
  return (
    <React.Fragment>
      <span
        className={`spinner-border spinner-border-sm ${color}`}
        role="status"
        aria-hidden="true"
      ></span>{" "}
      {text}
    </React.Fragment>
  );
};

export default SpinnerText;
