import React, { useEffect, useContext, useRef, useState } from "react";
import fire from "../../../../../services/fire";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import DatePicker from "react-datepicker";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Error from "@material-ui/icons/Error";

import { useForm, Controller } from "react-hook-form";

import AppGlobalContext from "../../../../../AppGlobalContext";

import {
  useWardSelection,
  useGuardian,
  useRegistrationDetails,
  useParishConfig,
  Sacraments,
} from "../../hooks/hooks";
import { registrationCollectionName } from "../../services/collections";
import { sortedDistricts } from "../../services/parishes";
import { registrationStatusNameToStatus } from "../../services/registrationStatus";

import SaveDraftConfirmationModal from "./SaveDraftConfirmationModal";
import { getBase64 } from "../../services/fileUtils";

const catchUpload = fire.functions("asia-east2").httpsCallable("catchUpload");

function handleSaveData(
  registrationCollection,
  registrationDetails,
  registrationData,
  ward,
  uid,
  setRegistrationStep,
  jumpTo,
  fromReview,
  setFromReview
) {
  if (!uid) {
    return () => {};
  }

  let registrationDoc;

  if (registrationDetails.id) {
    registrationDoc = registrationCollection.doc(registrationDetails.id);

    const docData = {
      ...registrationDetails,
      ...registrationData,
    };

    registrationDoc.update(docData).then(() => {
      if (jumpTo > 0) {
        if (fromReview) {
          setRegistrationStep(7);
          setFromReview(false);
        } else {
          setRegistrationStep(jumpTo);
        }

        window.scrollTo(0, 0);
      }
    });
  } else {
    registrationDoc = registrationCollection.doc();

    const docData = {
      id: registrationDoc.id,
      status: registrationStatusNameToStatus("unregistered"),
      submittedAt: new Date(),
      submittedBy: uid,
      child: {
        name: ward.name,
        uid: ward.uid,
      },
      payments: [],
      ...registrationData,
    };

    registrationDoc.set(docData).then(() => {
      if (jumpTo > 0) {
        if (fromReview) {
          setRegistrationStep(7);
          setFromReview(false);
        } else {
          setRegistrationStep(jumpTo);
        }

        window.scrollTo(0, 0);
      }
    });
  }
}

async function handleUploadBaptismCert(
  uploadpath,
  fileName,
  file,
  imageDataType,
  imageFileName,
  setValue,
  setIsUploadingBaptism
) {
  try {
    const base64File = await getBase64(file);

    const {
      data: { ok, error, message },
    } = await catchUpload({
      file: base64File,
      uploadpath,
      fileName,
      imageDataType,
    });

    if (ok) {
      const baptismCertUrl = `${uploadpath}/${fileName}`;

      setValue("sacraments.baptism.cert.path", baptismCertUrl);
      setValue("sacraments.baptism.cert.name", imageFileName);

      alert(`Baptism Certificate ${message}`);

      setIsUploadingBaptism(false);
    } else {
      console.error("handleUploadBaptismCert::error:", error);

      alert(message);

      setIsUploadingBaptism(false);
    }
  } catch (e) {
    console.error("handleUploadBaptismCert::error:", e);
    alert("An error has occured");
  }
}

async function handleUploadConfirmationCert(
  uploadpath,
  fileName,
  file,
  imageDataType,
  imageFileName,
  setValue,
  setIsUploadingConfirmation
) {
  try {
    const base64File = await getBase64(file);

    const {
      data: { ok, error, message },
    } = await catchUpload({
      file: base64File,
      uploadpath,
      fileName,
      imageDataType,
    });

    if (ok) {
      const confirmationCertUrl = `${uploadpath}/${fileName}`;

      setValue("sacraments.confirmation.cert.path", confirmationCertUrl);
      setValue("sacraments.confirmation.cert.name", imageFileName);

      alert(`Confirmation Certificate ${message}`);

      setIsUploadingConfirmation(false);
    } else {
      console.error("handleUploadConfirmationCert::error:", error);

      alert(message);

      setIsUploadingConfirmation(false);
    }
  } catch (e) {
    console.error("handleUploadBaptismCert::error:", e);

    alert("An error has occured");

    setIsUploadingConfirmation(false);
  }
}

export default function SacramentDetails({
  registrationStep,
  setRegistrationStep,
  fromReview,
  setFromReview,
}) {
  /** @type {{sacraments: Sacraments}} */
  const registrationDetails = useRegistrationDetails();
  const parishConfig = useParishConfig();

  const { user } = useContext(AppGlobalContext);
  const { selectedWardIndex } = useWardSelection();
  const { wardsData } = useGuardian();

  const ward = wardsData[selectedWardIndex];

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);

  /** @type {Sacraments} */
  const defaultSacraments = {
    baptism: {
      received: "",
      receivedInSingapore: "",
      date: "",
      church: "",
      cert: { path: "", name: "" },
    },
    reconciliation: {
      received: "",
      receivedInSingapore: "",
      date: "",
      church: "",
    },
    eucharist: {
      received: "",
      receivedInSingapore: "",
      date: "",
      church: "",
    },
    confirmation: {
      received: "",
      receivedInSingapore: "",
      date: "",
      church: "",
      cert: { path: "", name: "" },
    },
  };

  /** @type {(keyof Sacraments)[]} */
  const expressSacraments = registrationDetails.expressSacraments ?? []; // Sacrament(s) to skip prerequisite checks for

  const {
    register,
    watch,
    handleSubmit,
    getValues,
    setValue,
    reset,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { sacraments: defaultSacraments },
    criteriaMode: "all",
  });

  const churches = parishConfig.filter(({ isDummy = false }) => !isDummy);

  const effectTriggeredRef = useRef(false);

  useEffect(() => {
    if (!effectTriggeredRef.current && registrationDetails.id) {
      effectTriggeredRef.current = true;

      let receivedBaptism = "",
        baptisedInSingapore = "",
        receivedFirstRecon = "",
        firstReconInSingapore = "",
        receivedFhc = "",
        fhcInSingapore = "",
        receivedConfirmation = "",
        confirmationInSingapore = "";

      receivedBaptism = registrationDetails.sacraments?.baptism?.received;
      baptisedInSingapore =
        registrationDetails.sacraments?.baptism?.receivedInSingapore;
      receivedFirstRecon =
        registrationDetails.sacraments?.reconciliation?.received;
      firstReconInSingapore =
        registrationDetails.sacraments?.reconciliation?.receivedInSingapore;
      receivedFhc = registrationDetails.sacraments?.eucharist?.received;
      fhcInSingapore =
        registrationDetails.sacraments?.eucharist?.receivedInSingapore;
      receivedConfirmation =
        registrationDetails.sacraments?.confirmation?.received;
      confirmationInSingapore =
        registrationDetails.sacraments?.confirmation?.receivedInSingapore;

      reset({
        sacraments: {
          baptism: {
            received: receivedBaptism,
            receivedInSingapore: baptisedInSingapore,
            date:
              registrationDetails.sacraments?.baptism?.date?.toDate?.() || "",
            church: registrationDetails.sacraments?.baptism?.church || "",
            cert: {
              path: registrationDetails.sacraments?.baptism?.cert?.path || "",
              name: registrationDetails.sacraments?.baptism?.cert?.name || "",
            },
          },
          reconciliation: {
            received: receivedFirstRecon,
            receivedInSingapore: firstReconInSingapore,
            date:
              registrationDetails.sacraments?.reconciliation?.date?.toDate?.() ||
              "",
            church:
              registrationDetails.sacraments?.reconciliation?.church || "",
          },
          eucharist: {
            received: receivedFhc,
            receivedInSingapore: fhcInSingapore,
            date:
              registrationDetails.sacraments?.eucharist?.date?.toDate?.() || "",
            church: registrationDetails.sacraments?.eucharist?.church || "",
          },
          confirmation: {
            received: receivedConfirmation,
            receivedInSingapore: confirmationInSingapore,
            date:
              registrationDetails.sacraments?.confirmation?.date?.toDate?.() ||
              "",
            church: registrationDetails.sacraments?.confirmation?.church || "",
            cert: {
              path:
                registrationDetails.sacraments?.confirmation?.cert?.path || "",
              name:
                registrationDetails.sacraments?.confirmation?.cert?.name || "",
            },
          },
        },
      });
    }

    return () => {
      effectTriggeredRef.current = false;
      reset();
    };
  }, [registrationDetails]);

  function onSubmit() {
    const registrationData = getValues();

    let canProceed = true;

    if (registrationData.sacraments?.baptism?.received) {
      if (registrationData.sacraments?.baptism?.cert?.path === "") {
        canProceed = false;
      }
    }

    if (registrationData.receivedConfirmation) {
      if (registrationData.sacraments?.confirmation?.cert?.path === "") {
        canProceed = false;
      }
    }

    if (canProceed) {
      clearErrors("sacraments.baptism.cert.path");
      clearErrors("sacraments.confirmation.cert.path");

      handleSaveData(
        registrationCollection,
        registrationDetails,
        registrationData,
        ward,
        user.uid,
        setRegistrationStep,
        registrationStep + 1,
        fromReview,
        setFromReview
      );
    } else {
      if (registrationData.sacraments?.baptism?.cert?.path === "") {
        setError("sacraments.baptism.cert.path");
      }

      if (registrationData.sacraments?.confirmation?.cert?.path === "") {
        setError("sacraments.confirmation.cert.path");
      }

      window.scrollTo(0, 0);
    }
  }

  function onSaveDraft() {
    const registrationData = getValues();

    handleSaveData(
      registrationCollection,
      registrationDetails,
      registrationData,
      ward,
      user.uid,
      setRegistrationStep,
      0,
      fromReview,
      setFromReview
    );
  }

  const receivedBaptism = watch("sacraments.baptism.received");
  const baptisedInSingapore = watch("sacraments.baptism.receivedInSingapore");
  const dateOfBaptism = watch("sacraments.baptism.date");
  const baptismCertFileName = watch("sacraments.baptism.cert.name");
  const receivedFirstRecon = watch("sacraments.reconciliation.received");
  const firstReconInSingapore = watch(
    "sacraments.reconciliation.receivedInSingapore"
  );
  const receivedFhc = watch("sacraments.eucharist.received");
  const fhcInSingapore = watch("sacraments.eucharist.receivedInSingapore");
  const receivedConfirmation = watch("sacraments.confirmation.received");
  const confirmationInSingapore = watch(
    "sacraments.confirmation.receivedInSingapore"
  );
  const confirmationCertFileName = watch("sacraments.confirmation.cert.name");

  // console.log(watch("sacraments"));

  let showFR = false,
    showFHC = false;
  let showBaptised = true,
    showFirstReconInSingapore = true,
    showFhc = true,
    showConfirm = true;

  if (receivedFirstRecon) {
    showFR = true;
  }

  if (receivedFhc) {
    showFHC = true;
  }

  if (!baptisedInSingapore) {
    showBaptised = false;
  }

  if (!firstReconInSingapore) {
    showFirstReconInSingapore = false;
  }

  if (!fhcInSingapore) {
    showFhc = false;
  }

  if (!confirmationInSingapore) {
    showConfirm = false;
  }

  //
  // baptism
  //

  const [receivedBaptismCount, setReceivedBaptismCount] = useState(0);

  useEffect(() => {
    if (receivedBaptismCount > 1) {
      setValue("sacraments.baptism.receivedInSingapore", "");
      setValue("sacraments.baptism.date", "");
      setValue("sacraments.baptism.church", "");
      setValue("sacraments.baptism.cert.path", "");
      setValue("sacraments.baptism.cert.name", "");

      clearErrors("sacraments.baptism.cert.path");
    } else {
      setReceivedBaptismCount(receivedBaptismCount + 1);
    }
  }, [receivedBaptism]);

  const [baptisedCount, setBaptisedCount] = useState(0);

  useEffect(() => {
    if (baptisedCount > 1) {
      setValue("sacraments.baptism.church", "");
    } else {
      setBaptisedCount(baptisedCount + 1);
    }
  }, [baptisedInSingapore]);

  //
  // reconciliation
  //

  const [receivedReconCount, setReceivedReconCount] = useState(0);

  useEffect(() => {
    if (receivedReconCount > 1) {
      setValue("sacraments.reconciliation.date", "");
      setValue("sacraments.reconciliation.receivedInSingapore", "");
      setValue("sacraments.reconciliation.church", "");
    } else {
      setReceivedReconCount(receivedReconCount + 1);
    }
  }, [receivedFirstRecon]);

  const [reconCount, setReconCount] = useState(0);

  useEffect(() => {
    if (reconCount > 1) {
      setValue("sacraments.reconciliation.church", "");
    } else {
      setReconCount(reconCount + 1);
    }
  }, [firstReconInSingapore]);

  //
  // communion
  //

  const [receivedCommunionCount, setReceivedCommunionCount] = useState(0);

  useEffect(() => {
    if (receivedCommunionCount > 1) {
      setValue("sacraments.eucharist.date", "");
      setValue("sacraments.eucharist.receivedInSingapore", "");
      setValue("sacraments.eucharist.church", "");
    } else {
      setReceivedCommunionCount(receivedCommunionCount + 1);
    }
  }, [receivedFhc]);

  const [fhcCount, setFhcCount] = useState(0);

  useEffect(() => {
    if (fhcCount > 1) {
      setValue("sacraments.eucharist.church", "");
    } else {
      setFhcCount(fhcCount + 1);
    }
  }, [fhcInSingapore]);

  //
  // confirmation
  //

  const [receivedConfirmationCount, setReceivedConfirmationCount] = useState(0);

  useEffect(() => {
    if (receivedConfirmationCount > 1) {
      setValue("sacraments.confirmation.receivedInSingapore", "");
      setValue("sacraments.confirmation.date", "");
      setValue("sacraments.confirmation.church", "");
      setValue("sacraments.confirmation.cert.path", "");
      setValue("sacraments.confirmation.cert.name", "");

      clearErrors("sacraments.confirmation.cert.path");
    } else {
      setReceivedConfirmationCount(receivedConfirmationCount + 1);
    }
  }, [receivedConfirmation]);

  const [confirmCount, setConfirmCount] = useState(0);

  useEffect(() => {
    if (confirmCount > 1) {
      setValue("sacraments.confirmation.church", "");
    } else {
      setConfirmCount(confirmCount + 1);
    }
  }, [confirmationInSingapore]);

  const dateOfBirth = registrationDetails.dateOfBirth
    ? registrationDetails.dateOfBirth.toDate()
    : "";

  let baptismMinDate, firstReconMinDate, fhcMinDate, confirmationMinDate;

  if (dateOfBirth) {
    baptismMinDate = new Date(dateOfBirth);
    baptismMinDate.setDate(baptismMinDate.getDate() + 1);
  }

  if (dateOfBaptism) {
    firstReconMinDate = new Date(dateOfBaptism);
    firstReconMinDate.setDate(firstReconMinDate.getDate() + 1);
    fhcMinDate = new Date(dateOfBaptism);
    fhcMinDate.setDate(fhcMinDate.getDate() + 1);
    confirmationMinDate = new Date(dateOfBaptism);
    confirmationMinDate.setDate(confirmationMinDate.getDate() + 1);
  }

  const [isUploadingBaptism, setIsUploadingBaptism] = useState(false);
  const [isUploadingConfirmation, setIsUploadingConfirmation] = useState(false);

  const baptismRef = useRef();

  function baptismHandleClick() {
    baptismRef.current.click();
  }

  function baptismOnFileChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      setIsUploadingBaptism(true);

      const file = e.target.files[0];
      const imageDataType = file.type;
      const imageFileName = file.name;
      const uploadpath = `user/${user.userid}/private/baptism`;

      let fileName = "";

      switch (imageDataType) {
        case "application/pdf":
          fileName = `${user.userid}${registrationDetails.id}.pdf`;
          break;
        case "image/jpeg":
          fileName = `${user.userid}${registrationDetails.id}.jpeg`;
          break;
        case "image/png":
          fileName = `${user.userid}${registrationDetails.id}.png`;
          break;
        default:
      }

      if (file.size > 4000000) {
        alert("You have exceeded the max file size of 4MB");

        setError("sacraments.baptism.cert.path");

        setIsUploadingBaptism(false);
      } else if (fileName) {
        clearErrors("sacraments.baptism.cert.path");

        handleUploadBaptismCert(
          uploadpath,
          fileName,
          file,
          imageDataType,
          imageFileName,
          setValue,
          setIsUploadingBaptism
        );
      }
    } else {
      setError("sacraments.baptism.cert.path");
    }
  }

  const confirmationRef = useRef();

  function confirmationHandleClick() {
    confirmationRef.current.click();
  }

  function confirmationOnFileChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      setIsUploadingConfirmation(true);

      const file = e.target.files[0];
      const imageDataType = file.type;
      const imageFileName = file.name;
      const uploadpath = `user/${user.userid}/private/confirmation`;

      let fileName = "";

      switch (imageDataType) {
        case "application/pdf":
          fileName = `${user.userid}${registrationDetails.id}.pdf`;
          break;
        case "image/jpeg":
          fileName = `${user.userid}${registrationDetails.id}.jpeg`;
          break;
        case "image/png":
          fileName = `${user.userid}${registrationDetails.id}.png`;
          break;
        default:
      }

      if (file.size > 4000000) {
        alert("You have exceeded the max file size of 4MB");

        setError("sacraments.confirmation.cert.path");

        setIsUploadingConfirmation(false);
      } else if (fileName) {
        clearErrors("sacraments.confirmation.cert.path");

        handleUploadConfirmationCert(
          uploadpath,
          fileName,
          file,
          imageDataType,
          imageFileName,
          setValue,
          setIsUploadingConfirmation
        );
      }
    } else {
      setError("sacraments.confirmation.cert.path");
    }
  }

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {(receivedBaptism && !!errors.sacraments?.baptism?.cert?.path) ||
      (receivedConfirmation &&
        !!errors.sacraments?.confirmation?.cert?.path) ? (
        <>
          <Card
            className="d-flex flex-column border-danger"
            style={{ backgroundColor: "#FFF4F3" }}
          >
            <Card.Body className="d-flex flex-column align-items-center py-4">
              <Container>
                <h3>There is a problem with your submission</h3>
                {receivedBaptism && !!errors.sacraments?.baptism?.cert?.path ? (
                  <u className="text-danger">Baptism certificate is required</u>
                ) : (
                  <></>
                )}
                {!!errors.sacraments?.baptism?.cert?.path &&
                !!errors.sacraments?.confirmation?.cert?.path ? (
                  <br />
                ) : (
                  <></>
                )}
                {receivedConfirmation &&
                !!errors.sacraments?.confirmation?.cert?.path ? (
                  <u className="text-danger">
                    Confirmation certificate is required
                  </u>
                ) : (
                  <></>
                )}
              </Container>
            </Card.Body>
          </Card>
          <br />
        </>
      ) : (
        <></>
      )}
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <div
              className="position-absolute p-2 border-bottom border-left"
              style={{
                right: "0px",
                top: "0px",
                borderBottomLeftRadius: "0.25rem",
                backgroundColor: "#dee2e6",
              }}
            >
              3&nbsp;<span className="text-muted">of 4</span>
            </div>
            <h3>Baptism</h3>
          </Container>
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">
                    Has your child received the Sacrament of Baptism?
                  </Form.Label>
                  <Row className="w-100">
                    <Controller
                      control={control}
                      name="sacraments.baptism.received"
                      rules={{ validate: (v) => v === true || v === false }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              label="Yes"
                              id="receivedBaptismTrue"
                              onBlur={onBlur}
                              onChange={() => onChange(true)}
                              checked={value === true}
                              ref={ref}
                              isInvalid={!!errors.sacraments?.baptism?.received}
                            />
                          </Col>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              label="No"
                              id="receivedBaptismFalse"
                              onBlur={onBlur}
                              onChange={() => onChange(false)}
                              checked={value === false}
                              ref={ref}
                              isInvalid={!!errors.sacraments?.baptism?.received}
                            />
                          </Col>
                        </>
                      )}
                    />
                  </Row>
                </Form.Row>
                {!receivedBaptism ? (
                  <></>
                ) : (
                  <>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">
                        Was your child Baptised in a Catholic church in
                        Singapore?
                      </Form.Label>
                      <Row className="w-100">
                        <Controller
                          control={control}
                          name="sacraments.baptism.receivedInSingapore"
                          rules={{
                            validate: (v) =>
                              !receivedBaptism || v === true || v === false,
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <>
                              <Col xs="auto">
                                <Form.Check
                                  className="w-100"
                                  type="radio"
                                  label="Yes"
                                  id="baptisedInSingaporeTrue"
                                  onBlur={onBlur}
                                  onChange={() => onChange(true)}
                                  checked={value === true}
                                  ref={ref}
                                  isInvalid={
                                    !!errors.sacraments?.baptism
                                      ?.receivedInSingapore
                                  }
                                />
                              </Col>
                              <Col xs="auto">
                                <Form.Check
                                  className="w-100"
                                  type="radio"
                                  label="No"
                                  id="baptisedInSingaporeFalse"
                                  onBlur={onBlur}
                                  onChange={() => onChange(false)}
                                  checked={value === false}
                                  ref={ref}
                                  isInvalid={
                                    !!errors.sacraments?.baptism
                                      ?.receivedInSingapore
                                  }
                                />
                              </Col>
                            </>
                          )}
                        />
                      </Row>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">
                        When was your child Baptised? (e.g. 01 Jun 2018)
                      </Form.Label>
                      <Row className="w-100 mx-0">
                        <Col xs={12} sm={6}>
                          <Row>
                            <Controller
                              name={"sacraments.baptism.date"}
                              control={control}
                              rules={{
                                required: receivedBaptism,
                              }}
                              render={({
                                field: { onBlur, onChange, value },
                              }) => (
                                <Form.Control
                                  as={DatePicker}
                                  size="lg"
                                  selected={value}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  dateFormat="dd MMM yyyy"
                                  placeholderText="dd mmm yyyy"
                                  maxDate={new Date()}
                                  showYearDropdown={true}
                                  showMonthDropdown={true}
                                  isInvalid={!!errors.sacraments?.baptism?.date}
                                  minDate={
                                    baptismMinDate ? baptismMinDate : new Date()
                                  }
                                />
                              )}
                            />
                            {!!errors.sacraments?.baptism?.date ? (
                              <></>
                            ) : (
                              <div
                                className="position-absolute"
                                style={{ top: "10px", right: "10px" }}
                              >
                                <CalendarTodayIcon />
                              </div>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="d-flex flex-row w-100">
                        In which church was your child Baptised?
                      </Form.Label>
                      <Row className="w-100 mx-0">
                        <Col className="px-0" xs={12} sm={6}>
                          {showBaptised ? (
                            <Form.Control
                              className="w-100"
                              as="select"
                              size="lg"
                              {...register("sacraments.baptism.church", {
                                required: baptisedInSingapore,
                              })}
                              isInvalid={!!errors.sacraments?.baptism?.church}
                            >
                              <option
                                value=""
                                disabled
                                hidden
                                className="text-muted font-italic"
                              >
                                Please select
                              </option>
                              {sortedDistricts.map(
                                ([districtId, districtName]) => (
                                  <optgroup
                                    key={districtId}
                                    label={districtName}
                                  >
                                    {churches
                                      .filter(
                                        ({ district }) =>
                                          district.charAt(0).toUpperCase() +
                                            district.slice(1) ===
                                          districtName
                                      )
                                      .map(({ id, parish }) => (
                                        <option key={parish} value={id}>
                                          {parish}
                                        </option>
                                      ))}
                                  </optgroup>
                                )
                              )}
                            </Form.Control>
                          ) : (
                            <Form.Control
                              size="lg"
                              className="w-100"
                              placeholder="Please enter full address"
                              {...register("sacraments.baptism.church", {
                                required: !baptisedInSingapore,
                              })}
                              isInvalid={!!errors.sacraments?.baptism?.church}
                            />
                          )}
                        </Col>
                      </Row>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="d-flex flex-row w-100 mb-0">
                        <b>Baptism Certificate</b>
                      </Form.Label>
                      <span className="text-muted">
                        Max file size is 4MB. Supported file types .jpg, .png,
                        and .pdf.
                      </span>
                      <Row className="w-100 mt-3 mx-0">
                        <Col className="px-0" xs="auto">
                          {baptismCertFileName === "" ? (
                            <Button
                              // variant={!!errors.sacraments?.baptism?.cert?.path ? "danger" : "outline-primary"}
                              variant="outline-primary"
                              size="lg"
                              className="w-100 d-flex align-items-center"
                              onClick={baptismHandleClick}
                              disabled={
                                isUploadingBaptism || isUploadingConfirmation
                              }
                            >
                              {isUploadingBaptism ? (
                                <>
                                  <Spinner
                                    className="mr-1"
                                    animation="border"
                                  />
                                  Uploading
                                </>
                              ) : (
                                <>
                                  <ArrowUpwardOutlinedIcon className="mr-1" />
                                  Upload file
                                </>
                              )}
                            </Button>
                          ) : (
                            <Button
                              variant="secondary"
                              size="lg"
                              className="w-100 text-wrap text-dark"
                              style={{
                                backgroundColor: "#f3f3f3",
                                borderColor: "#f3f3f3",
                              }}
                              onClick={() => {
                                setValue("sacraments.baptism.cert.path", "");
                                setValue("sacraments.baptism.cert.name", "");

                                baptismRef.current.value = "";
                              }}
                              disabled={
                                isUploadingBaptism || isUploadingConfirmation
                              }
                            >
                              <span>{baptismCertFileName}</span>
                              <DeleteOutlinedIcon className="text-danger ml-1" />
                            </Button>
                          )}
                        </Col>
                      </Row>
                      <input
                        style={{ height: "0px" }}
                        id="file-input"
                        type="file"
                        onChange={baptismOnFileChange}
                        accept="application/pdf,image/jpeg,image/png"
                        ref={baptismRef}
                      />
                      <Form.Text muted className="w-100">
                        Max file size is 4MB. Supported file types: jpg, png,
                        pdf.
                      </Form.Text>
                    </Form.Row>
                    {receivedBaptism &&
                    !!errors.sacraments?.baptism?.cert?.path ? (
                      <Row className="align-items-center w-100 my-2 px-2 text-danger">
                        <Error className="mr-1" style={{ fontSize: "18px" }} />
                        Baptism certificate is required
                      </Row>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <br />
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <h3>First Reconciliation</h3>
          </Container>
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">
                    Has your child received their First Reconciliation?
                  </Form.Label>
                  <Controller
                    control={control}
                    name="sacraments.reconciliation.received"
                    rules={{
                      validate: {
                        baptismRequired: (v) =>
                          ((receivedBaptism ||
                            expressSacraments.includes("reconciliation")) &&
                            v === true) ||
                          v === false,
                      },
                    }}
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { invalid, error },
                    }) => (
                      <>
                        <Row className="w-100">
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              label="Yes"
                              id="receivedFirstReconTrue"
                              onBlur={onBlur}
                              onChange={() => onChange(true)}
                              checked={value === true}
                              ref={ref}
                              isInvalid={invalid}
                            />
                          </Col>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              label="No"
                              id="receivedFirstReconFalse"
                              onBlur={onBlur}
                              onChange={() => onChange(false)}
                              checked={value === false}
                              ref={ref}
                              isInvalid={invalid}
                            />
                          </Col>
                        </Row>
                        <Form.Text className="text-danger">
                          {invalid && (
                            <>
                              The following prerequisite Sacraments must also
                              have been received:
                              <ul className="my-0">
                                {error?.types?.baptismRequired && (
                                  <li>Baptism</li>
                                )}
                              </ul>
                              For special cases, please{" "}
                              <a href="mailto:mycatholicsg@catholic.org.sg">
                                contact us
                              </a>
                              .
                            </>
                          )}
                        </Form.Text>
                      </>
                    )}
                  />
                </Form.Row>
                {showFR ? (
                  <>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">
                        Did your child receive their First Reconciliation in a
                        Catholic church in Singapore?
                      </Form.Label>
                      <Row className="w-100">
                        <Controller
                          control={control}
                          name="sacraments.reconciliation.receivedInSingapore"
                          rules={{
                            validate: (v) =>
                              !receivedFirstRecon || v === true || v === false,
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <>
                              <Col xs="auto">
                                <Form.Check
                                  className="w-100"
                                  type="radio"
                                  label="Yes"
                                  id="firstReconInSingaporeTrue"
                                  onBlur={onBlur}
                                  onChange={() => onChange(true)}
                                  checked={value === true}
                                  ref={ref}
                                  isInvalid={
                                    !!errors.sacraments?.reconciliation
                                      ?.receivedInSingapore
                                  }
                                />
                              </Col>
                              <Col xs="auto">
                                <Form.Check
                                  className="w-100"
                                  type="radio"
                                  label="No"
                                  id="firstReconInSingaporeFalse"
                                  onBlur={onBlur}
                                  onChange={() => onChange(false)}
                                  checked={value === false}
                                  ref={ref}
                                  isInvalid={
                                    !!errors.sacraments?.reconciliation
                                      ?.receivedInSingapore
                                  }
                                />
                              </Col>
                            </>
                          )}
                        />
                      </Row>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100 text-wrap">
                        Date of First Reconcilation (e.g. 01 Jun 2018)
                      </Form.Label>
                      <Row className="w-100 mx-0">
                        <Col xs={12} sm={6}>
                          <Row>
                            <Controller
                              name={"sacraments.reconciliation.date"}
                              control={control}
                              rules={{
                                required: receivedFirstRecon,
                              }}
                              render={({
                                field: { onBlur, onChange, value },
                              }) => (
                                <Form.Control
                                  as={DatePicker}
                                  size="lg"
                                  selected={value}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  dateFormat="dd MMM yyyy"
                                  placeholderText="dd mmm yyyy"
                                  maxDate={new Date()}
                                  showYearDropdown={true}
                                  showMonthDropdown={true}
                                  minDate={
                                    firstReconMinDate
                                      ? firstReconMinDate
                                      : new Date()
                                  }
                                />
                              )}
                            />
                            {!!errors.sacraments?.reconciliation?.date ? (
                              <></>
                            ) : (
                              <div
                                className="position-absolute"
                                style={{ top: "10px", right: "10px" }}
                              >
                                <CalendarTodayIcon />
                              </div>
                            )}
                          </Row>
                        </Col>
                      </Row>
                      <Form.Text muted>
                        Select "01" for date if not known. Select "Jan" for
                        month if not known.
                      </Form.Text>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100 text-wrap">
                        In which church did your child receive their First
                        Reconciliation?&nbsp;
                        <span className="text-muted">(optional)</span>
                      </Form.Label>
                      <Row className="w-100 mx-0">
                        <Col className="px-0" xs={12} sm={6}>
                          {showFirstReconInSingapore ? (
                            <Form.Control
                              className="w-100"
                              as="select"
                              size="lg"
                              {...register("sacraments.reconciliation.church", {
                                required: firstReconInSingapore,
                              })}
                              isInvalid={
                                !!errors.sacraments?.reconciliation?.church
                              }
                            >
                              <option
                                value=""
                                disabled
                                hidden
                                className="text-muted font-italic"
                              >
                                Please select
                              </option>
                              {sortedDistricts.map(
                                ([districtId, districtName]) => (
                                  <optgroup
                                    key={districtId}
                                    label={districtName}
                                  >
                                    {churches
                                      .filter(
                                        ({ district }) =>
                                          district.charAt(0).toUpperCase() +
                                            district.slice(1) ===
                                          districtName
                                      )
                                      .map(({ id, parish }) => (
                                        <option key={parish} value={id}>
                                          {parish}
                                        </option>
                                      ))}
                                  </optgroup>
                                )
                              )}
                            </Form.Control>
                          ) : (
                            <Form.Control
                              size="lg"
                              className="w-100"
                              placeholder="Please enter full address"
                              {...register("sacraments.reconciliation.church", {
                                required: !firstReconInSingapore,
                              })}
                            />
                          )}
                        </Col>
                      </Row>
                    </Form.Row>
                  </>
                ) : null}
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <br />
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <h3>First Holy Communion</h3>
          </Container>
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">
                    Has your child received First Holy Communion?
                  </Form.Label>
                  <Controller
                    control={control}
                    name="sacraments.eucharist.received"
                    rules={{
                      validate: {
                        baptismRequired: (v) =>
                          ((receivedBaptism ||
                            expressSacraments.includes("eucharist")) &&
                            v === true) ||
                          v === false,
                        firstReconRequired: (v) =>
                          ((receivedFirstRecon ||
                            expressSacraments.includes("eucharist")) &&
                            v === true) ||
                          v === false,
                      },
                    }}
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { invalid, error },
                    }) => (
                      <>
                        <Row className="w-100">
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              label="Yes"
                              id="receivedFhcTrue"
                              onBlur={onBlur}
                              onChange={() => onChange(true)}
                              checked={value === true}
                              ref={ref}
                              isInvalid={invalid}
                            />
                          </Col>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              label="No"
                              id="receivedFhcFalse"
                              onBlur={onBlur}
                              onChange={() => onChange(false)}
                              checked={value === false}
                              ref={ref}
                              isInvalid={invalid}
                            />
                          </Col>
                        </Row>
                        <Form.Text className="text-danger">
                          {invalid && (
                            <>
                              The following prerequisite Sacraments must also
                              have been received:
                              <ul className="my-0">
                                {error?.types?.baptismRequired && (
                                  <li>Baptism</li>
                                )}
                                {error?.types?.firstReconRequired && (
                                  <li>First Reconciliation</li>
                                )}
                              </ul>
                              For special cases, please{" "}
                              <a href="mailto:mycatholicsg@catholic.org.sg">
                                contact us
                              </a>
                              .
                            </>
                          )}
                        </Form.Text>
                      </>
                    )}
                  />
                </Form.Row>
                {showFHC ? (
                  <>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">
                        Did your child receive First Holy Communion in a
                        Catholic church Singapore?
                      </Form.Label>
                      <Controller
                        control={control}
                        name="sacraments.eucharist.receivedInSingapore"
                        rules={{
                          validate: (v) =>
                            receivedFhc || v === true || v === false,
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { invalid },
                        }) => (
                          <>
                            <Row className="w-100">
                              <Col xs="auto">
                                <Form.Check
                                  className="w-100"
                                  type="radio"
                                  label="Yes"
                                  id="fhcInSingaporeTrue"
                                  onBlur={onBlur}
                                  onChange={() => onChange(true)}
                                  checked={value === true}
                                  ref={ref}
                                  isInvalid={invalid}
                                />
                              </Col>
                              <Col xs="auto">
                                <Form.Check
                                  className="w-100"
                                  type="radio"
                                  label="No"
                                  id="fhcInSingaporeFalse"
                                  onBlur={onBlur}
                                  onChange={() => onChange(false)}
                                  checked={value === false}
                                  ref={ref}
                                  isInvalid={invalid}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      />
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100 text-wrap">
                        Date of First Holy Communion (e.g. 01 Jun 2018)
                      </Form.Label>
                      <Row className="w-100 mx-0">
                        <Col xs={12} sm={6}>
                          <Row>
                            <Controller
                              name={"sacraments.eucharist.date"}
                              control={control}
                              rules={{
                                required: receivedFhc,
                              }}
                              render={({
                                field: { onBlur, onChange, value },
                              }) => (
                                <Form.Control
                                  as={DatePicker}
                                  size="lg"
                                  selected={value}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  dateFormat="dd MMM yyyy"
                                  placeholderText="dd mmm yyyy"
                                  maxDate={new Date()}
                                  showYearDropdown={true}
                                  showMonthDropdown={true}
                                  minDate={fhcMinDate ? fhcMinDate : new Date()}
                                />
                              )}
                            />
                            {!!errors.sacraments?.eucharist?.date ? (
                              <></>
                            ) : (
                              <div
                                className="position-absolute"
                                style={{ top: "10px", right: "10px" }}
                              >
                                <CalendarTodayIcon />
                              </div>
                            )}
                          </Row>
                        </Col>
                      </Row>
                      <Form.Text muted>
                        Select "01" for date if not known. Select "Jan" for
                        month if not known.
                      </Form.Text>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100 text-wrap">
                        In which church did your child receive First Holy
                        Communion?&nbsp;
                        <span className="text-muted">(optional)</span>
                      </Form.Label>
                      <Row className="w-100 mx-0">
                        <Col className="px-0" xs={12} sm={6}>
                          {showFhc ? (
                            <Form.Control
                              className="w-100"
                              as="select"
                              size="lg"
                              {...register("sacraments.eucharist.church", {
                                required: fhcInSingapore,
                              })}
                            >
                              <option
                                value=""
                                disabled
                                hidden
                                className="text-muted font-italic"
                              >
                                Please select
                              </option>
                              {sortedDistricts.map(
                                ([districtId, districtName]) => (
                                  <optgroup
                                    key={districtId}
                                    label={districtName}
                                  >
                                    {churches
                                      .filter(
                                        ({ district }) =>
                                          district.charAt(0).toUpperCase() +
                                            district.slice(1) ===
                                          districtName
                                      )
                                      .map(({ id, parish }) => (
                                        <option key={parish} value={id}>
                                          {parish}
                                        </option>
                                      ))}
                                  </optgroup>
                                )
                              )}
                            </Form.Control>
                          ) : (
                            <Form.Control
                              size="lg"
                              className="w-100"
                              placeholder="Please enter full address"
                              {...register("sacraments.eucharist.church", {
                                required: !fhcInSingapore,
                              })}
                            />
                          )}
                        </Col>
                      </Row>
                    </Form.Row>
                  </>
                ) : null}
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <br />
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <h3>Confirmation</h3>
          </Container>
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">
                    Has your child received the Sacrament of Confirmation?
                  </Form.Label>
                  <Controller
                    control={control}
                    name="sacraments.confirmation.received"
                    rules={{
                      validate: {
                        baptismRequired: (v) =>
                          ((receivedBaptism ||
                            expressSacraments.includes("confirmation")) &&
                            v === true) ||
                          v === false,
                        firstReconRequired: (v) =>
                          ((receivedFirstRecon ||
                            expressSacraments.includes("confirmation")) &&
                            v === true) ||
                          v === false,
                        fhcRequired: (v) =>
                          ((receivedFhc ||
                            expressSacraments.includes("confirmation")) &&
                            v === true) ||
                          v === false,
                      },
                    }}
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { invalid, error },
                    }) => (
                      <>
                        <Row className="w-100">
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              label="Yes"
                              id="receivedConfirmationTrue"
                              onBlur={onBlur}
                              onChange={() => onChange(true)}
                              checked={value === true}
                              ref={ref}
                              isInvalid={invalid}
                            />
                          </Col>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              label="No"
                              id="receivedConfirmationFalse"
                              onBlur={onBlur}
                              onChange={() => onChange(false)}
                              checked={value === false}
                              ref={ref}
                              isInvalid={invalid}
                            />
                          </Col>
                        </Row>
                        <Form.Text className="text-danger">
                          {invalid && (
                            <>
                              The following prerequisite Sacraments must also
                              have been received:
                              <ul className="my-0">
                                {error?.types?.baptismRequired && (
                                  <li>Baptism</li>
                                )}
                                {error?.types?.firstReconRequired && (
                                  <li>First Reconciliation</li>
                                )}
                                {error?.types?.fhcRequired && (
                                  <li>First Holy Communion</li>
                                )}
                              </ul>
                              For special cases, please{" "}
                              <a href="mailto:mycatholicsg@catholic.org.sg">
                                contact us
                              </a>
                              .
                            </>
                          )}
                        </Form.Text>
                      </>
                    )}
                  />
                </Form.Row>
                {receivedConfirmation ? (
                  <>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">
                        Was your child Confirmed in a Catholic church in
                        Singapore?
                      </Form.Label>
                      <Row className="w-100">
                        {" "}
                        <Controller
                          control={control}
                          name="sacraments.confirmation.receivedInSingapore"
                          rules={{
                            validate: (v) =>
                              receivedConfirmation || v === true || v === false,
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <>
                              <Col xs="auto">
                                <Form.Check
                                  className="w-100"
                                  type="radio"
                                  label="Yes"
                                  id="confirmationInSingaporeTrue"
                                  onBlur={onBlur}
                                  onChange={() => onChange(true)}
                                  checked={value === true}
                                  ref={ref}
                                  isInvalid={
                                    !!errors.sacraments?.confirmation
                                      ?.receivedInSingapore
                                  }
                                />
                              </Col>
                              <Col xs="auto">
                                <Form.Check
                                  className="w-100"
                                  type="radio"
                                  label="No"
                                  id="confirmationInSingaporeFalse"
                                  onBlur={onBlur}
                                  onChange={() => onChange(false)}
                                  checked={value === false}
                                  ref={ref}
                                  isInvalid={
                                    !!errors.sacraments?.confirmation
                                      ?.receivedInSingapore
                                  }
                                />
                              </Col>
                            </>
                          )}
                        />
                      </Row>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">
                        When was your child Confirmed? (e.g. 01 Jun 2018)
                      </Form.Label>
                      <Row className="w-100 mx-0">
                        <Col xs={12} sm={6}>
                          <Row>
                            <Controller
                              name={"sacraments.confirmation.date"}
                              control={control}
                              rules={{
                                required: confirmationInSingapore,
                              }}
                              render={({
                                field: { onBlur, onChange, value },
                              }) => (
                                <Form.Control
                                  as={DatePicker}
                                  size="lg"
                                  selected={value}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  dateFormat="dd MMM yyyy"
                                  placeholderText="dd mmm yyyy"
                                  maxDate={new Date()}
                                  showYearDropdown={true}
                                  showMonthDropdown={true}
                                  minDate={
                                    confirmationMinDate
                                      ? confirmationMinDate
                                      : new Date()
                                  }
                                />
                              )}
                            />
                            {!!errors.sacraments?.confirmation?.date ? (
                              <></>
                            ) : (
                              <div
                                className="position-absolute"
                                style={{ top: "10px", right: "10px" }}
                              >
                                <CalendarTodayIcon />
                              </div>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="d-flex flex-row w-100">
                        In which church was your child Confirmed?
                      </Form.Label>
                      <Row className="w-100 mx-0">
                        <Col className="px-0" xs={12} sm={6}>
                          {showConfirm ? (
                            <Form.Control
                              className="w-100"
                              as="select"
                              size="lg"
                              {...register("sacraments.confirmation.church", {
                                required: !confirmationInSingapore,
                              })}
                              isInvalid={
                                !!errors.sacraments?.confirmation?.church
                              }
                            >
                              <option
                                value=""
                                disabled
                                hidden
                                className="text-muted font-italic"
                              >
                                Please select
                              </option>
                              {sortedDistricts.map(
                                ([districtId, districtName]) => (
                                  <optgroup
                                    key={districtId}
                                    label={districtName}
                                  >
                                    {churches
                                      .filter(
                                        ({ district }) =>
                                          district.charAt(0).toUpperCase() +
                                            district.slice(1) ===
                                          districtName
                                      )
                                      .map(({ id, parish }) => (
                                        <option key={parish} value={id}>
                                          {parish}
                                        </option>
                                      ))}
                                  </optgroup>
                                )
                              )}
                            </Form.Control>
                          ) : (
                            <Form.Control
                              size="lg"
                              className="w-100"
                              placeholder="Please enter full address"
                              {...register("sacraments.confirmation.church", {
                                required: false,
                              })}
                              isInvalid={
                                !!errors.sacraments?.confirmation?.church
                              }
                            />
                          )}
                        </Col>
                      </Row>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="d-flex flex-row w-100 mb-0">
                        <b>Confirmation Certificate</b>
                      </Form.Label>
                      <span className="text-muted">
                        Max file size is 4MB. Supported file types .jpg, .png,
                        and .pdf.
                      </span>
                      <Row className="w-100 mt-3 mx-0">
                        <Col className="px-0" xs="auto">
                          {confirmationCertFileName === "" ? (
                            <Button
                              // variant={!!errors.sacraments?.confirmation?.cert?.path ? "danger" : "outline-primary"}
                              variant="outline-primary"
                              size="lg"
                              className="w-100 d-flex align-items-center"
                              onClick={confirmationHandleClick}
                              disabled={
                                isUploadingBaptism || isUploadingConfirmation
                              }
                            >
                              {isUploadingConfirmation ? (
                                <>
                                  <Spinner
                                    className="mr-1"
                                    animation="border"
                                  />
                                  Uploading
                                </>
                              ) : (
                                <>
                                  <ArrowUpwardOutlinedIcon className="mr-1" />
                                  Upload file
                                </>
                              )}
                            </Button>
                          ) : (
                            <Button
                              variant="secondary"
                              size="lg"
                              className="w-100 text-wrap text-dark"
                              style={{
                                backgroundColor: "#f3f3f3",
                                borderColor: "#f3f3f3",
                              }}
                              onClick={() => {
                                setValue(
                                  "sacraments.confirmation.cert.path",
                                  ""
                                );
                                setValue(
                                  "sacraments.confirmation.cert.name",
                                  ""
                                );

                                confirmationRef.current.value = "";
                              }}
                              disabled={
                                isUploadingBaptism || isUploadingConfirmation
                              }
                            >
                              <span>{confirmationCertFileName}</span>
                              <DeleteOutlinedIcon className="text-danger ml-1" />
                            </Button>
                          )}
                        </Col>
                      </Row>
                      <input
                        style={{ height: "0px" }}
                        id="file-input"
                        type="file"
                        onChange={confirmationOnFileChange}
                        accept="application/pdf,image/jpeg,image/png"
                        ref={confirmationRef}
                      />
                    </Form.Row>
                    {receivedConfirmation &&
                    !!errors.sacraments?.confirmation?.cert?.path ? (
                      <Row className="align-items-center w-100 my-2 px-2 text-danger">
                        <Error className="mr-1" style={{ fontSize: "18px" }} />
                        Confirmation certificate is required
                      </Row>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Container className="px-0 mt-4">
        <Row>
          <Col xs={12} sm="auto">
            <Button
              variant="outline-primary"
              size="lg"
              className="w-100"
              onClick={() => {
                setRegistrationStep(registrationStep - 1);

                window.scrollTo(0, 0);
              }}
              disabled={isUploadingBaptism || isUploadingConfirmation}
            >
              Back
            </Button>
          </Col>
          <Col className="d-none d-sm-flex" />
          <Col className="mt-3 mt-sm-0" xs="auto">
            <Button
              variant="outline-primary"
              size="lg"
              className="w-100"
              onClick={() => setShowModal(true)}
              disabled={isUploadingBaptism || isUploadingConfirmation}
            >
              Save Draft
            </Button>
          </Col>
          <Col className="col mt-3 mt-sm-0" sm="auto">
            <Button
              variant="primary"
              size="lg"
              className="w-100"
              onClick={handleSubmit(onSubmit)}
              disabled={isUploadingBaptism || isUploadingConfirmation}
            >
              Next
            </Button>
          </Col>
        </Row>
      </Container>
      {registrationDetails.id && registrationDetails.programmeType ? (
        <Container className="px-0 mt-4 text-wrap text-center">
          If you have enquiries, please contact your Parish Catechist
          Coordinator at&nbsp;
          <span className="text-primary" style={{ cursor: "pointer" }}>
            {registrationDetails.programmeType.enquiryEmail}
          </span>
        </Container>
      ) : (
        <></>
      )}
      <SaveDraftConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        onSaveDraft={onSaveDraft}
      />
    </>
  );
}
