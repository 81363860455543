import React, { useState, useEffect } from "react";
import _ from "lodash";

import Button from "react-bootstrap/Button";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";

import Moment from "react-moment";

import { getShortMonths } from "../../../../../utils/utils";

import { levelIdToName, levelRef } from "../../services/levels";
import { parishes } from "../../services/parishes";
import { useParishConfig } from "../../hooks/hooks";

import { tsToMoment } from "../services/formatTimestamp";

import { downloadCert } from "../services/downloadCert";

function handleAddNote(
  noteText,
  selectedItem,
  user,
  registrationCollection,
  setNoteText,
  setIsAddingNote,
  setSelectedItem,
  setSelectedNotes
) {
  if (!selectedItem.id || !user.uid) {
    setIsAddingNote(false);

    alert("Something went wrong while adding a note.");

    return;
  }

  const registrationDoc = registrationCollection.doc(selectedItem.id);

  const noteData = {
    createdAt: new Date(),
    note: noteText,
    noteBy: user.uid,
    noteByName: user.displayName,
  };

  let finalNotes = selectedItem.notes ? [...selectedItem.notes] : [];

  finalNotes.push(noteData);

  const docData = {
    ...selectedItem,
    notes: finalNotes,
  };

  Object.keys(docData).forEach(
    (key) => docData[key] === undefined && delete docData[key]
  );

  registrationDoc
    .update(docData)
    .then(() => {
      alert("Note successfully added.");

      setNoteText("");
      setSelectedItem(docData);
      setSelectedNotes([...docData.notes]);
      setIsAddingNote(false);
    })
    .catch((error) => {
      console.error("handleAddNote::error:", error);

      alert("Something went wrong while adding a note.");
    });
}

function parseRegistrationType(registrationType) {
  switch (registrationType) {
    case "new":
      return "New Registration";
    case "onboarding":
      return "Onboard Existing";
    case "transfer":
      return "External Transfer-In";
  }
  return null;
}

export default function RegistrationDetails({
  user,
  registrationCollection,
  selectedItem,
  setSelectedItem,
  selectedNotes,
  setSelectedNotes,
}) {
  const parishConfig = useParishConfig();

  const [isEditingNote, setIsEditingNote] = useState(false);
  const [isAddingNote, setIsAddingNote] = useState(false);

  const [isDownloadingCustodyDoc, setIsDownloadingCustodyDoc] = useState(false);
  const [isDownloadingBaptismCert, setIsDownloadingBaptismCert] =
    useState(false);
  const [isDownloadingConfirmationCert, setIsDownloadingConfirmationCert] =
    useState(false);

  const isDownloading = {
    custodyDoc: isDownloadingCustodyDoc,
    baptismCert: isDownloadingBaptismCert,
    confirmationCert: isDownloadingConfirmationCert,
  };
  const setIsDownloading = {
    custodyDoc: setIsDownloadingCustodyDoc,
    baptismCert: setIsDownloadingBaptismCert,
    confirmationCert: setIsDownloadingConfirmationCert,
  };

  const [noteText, setNoteText] = useState("");

  function noteTextChange({ currentTarget: input }) {
    setNoteText(input.value);
  }

  useEffect(() => {
    if (isAddingNote) {
      handleAddNote(
        noteText,
        selectedItem,
        user,
        registrationCollection,
        setNoteText,
        setIsAddingNote,
        setSelectedItem,
        setSelectedNotes
      );
    }

    return () => {};
  }, [isAddingNote]);

  function displayPostingTime(date) {
    if (!date) {
      return "";
    }

    const shortMonths = getShortMonths();
    const monthStr = shortMonths[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const meridiem = hour - 12 > 0 ? "PM" : "AM";

    const dateStr =
      day +
      " " +
      monthStr +
      " " +
      year +
      ", " +
      (hour > 12 ? hour - 12 : hour) +
      ":" +
      (minute < 10 ? "0" + minute : minute) +
      " " +
      meridiem;

    return dateStr;
  }

  function handleEditNote(
    user,
    index,
    selectedItem,
    selectedNotes,
    registrationCollection,
    setIsEditingNote,
    setSelectedNotes,
    setSelectedItem
  ) {
    if (!selectedItem.id || !user.uid) {
      setIsEditingNote(false);

      alert("Something went wrong while editing note.");

      return;
    }
    const notes = [...selectedNotes];

    notes[index].edit = false;
    notes[index].noteEditedBy = user.uid;
    notes[index].noteEditedByName = user.displayName;
    notes[index].editedAt = new Date();

    const newNotes = notes.map(function (e) {
      const { edit, ...rest } = e;
      return rest;
    });

    const registrationDoc = registrationCollection.doc(selectedItem.id);

    const docData = {
      ...selectedItem,
      notes: newNotes,
    };

    Object.keys(docData).forEach(
      (key) => docData[key] === undefined && delete docData[key]
    );

    registrationDoc
      .update(docData)
      .then(() => {
        alert("Note successfully edited.");

        setSelectedItem(docData);
        setSelectedNotes(notes);
        setIsEditingNote(false);
      })
      .catch((error) => {
        console.error("handleEditNote::error:", error);

        alert("Something went wrong while editing note.");
      });
  }

  function handlePreEditNote(index) {
    setIsEditingNote(true);

    setTimeout(() => {
      handleEditNote(
        user,
        index,
        selectedItem,
        selectedNotes,
        registrationCollection,
        setIsEditingNote,
        setSelectedNotes,
        setSelectedItem
      );
    }, 500);
  }

  if (selectedItem == null) return null;

  const parish = parishConfig.find(
    ({ id }) => id === selectedItem.selectedParishId
  );
  const programme = parish.programmes.find(
    ({ id }) => id === selectedItem.programmeType
  );
  const timeslot = programme.timeslots.find(
    ({ id }) => id === selectedItem.timeslot
  );

  // console.log(selectedItem);
  // console.log({ parish, programme, timeslot });

  return (
    <>
      <div className="mb-sm-3">
        <div className="mb-sm-3">
          <h5>Child's Basic Details</h5>
        </div>
        <strong>Name</strong>
        <div className="mb-sm-3">{selectedItem ? selectedItem.name : "-"}</div>
        <strong>Sex</strong>
        <div className="mb-sm-3">{selectedItem ? selectedItem.sex : "-"}</div>
        <strong>Date of Birth</strong>
        <div className="mb-sm-3">
          {selectedItem ? (
            <Moment
              date={tsToMoment(selectedItem.dateOfBirth)}
              format="DD MMM YYYY"
            />
          ) : (
            "-"
          )}
        </div>
        <strong>Country of Birth</strong>
        <div className="mb-sm-3">
          {selectedItem ? selectedItem.placeOfBirth : "-"}
        </div>
        <strong>Nationality</strong>
        <div className="mb-sm-3">
          {selectedItem ? selectedItem.nationality : "-"}
        </div>
        <strong>Mother's Religion</strong>
        <div className="mb-sm-3">
          {selectedItem && selectedItem.motherReligion
            ? selectedItem.motherReligion
            : "-"}
        </div>
        <strong>Father's Religion</strong>
        <div className="mb-sm-3">
          {selectedItem && selectedItem.fatherReligion
            ? selectedItem.fatherReligion
            : "-"}
        </div>
      </div>
      <div className="mb-sm-3">
        <div className="mb-sm-3">
          <h5>Address</h5>
        </div>
        <strong>Address</strong>
        <div>
          {" "}
          {`${selectedItem ? `${selectedItem.roadName} ` : ""}
      ${selectedItem ? `${selectedItem.blkNo} ` : ""}
      ${selectedItem ? `${selectedItem.floorNumber} ` : ""}
      ${selectedItem ? selectedItem.unitNumber : ""}`}
        </div>
        <div className="mb-sm-3">
          Singapore {selectedItem ? selectedItem.postalCode : ""}
        </div>
      </div>
      <div className="mb-sm-3">
        <div className="mb-sm-3">
          <h5>Child's Baptism</h5>
        </div>
        <strong>Received Baptism?</strong>
        <div className="mb-sm-3">
          {selectedItem?.sacraments?.baptism?.received ? "Yes" : "No"}
        </div>
        {selectedItem?.sacraments?.baptism?.received && (
          <>
            <div>
              {selectedItem?.sacraments?.baptism?.date ? (
                <Moment
                  date={tsToMoment(selectedItem.sacraments.baptism.date)}
                  format="DD MMM YYYY"
                />
              ) : (
                "—"
              )}
            </div>
            <div>
              {selectedItem.sacraments?.baptism?.receivedInSingapore
                ? parishes.find(
                    ({ parishId }) =>
                      parishId === +selectedItem.sacraments?.baptism?.church
                  )?.parishFull + " (Singapore)"
                : selectedItem.sacraments?.baptism?.church + " (Overseas)" ??
                  "—"}
            </div>
            <b>Baptism Certificate</b>
            <br />
            <Button
              disabled={isDownloading.baptismCert}
              variant="secondary"
              className="text-wrap text-dark"
              style={{
                backgroundColor: "#f3f3f3",
                borderColor: "#f3f3f3",
              }}
              onClick={async () => {
                setIsDownloading.baptismCert(true);
                await downloadCert(
                  selectedItem.sacraments?.baptism?.cert,
                  user
                );
                setIsDownloading.baptismCert(false);
              }}
            >
              {isDownloading.baptismCert && (
                <div
                  className="spinner-border mr-1"
                  role="status"
                  style={{ width: "1.5rem", height: "1.5rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              <AttachFileOutlinedIcon
                className="mr-1"
                style={{ color: "#7c7c7c" }}
              />
              {selectedItem.sacraments?.baptism?.cert.path
                ? selectedItem.sacraments?.baptism?.cert.name
                : "—"}
            </Button>
            <br />
          </>
        )}
        <br />
        <strong>Received First Reconciliation?</strong>
        <div className="mb-sm-3">
          {selectedItem?.sacraments?.reconciliation?.received ? "Yes" : "No"}
        </div>
        {selectedItem?.sacraments?.reconciliation?.received && (
          <>
            <div>
              {selectedItem?.sacraments?.reconciliation?.date ? (
                <Moment
                  date={tsToMoment(selectedItem.sacraments.reconciliation.date)}
                  format="DD MMM YYYY"
                />
              ) : (
                "—"
              )}
            </div>
            <div className="mb-sm-3">
              {selectedItem?.sacraments?.reconciliation?.receivedInSingapore
                ? "Singapore"
                : ""}
            </div>
            <div>
              {selectedItem?.sacraments?.reconciliation?.receivedInSingapore
                ? parishes.find(
                    ({ parishId }) =>
                      parishId ===
                      +selectedItem.sacraments?.reconciliation?.church
                  )?.parishFull
                : selectedItem.sacraments?.reconciliation?.church ?? "—"}
            </div>
          </>
        )}
        <strong>Received First Holy Communion?</strong>
        <div className="mb-sm-3">
          {selectedItem?.sacraments?.eucharist?.received ? "Yes" : "No"}
        </div>
        {selectedItem?.sacraments?.eucharist?.received && (
          <>
            <div>
              {typeof selectedItem?.sacraments?.eucharist?.date ? (
                <Moment
                  date={tsToMoment(selectedItem.sacraments.eucharist.date)}
                  format="DD MMM YYYY"
                />
              ) : (
                "—"
              )}
            </div>
            <div className="mb-sm-3">
              {selectedItem?.sacraments?.eucharist?.receivedInSingapore
                ? "Singapore"
                : ""}
            </div>
            <div>
              {selectedItem?.sacraments?.eucharist?.receivedInSingapore
                ? parishes.find(
                    ({ parishId }) =>
                      parishId === +selectedItem.sacraments?.eucharist?.church
                  )?.parishFull
                : selectedItem.sacraments?.eucharist?.church ?? "-"}
            </div>
          </>
        )}
        <strong>Received Confirmation?</strong>
        <div className="mb-sm-3">
          {selectedItem?.sacraments?.confirmation?.received ? "Yes" : "No"}
        </div>
        {selectedItem?.sacraments?.eucharist?.received && (
          <>
            <div>
              {selectedItem?.sacraments?.confirmation?.date ? (
                <Moment
                  date={tsToMoment(selectedItem.sacraments.confirmation.date)}
                  format="DD MMM YYYY"
                />
              ) : (
                "—"
              )}
            </div>
            <div className="mb-sm-3">
              {selectedItem?.sacraments?.confirmation?.receivedInSingapore
                ? "Singapore"
                : ""}
            </div>
            <div>
              {selectedItem?.sacraments?.confirmation?.receivedInSingapore
                ? parishes.find(
                    ({ parishId }) =>
                      parishId ===
                      +selectedItem?.sacraments?.confirmation?.church
                  )?.parishFull
                : selectedItem?.sacraments?.confirmation?.church ?? "—"}
            </div>
            <b>Confirmation Certificate</b>
            <br />
            <Button
              disabled={isDownloading.confirmationCert}
              variant="secondary"
              className="text-wrap text-dark"
              style={{
                backgroundColor: "#f3f3f3",
                borderColor: "#f3f3f3",
              }}
              onClick={async () => {
                setIsDownloading.confirmationCert(true);
                await downloadCert(
                  selectedItem.sacraments?.confirmation?.cert,
                  user
                );
                setIsDownloading.confirmationCert(false);
              }}
            >
              {isDownloading.confirmationCert && (
                <div
                  className="spinner-border mr-1"
                  role="status"
                  style={{ width: "1.5rem", height: "1.5rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              <AttachFileOutlinedIcon
                className="mr-1"
                style={{ color: "#7c7c7c" }}
              />
              {selectedItem.sacraments?.confirmation?.cert.path
                ? selectedItem.sacraments?.confirmation?.cert.name
                : "—"}
            </Button>
          </>
        )}
      </div>
      <div className="mb-sm-3">
        <div className="mb-sm-3">
          <h5>Child's Education</h5>
        </div>
        <strong>School as of 2023</strong>
        <div>{selectedItem ? selectedItem.school : "-"}</div>
        <div className="mb-sm-3">
          {selectedItem ? selectedItem.schoolLevel : "-"}
        </div>
      </div>
      <div className="mb-sm-3">
        <div className="mb-sm-3">
          <h5>Child's Miscellaneous</h5>
        </div>
        <strong>
          Do you have any additional information to share with us?
        </strong>
        <div className="mb-sm-3">
          {selectedItem ? selectedItem.additionalInformation : "-"}
        </div>
        <strong>
          Do you have any siblings attending catechesis at the same parish?
        </strong>
        {selectedItem?.siblingsDetails ? (
          <ul className="mb-sm-3">
            {selectedItem.siblingsDetails.map((data, index) => (
              <li className="w-100" key={`sibling-${index}`}>
                {data.siblingFullName || "—"} (
                {data.siblingLevel && data.siblingClass
                  ? `${levelIdToName(parseInt(data.siblingLevel))} ${
                      data.siblingClass
                    }`
                  : "—"}
                )
              </li>
            ))}
          </ul>
        ) : (
          "-"
        )}
      </div>
      <div className="mb-sm-3">
        <div className="mb-sm-3">
          <h5>Selected Programme</h5>
        </div>
        <strong>Parish</strong>
        <div className="mb-sm-3">{parish?.parish ?? "—"}</div>
        <strong>Programme</strong>
        <div className="mb-sm-3">{programme?.name ?? "—"}</div>
        <strong>Level</strong>
        <div className="mb-sm-3">
          {selectedItem ? levelIdToName(parseInt(selectedItem.level)) : "—"}
        </div>
        <strong>Timeslot</strong>
        <div className="mb-sm-3">{timeslot?.name ?? "—"}</div>
        <strong>Registration Type</strong>
        <div className="mb-sm-3">
          {parseRegistrationType(selectedItem?.registrationType) ?? "—"}
        </div>
      </div>

      <div className="mb-sm-3">
        <div className="mb-sm-3">
          <h5>Guardian Details</h5>
        </div>
        <strong>Guardian Name</strong>
        <div>{selectedItem?.guardianCode?.guardianName ?? "—"}</div>
        <br />
        <strong>Guardian Relationship</strong>
        <div>
          {selectedItem?.guardianCode
            ? selectedItem.guardianCode.guardianRelationship
            : "—"}
        </div>

        <br />
        <strong>Guardian Document</strong>
        {selectedItem?.guardianCode ? (
          <div>
            <Button
              disabled={isDownloading.custodyDoc}
              variant="secondary"
              className="text-wrap text-dark"
              style={{
                backgroundColor: "#f3f3f3",
                borderColor: "#f3f3f3",
              }}
              onClick={async () => {
                setIsDownloading.custodyDoc(true);
                await downloadCert(
                  { path: selectedItem.guardianCode?.custodyDoc },
                  user
                );
                setIsDownloading.custodyDoc(false);
              }}
            >
              {isDownloading.custodyDoc && (
                <div
                  className="spinner-border mr-1"
                  role="status"
                  style={{ width: "1.5rem", height: "1.5rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              <AttachFileOutlinedIcon
                className="mr-1"
                style={{ color: "#7c7c7c" }}
              />
              {selectedItem?.guardianCode?.custodyDoc
                ? selectedItem.guardianCode?.custodyDocFileName
                : "—"}
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="mb-sm-3">
        <div className="mb-sm-3">
          <h5>Contact Details</h5>
        </div>
        <strong>Preferred Contact</strong>
        <div>
          {!_.isEmpty(selectedItem?.mainContact)
            ? selectedItem.mainContact.name
            : "—"}
        </div>
        <div>
          {selectedItem?.mainContact
            ? selectedItem.mainContact.relationship === "Others"
              ? selectedItem.mainContact.relationshipOthers
              : selectedItem.mainContact.relationship
            : "—"}
        </div>
        <div>
          {selectedItem?.mainContact ? selectedItem.mainContact.religion : "—"}
        </div>
        <div>
          {selectedItem?.mainContact ? selectedItem.mainContact.email : "—"}
        </div>
        <div>
          {selectedItem?.mainContact ? selectedItem.mainContact.mobileNo : "—"}
        </div>
        <div className="mb-sm-3">
          {selectedItem?.mainContact
            ? selectedItem.mainContact.volunteer && (
                <>Note: Volunteer for Catechetical activities</>
              )
            : ""}
        </div>
        <strong>Secondary Contact</strong>
        <div>
          {selectedItem?.secondaryContact
            ? selectedItem.secondaryContact.name
            : "—"}
        </div>
        <div>
          {selectedItem?.secondaryContact
            ? selectedItem.secondaryContact.relationship === "Others"
              ? selectedItem.secondaryContact.relationshipOthers
              : selectedItem.secondaryContact.relationship
            : "—"}
        </div>
        <div>
          {selectedItem?.secondaryContact
            ? selectedItem.secondaryContact.religion
            : "—"}
        </div>
        <div>
          {selectedItem?.secondaryContact
            ? selectedItem.secondaryContact.email
            : "—"}
        </div>
        <div>
          {selectedItem?.secondaryContact
            ? selectedItem.secondaryContact.mobileNo
            : "—"}
        </div>
        {selectedItem?.secondaryContact.volunteer ? (
          <>
            <br />
            Note: Volunteer for Catechetical activities
          </>
        ) : (
          ""
        )}
        {selectedItem?.secondaryContact.emergencies ? (
          <>
            <br />
            Note: Only to be contacted for emergencies
          </>
        ) : (
          ""
        )}
      </div>
      <div className="mb-sm-3">
        <h5>Notes</h5>
      </div>
      <div className="form-group">
        <textarea
          onChange={noteTextChange}
          value={noteText}
          disabled={isAddingNote || isEditingNote}
          type="text"
          className="form-control form-control-lg"
        />
      </div>
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => setIsAddingNote(true)}
          disabled={isAddingNote || isEditingNote || noteText === ""}
        >
          Add Note
        </Button>
      </div>
      {selectedNotes.length ? (
        <div
          className="p-4 mt-3"
          style={{ backgroundColor: "#f5f5f5", borderRadius: "16px" }}
        >
          {selectedNotes.map((noteDetails, index) => {
            let postingTime = noteDetails.createdAt;

            if (Object.hasOwn(postingTime, "_seconds")) {
              postingTime = new Date(
                postingTime._seconds * 1000 + postingTime._nanoseconds / 1000000
              );
            } else if (postingTime.seconds) {
              postingTime = new Date(
                postingTime.seconds * 1000 + postingTime.nanoseconds / 1000000
              );
            } else if (typeof postingTime === "string") {
              postingTime = new Date(Date.parse(postingTime));
            }

            return (
              <div key={index}>
                <div className="mb-4">
                  <h5 className="mr-1">{noteDetails.noteByName}</h5>
                  &bull;&nbsp;
                  {displayPostingTime(postingTime)}
                  <span
                    className="text-primary"
                    style={{
                      position: "absolute",
                      right: "70px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const newNotes = [...selectedNotes];

                      if (newNotes[index].edit) {
                        newNotes[index] = _.cloneDeep(
                          selectedItem.notes[index]
                        );
                      } else {
                        newNotes[index].edit = !newNotes[index].edit;
                      }

                      setSelectedNotes([...newNotes]);
                    }}
                  >
                    {noteDetails.edit ? "Cancel Edit" : "Edit"}
                  </span>
                </div>
                {noteDetails.edit ? (
                  <>
                    <div className="form-group">
                      <textarea
                        onChange={(e) => {
                          const newNotes = [...selectedNotes];

                          newNotes[index].note = e.target.value;

                          setSelectedNotes([...newNotes]);
                        }}
                        value={noteDetails.note}
                        disabled={isAddingNote || isEditingNote}
                        type="text"
                        className="form-control form-control-lg"
                      />
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          if (!isAddingNote && !isEditingNote) {
                            handlePreEditNote(index);
                          }
                        }}
                        disabled={
                          isAddingNote ||
                          isEditingNote ||
                          noteDetails.note === ""
                        }
                      >
                        Edit Note
                      </Button>
                    </div>
                  </>
                ) : (
                  noteDetails.note
                )}
                {index < selectedNotes.length - 1 ? (
                  <>
                    {!noteDetails.edit ? <br /> : null}
                    <br />
                    <div
                      style={{
                        border: "1px solid lightgray",
                        borderTop: "0",
                      }}
                    />
                    <br />
                  </>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
}
