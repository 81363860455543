import fire from "./fire";

/**
 *
 * @param {*} parishCode example: sacredheart, ladyoflourdes, please see parish.js
 * @returns
 */
export async function getSchedule(parishCode) {
  const settings = await fire
    .firestore()
    .collection(`settings`)
    .doc(`schedule`)
    .collection(`config`)
    .doc(parishCode)
    .get();
  let data = null;
  if (settings && settings.exists) {
    data = settings.data();
  }
  return data;
}

export async function saveSchedule(data, parishCode, empty) {
  const settings = fire
    .firestore()
    .doc(`settings/schedule/config/${parishCode}`);
  const lastUpdate = new Date().getTime();
  if (empty) {
    await settings.set({ schedule: data, lastUpdate });
  } else {
    await settings.update({ schedule: data, lastUpdate });
  }
}

export function composeScheduleObj(id, data) {
  return {
    id: id,
    title: data.title,
    parish: data.parish,
    date: data.date,
    timestamp: data.timestamp,
    location: data.location,
    lang: data.lang,
    type: data.tag || "mass",
    notes: data.notes,
    day: data.day,
    month: data.month,
    capacity: data.capacity || 0,
    servers: data.servers || 0,
    reserved1: data.reserved1 || 0,
    reserved2: data.reserved2 || 0,
    duration: data.duration || 0,
    available: data.available || 0,
    weekend: data.weekend || false,
    closed: data.closed || false,
    source: data.source || "",
    serialcounter: 0,
    slotcounter: 0,
    availableslots: 0,
    disabled: false,
  };
}
