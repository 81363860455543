import React from "react";
import StoleBlue from "../../../../../img/sacraments/stole-blue.png";
import StoleGray from "../../../../../img/sacraments/stole-gray.png";
import FireBlue from "../../../../../img/sacraments/fire-blue.png";
import FireGray from "../../../../../img/sacraments/fire-gray.png";
import DropBlue from "../../../../../img/sacraments/drop-blue.png";
import DropGray from "../../../../../img/sacraments/drop-gray.png";
import ChaliceBlue from "../../../../../img/sacraments/chalice-blue.png";
import ChaliceGray from "../../../../../img/sacraments/chalice-gray.png";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// import { RegistrationDatum } from "../../hooks/hooks";

/**
 * @typedef SacramentsPillProps
 * @prop {RegistrationDatum} registrationDatum
 */

/**
 * @param {SacramentsPillProps}
 */
export default function SacramentsPill({ registrationDatum }) {
  const sacraments = registrationDatum?.sacraments;
  const baptism = sacraments?.baptism ?? { received: false };
  const reconciliation = sacraments?.reconciliation ?? { received: false };
  const eucharist = sacraments?.eucharist ?? { received: false };
  const confirmation = sacraments?.confirmation ?? { received: false };

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip style={{ opacity: 1 }}>Baptism</Tooltip>}
      >
        <span style={{ marginRight: "4px" }}>
          <img
            alt="Baptism"
            src={baptism.received ? DropBlue : DropGray}
            height="24px"
            width="24px"
            className="d-inline-block align-top"
          />
        </span>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip style={{ opacity: 1 }}>Reconciliation</Tooltip>}
      >
        <span style={{ marginRight: "4px" }}>
          <img
            alt="Reconciliation"
            src={reconciliation.received ? StoleBlue : StoleGray}
            height="24px"
            width="24px"
            className="d-inline-block align-top"
          />
        </span>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip style={{ opacity: 1 }}>Eucharist</Tooltip>}
      >
        <span style={{ marginRight: "4px" }}>
          <img
            alt="Eucharist"
            src={eucharist.received ? ChaliceBlue : ChaliceGray}
            height="24px"
            width="24px"
            className="d-inline-block align-top"
          />
        </span>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip style={{ opacity: 1 }}>Confirmation</Tooltip>}
      >
        <span>
          <img
            alt="Confirmation"
            src={confirmation.received ? FireBlue : FireGray}
            height="24px"
            width="24px"
            className="d-inline-block align-top"
          />
        </span>
      </OverlayTrigger>
    </div>
  );
}
