import React, { useContext } from 'react';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PayplusContext from '../payplusContext';
import MainMenuCardSimplePanel from '../../blocks/mainMenuCardPanelSimpleBlock';

const CampaignSelector = () => {
  const { payplusState, payplusDispatch } = useContext(PayplusContext);
  const { selectedCampaignList } = payplusState;

  const displayPayments = () => {
    const campaignElements = [];
    if (selectedCampaignList && selectedCampaignList.length > 0) {
      selectedCampaignList.forEach((campaign) => {
        campaignElements.push(
          <MainMenuCardSimplePanel
            key={campaign.campaignRef}
            handlerPrimary={(e) => {
              e.preventDefault();
              payplusDispatch({
                type: "SELECT_CAMPAIGN",
                payload: { selectedCampaign: campaign }
              });
            }}
            menulinklabel={campaign.campaignName}
            menuDetail={"S$" + campaign.amount}
            menuDescription={campaign.orgName}
            menuicon="payment"
            menutype="light"
            isDisabled={campaign.isPaidDisabled}
          />
        );
      });
    }
    return campaignElements;
  }

  return (
    <>
      <h2>Select Payment</h2>
      <div
        className="payplus-back--btn"
        onClick={() => payplusDispatch({
          type: "SET_STEP_ZERO",
        })}
      >
        <ArrowBackIosIcon style={{ fontSize: 16 }} />
        Back to Previous Selection
      </div>
      <div className="pb-3 pt-0 px-0">
        {displayPayments()}
      </div>
    </>
  )
}

export default CampaignSelector;