import React, { Component } from "react";
import BrowserTime from "./browserTime";

class NameClock extends Component {
  render() {
    return (
      <>
        <h5 className="mb-0 pb-0">{this.props.name}</h5>
        <h5 className="mb-0 pb-0">{this.props.id}</h5>
        <BrowserTime />
      </>
    );
  }
}

export default NameClock;
