import React from "react";

const Textarea = ({ name, label, error, ...rest }) => {
  let formClass = "form-control form-control-lg ";
  if (error) {
    formClass = `${formClass} is-invalid`;
  }
  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}

      <textarea {...rest} name={name} id={name} className={formClass} />
      {error && (
        <small style={{ marginTop: "5px" }} className="text-danger">
          {error}
        </small>
      )}
    </div>
  );
};

export default Textarea;
