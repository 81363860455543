import fire from "../../services/fire";

export const sendCampaignRequestEmail = ({
  email = false,
  approverName = false,
  requesterName = false,
  campaignName = false,
}) => {
  if (!email || !approverName || !requesterName || !campaignName) {
    return false;
  }
  const templateId = "d-ff94f39dc0084f0ebbeb673f00732c68";
  const callSendEmail = fire
    .functions("asia-east2")
    .httpsCallable("sendGenericEmail");
  const emailSubject = "myCatholicSG Pay+: QR Campaign Request";
  const emailBody = `
  Dear ${approverName}
  <br><br>
  A request has been made from ${requesterName}
  for the "${campaignName}" QR Campaign to be approved.
  <br><br><br>
  - myCatholicSG Pay+
  `;
  const param = {
    email,
    templateId,
    emailSubject,
    emailBody,
  };
  callSendEmail(param).then((res) => {
    if (res) {
      return true;
    } else {
      return false;
    }
  });
};

export const sendReqCampaignApprovalEmail = ({
  email = false,
  approverName = false,
  requesterName = false,
  campaignName = false,
  approverComments = "",
  approvalResult,
}) => {
  if (!email || !approverName || !requesterName || !campaignName) {
    return false;
  }
  const bodyResult = approvalResult ? "approved" : "rejected";
  const emailSubject = approvalResult
    ? "myCatholicSG Pay+: QR Campaign Approved"
    : "myCatholicSG Pay+: QR Campaign Rejected";
  const templateId = "d-ff94f39dc0084f0ebbeb673f00732c68";
  const callSendEmail = fire
    .functions("asia-east2")
    .httpsCallable("sendGenericEmail");
  const emailBody = `
  Dear ${requesterName}
  <br><br>
  Your QR Campaign, "${campaignName}" has been ${bodyResult} by ${approverName}.
  <br><br>
  Additional comments (if any), have been appended below:
  <br><br>
  ${approverComments}
  <br><br><br>
  - myCatholicSG Pay+
  `;
  const param = {
    email,
    templateId,
    emailSubject,
    emailBody,
  };
  callSendEmail(param).then((res) => {
    if (res) {
      return true;
    } else {
      return false;
    }
  });
  return;
};

export const sendAppCampaignApprovalEmail = ({
  email = false,
  approverName = false,
  campaignName = false,
  approvalResult,
}) => {
  if (!email || !approverName || !campaignName) {
    return false;
  }
  const bodyResult = approvalResult ? "approved" : "rejected";
  const emailSubject = approvalResult
    ? "myCatholicSG Pay+: QR Campaign Approval Confirmation"
    : "myCatholicSG Pay+: QR Campaign Rejection Confirmation";
  const templateId = "d-ff94f39dc0084f0ebbeb673f00732c68";
  const callSendEmail = fire
    .functions("asia-east2")
    .httpsCallable("sendGenericEmail");
  const emailBody = `
  Dear ${approverName}
  <br><br>
  This is an email confirmation that you have ${bodyResult} the QR Campaign, "${campaignName}"
  <br><br>
  If this was not you, please contact the team at Digital Church Team immediately.
  <br><br><br>
  - myCatholicSG Pay+
  `;
  const param = {
    email,
    templateId,
    emailSubject,
    emailBody,
  };
  callSendEmail(param).then((res) => {
    if (res) {
      return true;
    } else {
      return false;
    }
  });
  return;
};

export const sendAppCampaignRetireEmail = ({
  email = false,
  approverName = false,
  campaignName = false,
}) => {
  if (!email || !approverName || !campaignName) {
    return false;
  }
  const emailSubject = "myCatholicSG Pay+: QR Campaign Retirement";
  const templateId = "d-ff94f39dc0084f0ebbeb673f00732c68";
  const callSendEmail = fire
    .functions("asia-east2")
    .httpsCallable("sendGenericEmail");
  const emailBody = `
    Dear ${approverName}
  <br><br>
  This is an email confirmation that you have retired the QR Campaign, "${campaignName}"
  <br><br>
  If this was not you, please contact the team at Digital Church Team immediately.
  <br><br><br>
  - myCatholicSG Pay+
  `;
  const param = {
    email,
    templateId,
    emailSubject,
    emailBody,
  };
  callSendEmail(param).then((res) => {
    if (res) {
      return true;
    } else {
      return false;
    }
  });
  return false;
};

export const sendGenericHTMLEmail = async ({
  email = false,
  emailSubject = false,
  emailBody = false,
  emailReplyTo = "noreply_mycatholicsg@catholic.org.sg",
}) => {
  if (!email || !emailSubject || !emailBody) {
    return false;
  }
  console.log("sending...");
  const templateId = "d-ff94f39dc0084f0ebbeb673f00732c68";
  const callSendEmail = fire
    .functions("asia-east2")
    .httpsCallable("sendGenericEmail");
  const param = {
    email,
    templateId,
    emailSubject,
    emailBody,
    emailReplyTo,
  };
  const res = await callSendEmail(param);
  if (res) {
    console.log("Success");
    return true;
  } else {
    console.log("Fail");
    return false;
  }
};

export const sendTemplateIdEmail = ({
  email = false,
  emailSubject = false,
  sendGridId = false,
}) => {
  if (!email || !sendGridId || !emailSubject) {
    return false;
  }
  console.log("sending...");
  const templateId = sendGridId;
  const callSendEmail = fire
    .functions("asia-east2")
    .httpsCallable("sendGenericEmail");
  const param = {
    email,
    templateId,
    emailSubject,
    emailBody: "",
  };
  callSendEmail(param).then((res) => {
    if (res) {
      console.log("successfully sent " + email);
      return true;
    } else {
      console.log("failed to send " + email);
      return false;
    }
  });
  return false;
};

export const sendFestivalReminderEmail = ({
  email = false,
  eventlist = false,
}) => {
  console.log({ email });
  console.log({ eventlist });
  if (!email || !eventlist) {
    return false;
  }
  let list = "";
  for (let i = 0; i < eventlist.length; i++) {
    list += `${eventlist[i]}<br/>`;
  }

  const templateId = "d-ff94f39dc0084f0ebbeb673f00732c68";
  const callSendEmail = fire
    .functions("asia-east2")
    .httpsCallable("sendGenericEmail");
  const emailSubject = "Reminder: Catholic200SG Festival Events";
  const emailBody = `
  <br><br>
  Peace be with you!
  <br><br><br>
  Thank you for being part of the Catholic200SG celebrations from 4 to 11 Dec 2021 in Mission District.
  <br><br>
  <b>Booked Events</b>
  <br><br>
  You have booked the following events* (as of 30 Nov 2021):
  <br>
  ${list}
  *The events listed here do not include walk-in events. 
  <br><br>
  For booked events, you should register at least 15 minutes before the Praise & Worship,
  films or tours start, and 5 minutes ahead for all other events and activities (e.g. talks) to
  assure yourself of a place. Otherwise, your seat will be released to those waiting.
  <br><br> 
  If you cannot attend due to unforeseen circumstances, you may go to “myBookings“ (in the “myfestival” tab) in your mycatholic.sg/festival account to cancel your booking. 
  Please cancel as soon as you know you are unable to attend so that others may be able to
  participate.
  <br><br> 
  Please note that for booked events, we allow for walk-ins on a first-come-first-in basis due to the following:
  <br>
  - No-shows (within 5 or 15 minutes as described above)
  <br> 
  - Increased capacity in some of the rooms due to the latest SMM set out in the Transition Phase.
  <br><br>
  <b>Entry to Festival</b>
  <br> <br> 
  In order to attend the Festival, you need to:
  <br>
  - Have been fully vaccinated or have a negative Pre-Event Test or be a child under 12 years old (and not require vaccination)
  <br> 
  - Have a Festival Pass (either wear a Catholic200SG T-shirt or a lanyard with a Festival Ticket, unless you are a clergy or a religious)
  <br><br>
  Visit <a href='https://catholic200.sg/product-category/souvenirs/'>https://catholic200.sg/product-category/souvenirs/</a> to get your Catholic200SG T-shirt or you can buy the Festival Pass onsite.
  <br><br>
  Check out the Catholic200SG Festival FAQs for more information.  
  <br><br><br> 
  God bless,
  <br><br>
  Catholic200SG Committee
  <br>
  <img width="161" height="43" src='https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fcatholic200sg%2Fcatholic200sg-sign.png?alt=media&token=0d845959-0965-41e6-8679-032b37aae055' alt='Catholic200SG'/>
  `;
  const param = {
    email,
    templateId,
    emailSubject,
    emailBody,
  };
  console.log(param);
  callSendEmail(param).then((res) => {
    if (res) {
      return true;
    } else {
      return false;
    }
  });
};

export const sendBCCTemplateIdEmail = async ({
  email = false,
  emailSubject = false,
  sendGridId = false,
}) => {
  if (!email || !sendGridId || !emailSubject) {
    return false;
  }
  console.log("sending...");
  const templateId = sendGridId;
  const callSendEmail = fire
    .functions("asia-east2")
    .httpsCallable("sendBCCEmailTemplate");
  const param = {
    email,
    templateId,
    templateData: {
      subject: emailSubject,
      receiver: "MyCatholicSG",
    },
  };
  await callSendEmail(param).then((res) => {
    if (res) {
      console.log("successfully sent " + email);
      return true;
    } else {
      console.log("failed to send " + email);
      return false;
    }
  });
};
