import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

export default function ForbiddenRoute() {
  return (
    <>
      <h4 className="text-danger">Uh-oh.</h4>
      <h5>Your status may have just been updated!</h5>
      <hr className="w-100" />
      <h5>Please return to the menu.</h5>
      <div className="row w-100">
        <Link to="/catch" className="col">
          <Button variant="secondary" size="lg" className="w-100">
            Return to Menu
          </Button>
        </Link>
      </div>
    </>
  );
}
