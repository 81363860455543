import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncHopeWallContainer({ ...rest }) {
  const AsyncLoad = loadable(() => import("../v2/HopeWallContainer"));
  console.log("Loading", AsyncHopeWallContainer.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncHopeWallContainer;
