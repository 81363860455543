export const registrationCollectionName = "catch/default/catchregistrations";
export const transfersCollectionName = "catch/default/catchparishtransfers";
export const externalTransfersCollectionName =
  "catch/default/catchexternaltransfers";
export const guardianCodesCollectionName = "guardiancodes";
export const parishConfigCollectionName = "settings/catchsettings/config";
export const catchConfirmationFormsCollectionName =
  "catch/default/catchconfirmationforms";
export const catchContactUpdatesCollectionName =
  "catch/default/catchcontactupdates";
