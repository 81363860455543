import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";
import "react-calendar/dist/Calendar.css";
import "../../common/stylesheets/stepper.css";
import "../css/massIntentionStyle.css";

function AsyncMassIntentionContainer({ ...rest }) {
  const AsyncLoad = loadable(() => import("../MassIntentionContainer"));
  console.log("Loading", AsyncMassIntentionContainer.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncMassIntentionContainer;
