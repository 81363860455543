import fire from "../../../services/fire";
import { getSuperAdminCode, getArchdioceseCode } from "../../../utils/utils";

export async function getAllAnnouncementsByParish(parish) {
  const db = fire.firestore();
  const superadmincode = getSuperAdminCode();
  const archdiocesecode = getArchdioceseCode();

  const announcement = await db
    .collection("announcements")
    .orderBy("created", "desc")
    .get();

  var list = [];
  announcement.forEach((doc) => {
    let data = doc.data();
    if (
      data.parish === superadmincode ||
      data.parish === archdiocesecode ||
      data.parish === parish
    ) {
      list.push(doc.data());
    }
  });

  return list;
}

export async function getAllClassifiedAnnouncementsByParish(parish) {
  const db = fire.firestore();
  const superadmincode = getSuperAdminCode();
  const archdiocesecode = getArchdioceseCode();

  const announcement = await db
    .collection("announcements")
    .orderBy("created", "desc")
    .get();

  var list = [];
  announcement.forEach((doc) => {
    list.push(doc.data());
  });
  //parse super admin announcement
  const superadminlist = list.filter((item) => item.parish === superadmincode);
  //parse archdiocese announcement
  const archlist = list.filter((item) => item.parish === archdiocesecode);
  //parse parish announcement
  const parishlist = list.filter((item) => item.parish === parish);
  list = [...parishlist, ...superadminlist, ...archlist];
  return list;
}

export async function getAnnouncementByParish(parish, announcementid) {
  const db = fire.firestore();

  return await db
    .collection("announcements")
    .where("parish", "==", parish)
    .where("id", "==", parseInt(announcementid))
    .get();
}

export async function getAnnouncementById(announcementid) {
  const db = fire.firestore();

  return await db
    .collection("announcements")
    .where("id", "==", announcementid)
    .get();
}
