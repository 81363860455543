import React, { useState } from "react";
import { Accordion, Button, Card, Modal } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";

function WhyNeeded() {
  const [showmodal, setShowModal] = useState(false);

  return (
    <>
      <span
        className="pointer ml-1 mediumfontsize text-primary"
        onClick={() => setShowModal(true)}
      >
        <span>Why is this needed?</span>
      </span>
      <Modal
        show={showmodal}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="px-4 pt-4 pb-3">
          <Accordion>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="mediumfontsize pointer font-weight-bold bg-white"
              >
                Why is my Unique Identifier (NRIC/ FIN/ Passport Number)
                required for registration?
                <div className="float-right">
                  <AddIcon className="text-danger" />
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="mediumfontsize">
                  Your Unique Identifier ensures that your account remains
                  exclusive to you. Through your unique myCatholicSG account,
                  you will be able to access Catholic services in Singapore
                  across your lifetime, where data will be associated and stored
                  securely in this account. This unique identifier also allows
                  us to verify the identity of those accessing our Church
                  services, preventing impersonations, fraud, and scams, thereby
                  ensuring a safe and secure environment for everyone.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="mediumfontsize pointer font-weight-bold bg-white"
              >
                What happens to my Unique Identifier (NRIC/ FIN/ Passport
                Number) during registration?
                <div className="float-right">
                  <AddIcon className="text-danger" />
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body className="mediumfontsize">
                  To protect your data, your Unique Identifier is immediately
                  converted into an irreversible and unreadable format via
                  cryptographic hash. Only this converted format, along with the
                  last 2 characters of your Unique Identifier, are stored on
                  myCatholicSG and accessed by our services.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <div className="mt-3 text-right">
            <Button variant="link" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WhyNeeded;
