import React, { useState } from "react";
import Header from "../../common/header";
import ReloadModal from "../../common/reloadModal";
import { Button } from "react-bootstrap";
import { userLogout } from "../../../services/user";
import SpinnerText from "../../common/spinnerwithText";
import fire from "../../../services/fire";
import ResetSent from "./resetSent";
import { logEvent } from "../../../services/log";

function ResetPassword({ user }) {
  const [updating, setUpdating] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleClick = async () => {
    console.log(user);
    const email = user.email;
    try {
      //send reset
      setUpdating(true);
      console.log(email);
      const auth = fire.auth();
      await auth.sendPasswordResetEmail(email);
      logEvent("send_force_pass_reset_success", {
        email: email,
      });
      setEmailSent(true);
    } catch (error) {
      console.log(error);
      logEvent("send_force_pass_reset_fail", {
        email: email,
        message: error.message,
      });
    }
    setUpdating(false);
    setEmailSent(true);
  };

  const InitScreen = () => {
    return (
      <div>
        <h3 className="mb-4">Password Change Required</h3>
        <p className="">
          To enhance data protection and to strengthen your myCatholicSG
          account, a <b>stronger password</b> is required.
        </p>
        <p className="">Please click the button below to proceed.</p>
        {!updating ? (
          <Button
            variant="primary"
            className="btn btn-block btn-lg my-5"
            onClick={handleClick}
          >
            Reset My Password
          </Button>
        ) : (
          <Button
            disabled
            variant="primary"
            className="btn btn-block btn-lg my-4"
          >
            <SpinnerText text="Please wait..." />
          </Button>
        )}
      </div>
    );
  };

  return (
    <div>
      <Header user={user} smallpic={user.photoURL} hide="profile" />
      <ReloadModal />
      <div className="pt-4 mt-4 row justify-content-center mx-auto">
        <main className="container">
          <div className="col-lg-6 px-0 mx-auto">
            <div className="card shadow p-4">
              {emailSent === false ? <InitScreen /> : <ResetSent />}
              <Button
                variant="link"
                className="btn-block btn-lg mt-5 mb-0"
                onClick={async () => {
                  await userLogout();
                  window.location.reload();
                }}
              >
                <span className="">Logout</span>
              </Button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ResetPassword;
