import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncParishionerDashboard({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/parishionerDashboard"));
  console.log("Loading", AsyncParishionerDashboard.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncParishionerDashboard;
