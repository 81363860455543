import React, { useEffect, useContext, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import _ from "lodash";

import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";

import { RegistrationStatus, RegistrationDatum } from "../../hooks/hooks";
import {
  useIsMounted,
  useRegistration,
  QueriedAdminContext,
  TableFiltersContext,
} from "../hooks/hooks";

import {
  isSuperAdminRole,
  isCATCHAdminRole,
  isCATCHParishAdminRole,
} from "../../../../roles/service/roles";
import {
  registrationCollectionName,
  parishConfigCollectionName,
  catchConfirmationFormsCollectionName,
  transfersCollectionName,
} from "../../services/collections";
import { listProps, RegistrationAction } from "../services/registrationTable";
import { downloadCert } from "../services/downloadCert";
import {
  guardianRegistrationStatusDictionary,
  registrationStatusNameToStatus,
} from "../../services/registrationStatus";

import { parishes } from "../../services/parishes";

import { sendGenericHTMLEmail } from "../../../../email/emailTemplateHelpers";
import AppGlobalContext from "../../../../../AppGlobalContext";

import fire from "../../../../../services/fire";
import AbstractList from "../components/AbstractList";
import TransferClassModal from "../components/TransferClassModal";

import RegistrationDetails from "../components/RegistrationDetails";

const getCatchConfirmations = fire
  .functions("asia-east2")
  .httpsCallable("getCatchConfirmations");

async function autoAssignClass(selectedItems) {
  const db = fire.firestore();
  const parishConfigCollection = db.collection(parishConfigCollectionName);
  const registrationCollection = db.collection(registrationCollectionName);

  // Group by timeslot and sort
  const timeslotRegistrations = selectedItems.reduce((group, item) => {
    const { timeslot, selectedParishId, programmeType } = item;
    group[timeslot] = group[timeslot] ?? {};

    if (_.isEmpty(group[timeslot])) {
      group[timeslot] = {
        items: [item],
        selectedParishId,
        selectedProgrammeId: programmeType.id,
      };
    } else {
      group[timeslot].items.push(item);
    }
    _.sortBy(group[timeslot].items, (data) => {
      return data.submittedAt;
    });
    return group;
  }, {});

  for (const [key, selectedData] of Object.entries(timeslotRegistrations)) {
    let selectedProgramme,
      selectedProgrammeTimeslot,
      minCount = 0,
      minClassIndex = 0;

    const result = await parishConfigCollection
      .where("id", "==", selectedData.selectedParishId)
      .get();

    selectedProgramme = result.docs[0]
      ?.data()
      .programmes.find(({ id }) => id === selectedData.selectedProgrammeId);

    try {
      selectedProgrammeTimeslot = selectedProgramme.timeslots.find(
        ({ name }) => name === key
      );

      for await (const [
        index,
        data,
      ] of selectedProgrammeTimeslot.classes.entries()) {
        registrationCollection
          .where("assignedClass", "==", data)
          // eslint-disable-next-line no-loop-func
          .onSnapshot((snapshot) => {
            if (index === 0) {
              minCount = snapshot.docs.length;
              minClassIndex = 0;
            } else {
              minCount =
                snapshot.docs.length < minCount
                  ? snapshot.docs.length
                  : minCount;
              minClassIndex =
                snapshot.docs.length < minCount ? index : minClassIndex;
            }
          });
      }

      let classIndex = minClassIndex;

      for await (const d of selectedData.items) {
        let assigned = false;
        for (
          let i = classIndex;
          i < selectedProgrammeTimeslot.classes.length;
          i++
        ) {
          const classResult = await registrationCollection
            .where(
              "assignedClass",
              "==",
              selectedProgrammeTimeslot.classes[classIndex]
            )
            .get();

          if (
            classResult.docs.length < selectedProgramme.classCapacity &&
            d.assignedClass === ""
          ) {
            d.assignedClass = selectedProgrammeTimeslot.classes[classIndex];
            // d.status = 0;

            const registrationDoc = registrationCollection.doc(d.id);
            registrationDoc.update(d);
            assigned = true;
            classIndex = classIndex + 1;

            if (classIndex === selectedProgrammeTimeslot.classes.length) {
              classIndex = 0;
            }
            break;
          }
        }

        // if (!assigned) {
        //   throw new Error(
        //     `Classes are full or registrations have already been assigned to a class.`
        //   );
        // }
      }
    } catch (err) {
      // console.debug(selectedProgramme);
    }

    // setTimeslotRegistrations(timeslotRegistrations);
    // setIsAssigningClass(true);
  }
}

export default function RegistrationList() {
  const queriedAdminContext = useContext(QueriedAdminContext);
  const registrationState = useRegistration();
  const isMounted = useIsMounted();
  const tableFiltersState = useContext(TableFiltersContext);

  const { user } = useContext(AppGlobalContext);

  const isSuperAdmin = isSuperAdminRole(user);
  const isAdminCATCH = isCATCHAdminRole(user);
  const isParishAdmin = isCATCHParishAdminRole(user);

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);
  const confirmationCollection = db.collection(
    catchConfirmationFormsCollectionName
  );
  const transfersCollection = db.collection(transfersCollectionName);

  /** @type {{name: RegistrationStatus, value: number}[]} */
  const radios = [
    { name: "pending", value: 1 },
    { name: "rejected", value: 2 },
    { name: "accepted", value: 0 },
  ];

  /** @type {RegistrationStatus} */
  const initialStatus = radios[0].value;

  /** @type {RegistrationDatum[]} */
  const initialSelectedRows = [];
  const selectedRowsRef = useRef(initialSelectedRows);

  /**
   * @param {RegistrationAction} action
   */
  async function handleAction(action) {
    const selectedCount = selectedRowsRef.current.length;

    if (selectedCount === 0) {
      alert("Please select some rows first!");
      return Promise.resolve();
    }

    /** @type {RegistrationStatus} */
    let newStatus;

    const batch = db.batch();
    let updatedCount = 0;

    // eslint-disable-next-line default-case
    switch (action) {
      case "moveTo":
        const selectedProgramme = [];

        for (const row of selectedRowsRef.current) {
          if (!selectedProgramme || selectedProgramme.length === 0) {
            selectedProgramme.push(row.programmeType);
          } else if (
            !selectedProgramme.some((obj) => obj.id === row.programmeType.id)
          ) {
            selectedProgramme.push(row.programmeType);
          }
        }

        if (selectedProgramme.length > 1) {
          alert(
            `Unable to transfer class. Please select registrations with the same programme.`
          );
        } else {
          setTransferModalIsOpen(true);
        }
        break;
      case "accept":
      case "reject":
      case "unassign":
        newStatus = action === "unassign" ? "pending" : `${action}ed`;

        let registrationUpdate = {};
        let transferUpdate = {};

        for (const row of selectedRowsRef.current) {
          if (
            action === "reject" ||
            !registrationState.data.some(
              ({ submittedBy, status }) =>
                status === newStatus && submittedBy === row.submittedBy
            )
          ) {
            registrationUpdate = {
              ...registrationUpdate,
              status: registrationStatusNameToStatus(newStatus),
            };
            transferUpdate = {
              ...transferUpdate,
              status: newStatus === "accepted" ? newStatus : `${newStatus}In`,
            };

            updatedCount++;
          }

          if (action === "accept" || action === "reject") {
            registrationUpdate = {
              ...registrationUpdate,
              processedAt: new Date(),
              processedBy: user.uid,
              processedByName: user.displayName,
            };
            transferUpdate = {
              ...transferUpdate,
              processedAt: new Date(),
              processedBy: user.uid,
              processedByName: user.displayName,
            };
          }

          if (action === "accept") {
            const arrayOfContactEmails = [];
            let selectedContacts = "";

            if (row.mainContact) {
              if (row.mainContact.name !== "") {
                selectedContacts = selectedContacts.concat(
                  row.mainContact.name
                );
                arrayOfContactEmails.push(row.mainContact.email);
              }
            }

            if (row.secondaryContact) {
              if (
                row.secondaryContact.exists &&
                !row.secondaryContact.emergencies
              ) {
                selectedContacts = selectedContacts.concat(
                  ` & ${row.secondaryContact.name}`
                );
                arrayOfContactEmails.push(row.secondaryContact.email);
              }
            }

            if (selectedContacts === "") {
              selectedContacts = "—";
            }

            const selectedParish = parishes.find(
              ({ parishId }) => parishId === +row.selectedParishId
            );

            let parishName = "—";
            let coordinatorEmail = "—";

            if (selectedParish) {
              parishName = selectedParish.parish;
              coordinatorEmail = selectedParish.coordinatorEmail;
            }

            const emailHTML = `Dear ${selectedContacts},
            <br/><br/>
            We are glad to inform you that your registration for ${
              row.programmeName
            } in ${parishName} has been successfully accepted, and your child will be starting ${
              row.sex === "Female" ? "her" : "his"
            } faith journey in ${
              selectedParish.registrationYear
            }. Please keep an eye out for a separate message from the Catechetical Ministry either via email or chat, whereby they will provide you with additional important details. For any clarifications or enquiries, please contact ${coordinatorEmail}.
            <br/><br/>
            God Bless,
            <br/>
            myCatholicSG Admin`;

            await sendGenericHTMLEmail({
              email: [...new Set(arrayOfContactEmails)],
              emailSubject: "Catechetical Registration Accepted",
              emailBody: emailHTML,
            });
          }

          if (action === "unassign") {
            registrationUpdate = { ...registrationUpdate, assignedClass: "" };
          }

          // console.log(row);

          if (Object.keys(registrationUpdate).length > 0)
            batch.update(
              registrationCollection.doc(row.id),
              registrationUpdate
            );
          if (Object.keys(transferUpdate).length > 0 && row.transfer?.id)
            batch.update(
              transfersCollection.doc(row.transfer?.id),
              transferUpdate
            );
        }

        if (updatedCount === 0) {
          alert(
            `There are ${newStatus} registrations in conflict with your selected registrations.`
          );
        } else {
          await batch.commit();
          for (const row of selectedRowsRef.current) {
            const rowToUpdate = registrationState.data.find(
              (obj) => obj.id === row.id
            );
            if (rowToUpdate) {
              rowToUpdate.status = registrationStatusNameToStatus(newStatus);
            }

            queriedAdminContext.editData(registrationState);
          }
          alert(
            updatedCount === selectedCount
              ? "Update successful!"
              : `Update partially successful!\nThere are ${newStatus} registrations in conflict with some of your selected registrations.`
          );
        }

        break;
      case "waive":
        for (const row of selectedRowsRef.current) {
          const parish = parishConfig.find(
            (cfg) => cfg.id === row.selectedParishId
          );
          const programme = parish.programmes.find(
            (p) => p.id === row.programmeType
          );
          const payment = row.payments.find(
            ({ year, status }) =>
              year === programme.catecheticalYear &&
              status === "waiverRequested"
          );

          if (!!payment) {
            payment.status = "waiverApproved";
            batch.update(registrationCollection.doc(row.id), {
              payments: row.payments,
            });
            updatedCount++;
          }
        }

        if (updatedCount === 0) {
          alert(`The selected registrations are not requesting for waivers.`);
        } else {
          await batch.commit();
          alert(
            updatedCount === selectedCount
              ? "Waive successful!"
              : `Waive partially successful!\nThere are some registrations not requesting for waivers.`
          );
        }
        break;
      case "rejectwaiver":
        for (const row of selectedRowsRef.current) {
          const parish = parishConfig.find(
            (cfg) => cfg.id === row.selectedParishId
          );
          const programme = parish.programmes.find(
            (p) => p.id === row.programmeType
          );
          const payment = row.payments.find(
            ({ year, status }) =>
              year === programme.catecheticalYear &&
              status === "waiverRequested"
          );

          if (!!payment) {
            payment.status = "waiverRejected";
            batch.update(registrationCollection.doc(row.id), {
              payments: row.payments,
            });
            updatedCount++;
          }
        }

        if (updatedCount === 0) {
          alert(`The selected registrations are not requesting for waivers.`);
        } else {
          await batch.commit();
          alert(
            updatedCount === selectedCount
              ? "Waivers rejected."
              : `Waiver rejection partially successful.\nThere are some registrations not requesting for waivers.`
          );
        }
        break;
      case "email":
        let emails = "mailto:";
        if (selectedRowsRef.current.length === 1) {
          emails += selectedRowsRef.current[0].mainContact.email;

          if (
            selectedRowsRef.current[0].secondaryContact.exists &&
            selectedRowsRef.current[0].secondaryContact.emergencies === false
          ) {
            emails += `;${selectedRowsRef.current[0].secondaryContact.email}`;
          }
        } else {
          emails += "?bcc=";
          selectedRowsRef.current.forEach((row, index) => {
            emails += row.mainContact.email;

            if (
              row.secondaryContact.exists &&
              row.secondaryContact.emergencies === false
            ) {
              emails += `;${row.secondaryContact.email}`;
            }

            if (index < selectedRowsRef.current.length - 1) {
              emails += ";";
            }
          });
        }

        window.location.href = emails;
        break;
    }
    return Promise.resolve();
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [transferModalIsOpen, setTransferModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedNotes, setSelectedNotes] = useState([]);

  const [
    isDownloadingGodfatherBaptismCert,
    setIsDownloadingGodfatherBaptismCert,
  ] = useState(false);
  const [
    isDownloadingGodfatherConfirmationCert,
    setIsDownloadingGodfatherConfirmationCert,
  ] = useState(false);
  const [
    isDownloadingGodmotherBaptismCert,
    setIsDownloadingGodmotherBaptismCert,
  ] = useState(false);
  const [
    isDownloadingGodmotherConfirmationCert,
    setIsDownloadingGodmotherConfirmationCert,
  ] = useState(false);

  const isDownloading = {
    godfatherBaptismCert: isDownloadingGodfatherBaptismCert,
    godfatherConfirmationCert: isDownloadingGodfatherConfirmationCert,
    godmotherBaptismCert: isDownloadingGodmotherBaptismCert,
    godmotherConfirmationCert: isDownloadingGodmotherConfirmationCert,
  };
  const setIsDownloading = {
    godfatherBaptismCert: setIsDownloadingGodfatherBaptismCert,
    godfatherConfirmationCert: setIsDownloadingGodfatherConfirmationCert,
    godmotherBaptismCert: setIsDownloadingGodmotherBaptismCert,
    godmotherConfirmationCert: setIsDownloadingGodmotherConfirmationCert,
  };

  const openModal = async (item) => {
    if (isParishAdmin) {
      const { data: { ok, data: { confirmationQuery = [] } = {} } = {} } =
        await getCatchConfirmations({
          uid: user.uid,
          parish: user.parish,
          childId: item.child.uid,
        });

      if (ok) {
        item.confirmationDetails = confirmationQuery[0];
      } else {
        return;
      }
    } else if (isAdminCATCH || isSuperAdmin) {
      const selectedConfirmation = await confirmationCollection
        .where("child.uid", "==", item.child.uid)
        .get();

      if (!selectedConfirmation.empty) {
        item.confirmationDetails = selectedConfirmation.docs[0].data();
      }
    } else {
      return;
    }

    setModalIsOpen(true);
    setSelectedItem(item);

    if (item.notes) {
      setSelectedNotes(_.cloneDeep(item.notes));
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setTransferModalIsOpen(false);
    setSelectedItem(null);
    setSelectedNotes([]);
  };

  const [parishConfig, setParishConfig] = useState(undefined);

  useEffect(() => {
    db.collection(parishConfigCollectionName)
      .get()
      .then((docs) => {
        const configs = [];

        docs.forEach((doc) => configs.push(doc.data()));
        setParishConfig(configs);
      });
  }, []);

  const dataProps = registrationState.data.map((d) => ({
    ...d,
    catecheticalYear:
      parishConfig === undefined
        ? undefined
        : parishConfig
            .find((cfg) => cfg.id === d.selectedParishId)
            ?.programmes?.find((pr) => pr.id === d.programmeType)
            ?.catecheticalYear,
  }));

  function transferClass(selectedItems, selectedClass) {
    for (const row of selectedItems) {
      row.assignedClass = selectedClass.split("---")[0];
      row.timeslot = selectedClass.split("---")[1];

      const registrationDoc = registrationCollection.doc(row.id);

      registrationDoc.update(row);
    }

    closeModal();
  }

  function handleStatus(status) {
    const selectedStatus = radios.filter((radio) => radio.value === status);

    if (isMounted() && selectedStatus.length > 0) {
      tableFiltersState.editData({
        data: {
          ...tableFiltersState,
          registrationFilterState: {
            ...tableFiltersState.registrationFilterState,
            status: selectedStatus[0].name,
          },
        },
      });
    }
  }

  return (
    <>
      <AbstractList
        {...listProps}
        id="registration-table"
        statusDictionary={guardianRegistrationStatusDictionary}
        radios={radios}
        defaultStatus={initialStatus}
        rawDataProps={dataProps}
        selectedRowsRef={selectedRowsRef}
        handleAction={handleAction}
        handleAssignClass={autoAssignClass}
        handleSelectedStatus={handleStatus}
      />

      {registrationState.exists
        ? registrationState.data.map((item) => {
            let programmeType = item.programmeType;

            if (typeof programmeType === "object") {
              programmeType = programmeType.id;
            }

            const name = `${item.id || ""}-${programmeType || ""}-${
              item.timeslot || ""
            }`;

            return (
              <button
                key={name}
                id={`button-${name}`}
                style={{ display: "none" }}
                disabled={modalIsOpen}
                onClick={() => {
                  if (!modalIsOpen) {
                    // console.log(item);
                    openModal(item);
                  }
                }}
              />
            );
          })
        : null}

      <Modal
        size="lg"
        show={modalIsOpen}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable="true"
      >
        <Modal.Body>
          <div style={{ padding: "30px" }}>
            {selectedItem && selectedItem.confirmationDetails ? (
              <>
                <div className="mb-sm-3">
                  <div className="mb-sm-3">
                    <h5>Confirmation Details</h5>
                  </div>
                  <strong>Receiving Confirmation?</strong>
                  <div className="mb-sm-3">
                    {selectedItem.confirmationDetails &&
                    selectedItem.confirmationDetails.isReceiving
                      ? "Yes"
                      : "No"}
                  </div>
                  {selectedItem.confirmationDetails.isReceiving === true ? (
                    <>
                      <strong>Confirmation Name</strong>
                      <div className="mb-sm-3">
                        {selectedItem.confirmationDetails &&
                        selectedItem.confirmationDetails.confirmationName
                          ? selectedItem.confirmationDetails.confirmationName
                          : "-"}
                      </div>
                      <strong>
                        Name to be printed on the Confirmation Certificate
                      </strong>
                      <div className="mb-sm-3">
                        {selectedItem.confirmationDetails &&
                        selectedItem.confirmationDetails.printedConfirmationName
                          ? selectedItem.confirmationDetails
                              .printedConfirmationName
                          : "-"}
                      </div>
                      <strong>Godparent Details</strong>
                      <br />
                      <strong>Godfather Name</strong>
                      <div>
                        {selectedItem.confirmationDetails &&
                        selectedItem.confirmationDetails.godfather
                          ? selectedItem.confirmationDetails.godfather.name
                          : "-"}
                      </div>
                      <b>Baptism Certificate</b>
                      <br />
                      <Button
                        disabled={isDownloading.godfatherBaptismCert}
                        variant="secondary"
                        className="text-wrap text-dark"
                        style={{
                          backgroundColor: "#f3f3f3",
                          borderColor: "#f3f3f3",
                        }}
                        onClick={async () => {
                          setIsDownloading.godfatherBaptismCert(true);
                          await downloadCert(
                            selectedItem.confirmationDetails?.godfather?.baptism
                              ?.cert,
                            user
                          );
                          setIsDownloading.godfatherBaptismCert(false);
                        }}
                      >
                        {isDownloading.godfatherBaptismCert && (
                          <div
                            className="spinner-border mr-1"
                            role="status"
                            style={{ width: "1.5rem", height: "1.5rem" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        <AttachFileOutlinedIcon
                          className="mr-1"
                          style={{ color: "#7c7c7c" }}
                        />
                        {selectedItem.confirmationDetails?.godfather?.baptism
                          ?.cert.path
                          ? selectedItem.confirmationDetails?.godfather?.baptism
                              ?.cert.name
                          : "—"}
                      </Button>
                      <br />
                      <b>Confirmation Certificate</b>
                      <br />
                      <Button
                        disabled={isDownloading.godfatherConfirmationCert}
                        variant="secondary"
                        className="text-wrap text-dark"
                        style={{
                          backgroundColor: "#f3f3f3",
                          borderColor: "#f3f3f3",
                        }}
                        onClick={async () => {
                          setIsDownloading.godfatherConfirmationCert(true);
                          await downloadCert(
                            selectedItem.confirmationDetails?.godfather
                              ?.confirmation?.cert,
                            user
                          );
                          setIsDownloading.godfatherConfirmationCert(false);
                        }}
                      >
                        {isDownloading.godfatherConfirmationCert && (
                          <div
                            className="spinner-border mr-1"
                            role="status"
                            style={{ width: "1.5rem", height: "1.5rem" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        <AttachFileOutlinedIcon
                          className="mr-1"
                          style={{ color: "#7c7c7c" }}
                        />
                        {selectedItem.confirmationDetails?.godfather
                          ?.confirmation?.cert.path
                          ? selectedItem.confirmationDetails?.godfather
                              ?.confirmation?.cert.name
                          : "—"}
                      </Button>
                      <br />
                      <br />
                      <strong>Godmother Name</strong>
                      <div>
                        {selectedItem.confirmationDetails &&
                        selectedItem.confirmationDetails.godmother
                          ? selectedItem.confirmationDetails.godmother.name
                          : "-"}
                      </div>
                      <b>Baptism Certificate</b>
                      <br />
                      <Button
                        disabled={isDownloading.godmotherBaptismCert}
                        variant="secondary"
                        className="text-wrap text-dark"
                        style={{
                          backgroundColor: "#f3f3f3",
                          borderColor: "#f3f3f3",
                        }}
                        onClick={async () => {
                          setIsDownloading.godmotherBaptismCert(true);
                          await downloadCert(
                            selectedItem.confirmationDetails?.godmother?.baptism
                              ?.cert,
                            user
                          );
                          setIsDownloading.godmotherBaptismCert(false);
                        }}
                      >
                        {isDownloading.godmotherBaptismCert && (
                          <div
                            className="spinner-border mr-1"
                            role="status"
                            style={{ width: "1.5rem", height: "1.5rem" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        <AttachFileOutlinedIcon
                          className="mr-1"
                          style={{ color: "#7c7c7c" }}
                        />
                        {selectedItem.confirmationDetails?.godmother?.baptism
                          ?.cert.path
                          ? selectedItem.confirmationDetails?.godmother?.baptism
                              ?.cert.name
                          : "—"}
                      </Button>
                      <br />
                      <b>Confirmation Certificate</b>
                      <br />
                      <Button
                        disabled={isDownloading.godmotherConfirmationCert}
                        variant="secondary"
                        className="text-wrap text-dark"
                        style={{
                          backgroundColor: "#f3f3f3",
                          borderColor: "#f3f3f3",
                        }}
                        onClick={async () => {
                          setIsDownloading.godmotherConfirmationCert(true);
                          await downloadCert(
                            selectedItem.confirmationDetails?.godmother
                              ?.confirmation?.cert,
                            user
                          );
                          setIsDownloading.godmotherConfirmationCert(false);
                        }}
                      >
                        {isDownloading.godmotherConfirmationCert && (
                          <div
                            className="spinner-border mr-1"
                            role="status"
                            style={{ width: "1.5rem", height: "1.5rem" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        <AttachFileOutlinedIcon
                          className="mr-1"
                          style={{ color: "#7c7c7c" }}
                        />
                        {selectedItem.confirmationDetails?.godmother
                          ?.confirmation?.cert.path
                          ? selectedItem.confirmationDetails?.godmother
                              ?.confirmation?.cert.name
                          : "—"}
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <hr
                  style={{
                    borderColor: "black",
                    marginLeft: "-30px",
                    marginRight: "-30px",
                  }}
                />
                <br />
              </>
            ) : (
              <></>
            )}

            <RegistrationDetails
              {...{
                user,
                registrationCollection,
                selectedItem,
                setSelectedItem,
                selectedNotes,
                setSelectedNotes,
              }}
            />
          </div>
        </Modal.Body>
      </Modal>

      {transferModalIsOpen && (
        <TransferClassModal
          transferModalIsOpen={transferModalIsOpen}
          closeModal={closeModal}
          selectedItems={selectedRowsRef.current}
          transferClass={transferClass}
        />
      )}
    </>
  );
}
