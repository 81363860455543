import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncMassAttendance({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/massAttendance"));
  console.log("Loading", AsyncMassAttendance.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncMassAttendance;
