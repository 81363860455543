import React, { useReducer, useState, useEffect, useCallback } from "react";
import fire from "../../../../../services/fire";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import { useHistory } from "react-router-dom";

import FaceIcon from "@material-ui/icons/Face";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

import { useForm } from "react-hook-form";
const consumeGuardianCode = fire
  .functions("asia-east2")
  .httpsCallable("consumeGuardianCode");

/**
 * @typedef AccessCodeState
 * @prop {string} accessCode
 * @prop {boolean} isProcessing
 */

/**
 * @param {AccessCodeState} state
 * @param {{type: string, payload: Partial<AccessCodeState>}} action
 * @returns {AccessCodeState}
 */
function accessCodeReducer(state, { type, payload }) {
  switch (type) {
    case "startGenerate":
      return { ...state, isProcessing: true };
    case "endGenerate":
      return { accessCode: payload.accessCode, isProcessing: false };
    case "startConsume":
      return { accessCode: payload.accessCode, isProcessing: true };
    case "endConsume":
      return { ...state, isProcessing: false };
    default:
      return { ...state };
  }
}

export default function GuardianLink({ guardianSetupPart, guardianDetails }) {
  const {
    register,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { code: "" } });

  const code = watch("code");

  /** @type {AccessCodeState} */
  const initialAccessCodeState = {
    accessCode: "",
    isProcessing: false,
  };
  const [accessCodeState, accessCodeDispatch] = useReducer(
    accessCodeReducer,
    initialAccessCodeState
  );

  const history = useHistory();
  const callBack = useCallback(
    () => history.push({ pathname: "/catch", guardianLinkSuccess: true }),
    [history]
  );

  async function handleConsume() {
    const enteredCode = getValues("code").toUpperCase();
    accessCodeDispatch({
      type: "startConsume",
      payload: { accessCode: enteredCode },
    });

    // Test entered code if valid
    const {
      data: { message, ok },
    } = await consumeGuardianCode({ code: enteredCode, guardianDetails });

    accessCodeDispatch({ type: "endConsume" });

    if (ok) {
      callBack();
    } else {
      alert(message);
    }
  }

  return (
    <Container className="p-4">
      <b className={guardianSetupPart === 1 ? "text-primary" : "text-dark"}>
        Part 2 — Link with Child Account
      </b>
      {guardianSetupPart === 1 ? (
        <>
          <br />
          <br />
          To link your Guardian Account with a Child account, please follow the
          steps below.
          <br />
          <br />
          <Row className="mx-0">
            <Col
              className="d-flex justify-content-center align-items-center rounded-circle"
              style={{ width: 50, height: 50, backgroundColor: "#e8e8e8" }}
              xs="auto"
            >
              <b>1</b>
            </Col>
            <Col className="d-flex align-items-center">
              Get your child's account
            </Col>
          </Row>
          <br />
          <Row className="mx-0">
            <Col
              className="d-flex justify-content-center align-items-center rounded-circle"
              style={{ width: 50, height: 50, backgroundColor: "#e8e8e8" }}
              xs="auto"
            >
              <b>2</b>
            </Col>
            <Col className="d-flex align-items-center">
              <span className="text-wrap">
                Go to <FaceIcon className="mx-1" /> Catechesis {"> "}
                <SupervisorAccountIcon className="mx-1" /> Guardian Setup on
                your child's account
              </span>
            </Col>
          </Row>
          <br />
          <Row className="mx-0">
            <Col
              className="d-flex justify-content-center align-items-center rounded-circle"
              style={{ width: 50, height: 50, backgroundColor: "#e8e8e8" }}
              xs="auto"
            >
              <b>3</b>
            </Col>
            <Col className="d-flex align-items-center">
              <Container className="px-0">
                Follow the steps to generate an access code
                <br />
                <span className="text-secondary" style={{ fontSize: "14px" }}>
                  Note: The access code will expire in 24 hours.
                </span>
              </Container>
            </Col>
          </Row>
          <br />
          <Row className="mx-0">
            <Col
              className="d-flex justify-content-center align-items-center rounded-circle"
              style={{ width: 50, height: 50, backgroundColor: "#e8e8e8" }}
              xs="auto"
            >
              <b>4</b>
            </Col>
            <Col className="d-flex align-items-center">
              Copy the access code from your child's account
            </Col>
          </Row>
          <br />
          <Row className="mx-0">
            <Col
              className="d-flex justify-content-center align-items-center rounded-circle"
              style={{ width: 50, height: 50, backgroundColor: "#e8e8e8" }}
              xs="auto"
            >
              <b>5</b>
            </Col>
            <Col className="d-flex align-items-center">
              Return to this screen and enter the access code generated
            </Col>
          </Row>
          <br />
          <Row className="mx-0">
            <Col style={{ width: 50 }} xs="auto"></Col>
            <Col>
              <Form onSubmit={handleSubmit(handleConsume)}>
                <Row>
                  <Col xs={6} style={{ minWidth: 200 }}>
                    <Form.Control
                      size="lg"
                      className={code !== "" ? "text-center w-100" : "w-100"}
                      style={
                        code !== ""
                          ? {
                              fontFamily: "monospace",
                              textTransform: "uppercase",
                            }
                          : {}
                      }
                      placeholder="Enter access code"
                      isInvalid={!!errors?.code}
                      {...register("code", {
                        required: true,
                        pattern: /^[A-Z0-9]{8}$/i,
                      })}
                    />
                    <Form.Text className="text-danger text-center font-weight-bold">
                      {!!errors?.code ? "Invalid format!" : ""}
                    </Form.Text>
                  </Col>
                  <Col
                    className="mt-3 mt-sm-0"
                    xs={6}
                    sm="auto"
                    style={{ minWidth: 200 }}
                  >
                    <Button
                      size="lg"
                      type="submit"
                      disabled={accessCodeState.isProcessing}
                      className="w-100"
                    >
                      {accessCodeState.isProcessing ? (
                        <Spinner animation="border" />
                      ) : (
                        <>Verify</>
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
}
