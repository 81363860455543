export const INIT_PAYPLUS_STATE = {
  userObj: {},
  isLoading: true,
  isCampaignLoading: true,
  parishCampaigns: [],
  orgCampaigns: [],
  userHasProductCampaign: false,
  selectedCampaignList: [],
  selectedCampaign: {},
  paymentConsent: false,
  step: 0,
};

export const payplusReducer = (state, action) => {
  const { type = "", payload = {} } = action;
  switch (type) {
    case "SET_USER_READY": {
      const { userObj } = payload;
      return {
        ...state,
        userObj,
        isLoading: false,
      };
    }
    case "SET_HAS_PRODUCT_CAMPAIGN": {
      return {
        ...state,
        userHasProductCampaign: true,
      };
    }
    case "SET_CAMPAIGN_READY": {
      const { parishCampaigns, orgCampaigns } = payload;
      return {
        ...state,
        isCampaignLoading: false,
        parishCampaigns,
        orgCampaigns,
      };
    }
    case "SET_SELECTED_LIST": {
      const { selectedCampaignList } = payload;
      return {
        ...state,
        step: 1,
        selectedCampaignList,
      };
    }
    case "SET_STEP_ZERO": {
      return {
        ...state,
        step: 0,
        selectedCampaignList: [],
        selectedCampaign: {},
        paymentConsent: false,
      };
    }
    case "SELECT_CAMPAIGN": {
      const { selectedCampaign } = payload;
      return {
        ...state,
        step: 2,
        selectedCampaign,
      };
    }
    case "SET_STEP_ONE": {
      return {
        ...state,
        step: 1,
        selectedCampaign: [],
        paymentConsent: false,
      };
    }
    case "SET_CONSENT": {
      const { paymentConsent } = payload;
      return {
        ...state,
        paymentConsent,
      };
    }
    case "SET_FINAL_STEP": {
      return {
        ...state,
        step: 3,
      };
    }
    default:
      return state;
  }
};
